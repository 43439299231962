import React, { FC, ReactNode } from 'react';
import cx from 'classnames';
import { RequiredIndicator } from './RequiredIndicator';
import { TooltipWrapper } from './TooltipWrapper';
import { DefinitionElementType } from '../types/generated/formDefinitionItem';
import { nl2br } from '../shared/utilities/nl2br';

interface FieldLabelProps {
    name: string;
    labelProperties:
        | JSX.IntrinsicElements['label']
        | JSX.IntrinsicElements['legend'];
    useLegendTag?: boolean;
    content?: ReactNode;
    showRequiredIndicator?: boolean;
    tooltipContent?: ReactNode;
    tooltipWrapperProperties?: JSX.IntrinsicElements['div'];
    isFormPreview: boolean;
    businessTerm?: string;
    reApplyRequiredIndicator?: boolean;
    fieldType?: any;
}

export const FieldLabel: FC<FieldLabelProps> = ({
    name,
    labelProperties = {},
    content,
    useLegendTag,
    showRequiredIndicator,
    tooltipContent,
    tooltipWrapperProperties,
    isFormPreview,
    businessTerm,
    fieldType,
    reApplyRequiredIndicator,
}) => {
    const { className } = labelProperties;
    const contentOfLabel = (
        <span className="mr-auto">
            {content}
            {!isFormPreview && (
                <>
                    <RequiredIndicator
                        showRequired={
                            showRequiredIndicator || reApplyRequiredIndicator
                        }
                    />
                    <TooltipWrapper
                        content={nl2br(tooltipContent as string)}
                        tooltipWrapperProperties={tooltipWrapperProperties}
                    />
                </>
            )}
            {isFormPreview &&
                (fieldType === DefinitionElementType.Datepicker ||
                    fieldType === DefinitionElementType.Time) && (
                    <TooltipWrapper
                        content={nl2br(tooltipContent as string)}
                        tooltipWrapperProperties={tooltipWrapperProperties}
                    />
                )}
        </span>
    );

    const renderBusinessTerm = businessTerm &&
        !businessTerm.startsWith('INTERNAL-') &&
        !businessTerm.startsWith('-') && (
            <div
                className={cx(
                    'mr-1 text-dove text-caption font-normal w-min-fit',
                    fieldType === DefinitionElementType.CpvSelector &&
                        'invisible'
                )}
                id={`${name}businessTerm`}
            >
                <p className="break-all">{businessTerm}</p>
            </div>
        );

    if (useLegendTag) {
        return (
            <legend
                {...(labelProperties as JSX.IntrinsicElements['legend'])}
                className={cx('flex items-center w-full flex-wrap', className)}
            >
                {contentOfLabel}
                {renderBusinessTerm}
            </legend>
        );
    }

    return (
        <label
            {...(labelProperties as JSX.IntrinsicElements['label'])}
            className={cx('flex items-center flex-wrap', className)}
            htmlFor={name}
        >
            {contentOfLabel}
            {renderBusinessTerm}
        </label>
    );
};
