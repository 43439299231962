import axios, { AxiosResponse } from 'axios';
import { ConfiguratorVersion } from '../../types/generated/configuratorVersion';
import useSWRImmutable from 'swr/immutable';

const fetcher = (url: string) =>
    axios
        .get(url)
        .then(
            async (res: AxiosResponse): Promise<ConfiguratorVersion[]> =>
                res.data
        );

const useFetchBaseVersions = (country?: string) => {
    const { data, error, mutate } = useSWRImmutable(
        `/configurator/base-versions/${country ?? ''}`,
        fetcher,
        { shouldRetryOnError: false }
    );

    return {
        setStateBaseVersions: mutate,
        baseVersions: data,
        isFetchingBaseVersions: !data && !error,
        isErrorFetchingBaseVersions: error,
    };
};

export default useFetchBaseVersions;
