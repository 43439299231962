/* eslint-disable no-nested-ternary */
import {
    Checkmark16,
    Column32,
    Document24,
    Erase32,
    Filter32,
    Renew32,
    Screen24,
    Shuffle24,
} from '@carbon/icons-react';
import React, { useRef, useState } from 'react';
import { TFunction } from 'react-i18next';
import {
    PanelInterface,
    useAccordionProps,
} from '@mercell/use-accordion-react-hook';
import { useExpandedState } from '@mercell/use-expanded-state-react-hook';
import cx from 'classnames';
import Button from '@mercell/button-react';
import {
    Input,
    InputContainer,
    InputLeftElement,
    InputRightElement,
} from '@mercell/input-react';
import Checkbox from '@mercell/checkbox-react';
import { DropdownMenu, DropdownMenuItem } from '@mercell/dropdown-menu-react';
import { SelectOption } from '../types/generated/formSelectorConfiguration';
import { Table } from '@tanstack/react-table';
import { DashboardEntry } from '../types/generated/dashboardResponseEntry';
import Tooltip from '@mercell/tooltip-react';

const DashboardFiltersAccordion = ({
    platformNames,
    meFormStatuses,
    countries,
    subTypes,
    portalStatuses,
    portals,
    t,
    onCountryChange,
    onPlatformChange,
    onPortalNameChange,
    onNoticeSubtypeChange,
    onPortalStatusesChange,
    onStatusesChange,
    numberOfSelectedFilters,
    clearFilter,
    onSearchMaskChange,
    filterData,
    table,
    globalFilter,
    getDashboardData,
    setIsDashboardLoading,
}: {
    platformNames?: string[];
    meFormStatuses?: any[];
    countries?: SelectOption[];
    subTypes?: SelectOption[];
    portalStatuses?: any[];
    portals?: any[];
    t: TFunction<string, unknown>;
    onCountryChange: (newData: SelectOption) => void;
    onPlatformChange: (newData: SelectOption, resetData?: boolean) => void;
    onNoticeSubtypeChange: (newData: SelectOption) => void;
    onPortalNameChange: (newData: SelectOption) => void;
    onPortalStatusesChange: (newData: SelectOption) => void;
    onStatusesChange: (newData: SelectOption) => void;
    onSearchMaskChange: (newVal: string) => void;
    numberOfSelectedFilters: number;
    clearFilter: () => void;
    filterData: any;
    table: Table<DashboardEntry>;
    globalFilter: string;
    getDashboardData: (...args: any[]) => void;
    setIsDashboardLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { expanded, onClick } = useExpandedState(false);
    const { trigger, panel } = useAccordionProps(`dashboard-filters`, expanded);
    const [showPlatform, setShowPlatform] = useState<boolean>(false);
    const [showDisplayColumns, setShowDisplayColumns] =
        useState<boolean>(false);
    const platformsButtonRef = useRef<HTMLButtonElement>(null);
    const columnsButtonRef = useRef<HTMLButtonElement>(null);

    const globalFilterChangeHandler = (value: string) => {
        onSearchMaskChange(value);
    };

    return (
        <div className="flex flex-col w-full">
            <div className="flex items-center w-full flex-wrap">
                <div className="flex items-center mr-auto">
                    <div className="flex divide-x divide-concrete  border rounded-default text-white ">
                        <Button
                            {...trigger}
                            onClick={onClick}
                            scheme="primary"
                            className="rounded-br-none rounded-tr-none focus:ring-offset-0"
                            iconSettings={{ Icon: Filter32 }}
                        >
                            {`${
                                numberOfSelectedFilters !== 0
                                    ? numberOfSelectedFilters
                                    : ''
                            } ${t('dashboard:Filters')}`}
                        </Button>

                        <div>
                            <Tooltip
                                placement="top"
                                message={t('dashboard:ClearFilter')}
                            >
                                <Button
                                    onClick={() => {
                                        clearFilter();
                                    }}
                                    scheme="primary"
                                    className="!rounded-none bg-minsk  focus:ring-offset-0"
                                    iconSettings={{ Icon: Erase32 }}
                                />
                            </Tooltip>
                        </div>

                        <div>
                            <Tooltip
                                placement="top"
                                message={t('dashboard:Refresh')}
                            >
                                <Button
                                    onClick={() => {
                                        setIsDashboardLoading(true);
                                        getDashboardData();
                                    }}
                                    scheme="primary"
                                    className="rounded-bl-none rounded-tl-none bg-minsk  focus:ring-offset-0"
                                    iconSettings={{ Icon: Renew32 }}
                                />
                            </Tooltip>
                        </div>
                    </div>

                    <Tooltip placement="top" message={t('dashboard:REPs')}>
                        <Button
                            ref={platformsButtonRef}
                            aria-haspopup="menu"
                            aria-expanded={showPlatform}
                            onClick={() =>
                                setShowPlatform((showVal) => !showVal)
                            }
                            scheme="secondary"
                            className="m-2 focus:ring-offset-0"
                        >
                            {filterData.searchFilterData?.tenderingSystem
                                .length > 1
                                ? t('dashboard:MultiPlatform')
                                : filterData.searchFilterData?.tenderingSystem
                                      .length === 1
                                ? filterData.searchFilterData?.tenderingSystem
                                : t('dashboard:SelectPlatform')}
                        </Button>
                    </Tooltip>
                </div>
                {showPlatform && (
                    <DropdownMenu
                        id="platformNamesDropdown"
                        className="p-2 break-all"
                        anchorRef={platformsButtonRef}
                        closeOnClick={false}
                        onClose={() => setShowPlatform(false)}
                    >
                        {platformNames?.map((platform) => (
                            <div className="mt-4">
                                <DropdownMenuItem
                                    className="hover:bg-transparent hover:text-minsk"
                                    onClick={() => {
                                        onPlatformChange({
                                            label: platform,
                                            value: platform,
                                        });
                                    }}
                                >
                                    <div className="flex items-center">
                                        <p>
                                            {filterData.searchFilterData?.tenderingSystem.includes(
                                                platform
                                            ) && (
                                                <Checkmark16 className="mr-4" />
                                            )}
                                        </p>
                                        <p> {platform}</p>
                                    </div>
                                </DropdownMenuItem>
                            </div>
                        ))}
                    </DropdownMenu>
                )}

                <div className="flex items-center ">
                    <Button
                        ref={columnsButtonRef}
                        aria-haspopup="menu"
                        onClick={() =>
                            setShowDisplayColumns((showVal) => !showVal)
                        }
                        aria-expanded={showDisplayColumns}
                        scheme="secondary"
                        className="rounded-default focus:ring-offset-0 mr-2"
                        iconSettings={{ Icon: Column32 }}
                    >
                        {t('dashboard:Columns')}
                    </Button>
                    {showDisplayColumns && (
                        <DropdownMenu
                            id="displayColumnsDropdown"
                            className="p-2 break-all"
                            anchorRef={columnsButtonRef}
                            onClose={() => setShowDisplayColumns(false)}
                        >
                            {table
                                .getAllLeafColumns()
                                .map((column: any, optionIdx: number) => {
                                    if (
                                        column.getCanHide() &&
                                        column.id !== 'formId' &&
                                        column.id !== 'portalNoticeId' &&
                                        column.id !==
                                            'portalNoticeIdAndVersionId'
                                    ) {
                                        return (
                                            <div className="mt-4">
                                                <DropdownMenuItem
                                                    className="hover:bg-transparent hover:text-minsk"
                                                    onClick={column.getToggleVisibilityHandler()}
                                                >
                                                    <Checkbox
                                                        className="ml-2"
                                                        onChange={column.getToggleVisibilityHandler()}
                                                        checked={column.getIsVisible()}
                                                    />
                                                    <label
                                                        htmlFor={`column-${optionIdx}`}
                                                        className="ml-3 min-w-0 flex-1 text-gray-600"
                                                    >
                                                        {
                                                            column.columnDef
                                                                .header
                                                        }
                                                    </label>
                                                </DropdownMenuItem>
                                            </div>
                                        );
                                    }
                                    return <div />;
                                })}
                        </DropdownMenu>
                    )}
                    <InputContainer className="w-[500px] rounded-default min-w-[50%] ">
                        <InputLeftElement className="bg-minsk text-white pr-2">
                            <Document24 />
                        </InputLeftElement>
                        <Input
                            placeholder={t('dashboard:SearchPlaceholder')}
                            value={globalFilter}
                            onChange={(e) =>
                                globalFilterChangeHandler(e.target.value)
                            }
                            className="bg-minsk text-white placeholder:italic placeholder:text-athens focus:placeholder:invisible"
                        />
                        <InputRightElement className="pl-4 border-l-2 border-alto " />
                    </InputContainer>
                </div>
            </div>
            <div
                {...(panel as PanelInterface<HTMLDivElement>)}
                className={cx(
                    'h-auto overflow-hidden my-0 mx-6 transition-all duration-500 text-body border-b border-base-50 border-alto scroll-shadows',
                    { 'mt-0 mx-6 mb-6 ': expanded }
                )}
            >
                <div className="flex w-full gap-x-4 px-14 text-sm sm:px-6 md:gap-x-16 lg:px-8 p-12 2xl:justify-center justify-start overflow-x-scroll shadow-sm">
                    <div className=" gap-y-10  md:gap-x-12 min-w-fit mr-6">
                        <fieldset className="max-h-[300px] overflow-y-scroll break-all">
                            <legend className="block title-medium">
                                {`${t('dashboard:Country')} (${
                                    filterData?.searchFilterData?.country.length
                                })`}
                            </legend>
                            <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                                {countries?.map(
                                    (
                                        option: SelectOption,
                                        optionIdx: number
                                    ) => (
                                        <div
                                            key={option?.value}
                                            className="flex items-center text-base sm:text-sm"
                                        >
                                            <Checkbox
                                                className="ml-2"
                                                onChange={() => {
                                                    onCountryChange(option);
                                                }}
                                                checked={filterData?.searchFilterData?.country?.includes(
                                                    option?.value
                                                )}
                                            />
                                            <label
                                                htmlFor={`country-${optionIdx}`}
                                                className="ml-3 min-w-0 flex-1 text-gray-600"
                                            >
                                                {t(option?.label ?? '')}
                                            </label>
                                        </div>
                                    )
                                )}
                            </div>
                        </fieldset>
                    </div>

                    <div className=" gap-y-10  md:gap-x-12 min-w-fit mr-6">
                        <fieldset className="max-h-[300px] overflow-y-scroll break-all">
                            <legend className="block title-medium">
                                {`${t('dashboard:PortalName')} (${
                                    filterData?.searchFilterData?.portalname
                                        .length
                                })`}
                            </legend>
                            <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                                {portals?.map(
                                    (option: any, optionIdx: number) => (
                                        <div
                                            key={option?.value}
                                            className="flex items-center text-base sm:text-sm"
                                        >
                                            <Checkbox
                                                className="ml-2"
                                                onChange={() => {
                                                    onPortalNameChange({
                                                        label: option.name,
                                                        value: option.name,
                                                    });
                                                }}
                                                checked={filterData?.searchFilterData?.portalname?.includes(
                                                    option?.name
                                                )}
                                            />
                                            <label
                                                htmlFor={`portal-${optionIdx}`}
                                                className="ml-3 min-w-0 flex-1 text-gray-600"
                                            >
                                                {t(option?.name)}
                                            </label>
                                        </div>
                                    )
                                )}
                            </div>
                        </fieldset>
                    </div>

                    <div className=" gap-y-10  md:gap-x-12 min-w-fit mr-6">
                        <fieldset className="max-h-[300px] overflow-y-scroll break-all">
                            <legend className="block title-medium">
                                {`${t('dashboard:PortalStatus')} (${
                                    filterData?.searchFilterData?.portalStatus
                                        .length
                                })`}
                            </legend>
                            <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                                {portalStatuses?.map(
                                    (option: any, optionIdx: number) => (
                                        <div
                                            key={option?.value}
                                            className="flex items-center text-base sm:text-sm"
                                        >
                                            <Checkbox
                                                className="ml-2"
                                                onChange={() => {
                                                    onPortalStatusesChange({
                                                        label: option.name,
                                                        value: option.name,
                                                    });
                                                }}
                                                checked={filterData?.searchFilterData?.portalStatus?.includes(
                                                    option?.name
                                                )}
                                            />
                                            <label
                                                htmlFor={`portal-${optionIdx}`}
                                                className="ml-3 min-w-0 flex-1 text-gray-600"
                                            >
                                                {t(option?.name)}
                                            </label>
                                        </div>
                                    )
                                )}
                            </div>
                        </fieldset>
                    </div>
                    <div className=" gap-y-10  md:gap-x-12 min-w-fit mr-6">
                        <fieldset className="max-h-[300px] overflow-y-scroll break-all">
                            <legend className="block title-medium">
                                {`${t('dashboard:MeFormsStatus')} (${
                                    filterData?.searchFilterData?.status.length
                                })`}
                            </legend>
                            <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                                {meFormStatuses?.map(
                                    (option: any, optionIdx: number) => (
                                        <div
                                            key={option?.value}
                                            className="flex items-center text-base sm:text-sm"
                                        >
                                            <Checkbox
                                                className="ml-2"
                                                onChange={() => {
                                                    onStatusesChange({
                                                        label: option.name,
                                                        value: option.value,
                                                    });
                                                }}
                                                checked={filterData?.searchFilterData?.status?.includes(
                                                    option?.value.toString()
                                                )}
                                            />
                                            <label
                                                htmlFor={`portal-${optionIdx}`}
                                                className="ml-3 min-w-0 flex-1 text-gray-600"
                                            >
                                                {t(
                                                    `list.audit-log-status:${option?.name}`
                                                )}
                                            </label>
                                        </div>
                                    )
                                )}
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className="flex w-full gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8 p-12  2xl:justify-center justify-start">
                    <div className=" gap-y-10  md:gap-x-12 w-fit">
                        <fieldset className="max-h-[300px] overflow-y-scroll break-all">
                            <legend className="block title-medium">
                                {`${t('dashboard:Subtype')} (${
                                    filterData?.searchFilterData?.subtype.length
                                })`}
                            </legend>
                            <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                                {subTypes?.map(
                                    (
                                        option: SelectOption,
                                        optionIdx: number
                                    ) => (
                                        <div
                                            key={option?.value}
                                            className="flex items-center text-base sm:text-sm"
                                        >
                                            <Checkbox
                                                className="ml-2"
                                                onChange={() => {
                                                    onNoticeSubtypeChange({
                                                        label: option.label,
                                                        value: option.value,
                                                    });
                                                }}
                                                checked={filterData?.searchFilterData?.subtype?.includes(
                                                    option?.value
                                                )}
                                            />
                                            <label
                                                htmlFor={`subType-${optionIdx}`}
                                                className="ml-3 min-w-0 flex-1 text-gray-600"
                                            >
                                                {t(option?.label ?? '')}
                                            </label>
                                        </div>
                                    )
                                )}
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardFiltersAccordion;
