import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

const fetcher = (url: string) =>
    axios.get(url).then(async (res: AxiosResponse) => {
        const { data } = res;
        return data;
    });

const useFetchPlatformNames = () => {
    const { data, error } = useSWRImmutable('/dashboard/portal-name', fetcher, {
        shouldRetryOnError: false,
    });

    return {
        portalName: data?.statuses,
        isLoadingPortalNames: !error && !data,
        isErrorFetchingPortalNames: error,
    };
};

export default useFetchPlatformNames;
