/* istanbul ignore file */
import { FormAddResult } from '../types/generated/formAddResult';
import axios, { AxiosResponse } from 'axios';
import { getFormLanguage } from '../shared/storageService/sessionService';

export const fetchNewFormPath = async (formId: string, sectionPath: string) => {
    const language = getFormLanguage();

    const sectionPathToNewElement = await axios
        .get(`form/add/${formId}/${language}/${sectionPath}`)
        .then((res: AxiosResponse<FormAddResult>) => res.data)
        .catch((error) => {
            throw error;
        });

    return sectionPathToNewElement;
};
