/* istanbul ignore file */
import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';
import { getFormLanguage } from '../../shared/storageService/sessionService';
import { FormArrayResult } from '../../types/generated/formArrayResult';
import { useEffect } from 'react';
import { useSWRConfig } from 'swr';

const fetcher = (url: string) =>
    axios.get(url).then((res: AxiosResponse<FormArrayResult>) => res.data);

const useFetchArray = (
    formId?: string,
    sectionPath?: string,
    arrayPath?: string
) => {
    const language = getFormLanguage();
    const { cache } = useSWRConfig();
    const url =
        !formId || !sectionPath || !language
            ? null
            : `array/items/${formId}/${language}/${sectionPath}${
                  arrayPath ? `/${arrayPath}` : ''
              }`;
    const { data, error, mutate } = useSWRImmutable(url, fetcher, {
        revalidateOnMount: true,
        shouldRetryOnError: false,
    });
    useEffect(
        () => () => {
            cache.delete(url);
        },
        [cache, url]
    );
    return {
        setState: mutate,
        formData: data,
        isLoadingArray: !error && !data && formId && sectionPath,
        isErrorFetchingArrayElements: error,
        tenderName: data?.tenderName,
        isAddingDisabled: data?.isAddingDisabled,
        isCopyLotAllowed: data?.isCopyLotAllowed,
    };
};
export default useFetchArray;
