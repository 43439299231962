import React, { useEffect, useState } from 'react';
import './wydr';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { tokenHelper } from './authorization/tokenHelper';
import OktaAuth from '@okta/okta-auth-js';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CheckmarkOutline32, ProgressBarRound32 } from '@carbon/icons-react';
import { errorHandler } from './authorization/errorHandler';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';

const AxiosInterceptor = ({
    children,
    oktaAuth,
    restoreOriginalUri,
}: {
    children: any;
    oktaAuth: OktaAuth;
    restoreOriginalUri: (
        _oktaAuth: OktaAuth,
        originalUri: string
    ) => Promise<void>;
}) => {
    const [isSet, setIsSet] = useState(false);
    const { copyErrorMessageMef2099 } = useFlags();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation([
        'form-content',
        'form-configurator',
        'rules',
        'toast-content',
    ]);

    useEffect(() => {
        setIsSet(true);
        const requestInterceptor = (request: AxiosRequestConfig) => {
            const pendingText = request.headers['Toast-Pending-Text'];
            const successText = request.headers['Toast-Success-Text'];

            const { SkipToast } = request.headers;
            if (!SkipToast) {
                if (toast.isActive(request.url ?? '')) {
                    toast.update(request.url ?? '', {
                        render: pendingText,
                        type: 'default',
                        isLoading: true,
                        icon: (
                            <ProgressBarRound32 className=" text-white animate-spin" />
                        ),
                    });
                } else {
                    toast.loading(pendingText, {
                        toastId: request.url,
                        icon: (
                            <ProgressBarRound32 className=" text-white animate-spin" />
                        ),
                    });
                }
            }
            if (pendingText) {
                request.headers['Toast-Pending-Text'] = encodeURIComponent(
                    request.headers['Toast-Pending-Text']
                );
            }
            if (successText) {
                request.headers['Toast-Success-Text'] = encodeURIComponent(
                    request.headers['Toast-Success-Text']
                );
            }

            return tokenHelper(request);
        };

        const resInterceptor = (response: AxiosResponse) => {
            let successText = response.config.headers['Toast-Success-Text'];
            if (successText) {
                const decodedSuccessText = decodeURIComponent(successText);
                successText = decodedSuccessText;
            }

            const { SkipToast } = response.config.headers;
            if (!SkipToast) {
                if (toast.isActive(response.config.url ?? '')) {
                    toast.update(response.config.url ?? '', {
                        render: successText,
                        type: 'success',
                        isLoading: false,
                        autoClose: 3000,
                        delay: 1000,
                        icon: <CheckmarkOutline32 />,
                    });
                } else {
                    toast.success(successText, {
                        toastId: response.config.url,
                    });
                }
            }

            return response;
        };

        const errInterceptor = (error: AxiosError) => {
            errorHandler(
                error,
                oktaAuth,
                restoreOriginalUri,
                t,
                i18n,
                navigate,
                copyErrorMessageMef2099
            );
            return Promise.reject(error.response);
        };
        const interceptor = axios.interceptors.response.use(
            resInterceptor,
            errInterceptor
        );

        const requester = axios.interceptors.request.use(requestInterceptor);
        return () => {
            axios.interceptors.response.eject(interceptor);
            axios.interceptors.request.eject(requester);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n, oktaAuth, restoreOriginalUri, t]);
    return isSet && children;
};

export default axios;
export { AxiosInterceptor };
