import axios from 'axios';
import { ConfiguratorCodelist } from '../../types/generated/configuratorCodelist';

const setCodeVisibility = async (
    versionId: string,
    codelist: ConfiguratorCodelist
) =>
    axios
        .post(`configurator/codelist/${versionId}`, codelist)
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });

export default setCodeVisibility;
