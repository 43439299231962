import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

const fetcher = (url: string) =>
    axios.get(url).then(async (res: AxiosResponse): Promise<string[]> => {
        const { data } = res;
        return data;
    });

const useFetchPlatformNames = () => {
    const { data, error } = useSWRImmutable('/testapp/platforms', fetcher, {
        shouldRetryOnError: false,
    });

    return {
        platformNames: data,
        isLoadingPlatformNames: !error && !data,
        isErrorFetchingPlatformNames: error,
    };
};

export default useFetchPlatformNames;
