/* istanbul ignore file */
import axios, { AxiosResponse } from 'axios';
import { useCallback, MouseEvent, useState } from 'react';
import { getAdminTokenFromApi } from '../../authorization/getAuthFromApi';
import { toast } from 'react-toastify';
import { TFunction } from 'react-i18next';
import { useAppSelector } from '../../redux/hooks';

export interface FormCreationInterface {
    formId: string;
}

export interface CreateFormProps {
    platformName?: string;
    tenderId?: string;
    country?: string;
    noticeSubtype?: string;
    language?: string;
    manyLotsEnabled?: boolean;
    multiStageEnabled?:boolean;
    formId?: string;
    changeNoticeEnabled?: boolean;
    timeZone?: string;
    formData?: string;
    onSuccessCallback?: (formId?: string) => void;
    onFailCallback?: (error?: any) => void;
    toastText?: {
        onSuccessText?: string;
        onPendingText?: string;
    };
    t: TFunction<string, unknown>;
}

const axiosPost = (
    url: string,
    formData?: Record<string, any>,
    toastText?: {
        onSuccessText?: string;
        onPendingText?: string;
    }
): Promise<any | undefined> =>
    axios
        .post(url, formData, {
            headers: {
                'Toast-Pending-Text': toastText?.onPendingText,
                'Toast-Success-Text': toastText?.onSuccessText,
            },
        })
        .then(
            (res: AxiosResponse<FormCreationInterface>): string =>
                res.data.formId
        )
        .catch((error: any) => error);

interface SubmitReturn {
    onSubmit: (
        event: MouseEvent<HTMLFormElement>
    ) => Promise<string | undefined>;
}

export const useCreateForm = (props: CreateFormProps): SubmitReturn => {
    const [prevTenderId, setPrevTenderId] = useState<string>('');

    const reduxUserId = useAppSelector((state) => state.store.userId);
    const reduxRole = useAppSelector((state) => state.store.role);

    const {
        tenderId,
        noticeSubtype,
        language,
        platformName,
        formData,
        country,
        manyLotsEnabled,
        multiStageEnabled,
        formId,
        changeNoticeEnabled,
        timeZone,
        onSuccessCallback,
        onFailCallback,
        toastText,
        t,
    } = props;

    const onSubmit = useCallback(
        async (event: any): Promise<string | undefined> => {
            event.preventDefault();
            if (!tenderId || !noticeSubtype || !language) {
                const errorMessage = t(
                    'form-content:CreateFormOnSubmitMissingFields'
                );
                toast.error(errorMessage);
            } else {
                if (prevTenderId !== tenderId) {
                    await getAdminTokenFromApi({
                        name: platformName,
                        userId: reduxUserId,
                        role: reduxRole,
                        formUserRights: 2,
                        tenderId,
                    });
                    setPrevTenderId(tenderId);
                }

                if (formData)
                    try {
                        JSON.parse(formData);
                    } catch (e) {
                        const errorMessage = t(
                            'form-content:CreateFormNotCorrectJsonContent'
                        );
                        toast.error(errorMessage);
                    }

                let formDataObject = JSON.parse(formData || '{}');

                if (!('many_lots_enabled' in formDataObject)) {
                    formDataObject = {
                        ...formDataObject,
                        ManyLotsEnabled: manyLotsEnabled,
                    };
                }
                if(multiStageEnabled !== undefined){
                    formDataObject = {
                        ...formDataObject,
                        MultipleStageEnabled: multiStageEnabled
                    }
                }
                if (!('timeZone' in formDataObject && timeZone !== '')) {
                    formDataObject = {
                        ...formDataObject,
                        NoticeTimeZone: timeZone,
                    };
                }

                const data = await axiosPost(
                    `rep/form/create/v2/${
                        country || '-'
                    }/${noticeSubtype}/${language}/${
                        formId ?? '-'
                    }/${changeNoticeEnabled}`,
                    formDataObject,
                    toastText
                );

                if (
                    onFailCallback &&
                    data &&
                    data.status &&
                    data.status !== 200
                ) {
                    onFailCallback(data.data.params[0]);
                    return;
                }

                if (onSuccessCallback && data) onSuccessCallback(data);
            }

            return undefined;
        },
        [
            tenderId,
            noticeSubtype,
            language,
            t,
            prevTenderId,
            formData,
            country,
            formId,
            changeNoticeEnabled,
            toastText,
            onFailCallback,
            onSuccessCallback,
            platformName,
            reduxUserId,
            reduxRole,
            manyLotsEnabled,
            timeZone,
            multiStageEnabled
        ]
    );
    return { onSubmit };
};
