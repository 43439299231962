import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import useFetchValidation from '../../hooks/administrativeHooks/useFetchValidation';
import { formOptions } from '../../types/enums/formOptions';
import ValidationFullComponent from '../../components/ValidationFullComponent';

const ValidationPageFull = () => {
    const location = useLocation();

    const useSlowSchematron =
        location.pathname.includes(formOptions.validationFull) ||
        location.pathname.includes(formOptions.validationSchematron);
    const onlySchematron = location.pathname.includes(
        formOptions.validationSchematron
    );

    const { formId } = useParams<{
        formId: string;
    }>();
    const { validation, isErrorFetchingValidation, isLoadingValidation } =
        useFetchValidation(formId ?? '', useSlowSchematron, onlySchematron);

    return (
        <ValidationFullComponent
            isLoadingValidation={isLoadingValidation}
            isErrorFetchingValidation={isErrorFetchingValidation}
            validation={validation}
        />
    );
};

export default ValidationPageFull;
