import axios from 'axios';
import { FeatureFlagBody } from 'launchdarkly-api-typescript';

export const createFlag = async (
    featureFlagBody: FeatureFlagBody,
    toastText?: {
        onSuccessText?: string;
        onPendingText?: string;
    }
) =>
    axios
        .post(`/featureflag/features/create`, featureFlagBody, {
            headers: {
                'Toast-Pending-Text': toastText?.onPendingText,
                'Toast-Success-Text': toastText?.onSuccessText,
            },
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
