import { Dispatch, SetStateAction } from 'react';
import { ValidationErrorObject } from '../../types/validationError';
import { TFunction } from 'react-i18next';
import { addErrorObjectWithPathsRecursive } from './addErrorObjectWithPathsRecursive';
import { searchForRenderedFields } from './searchForRenderedFields';
import axios, { AxiosResponse } from 'axios';
import { KeyedMutator } from 'swr';
import { FormMenuResult } from '../../types/generated/formMenuResult';

export interface ValidateResponse {
    fieldErrors: Record<string, unknown>;
    formErrors: Record<string, unknown>;
}

const fetcher = ({
    url,
    setHotReloadOfMenu,
    setIsLoadingFetchingValidations,
    setIsErrorFetchingValidations,
    setRefetchStatus,
    isTranslate,
}: {
    url: string;
    setHotReloadOfMenu: KeyedMutator<FormMenuResult>;
    setIsLoadingFetchingValidations: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    setIsErrorFetchingValidations: React.Dispatch<React.SetStateAction<any>>;
    setRefetchStatus: React.Dispatch<React.SetStateAction<boolean>>;
    isTranslate: boolean;
}) =>
    axios
        .get(url, { headers: { SkipToast: true } })
        .then(
            async (
                res: AxiosResponse<ValidateResponse>
            ): Promise<ValidateResponse> => {
                const { data } = res;
                if (!isTranslate) {
                    setHotReloadOfMenu();
                    setRefetchStatus(
                        (refetchStatus: boolean) => !refetchStatus
                    );
                }
                setIsErrorFetchingValidations(undefined);
                setIsLoadingFetchingValidations(false);
                return data;
            }
        )
        .catch((error) => {
            setIsErrorFetchingValidations(error);
            setIsLoadingFetchingValidations(false);
            throw error;
        });

export const useValidate =
    (
        setBackendValidationErrors: Dispatch<
            SetStateAction<ValidationErrorObject>
        >,
        formId: string,
        parentSectionId: string,
        sectionId: string,
        t: TFunction<string, unknown>,
        setHotReloadOfMenu: KeyedMutator<FormMenuResult>,
        setIsLoadingFetchingValidations: React.Dispatch<
            React.SetStateAction<boolean>
        >,
        setIsErrorFetchingValidations: React.Dispatch<
            React.SetStateAction<any>
        >,
        setRefetchStatus: React.Dispatch<React.SetStateAction<boolean>>
    ) =>
    async (isTranslate = false): Promise<ValidateResponse> => {
        setIsLoadingFetchingValidations(true);
        const submittedErrors = await fetcher({
            url: `rep/form/validate-frontend/${formId}`,
            setHotReloadOfMenu,
            setIsLoadingFetchingValidations,
            setIsErrorFetchingValidations,
            setRefetchStatus,
            isTranslate,
        });

        if (submittedErrors && !submittedErrors.fieldErrors) {
            setBackendValidationErrors({
                submittedErrors: {},
                errorObjectWithPaths: {},
                renderedFieldsWithErrors: {},
                isFormValidatedWithoutErrors: true,
            });
        }

        if (submittedErrors && submittedErrors.fieldErrors) {
            const mainSectionKeys = Object.keys(submittedErrors?.fieldErrors);
            const errorObjectWithPaths: ValidationErrorObject['errorObjectWithPaths'] =
                mainSectionKeys.reduce(
                    (restObject, key) => ({ ...restObject, [key]: [] }),
                    {}
                );
            addErrorObjectWithPathsRecursive(
                submittedErrors?.fieldErrors,
                errorObjectWithPaths,
                mainSectionKeys,
                t,
                formId
            );
            const renderedFieldsWithErrors = searchForRenderedFields(
                errorObjectWithPaths[parentSectionId || sectionId]?.map(
                    (errorObjects) => errorObjects.fieldPath
                )
            );
            setBackendValidationErrors({
                submittedErrors: submittedErrors?.fieldErrors,
                renderedFieldsWithErrors,
                errorObjectWithPaths,
                isFormValidatedWithoutErrors: false,
            });
        }
        return submittedErrors;
    };
