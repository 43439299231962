import axios, { AxiosResponse } from 'axios';
import { ConfiguratorFormDefinition } from '../../types/generated/configuratorFormDefinition';

const saveAllowedDefinition = async (
    versionId: string,
    payload: ConfiguratorFormDefinition,
    setError: React.Dispatch<React.SetStateAction<string | undefined>>
) =>
    axios
        .post(`/configurator/definitions/${versionId}`, payload)
        .then((res: AxiosResponse) => res.status)
        .catch((error) => {
            setError(error.response?.data);
            throw error;
        });

export default saveAllowedDefinition;
