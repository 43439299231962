import React from 'react';
import {
    Checkmark24,
    Copy24,
    LicenseDraft24,
    Link24,
    OverflowMenuVertical16,
    TextLinkAnalysis24,
} from '@carbon/icons-react';
import Button from '@mercell/button-react';
import { DropdownMenu, DropdownMenuItem } from '@mercell/dropdown-menu-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FormStatus } from '../../types/generated/formMenuResult';
import { getOtpUrlFromApi } from '../../authorization/getAuthFromApi';
import { formOptions } from '../../types/enums/formOptions';
import { PublicationTaskStatus } from './publicationTaskStatus';

interface TableDropDownProps {
    cell: any;
    t: any;
    handleDropDownShow: (e: any, cell: any) => void;
    copyToClipboard: (text: string) => void;
    openModal: (formID: string) => void;
    openModalBackToDispatched: (formID: string) => void;
    openModalPublicationStop: (formID: string) => void;

    viewAuditLog: (
        formId: string,
        tenderId: string,
        tenderingSystem: string
    ) => void;

    viewStatusLog: (
        formId: string,
        tenderId: string,
        tenderingSystem: string
    ) => void;

    viewPublicationLog: (formId: string) => void;
    viewValidationFull: (tenderData: any) => void;
}

const TableDropDown = (props: TableDropDownProps) => {
    const { mef1941 } = useFlags();

    const {
        cell,
        t,
        handleDropDownShow,
        copyToClipboard,
        openModal,
        openModalBackToDispatched,
        openModalPublicationStop,
    } = props;
    const buttonRef = React.useRef<HTMLButtonElement>(null);

    const shouldDisabled = (original: any): boolean | undefined => {
        const result =
            original.status === FormStatus.Error ||
            original.status === FormStatus.ReadyForTranslation;

        if (mef1941) {
            return !(
                result ||
                (original.portalName === 'UHM' &&
                    original.portalStatus ===
                        PublicationTaskStatus[PublicationTaskStatus.Failed])
            );
        }

        return !result;
    };

    return (
        <>
            <Button
                scheme="icon"
                className="rt-tr-group focus:ring-offset-0 text-minsk focus:bg-transparent"
                ref={buttonRef}
                style={{ boxShadow: 'none' }}
                iconSettings={{
                    Icon: OverflowMenuVertical16,
                }}
                onClick={(e) => handleDropDownShow(e, cell)}
                data-test="array-button"
            />
            {cell.row.original.showDropDown && (
                <DropdownMenu
                    id="dashboard-table-dropdown-menu"
                    anchorRef={buttonRef}
                    closeOnClick
                    onClose={(e) => handleDropDownShow(e, cell)}
                    popperOptions={{ placement: 'left-start' }}
                    className="p-4 !min-w-[300px]"
                >
                    <div className="flex items-center justify-center px-2">
                        <LicenseDraft24 className="text-minsk" />
                        <DropdownMenuItem
                            className="hover:bg-transparent hover:text-minsk focus:ring-offset-0  active:bg-transparent"
                            data-test="array-item-edit-button"
                            onClick={() => openModal(cell.row.original.formId)}
                            disabled={
                                !(
                                    cell.row.original.status ===
                                        FormStatus.ReadyForPublication ||
                                    cell.row.original.status ===
                                        FormStatus.Error ||
                                    cell.row.original.status ===
                                        FormStatus.ReadyForTranslation ||
                                    cell.row.original.status ===
                                        FormStatus.Deleted ||
                                    cell.row.original.status ===
                                        FormStatus.PublicationStopped
                                )
                            }
                        >
                            {t('dashboard:ReturnStatusToDraft')}
                        </DropdownMenuItem>
                    </div>
                    <div className="flex items-center justify-center px-2">
                        <LicenseDraft24 className="text-minsk" />
                        <DropdownMenuItem
                            className="hover:bg-transparent hover:text-minsk focus:ring-offset-0  active:bg-transparent"
                            data-test="array-item-edit-button"
                            onClick={() =>
                                openModalBackToDispatched(
                                    cell.row.original.formId
                                )
                            }
                            disabled={shouldDisabled(cell.row.original)}
                        >
                            {t(
                                'dashboard:ReturnStatusToDispatchedForPublication'
                            )}
                        </DropdownMenuItem>
                    </div>
                    <div className="flex items-center justify-center px-2">
                        <TextLinkAnalysis24 className="text-minsk" />
                        <DropdownMenuItem
                            className="hover:bg-transparent hover:text-minsk focus:ring-offset-0  active:bg-transparent"
                            data-test="array-item-edit-button"
                            onClick={() =>
                                props.viewAuditLog(
                                    cell.row.original.formId,
                                    cell.row.original.tenderId,
                                    props?.cell.row.original.tenderingSystem
                                )
                            }
                        >
                            {t('form-content:AuditLog')}
                        </DropdownMenuItem>
                    </div>
                    <div className="flex items-center justify-center px-2">
                        <TextLinkAnalysis24 className="text-minsk" />
                        <DropdownMenuItem
                            className="hover:bg-transparent hover:text-minsk focus:ring-offset-0  active:bg-transparent"
                            data-test="array-item-edit-button"
                            onClick={() =>
                                props.viewStatusLog(
                                    cell.row.original.formId,
                                    cell.row.original.tenderId,
                                    props?.cell.row.original.tenderingSystem
                                )
                            }
                        >
                            {t('form-content:StatusLog')}
                        </DropdownMenuItem>
                    </div>

                    <div className="flex items-center justify-center px-2">
                        <TextLinkAnalysis24 className="text-minsk" />
                        <DropdownMenuItem
                            className="hover:bg-transparent hover:text-minsk focus:ring-offset-0  active:bg-transparent"
                            data-test="array-item-edit-button"
                            onClick={() =>
                                props.viewPublicationLog(
                                    cell.row.original.formId
                                )
                            }
                        >
                            {t('form-content:PublicationLog')}
                        </DropdownMenuItem>
                    </div>
                    <div className="flex items-center justify-center px-2">
                        <Link24 className="text-minsk" />
                        <DropdownMenuItem
                            className="hover:bg-transparent hover:text-minsk focus:ring-offset-0  active:bg-transparent"
                            data-test="array-item-edit-button"
                        >
                            {t('form-content:LinkToPortal')}
                        </DropdownMenuItem>
                    </div>
                    <div className="flex items-center justify-center px-2">
                        <Copy24 className="text-minsk" />
                        <DropdownMenuItem
                            className="hover:bg-transparent hover:text-minsk focus:ring-offset-0  active:bg-transparent"
                            onClick={() =>
                                copyToClipboard(cell.row.original.formId)
                            }
                            data-test="array-item-edit-button"
                        >
                            {t('dashboard:CopyFormID')}
                        </DropdownMenuItem>
                    </div>
                    <div className="flex items-center justify-center px-2">
                        <Copy24 className="text-minsk" />
                        <DropdownMenuItem
                            className="hover:bg-transparent hover:text-minsk focus:ring-offset-0  active:bg-transparent"
                            disabled={!cell.row.original.portalNoticeId}
                            onClick={() =>
                                copyToClipboard(
                                    cell.row.original.portalNoticeId
                                )
                            }
                            data-test="array-item-edit-button"
                        >
                            {t('dashboard:CopyPortalNoticeId')}
                        </DropdownMenuItem>
                    </div>
                    <div className="flex items-center justify-center px-2">
                        <Link24 className="text-minsk" />
                        <DropdownMenuItem
                            className="hover:bg-transparent hover:text-minsk focus:ring-offset-0  active:bg-transparent"
                            onClick={async () => {
                                const { formId } = cell.row.original;
                                const otpUrl = await getOtpUrlFromApi(
                                    formId,
                                    2,
                                    formOptions.form
                                );
                                window
                                    .open(otpUrl ?? '/login', '_blank')
                                    ?.focus();
                            }}
                            data-test="array-item-edit-button"
                        >
                            {t('dashboard:OpenForm')}
                        </DropdownMenuItem>
                    </div>

                    <div className="flex items-center justify-center px-2">
                        <LicenseDraft24 className="text-minsk" />
                        <DropdownMenuItem
                            className="hover:bg-transparent hover:text-minsk focus:ring-offset-0  active:bg-transparent"
                            data-test="array-item-edit-button"
                            onClick={() =>
                                openModalPublicationStop(
                                    cell.row.original.formId
                                )
                            }
                            disabled={
                                !(
                                    cell.row.original.portalStatus ===
                                        PublicationTaskStatus[
                                            PublicationTaskStatus.InProgress
                                        ] &&
                                    cell.row.original.status ===
                                        FormStatus.DispatchedForPublication
                                )
                            }
                        >
                            {t('dashboard:StopPublication')}
                        </DropdownMenuItem>
                    </div>

                    <div className="flex items-center justify-center px-2">
                        <Checkmark24 className="text-minsk" />
                        <DropdownMenuItem
                            className="hover:bg-transparent hover:text-minsk focus:ring-offset-0  active:bg-transparent"
                            onClick={() =>
                                props.viewValidationFull(cell.row.original)
                            }
                            data-test="array-item-edit-button"
                        >
                            {t('dashboard:ValidateFormFull')}
                        </DropdownMenuItem>
                    </div>
                </DropdownMenu>
            )}
        </>
    );
};

export default TableDropDown;
