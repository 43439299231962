import React, { useState } from 'react';
import {
    Delete32,
    OverflowMenuVertical16,
    TextLinkAnalysis32,
} from '@carbon/icons-react';
import Button from '@mercell/button-react';
import { DropdownMenu, DropdownMenuItem } from '@mercell/dropdown-menu-react';
import { useTranslation } from 'react-i18next';

const ActionMenu = (props: any) => {
    const {
        cell,
        setDeleteFeatureFlagValues,
        setIsDeleteModalVisible,
        fetchFlagAuditlog,
        setAuditlogData,
        setIsAuditlogModalVisible,
    } = props;
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const [showDropDown, setshowDropDown] = useState<boolean>(false);

    const { t } = useTranslation('feature-flags');

    return (
        <>
            <Button
                scheme="icon"
                className="rt-tr-group focus:ring-offset-0 text-minsk focus:bg-transparent"
                ref={buttonRef}
                style={{ boxShadow: 'none' }}
                iconSettings={{
                    Icon: OverflowMenuVertical16,
                }}
                onClick={() => {
                    setshowDropDown(true);
                }}
                data-test="array-button"
            />
            {showDropDown && (
                <DropdownMenu
                    id="dashboard-table-dropdown-menu"
                    anchorRef={buttonRef}
                    closeOnClick
                    onClose={() => {
                        setshowDropDown(false);
                    }}
                    popperOptions={{ placement: 'left-start' }}
                    className="p-4 !min-w-[300px]"
                >
                    <div
                        className="flex items-center justify-center px-2 hover:cursor-pointer"
                        onClick={() => {
                            setDeleteFeatureFlagValues({
                                key: cell.row.original.key,
                                name: cell.row.original.name,
                            });
                            setIsDeleteModalVisible(true);
                        }}
                        aria-hidden="true"
                    >
                        <Delete32 className="text-minsk" />
                        <DropdownMenuItem
                            className="hover:bg-transparent hover:text-minsk focus:ring-offset-0  active:bg-transparent"
                            data-test="array-item-edit-button"
                        >
                            {t('DeleteFeatureFlag')}
                        </DropdownMenuItem>
                    </div>
                    <div
                        className="flex items-center justify-center px-2 hover:cursor-pointer"
                        onClick={async () => {
                            const featureAuditlog = await fetchFlagAuditlog(
                                cell.row.original.key
                            );
                            setAuditlogData(featureAuditlog);
                            setIsAuditlogModalVisible(true);
                        }}
                        aria-hidden="true"
                    >
                        <TextLinkAnalysis32 className="text-minsk" />
                        <DropdownMenuItem
                            className="hover:bg-transparent hover:text-minsk focus:ring-offset-0  active:bg-transparent"
                            data-test="array-item-edit-button"
                        >
                            {t('AuditLog')}
                        </DropdownMenuItem>
                    </div>
                </DropdownMenu>
            )}
        </>
    );
};

export default ActionMenu;
