import Toggle from '@mercell/toggle-react';
import React, { useState } from 'react';
import {
    getTranslationKeysVisibility,
    setTranslationKeysVisibility,
} from '../shared/storageService/sessionService';
import { useTranslation } from 'react-i18next';

const TranslationVisibilityToggle = () => {
    const { t } = useTranslation('form-content');
    const [showTranslation, setShowTranslation] = useState<boolean>(
        getTranslationKeysVisibility() ?? false
    );

    const onChange = () => {
        setTranslationKeysVisibility(!showTranslation);
        setShowTranslation(!showTranslation);
        document.location.reload();
    };
    // if (process.env.NODE_ENV !== 'development') return null;
    return (
        <div className="whitespace-pre-line md:visible invisible">
            <Toggle
                onLabel={t('ShowTranslations')}
                offLabel={t('ShowTranslationKeys')}
                checked={showTranslation}
                onChange={onChange}
            />
        </div>
    );
};

export default TranslationVisibilityToggle;
