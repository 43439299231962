import { Mutator } from 'final-form';
import { Dispatch, SetStateAction } from 'react';
import { ValidationErrorObject } from '../../types/validationError';

type MutatorArgs = [
    languageSearchParam: string,
    setBackendValidationErrors: Dispatch<SetStateAction<ValidationErrorObject>>
];

export const lingualFieldSelectionMutator: Mutator = (
    args: MutatorArgs,
    state,
    tools
) => {
    const [languageSearchParam, setBackendValidationErrors] = args;
    const lingualTextLanguageSelectorNode = document.querySelectorAll(
        "[id^='LingualTextLanguageSelector']"
    );
    if (lingualTextLanguageSelectorNode.length) {
        tools.changeValue(state, 'LingualTextLanguageSelector', (oldValue) => {
            if (languageSearchParam && oldValue !== languageSearchParam) {
                setBackendValidationErrors((currentVal) => ({
                    ...currentVal,
                    renderedFieldsWithErrors: {},
                }));
                return languageSearchParam;
            }
            return oldValue;
        });
    }
};
