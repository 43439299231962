import axios, { AxiosResponse } from 'axios';
import {
    UserRightsResult,
    UserRole,
} from '../types/generated/userRightsResult';
import useSWR from 'swr';

const fetcher = (url: string) =>
    axios
        .get(url, { headers: { SkipToast: true } })
        .then(async (res: AxiosResponse): Promise<UserRightsResult> => {
            const { data } = res;
            return data;
        });

const useFetchUserRights = () => {
    const { data, error, mutate } = useSWR(`/auth/user-rights`, fetcher, {
        shouldRetryOnError: true,
        revalidateOnFocus: true,
    });

    return {
        UserRights: data,
        isLoadingUserRights: !error && !data,
        isErrorFetchingUserRights: error,
        mutateUserRights: mutate,
    };
};

export default useFetchUserRights;
