import React from 'react';
import { TrashCan32 } from '@carbon/icons-react';
import { Button } from '@mercell/button-react';
import { TFunction } from 'react-i18next';
import { FormDefinitionItem } from '../../types/generated/formDefinitionItem';

export function getArrayFieldLabel(
    t: TFunction<string, unknown>,
    formDefinition: FormDefinitionItem,
    isFormPreview: boolean
):
    | ((
          arrayFieldIndex: number,
          name: string,
          hideRemoveButton: boolean,
          removeAction: () => void
      ) => React.ReactNode)
    | undefined {
    return (
        index: number,
        name: string,
        hideRemoveButton: boolean,
        action: () => void
    ) => (
        <div className="pt-10 pl-14 flex justify-between items-center array-title">
            <div className="title-medium">
                {t(formDefinition.label ?? formDefinition.name)}{' '}
                {formDefinition.hideArrayItemIndex ? '' : index}
            </div>

            {!hideRemoveButton &&
                !formDefinition.isArrayLocked &&
                !isFormPreview && (
                    <Button
                        scheme="tertiary"
                        iconSettings={{ Icon: TrashCan32 }}
                        type="button"
                        onClick={action}
                        data-test="remove-button"
                    >
                        {t(
                            formDefinition.labelRemove ??
                                'form-content:LabelRemove'
                        )}
                    </Button>
                )}
        </div>
    );
}
