import { Alert, AlertText } from '@mercell/alert-react';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

type DivProps = JSX.IntrinsicElements['div'];

interface Props extends DivProps {
    error: string;
}

const FormError: React.FunctionComponent<Props> = ({ error, ...rest }) => {
    const { t } = useTranslation(['list.error', 'form-content', 'rules']);

    return (
        <Alert key={error} scheme="error" role="alert" {...rest}>
            <AlertText>
                <Trans>{t(error ?? '')}</Trans>

                {/* Implement field errors
                <>
                    <strong>Field:</strong> {error.name} <strong>Error:</strong>{' '}
                    {error.error}
                </>
             */}
            </AlertText>
        </Alert>
    );
};

export default FormError;
