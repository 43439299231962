import AppState from '../types/appState';
import { Action } from './action';

// A reducer is simply a function that changes the state based on an action
// You can think of a reducer as an event listener which handles events based on the received action (event) type.
export const reducer = (state: AppState, action: Action): AppState => {
    switch (action.type) {
        case 'CHANGE_USER': {
            return {
                ...state,
                userId: action.payload.userId,
            };
        }
        case 'CHANGE_USERNAME': {
            return {
                ...state,
                userName: action.payload.userName,
            };
        }
        case 'SET_ROLE': {
            return {
                ...state,
                userRole: action.payload.userRole,
            };
        }
        case 'SET_PLATFORM_NAME': {
            return {
                ...state,
                platformName: action.payload.platformName,
            };
        }
        case 'CHANGE_MENU': {
            return {
                ...state,
                menuSections: action.payload.menuSections,
            };
        }
        case 'SET_TENDER_NAME': {
            return {
                ...state,
                tenderName: action.payload.tenderName,
            };
        }
        case 'SET_LOCALE': {
            return {
                ...state,
                locale: action.payload.locale,
            };
        }
        case 'SET_USER_RIGHTS': {
            return {
                ...state,
                UserRights: action.payload.UserRights,
            };
        }
        case 'SET_NOTICE_STATUS': {
            return {
                ...state,
                noticeStatus: action.payload.noticeStatus,
            };
        }
        case 'SET_AUTO_SAVE': {
            return {
                ...state,
                autoSave: action.payload.autoSave,
            };
        }
        default:
            throw new Error(`Action not found`);
    }
};
