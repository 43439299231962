import Checkbox from '@mercell/checkbox-react';
import { DatePicker } from '@mercell/date-picker-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { parse } from 'date-fns';

const PublicationModalContent: FC<{
    activateLocally: boolean;
    currentDate: string | undefined;
    onChangeDate: (date: Date) => void;
    trriggerActivateLocally: () => void;
}> = ({
    currentDate,
    onChangeDate,
    activateLocally,
    trriggerActivateLocally,
}) => {
    const { t } = useTranslation(['form-configurator']);
    return (
        <div>
            <h2 className="text-left my-5">
                {t('form-configurator:PublicationDetails')}
            </h2>
            <p className="text-left my-5">
                {t('form-configurator:PublicationText')}
            </p>
            <div>
                <Checkbox
                    id="activate-locally"
                    checked={activateLocally}
                    onChange={trriggerActivateLocally}
                >
                    {t('form-configurator:Activate')}
                </Checkbox>
            </div>
            <div className="w-1/2 my-10">
                <div className="text-left">
                    {t('form-configurator:PublicationDate')}
                </div>
                <div>
                    <DatePicker
                        placeholderText={t('form-configurator:ChooseDate')}
                        selected={
                            currentDate
                                ? parse(currentDate, 'yyyy-MM-dd', new Date())
                                : undefined
                        }
                        dateFormat="yyyy-MM-dd"
                        minDate={new Date()}
                        onChange={onChangeDate}
                    />
                </div>
            </div>
        </div>
    );
};

export default PublicationModalContent;
