import { WarningSquare32 } from '@carbon/icons-react';
import React from 'react';
import LoadingSpinner from '@mercell/loading-spinner-react';
import { useTranslation } from 'react-i18next';
import NotFoundPage from '../pages/NotFound/NotFoundPage';

const ValidationFullComponent = ({
    isLoadingValidation,
    isErrorFetchingValidation,
    validation,
}: {
    isLoadingValidation: boolean;
    isErrorFetchingValidation: any;
    validation: any;
}) => {
    const { t } = useTranslation(['rules', 'form-content']);
    return (
        <>
            {isLoadingValidation && (
                <div className="col-span-full h-full flex items-center justify-center">
                    <LoadingSpinner />
                </div>
            )}
            {isErrorFetchingValidation && (
                <NotFoundPage
                    wrapperClass="col-span-full"
                    errorCode={isErrorFetchingValidation.status.toString()}
                />
            )}
            {validation && (
                <div className="col-span-full mx-6 h-96 mb-6">
                    {validation?.length === 0 ? (
                        <div className="mb-1 flex">
                            <p className="text-well-read">
                                <b>{t('dashboard:NoErrorsToFix')}</b>
                            </p>
                        </div>
                    ) : (
                        validation?.map(
                            (
                                v: { [s: string]: any } | { error: string },
                                i: { toString: () => any }
                            ) => (
                                <div
                                    key={v.error + i.toString()}
                                    className="mb-5"
                                >
                                    <div className="flex justify-center mb-5">
                                        <WarningSquare32 />
                                    </div>
                                    {Object.entries(v).map(([key, value]) => (
                                        <div
                                            key={key + value}
                                            className="mb-1 flex"
                                        >
                                            <p className="text-important-accent underline">
                                                {key.toLocaleUpperCase()}
                                            </p>{' '}
                                            <p className="text-well-read w-full break-all">
                                                :{' '}
                                                {key === 'error' ? (
                                                    <b>{t(`${value}`)}</b>
                                                ) : (
                                                    value
                                                )}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            )
                        )
                    )}
                </div>
            )}
        </>
    );
};

export default ValidationFullComponent;
