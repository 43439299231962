import React from 'react';
import { useParams } from 'react-router-dom';
import useFetchFamilyTree from '../../hooks/administrativeHooks/useFetchFamilyTree';
import NotFoundPage from '../NotFound/NotFoundPage';
import LoadingSpinner from '@mercell/loading-spinner-react';

const FamilyTreePage = () => {
    const { formId } = useParams<{ formId: string }>();

    const { familyTree, isLoadingFamilyTree, isErrorFetchingFamilyTree } =
        useFetchFamilyTree(formId ?? '');

    if (isLoadingFamilyTree)
        return (
            <div className="col-span-full h-full flex items-center justify-center">
                <LoadingSpinner />
            </div>
        );

    if (isErrorFetchingFamilyTree) {
        return (
            <NotFoundPage
                wrapperClass="col-span-full"
                errorCode={isErrorFetchingFamilyTree.status.toString()}
            />
        );
    }

    return (
        <div className="col-span-full">
            <pre>{familyTree}</pre>
        </div>
    );
};

export default FamilyTreePage;
