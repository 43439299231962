import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useFetchAuditLog from '../../hooks/administrativeHooks/useFetchAuditLog';
import NotFoundPage from '../NotFound/NotFoundPage';
import LoadingSpinner from '@mercell/loading-spinner-react';

const AuditLogPage = () => {
    const { formId } = useParams<{ formId: string }>();
    const { t } = useTranslation([
        'list.audit-log-status',
        'list.audit-log-action',
    ]);
    const { auditLog, isErrorFetchingAuditLog, isLoadingAuditLog } =
        useFetchAuditLog(formId ?? '');
    if (isLoadingAuditLog)
        return (
            <div className="col-span-full h-full flex items-center justify-center">
                <LoadingSpinner />
            </div>
        );

    if (isErrorFetchingAuditLog)
        return (
            <NotFoundPage
                wrapperClass="col-span-full"
                errorCode={isErrorFetchingAuditLog.status.toString()}
            />
        );

    return (
        <div className="col-span-full">
            <div className="flex text-dove text-caption p-5 justify-between border-b border-alto uppercase">
                <p className="flex-1 px-3">Timestamp</p>
                <p className="flex-1 px-3">User ID</p>
                <p className="flex-1 px-3">Form status</p>
                <p className="flex-1 px-3">Action</p>
                <p className="flex-1 px-3">Form version</p>
            </div>
            {auditLog?.map((log, i) => (
                <div
                    key={log.id + i.toString()}
                    className="flex p-5 justify-between border-b border-alto"
                >
                    <p className="flex-1 px-3">
                        {new Date(log.timestamp).toLocaleDateString()}{' '}
                        {new Date(log.timestamp).toLocaleTimeString()}
                    </p>
                    <p className="flex-1 px-3">{log.userId}</p>
                    <p className="flex-1 px-3">
                        {t(`list.audit-log-status:${log.formStatus}`)}
                    </p>
                    <p className="flex-1 px-3">
                        {t(`list.audit-log-action:${log.action}`)}
                    </p>
                    <p className="flex-1 px-3">{log.formVersion}</p>
                </div>
            ))}
        </div>
    );
};

export default AuditLogPage;
