import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';
import { VersionOverview } from '../../types/generated/versionOverview';

const fetcher = (url: string) =>
    axios
        .get(url)
        .then(async (res: AxiosResponse): Promise<VersionOverview[]> => {
            const { data } = res;
            return data;
        });

const useFetchVersionOverview = () => {
    const { data, error } = useSWRImmutable(
        `/configurator/version-overview`,
        fetcher,
        { shouldRetryOnError: false }
    );

    return {
        versionOverview: data,
        isLoadingVersionOverview: !error && !data,
        isErrorFetchingVersionOverview: error,
    };
};

export default useFetchVersionOverview;
