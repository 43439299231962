import React, { FC, MouseEvent } from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';

export type ContentMenuItem = {
    title: string;
    path: string;
    parentSectionPath: string | undefined;
    isItemActive: boolean;
    isChevronActive: boolean;
    ariaLabel?: string;
};

export type CustomContentMenuProps = {
    primaryItems?: ContentMenuItem[];
    secondaryItems?: ContentMenuItem[];
    onClick: (
        e: MouseEvent<HTMLAnchorElement>,
        title: string,
        path: string
    ) => void;
};
const CustomContentMenu: FC<
    CustomContentMenuProps & Omit<JSX.IntrinsicElements['div'], 'onClick'>
> = ({ primaryItems, secondaryItems, onClick, ...restProps }) => {
    const showSecondary = secondaryItems && secondaryItems.length > 0;

    // this is a workaround of the current implementation without having parent section ref in URL
    if (primaryItems) {
        primaryItems.forEach((item) => {
            if (item.isItemActive && !!item.parentSectionPath) {
                const result = primaryItems.find(
                    (a) => a.path === item.parentSectionPath
                );
                if (result) {
                    primaryItems[primaryItems.indexOf(result)].isChevronActive =
                        true;
                }
            }
        });
    }

    const menuItem = (
        itemTitle: string,
        path: string,
        isItemActive: boolean,
        isChevronActive: boolean,
        parentSectionPath?: string
    ) => (
        <NavLink
            to={path}
            key={itemTitle + path}
            onClick={(e) => onClick(e, itemTitle, path)}
        >
            <li
                style={{
                    backgroundColor:
                        isChevronActive && !isItemActive ? 'white' : '',
                }}
                className={cx(
                    `p-2 my-1 text-valhalla flex items-baseline pl-[1.375rem] hover:bg-hover-grey`,
                    {
                        'text-studio pl-[0] bg-snuff font-semibold hover:bg-snuff':
                            isItemActive || isChevronActive,
                    }
                )}
                data-test={itemTitle}
            >
                {!parentSectionPath ? (
                    <>
                        <img
                            className={cx('w-2.5 h-2.5 mx-1.5', {
                                hidden: !(isChevronActive || isItemActive),
                            })}
                            src="https://cdn.mercell.com/icons/favicon.ico"
                            alt="toggle-arrow"
                        />
                        <span className="mr-12"> {itemTitle}</span>
                    </>
                ) : (
                    <span
                        className={cx(
                            'pr-4 text-caption',
                            isItemActive ? 'pl-9' : 'pl-4'
                        )}
                    >
                        {itemTitle}
                    </span>
                )}
            </li>
        </NavLink>
    );
    return (
        <div
            {...restProps}
            className={cx(
                'py-2 rounded-default w-40 text-left bg-white border border-alto',
                restProps.className
            )}
        >
            <ul>
                {!primaryItems?.length
                    ? Array.from(Array(9).keys()).map((ix) => (
                          <li
                              key={ix}
                              className={cx(
                                  `p-2 my-1 text-valhalla flex items-baseline`
                              )}
                          >
                              <p className="bg-alto w-full h-6 animate-pulse" />
                          </li>
                      ))
                    : primaryItems.map(
                          ({
                              title,
                              path,
                              isItemActive,
                              isChevronActive,
                              parentSectionPath,
                          }) =>
                              menuItem(
                                  title,
                                  path,
                                  isItemActive,
                                  isChevronActive,
                                  parentSectionPath
                              )
                      )}
                {showSecondary && (
                    <>
                        <hr />
                        {secondaryItems?.map(
                            ({
                                title,
                                path,
                                parentSectionPath,
                                isItemActive,
                                isChevronActive,
                            }) =>
                                menuItem(
                                    title,
                                    path,
                                    isItemActive,
                                    isChevronActive,
                                    parentSectionPath
                                )
                        )}
                    </>
                )}
            </ul>
        </div>
    );
};
export default CustomContentMenu;
