import axios, { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import useSWRImmutable from 'swr/immutable';

export interface PublicationTaskHistory {
    action: string;
    formId: string;
    executedAt?: DateTime;
    internalStatus: string;
    createdAt?: DateTime;
    errorMessage: string;
}

const fetcher = (url: string) =>
    axios
        .get(url)
        .then(async (res: AxiosResponse): Promise<PublicationTaskHistory[]> => {
            const { data } = res;
            return data;
        });

const useFetchPublicationlog = (formId: string) => {
    const { data, error } = useSWRImmutable(
        `/dashboard/publication-task-history/${formId}`,
        fetcher,
        { shouldRetryOnError: false }
    );

    return {
        publicationlog: data,
        isLoadingAuditLog: !error && !data,
        isErrorFetchingPublicationlog: error,
    };
};

export default useFetchPublicationlog;
