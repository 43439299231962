import React from 'react';
import { FieldStructure, SelectFieldStructure } from '@mercell/form-react';
import {
    FormDefinitionItem,
    FormOptionItem,
} from '../../types/generated/formDefinitionItem';
import { getRulesSetup } from '../getRulesSetup';
import * as yup from 'yup';
import { TFunction } from 'react-i18next';

export const mapSelectField = (
    formDefinition: FormDefinitionItem,
    genericFieldStructure: FieldStructure,
    t: TFunction<string, unknown>
): SelectFieldStructure => {
    let validations;

    if (formDefinition.rules && formDefinition.options?.length) {
        validations = getRulesSetup(
            formDefinition.isMultiSelect
                ? yup.array().ensure()
                : yup.string().ensure(),
            formDefinition.rules,
            t
        );
    } else if (formDefinition.rules) {
        validations = getRulesSetup(
            formDefinition.isMultiSelect
                ? yup.array().nullable()
                : yup.string().nullable(),
            formDefinition.rules,
            t
        );
    }
    if (genericFieldStructure.associatedValidationBasedOnOtherFieldValues) {
        if (
            formDefinition.conditionalValidation?.rules &&
            formDefinition.options?.length
        ) {
            const conditionalValidations = getRulesSetup(
                formDefinition.isMultiSelect
                    ? yup.array().ensure()
                    : yup.string().ensure(),
                formDefinition.conditionalValidation.rules,
                t
            );
            genericFieldStructure.associatedValidationBasedOnOtherFieldValues.rules =
                conditionalValidations;
        } else if (formDefinition.conditionalValidation?.rules) {
            const conditionalValidations = getRulesSetup(
                formDefinition.isMultiSelect
                    ? yup.array().nullable()
                    : yup.string().nullable(),
                formDefinition.conditionalValidation.rules,
                t
            );
            genericFieldStructure.associatedValidationBasedOnOtherFieldValues.rules =
                conditionalValidations;
        }
    }

    let options: FormOptionItem[] = [];

    if (formDefinition.options) {
        options = formDefinition.options.map((option: FormOptionItem) => {
            const formOption: FormOptionItem = {
                label: t(`${option.label}`),
                value: option.value,
                parentId: option.parentId,
                addDefaultTag: option.addDefaultTag,
            };
            return formOption;
        });

        if (formDefinition.isSortable ?? true) {
            options.sort((a, b) => a.label.localeCompare(b.label));
        }
    }

    return {
        ...genericFieldStructure,
        fieldToTrack: {
            fieldName: formDefinition.parentField?.fieldName ?? '',
            useSamePath: formDefinition.parentField?.useSamePath,
        },
        validations,
        type: 'select',
        placeholder: t('form-content:Select'),
        isClearable: true,
        isMulti: formDefinition.isMultiSelect,
        closeMenuOnSelect: !formDefinition.isMultiSelect,
        options,
        optionsList: (trackedValue: any) => {
            if (formDefinition.parentField) {
                if (
                    formDefinition.parentField?.singleOptions?.length !== 0 &&
                    formDefinition.parentField.isArray &&
                    trackedValue.length === 1
                ) {
                    // if selected value should be a single item in list
                    if (
                        formDefinition.parentField?.singleOptions?.some((x) =>
                            (trackedValue as string[]).includes(x)
                        )
                    ) {
                        return options
                            .filter((x) =>
                                (trackedValue as string[]).includes(x.value)
                            )
                            .map((option) => ({
                                label: option.label,
                                value: option.value,
                            }));
                    }

                    // if selected value is not specified in the single options list, then remove all single options
                    return options
                        .filter(
                            (x) =>
                                !formDefinition.parentField?.singleOptions?.includes(
                                    x.value
                                ) ||
                                (trackedValue as string[]).includes(x.value)
                        )
                        .map((option) => ({
                            label: option.label,
                            value: option.value,
                        }));
                }

                switch (formDefinition.parentField.isArray) {
                    case true:
                        return trackedValue.length !== 0
                            ? options.filter((x) =>
                                  formDefinition.parentField?.isExcluded
                                      ? !(trackedValue as string[]).includes(
                                            x.parentId ?? x.value
                                        )
                                      : (trackedValue as string[]).includes(
                                            x.parentId ?? x.value
                                        )
                              )
                            : options;
                    default:
                        return trackedValue
                            ? options
                                  .filter((x) =>
                                      formDefinition.parentField?.isExcluded
                                          ? (x.parentId ?? x.value) !==
                                            trackedValue
                                          : (x.parentId ?? x.value) ===
                                            trackedValue
                                  )
                                  .map((option) => ({
                                      label: option.label,
                                      value: option.value,
                                  }))
                            : options.map((option) => ({
                                  label: option.label,
                                  value: option.value,
                              }));
                }
            } else
                return options.map((option) => ({
                    label: option.label,
                    value: option.value,
                }));
        },
    };
};

export const mapSelectReadOnly = (
    isMultiSelect: boolean,
    isSortable: boolean,
    translationFileName: string,
    content: any,
    options: any,
    translatedContent: string,
    t: TFunction<string, unknown>
) => {
    if (isMultiSelect && translationFileName) {
        if (content && Array.isArray(content) && content.length) {
            const translatedArray = content.map((el: string, i: number) => (
                <p key={el}>
                    {t(`${translationFileName}:${el ?? ''}`)}
                    {content.length > 1 && i < content.length - 1}{' '}
                </p>
            ));
            return isSortable ? translatedArray.sort() : translatedArray;
        }
    } else if (isMultiSelect) {
        if (content && Array.isArray(content) && content.length) {
            const translatedArray = content.map((el: string, i: number) => (
                <p key={el}>
                    {Array.isArray(options) && options.length
                        ? options.find((option) => option.value === el)?.label
                        : el}
                    {content.length > 1 && i < content.length - 1}{' '}
                </p>
            ));
            return isSortable ? translatedArray.sort() : translatedArray;
        }
    } else if (
        !translationFileName &&
        options &&
        Array.isArray(options) &&
        options.length
    ) {
        return options.find((option) => option.value === content)?.label;
    } else if (translationFileName) {
        return translatedContent;
    }

    return content;
};
