/* eslint-disable no-unsafe-optional-chaining */
import React, {
    ChangeEvent,
    FC,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Input, InputContainer, InputLeftAddon } from '@mercell/input-react';
import {
    Add32,
    AlarmSubtract32,
    ArrowLeft32,
    ArrowRight32,
    CaretDown16,
    CaretUp16,
} from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import OktaAuthWrapper from '../../../authorization/OktaAuthWrapper';
import useFetchFeatureFlags from '../../../hooks/administrativeHooks/useFetchFeatureFlags';
import { Ribbon } from '@mercell/ribbon-react';
import { format, parseISO } from 'date-fns';
import { useAppSelector } from '../../../redux/hooks';
import Button from '@mercell/button-react';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { Form, Field } from 'react-final-form';
import Textarea from '@mercell/textarea-react';
import {
    FeatureFlagBodyBoolean,
    UpdateFlagWithEnvironmentKey,
} from './DefaultFeatureFlagOperations';
import { createFlag } from '../../../apiCalls/featureFlags/createFlag';
import Toggle from '@mercell/toggle-react';
import { updateFlag } from '../../../apiCalls/featureFlags/updateFlag';
import { deleteFlag } from '../../../apiCalls/featureFlags/deleteFlag';
import { fetchFlagAuditlog } from '../../../apiCalls/featureFlags/fetchFlagAuditlog';
import FieldError from '@mercell/field-error-react';
import { nl2br } from '../../../shared/utilities/nl2br';
import { AuditLogEntryRep, FeatureFlagBody } from 'launchdarkly-api-typescript';
import NoAccessPage from '../../NotFound/NoAccessPage';
import cx from 'classnames';
import Checkbox from '@mercell/checkbox-react';
import {
    ColumnDef,
    PaginationState,
    TableOptions,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { FeatureFlagModel } from '../../../types/generated/featureFlagModel';
import ActionMenu from './ActionMenu';
import { useFlags } from 'launchdarkly-react-client-sdk';
import RadioButton from '@mercell/radio-button-react';

interface FeatureFlag {
    key: string;
    name: string;
    description?: string;
    value?: boolean;
    environment: string;
    comment?: string;
    temporary?: boolean;
    variation1?: string;
    variation2?: string;
}

type ColumnMeta = {
    meta?: {
        size?: number | string;
        textAlign?: 'text-center';
    };
};
type AugmentedColumnDef = ColumnDef<FeatureFlagModel> & ColumnMeta;

const FeatureFlagsListPage: FC = () => {
    const {
        features,
        isLoading,
        isErrorFetchingFeatures,
        refetchFeatureFlags,
    } = useFetchFeatureFlags();
    const [isFlagCreateModalVisible, setIsFlagCreateModalVisible] =
        useState<boolean>(false);
    const [isFlagUpdateModalVisible, setIsFlagUpdateModalVisible] =
        useState<boolean>(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] =
        useState<boolean>(false);
    const [isAuditlogModalVisible, setIsAuditlogModalVisible] =
        useState<boolean>(false);

    const { t } = useTranslation(['form-content', 'feature-flags']);

    const [searchString, setSearchString] = useState<string>();

    const [filteredData, setFilteredData] =
        useState<FeatureFlagModel[]>(features);

    const { featureFlagMef2173 } = useFlags();

    const reduxUserRights = useAppSelector((state) => state.store.userRights);
    const reduxDateFormat = useAppSelector((state) => state.store.dateFormat);
    const reduxLocale = useAppSelector((state) => state.store.locale);
    const [editFeatureFlagValues, setEditFeatureFlagValues] = useState<
        FeatureFlag | undefined
    >();
    const [deleteFeatureFlagValues, setDeleteFeatureFlagValues] =
        useState<Pick<FeatureFlag, 'name' | 'key'>>();
    const [flagBooleanValue, setFlagBooleanValue] = useState<boolean>(false);
    const [auditLogData, setAuditlogData] = useState<AuditLogEntryRep[]>();
    const [temporaryValue, setTemporaryValue] = useState<boolean>(true);
    const [variationType, setVariationType] = useState<string>('boolean');
    const formRef: any = useRef(null);
    const [pagination, setPagination] = React.useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [numberOfRecords, setNumberOfRecords] = useState<number>();

    const convertVariationType = (val: string | undefined) =>
        variationType === 'string' ? val : Number(val);

    const handleCreateFlagSubmit = async (values: FeatureFlag) => {
        let featureFlagBodyCreate: FeatureFlagBody = {
            ...FeatureFlagBodyBoolean,
            name: values.name,
            key: featureFlagMef2173 ? `FeatureFlag_${values.key}` : values.key,
            description: values.description,
            temporary: temporaryValue,
        };

        if (variationType === 'string' || variationType === 'number') {
            featureFlagBodyCreate = {
                ...featureFlagBodyCreate,
                variations: [
                    {
                        name: 'Variation1',
                        value: convertVariationType(values?.variation1),
                    },
                    {
                        name: 'Variation2',
                        value: convertVariationType(values?.variation2),
                    },
                ],
            };
        }
        await createFlag(featureFlagBodyCreate, {
            onPendingText: t('toast-content:ToastCreatingFlagPending'),
            onSuccessText: t('toast-content:ToastCreatingFlagSuccess'),
        });
        refetchFeatureFlags();
        setIsFlagCreateModalVisible(false);
        setVariationType('boolean');
    };

    const handleUpdateFlagSubmit = async (values: FeatureFlag) => {
        const patchComment = UpdateFlagWithEnvironmentKey(
            flagBooleanValue,
            values.name,
            values.description,
            editFeatureFlagValues?.environment,
            values.comment
        );

        await updateFlag(values.key, patchComment, {
            onPendingText: t('toast-content:ToastUpdatingFlagPending'),
            onSuccessText: t('toast-content:ToastUpdatingFlagSuccess'),
        });
        refetchFeatureFlags();
        setIsFlagUpdateModalVisible(false);
    };

    const handleDeleteFlag = async (key?: string) => {
        if (key) {
            await deleteFlag(key, {
                onPendingText: t('toast-content:ToastDeletingFlagPending'),
                onSuccessText: t('toast-content:ToastDeletingFlagSuccess'),
            });
            refetchFeatureFlags();
            setIsDeleteModalVisible(false);
        }
    };
    const handleChange = (event: any) => {
        setTemporaryValue(event.target.checked);
    };

    const onChangeVariationType = (event: ChangeEvent<HTMLInputElement>) => {
        setVariationType(event.target.value);
    };

    useEffect(() => {
        const tempData = features?.filter((feature) => {
            if (
                searchString?.length === 0 ||
                (feature?.key ?? '')
                    .toLocaleLowerCase()
                    .includes(searchString ?? '') ||
                (feature?.name ?? '')
                    .toLocaleLowerCase()
                    .includes(searchString ?? '') ||
                (feature?.description ?? '')
                    .toLocaleLowerCase()
                    .includes(searchString ?? '')
            ) {
                return true;
            }
            return false;
        });

        setFilteredData([...tempData]);
        setNumberOfRecords(tempData.length);
    }, [features, searchString]);

    const customSorting = (rowA: any, rowB: any, columnId: any) =>
        rowA.getValue(columnId).isEnabled.toString() <
        rowB.getValue(columnId).isEnabled.toString()
            ? 1
            : -1;

    const columns = useMemo<AugmentedColumnDef[]>(
        () => [
            {
                id: 'keyNameAndDescription',
                accessorFn: (row) => ({
                    key: row.key,
                    name: row.name,
                    description: row.description,
                }),
                cell: (info: any) => info.getValue(),
                header: t('feature-flags:Feature'),
                meta: {
                    size: '2/4',
                    textAlign: 'text-center',
                },
                enableSorting: false,
            },
            {
                id: 'development',
                accessorFn: (row) => ({
                    key: row.key,
                    name: row.name,
                    description: row.description,
                    environment: 'development',
                    ...row.values.development,
                }),
                cell: (info: any) => info.getValue(),
                header: t('feature-flags:Development'),
                meta: {
                    size: '3/12',
                    textAlign: 'text-center',
                },
                sortingFn: customSorting,
            },
            {
                id: 'stage',
                accessorFn: (row) => ({
                    key: row.key,
                    name: row.name,
                    description: row.description,
                    environment: 'test',
                    ...row.values.test,
                }),
                cell: (info: any) => info.getValue(),
                header: t('feature-flags:Stage'),
                meta: {
                    size: '3/12',
                    textAlign: 'text-center',
                },
                sortingFn: customSorting,
            },
            {
                id: 'demo',
                accessorFn: (row) => ({
                    key: row.key,
                    name: row.name,
                    description: row.description,
                    environment: 'demo',
                    ...row.values.demo,
                }),
                cell: (info: any) => info.getValue(),
                header: t('feature-flags:Demo'),
                meta: {
                    size: '3/12',
                    textAlign: 'text-center',
                },
                sortingFn: customSorting,
            },
            {
                id: 'production',
                accessorFn: (row) => ({
                    key: row.key,
                    name: row.name,
                    description: row.description,
                    environment: 'production',
                    ...row.values.production,
                }),
                cell: (info: any) => info.getValue(),
                header: t('feature-flags:Production'),
                meta: {
                    size: '3/12',
                    textAlign: 'text-center',
                },
                sortingFn: customSorting,
            },
            {
                id: 'menuColumnAlarmSubtract',
                meta: {
                    size: '20',
                    textAlign: 'text-center',
                },
                enableSorting: false,
            },
            {
                id: 'actionMenu',
                cell: (info: any) => info.getValue(),
                meta: {
                    size: '20',
                    textAlign: 'text-center',
                },
                enableSorting: false,
            },
        ],
        [t]
    );
    const tableOptions: TableOptions<FeatureFlagModel> = {
        data: filteredData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        state: {
            pagination,
        },
    };

    const table = useReactTable(tableOptions);

    const getCellValue = (cell: any) => {
        switch (cell.column.id) {
            case 'keyNameAndDescription': {
                const { name, key, description } = cell.getValue();
                return (
                    <div className="">
                        <p>
                            <strong>{key}</strong>
                        </p>
                        <p className="mb-5">
                            <i>{name}</i>
                        </p>
                        <p>{description}</p>
                    </div>
                );
            }
            case 'demo':
            case 'development':
            case 'stage':
            case 'production': {
                const {
                    name,
                    key,
                    description,
                    isEnabled,
                    modifiedDate,
                    environment,
                } = cell.getValue();
                return (
                    <div className="flex justify-center ">
                        <Ribbon
                            className="hover:cursor-pointer min-w-[160px]"
                            onClick={() => {
                                setFlagBooleanValue(isEnabled);
                                setEditFeatureFlagValues({
                                    name,
                                    key,
                                    description,
                                    environment,
                                    value: isEnabled,
                                });

                                setIsFlagUpdateModalVisible(true);
                            }}
                            scheme={isEnabled ? 'success' : 'error'}
                        >
                            {format(
                                parseISO(modifiedDate.toString()),
                                reduxDateFormat.DateTime,
                                {
                                    locale: reduxLocale,
                                }
                            )}
                        </Ribbon>
                    </div>
                );
            }
            case 'menuColumnAlarmSubtract': {
                return (
                    <div
                        className="flex justify-center"
                        title={t('EnabledInProduction')}
                    >
                        {cell.row.original.deleteCandidate === true ? (
                            <AlarmSubtract32 />
                        ) : (
                            ''
                        )}
                    </div>
                );
            }
            case 'actionMenu': {
                return (
                    <ActionMenu
                        cell={cell}
                        setDeleteFeatureFlagValues={setDeleteFeatureFlagValues}
                        setIsDeleteModalVisible={setIsDeleteModalVisible}
                        fetchFlagAuditlog={fetchFlagAuditlog}
                        setAuditlogData={setAuditlogData}
                        setIsAuditlogModalVisible={setIsAuditlogModalVisible}
                    />
                );
            }
            default:
                return cell.getValue();
        }
    };

    if (isErrorFetchingFeatures?.status === 401) {
        return <NoAccessPage />;
    }

    return (
        <OktaAuthWrapper>
            <div className="col-span-full xl:justify-self-center whitespace-normal px-4">
                <ConfirmationModal
                    title={t('feature-flags:CreateFlag')}
                    closeModal={() => setIsFlagCreateModalVisible(false)}
                    isModalVisible={isFlagCreateModalVisible}
                >
                    <Form
                        onSubmit={(data: FeatureFlag) => {
                            handleCreateFlagSubmit(data);
                        }}
                        render={({ handleSubmit, form }) => (
                            <form ref={formRef} onSubmit={handleSubmit}>
                                <div className="flex flex-col gap-y-1 max-h-[460px] overflow-y-auto md:h-[460px] px-1 -mt-3">
                                    <label
                                        htmlFor="key"
                                        className="text-body font-semibold"
                                    >
                                        {t('feature-flags:Key')}
                                    </label>
                                    <Field
                                        name="key"
                                        validate={(value) =>
                                            value
                                                ? undefined
                                                : [
                                                      t(
                                                          'form-content:ERROR_VALUE_IS_REQUIRED'
                                                      ),
                                                  ]
                                        }
                                        render={({ input, meta }) => (
                                            // eslint-disable-next-line react/jsx-no-useless-fragment
                                            <>
                                                {featureFlagMef2173 ? (
                                                    <>
                                                        <InputContainer>
                                                            <InputLeftAddon>
                                                                FeatureFlag_
                                                            </InputLeftAddon>
                                                            <Input {...input} />
                                                        </InputContainer>
                                                        <FieldError>
                                                            {meta.error}
                                                        </FieldError>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Input {...input} />
                                                        <FieldError>
                                                            {meta.error}
                                                        </FieldError>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    />

                                    <label
                                        htmlFor="name"
                                        className="text-body font-semibold"
                                    >
                                        {t('feature-flags:Name')}
                                    </label>
                                    <Field
                                        name="name"
                                        validate={(value) =>
                                            value
                                                ? undefined
                                                : [
                                                      t(
                                                          'form-content:ERROR_VALUE_IS_REQUIRED'
                                                      ),
                                                  ]
                                        }
                                        render={({ input, meta }) => (
                                            <>
                                                <InputContainer>
                                                    <Input {...input} />
                                                </InputContainer>
                                                <FieldError>
                                                    {meta.error}
                                                </FieldError>
                                            </>
                                        )}
                                    />
                                    {(variationType === 'number' ||
                                        variationType === 'string') && (
                                        <div className="flex flex-col gap-y-1 mt-2">
                                            <label
                                                htmlFor="variation1"
                                                className="text-body font-semibold"
                                            >
                                                {t('feature-flags:Variation', {
                                                    element: '1',
                                                })}
                                            </label>
                                            <Field
                                                name="variation1"
                                                validate={(value) =>
                                                    value
                                                        ? undefined
                                                        : [
                                                              t(
                                                                  'form-content:ERROR_VALUE_IS_REQUIRED'
                                                              ),
                                                          ]
                                                }
                                                render={({ input, meta }) => (
                                                    <>
                                                        <InputContainer>
                                                            <Input
                                                                {...input}
                                                                type={
                                                                    variationType ===
                                                                    'string'
                                                                        ? 'text'
                                                                        : 'number'
                                                                }
                                                                className="[&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                                                            />
                                                        </InputContainer>
                                                        <FieldError>
                                                            {meta.error}
                                                        </FieldError>
                                                    </>
                                                )}
                                            />
                                            <label
                                                htmlFor="variation2"
                                                className="text-body font-semibold"
                                            >
                                                {t('feature-flags:Variation', {
                                                    element: '2',
                                                })}
                                            </label>
                                            <Field
                                                name="variation2"
                                                validate={(value) =>
                                                    value
                                                        ? undefined
                                                        : [
                                                              t(
                                                                  'form-content:ERROR_VALUE_IS_REQUIRED'
                                                              ),
                                                          ]
                                                }
                                                render={({ input, meta }) => (
                                                    <>
                                                        <InputContainer>
                                                            <Input
                                                                {...input}
                                                                type={
                                                                    variationType ===
                                                                    'string'
                                                                        ? 'text'
                                                                        : 'number'
                                                                }
                                                                className="[&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                                                            />
                                                        </InputContainer>
                                                        <FieldError>
                                                            {meta.error}
                                                        </FieldError>
                                                    </>
                                                )}
                                            />
                                        </div>
                                    )}
                                    <label
                                        htmlFor="description"
                                        className="text-body font-semibold"
                                    >
                                        {t('feature-flags:Description')}
                                    </label>
                                    <Field
                                        name="description"
                                        render={({ input }) => (
                                            <Textarea
                                                {...input}
                                                className="min-h-[100px] resize-none"
                                            />
                                        )}
                                    />
                                    <div className="mt-3">
                                        <Field
                                            name="temporary"
                                            render={({ input }) => (
                                                <Checkbox
                                                    {...input}
                                                    onChange={handleChange}
                                                    checked={temporaryValue}
                                                >
                                                    <label
                                                        htmlFor="temporary"
                                                        className="text-body font-semibold"
                                                    >
                                                        {t(
                                                            'feature-flags:Temporary'
                                                        )}
                                                    </label>
                                                </Checkbox>
                                            )}
                                        />
                                    </div>
                                    <label
                                        htmlFor="variation-group"
                                        className="text-body font-semibold mt-1"
                                    >
                                        {t('feature-flags:Variation', {
                                            element: '',
                                        })}
                                    </label>
                                    <div className="flex gap-x-8">
                                        <RadioButton
                                            id="variation-boolean"
                                            name="variation-group"
                                            value="boolean"
                                            checked={
                                                variationType === 'boolean'
                                            }
                                            onChange={onChangeVariationType}
                                        >
                                            {t('feature-flags:Boolean')}
                                        </RadioButton>
                                        <RadioButton
                                            id="variation-string"
                                            name="variation-group"
                                            value="string"
                                            checked={variationType === 'string'}
                                            onChange={onChangeVariationType}
                                        >
                                            {t('feature-flags:String')}
                                        </RadioButton>
                                        <RadioButton
                                            id="variation-number"
                                            name="variation-group"
                                            value="number"
                                            checked={variationType === 'number'}
                                            onChange={onChangeVariationType}
                                        >
                                            {t('feature-flags:Number')}
                                        </RadioButton>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-x-2 justify-end mt-4 -mb-8">
                                    <Button
                                        scheme="primary"
                                        className="mr-2"
                                        onClick={() => {
                                            form.submit();
                                        }}
                                    >
                                        {t('form-content:LabelSave')}
                                    </Button>
                                    <Button
                                        scheme="secondary"
                                        className="mr-2"
                                        onClick={() => {
                                            setIsFlagCreateModalVisible(false);
                                            setVariationType('boolean');
                                        }}
                                    >
                                        {t('form-content:LabelCancel')}
                                    </Button>
                                </div>
                            </form>
                        )}
                    />
                </ConfirmationModal>
                <ConfirmationModal
                    closeModal={() => {
                        setEditFeatureFlagValues(undefined);
                        setIsFlagUpdateModalVisible(false);
                    }}
                    isModalVisible={isFlagUpdateModalVisible}
                >
                    <Form
                        initialValues={{
                            key: editFeatureFlagValues?.key,
                            name: editFeatureFlagValues?.name,
                            description: editFeatureFlagValues?.description,
                            comment: undefined,
                        }}
                        onSubmit={(data: FeatureFlag) => {
                            handleUpdateFlagSubmit(data);
                        }}
                        render={({ handleSubmit, form }) => (
                            <form
                                ref={formRef}
                                onSubmit={handleSubmit}
                                className="flex flex-col pb-6"
                            >
                                <div className="flex">
                                    <span className="flex flex-col">
                                        <label
                                            htmlFor="environment"
                                            className="text-h3 font-semibold"
                                        >
                                            {t('feature-flags:Environment')}
                                        </label>
                                        {editFeatureFlagValues && (
                                            <label htmlFor="environmentValue">
                                                {editFeatureFlagValues?.environment !==
                                                'test'
                                                    ? editFeatureFlagValues.environment
                                                          ?.charAt(0)
                                                          .toUpperCase() +
                                                      editFeatureFlagValues?.environment?.slice(
                                                          1
                                                      )
                                                    : 'Stage'}
                                            </label>
                                        )}
                                    </span>
                                    <Toggle
                                        className="ml-auto"
                                        onLabel={t('On')}
                                        offLabel={t('Off')}
                                        checked={flagBooleanValue}
                                        onChange={() => {
                                            setFlagBooleanValue(
                                                !flagBooleanValue
                                            );
                                        }}
                                    />
                                </div>
                                <hr />
                                <div className="h-[396px] overflow-auto mt-3">
                                    <div>
                                        <label
                                            htmlFor="key"
                                            className="text-body font-semibold"
                                        >
                                            {t('feature-flags:Key')}
                                        </label>
                                        <Field
                                            name="key"
                                            render={({ input }) => (
                                                <Input
                                                    {...input}
                                                    disabled
                                                    className="mt-2"
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="mt-2">
                                        <label
                                            htmlFor="name"
                                            className="text-body font-semibold"
                                        >
                                            {t('feature-flags:Name')}
                                        </label>
                                        <Field
                                            name="name"
                                            validate={(value) =>
                                                value
                                                    ? undefined
                                                    : [
                                                          t(
                                                              'form-content:ERROR_VALUE_IS_REQUIRED'
                                                          ),
                                                      ]
                                            }
                                            render={({ input, meta }) => (
                                                <>
                                                    <Input
                                                        {...input}
                                                        className="mt-2"
                                                    />
                                                    <FieldError>
                                                        {meta.error}
                                                    </FieldError>
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="mt-2">
                                        <label
                                            htmlFor="description"
                                            className="text-body font-semibold"
                                        >
                                            {t('feature-flags:Description')}
                                        </label>
                                        <Field
                                            name="description"
                                            render={({ input }) => (
                                                <Textarea
                                                    {...input}
                                                    className="min-h-[100px] resize-none mt-2"
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="mt-4">
                                        <label
                                            htmlFor="comment"
                                            className="text-body font-semibold"
                                        >
                                            {t('feature-flags:Comment')}
                                        </label>
                                        <Field
                                            name="comment"
                                            validate={(value) => {
                                                if (
                                                    editFeatureFlagValues?.environment ===
                                                    'production'
                                                ) {
                                                    return value
                                                        ? undefined
                                                        : [
                                                              t(
                                                                  'form-content:ERROR_VALUE_IS_REQUIRED'
                                                              ),
                                                          ];
                                                }
                                            }}
                                            render={({ input, meta }) => (
                                                <>
                                                    <Textarea
                                                        {...input}
                                                        className="min-h-[100px] resize-none mt-2"
                                                    />
                                                    <FieldError>
                                                        {meta.error}
                                                    </FieldError>
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="flex gap-x-2 fixed right-4 bottom-4">
                                    <Button
                                        scheme="primary"
                                        onClick={() => {
                                            form.submit();
                                        }}
                                    >
                                        {t('form-content:LabelSave')}
                                    </Button>
                                    <Button
                                        scheme="secondary"
                                        onClick={() => {
                                            setEditFeatureFlagValues(undefined);
                                            setIsFlagUpdateModalVisible(false);
                                        }}
                                    >
                                        {t('form-content:LabelCancel')}
                                    </Button>
                                </div>
                            </form>
                        )}
                    />
                </ConfirmationModal>
                {reduxUserRights?.featuresReviewEnabled && (
                    <div>
                        <h2 className="mb-10">
                            {' '}
                            {t('feature-flags:FeatureFlags')}
                        </h2>
                        <div className="flex justify-between">
                            <div className="mb-10">
                                <InputContainer>
                                    <Input
                                        placeholder={t(
                                            'form-configurator:Search'
                                        )}
                                        onChange={(
                                            e: ChangeEvent<HTMLInputElement>
                                        ) =>
                                            setSearchString(
                                                e.currentTarget.value.toLocaleLowerCase()
                                            )
                                        }
                                    />
                                </InputContainer>
                            </div>
                            <Button
                                onClick={() => {
                                    setIsFlagCreateModalVisible(true);
                                }}
                                scheme="primary"
                                className="bg-minsk focus:ring-offset-0 ml-auto"
                                iconSettings={{ Icon: Add32 }}
                            />
                        </div>
                    </div>
                )}
                <div className="col-span-full">
                    <div className="overflow-hidden border border-alto md:rounded-default">
                        <table className="min-w-full divide-y divide-concrete table-fixed">
                            <thead className="bg-minsk">
                                <tr>
                                    {table
                                        .getFlatHeaders()
                                        .map((header: any) => {
                                            const columnSize =
                                                header.column.columnDef.meta
                                                    ?.size;

                                            const textAlign =
                                                header.column.columnDef.meta
                                                    ?.textAlign;
                                            const columnWidth = `w-${columnSize}`;
                                            return (
                                                <th
                                                    key={header.id}
                                                    onClick={header.column.getToggleSortingHandler()}
                                                    scope="col"
                                                    className={cx(
                                                        'p-2 text-white title-body select-none',
                                                        columnSize &&
                                                            columnWidth,
                                                        textAlign && textAlign,
                                                        header.column.getCanSort() &&
                                                            ' hover:cursor-pointer'
                                                    )}
                                                >
                                                    {header.isPlaceholder ? null : (
                                                        <div>
                                                            {flexRender(
                                                                header.column
                                                                    .columnDef
                                                                    .header,
                                                                header.getContext()
                                                            )}
                                                            {{
                                                                asc: (
                                                                    <CaretDown16 className="inline-block" />
                                                                ),
                                                                desc: (
                                                                    <CaretUp16 className="inline-block" />
                                                                ),
                                                            }[
                                                                header.column.getIsSorted() as string
                                                            ] ?? null}
                                                        </div>
                                                    )}
                                                </th>
                                            );
                                        })}
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-concrete bg-white block max-h-[calc(60vh-100px)] lg:max-h-[calc(50vh-100px)] xl:max-h-[calc(60vh-100px)] 3xl:max-h-[calc(70vh-100px)] overflow-auto">
                                {!features || isLoading
                                    ? Array.from(Array(10).keys()).map((ix) => (
                                          <tr key={ix} className="rt-tr-group">
                                              {Array.from(Array(6).keys()).map(
                                                  (ix2) => (
                                                      <td
                                                          key={ix2}
                                                          className={cx(
                                                              'px-12 text-black title-body break-all !h-24'
                                                          )}
                                                      >
                                                          <p className="bg-alto w-full h-6 animate-pulse rounded-default" />
                                                      </td>
                                                  )
                                              )}
                                          </tr>
                                      ))
                                    : table
                                          .getRowModel()
                                          .rows.map((row: any) => (
                                              <tr
                                                  key={row.id}
                                                  className="rt-tr-group"
                                              >
                                                  {row
                                                      .getVisibleCells()
                                                      .map((cell: any) => {
                                                          const columnSize =
                                                              cell.column
                                                                  .columnDef
                                                                  .meta?.size;

                                                          const textAlign =
                                                              cell.column
                                                                  .columnDef
                                                                  .meta
                                                                  ?.textAlign;
                                                          const columnWidth = `w-${columnSize}`;

                                                          return (
                                                              <td
                                                                  key={cell.id}
                                                                  className={cx(
                                                                      'p-2 text-black title-body break-all text-[14px] font-medium',
                                                                      columnSize &&
                                                                          columnWidth,
                                                                      textAlign &&
                                                                          textAlign
                                                                  )}
                                                              >
                                                                  {getCellValue(
                                                                      cell
                                                                  )}
                                                              </td>
                                                          );
                                                      })}
                                              </tr>
                                          ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-6 sm:flex sm:items-center sm:justify-between">
                        {!isLoading && (
                            <span className="flex items-center gap-1">
                                <div>{t('feature-flags:Page')}</div>
                                <strong>
                                    {table.getState().pagination.pageIndex + 1}{' '}
                                    {t('feature-flags:Of')}{' '}
                                    {table.getPageCount()}
                                </strong>
                                {numberOfRecords !== 0 && (
                                    <p>{`(${numberOfRecords} ${t(
                                        'feature-flags:Records'
                                    )})`}</p>
                                )}
                            </span>
                        )}
                        <div className="mt-4 flex items-center sm:mt-0 ml-auto">
                            {table.getCanPreviousPage() && (
                                <Button
                                    scheme="secondary"
                                    className="m-2 focus:ring-offset-0"
                                    onClick={() => table.previousPage()}
                                    iconSettings={{
                                        iconSide: 'left',
                                        Icon: ArrowLeft32,
                                    }}
                                >
                                    <div className="text-xs w-max">
                                        {t('feature-flags:Previous')}
                                    </div>
                                </Button>
                            )}
                            {table.getCanNextPage() && (
                                <Button
                                    scheme="primary"
                                    className="m-2 focus:ring-offset-0"
                                    onClick={() => table.nextPage()}
                                    iconSettings={{
                                        iconSide: 'right',
                                        Icon: ArrowRight32,
                                    }}
                                >
                                    <div className="text-xs w-max">
                                        {t('feature-flags:Next')}
                                    </div>
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
                <ConfirmationModal
                    cancelText={t('form-content:LabelCancel')}
                    confirmText={t('form-content:LabelDelete')}
                    onConfirm={() =>
                        handleDeleteFlag(deleteFeatureFlagValues?.key)
                    }
                    closeModal={() => setIsDeleteModalVisible(false)}
                    isModalVisible={isDeleteModalVisible}
                    title={t('form-content:ConfirmDelete')}
                >
                    <p className="text-center">
                        {t('form-content:ArrayItemDeleteConfirmation', {
                            elementName: deleteFeatureFlagValues?.name,
                        })}
                    </p>
                </ConfirmationModal>
                <ConfirmationModal
                    cancelText={t('form-content:LabelCancel')}
                    closeModal={() => setIsAuditlogModalVisible(false)}
                    isModalVisible={isAuditlogModalVisible}
                    title={t('feature-flags:AuditLog')}
                >
                    <div className="flex flex-col overflow-y-scroll max-h-[500px]">
                        {auditLogData?.map((item: any) => (
                            <div className="flex flex-col p-6 gap-y-4 border-2 border-alto mb-4 rounded-default">
                                {item.date && (
                                    <span>
                                        <p className="text-body font-semibold">
                                            {t('feature-flags:Date')}
                                        </p>
                                        {format(
                                            parseISO(item.date.toString()),
                                            reduxDateFormat.DateTime,
                                            {
                                                locale: reduxLocale,
                                            }
                                        )}
                                    </span>
                                )}
                                {item.user && item.user !== '' && (
                                    <span>
                                        <p className="text-body font-semibold">
                                            {t('feature-flags:User')}
                                        </p>
                                        {item.user}
                                    </span>
                                )}
                                {item.environment && item.environment !== '' && (
                                    <span>
                                        <p className="text-body font-semibold">
                                            {t('feature-flags:Environment')}
                                        </p>
                                        {item.environment
                                            ?.charAt(0)
                                            .toUpperCase() +
                                            item?.environment?.slice(1)}
                                    </span>
                                )}
                                {item.title && item.title !== '' && (
                                    <span>
                                        <p className="text-body font-semibold">
                                            {t('feature-flags:Action')}
                                        </p>
                                        {item.title?.charAt(0).toUpperCase() +
                                            item?.title?.slice(1)}
                                    </span>
                                )}
                                {item.comment && item.comment !== '' && (
                                    <span>
                                        <p className="text-body font-semibold">
                                            {t('feature-flags:Comment')}
                                        </p>
                                        {nl2br(item.comment)}
                                    </span>
                                )}
                            </div>
                        ))}
                    </div>
                </ConfirmationModal>
            </div>
        </OktaAuthWrapper>
    );
};

export default FeatureFlagsListPage;
