import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

export interface AuditLog {
    action: string;
    formId: string;
    formStatus: number;
    formVersion: string;
    id: string;
    tenderId: string;
    timestamp: string;
    userId: string;
}

const fetcher = (url: string) =>
    axios.get(url).then(async (res: AxiosResponse): Promise<AuditLog[]> => {
        const { data } = res;
        return data;
    });

const useFetchAuditlog = (formId: string) => {
    const { data, error } = useSWRImmutable(
        `/rep/form/auditlog/${formId}`,
        fetcher,
        { shouldRetryOnError: false }
    );

    return {
        auditLog: data,
        isLoadingAuditLog: !error && !data,
        isErrorFetchingAuditLog: error,
    };
};

export default useFetchAuditlog;
