import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

const fetcher = (url: string) =>
    axios.get(url).then(async (res: AxiosResponse) => res.data);

const useFetchFormJson = (formId: string) => {
    const { data, error } = useSWRImmutable(`/rep/form/${formId}`, fetcher, {
        shouldRetryOnError: false,
    });

    return {
        formJson: data ?? {},
        isLoadingFormJson: !data && !error,
        isErrorFetchingFormJson: error,
    };
};

export default useFetchFormJson;
