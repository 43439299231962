import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

const fetcher = (url: string) =>
    axios.get(url).then(async (res: AxiosResponse): Promise<string> => {
        const { data } = res;
        return data;
    });

const useFetchPreview = (
    formId: string,
    language: string,
    previewDocumentTypeIdentifier: string
) => {
    const { data, error } = useSWRImmutable(
        `/rep/form/previewdocument/${formId}/${language}/${previewDocumentTypeIdentifier}`,
        fetcher,
        { shouldRetryOnError: false }
    );

    return {
        filestream: data,
        isLoadingPreview: !error && !data,
        isErrorFetchingPreview: error,
    };
};

export default useFetchPreview;
