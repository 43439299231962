/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';

type CallbackFunction = (...args: any[]) => void;

function useDebouncedCallback(
    callback: any,
    delay: number,
    deps: any[]
): CallbackFunction {
    const debouncedCallback = useCallback(debounce(callback, delay), deps);

    useEffect(
        () => () => {
            debouncedCallback.cancel();
        },
        [debouncedCallback]
    );

    return debouncedCallback;
}

export default useDebouncedCallback;
