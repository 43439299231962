import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { useParams } from 'react-router-dom';
import ReactJson from 'react-json-view';

const TempFullDataStructurePage = () => {
    const { subtypeId } = useParams() as {
        subtypeId: string;
    };

    const [jsonData, setJsonData] = useState<any>();

    useEffect(() => {
        Axios.get(`test/fulldata/${subtypeId ?? 1}`).then((response) => {
            const { data } = response;
            setJsonData(data);
        });
    }, [subtypeId]);

    return (
        <div className="py-20 col-span-12">
            <ReactJson
                src={jsonData}
                collapsed={1}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={false}
                indentWidth={8}
            />
        </div>
    );
};

export default TempFullDataStructurePage;
