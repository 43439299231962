import {
    getFormLanguage,
    setFormLanguage,
} from '../storageService/sessionService';

export const lingualTextLanguageSelectorMutator = (args: any, state: any) => {
    const lingualTextLanguageSelectorNode = document.querySelectorAll(
        "[id^='LingualTextLanguageSelector']"
    );

    if (lingualTextLanguageSelectorNode.length > 1) {
        const { formState } = state;

        const language = getFormLanguage();
        const selectedFormLanguage =
            formState.values.LingualTextLanguageSelector;
        if (selectedFormLanguage !== language) {
            setFormLanguage(selectedFormLanguage);
        }
    }
};
