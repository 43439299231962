import axios from 'axios';

export const deleteFormFromDB = (
    formId: string,
    toastText?: {
        onSuccessText?: string;
        onPendingText?: string;
    }
) =>
    axios
        .delete(`/rep/form/${formId}`, {
            headers: {
                'Toast-Pending-Text': toastText?.onPendingText,
                'Toast-Success-Text': toastText?.onSuccessText,
            },
        })
        .then((res) => res.status === 200)
        .catch((error) => {
            throw error;
        });
