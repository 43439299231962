import React, { FC, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ValidationErrorObject } from '../../types/validationError';
import { useValidate } from '../../shared/validationModule/useValidate';
import cx from 'classnames';
import useFetchSections from '../../hooks/formHooks/useFetchSections';
import FormTranslationReviewPage from './FormTranslationReviewPage';
import { FormStatus } from '../../types/generated/formMenuResult';
import LoadingSpinner from '@mercell/loading-spinner-react';
import useFetchSessionInfo from '../../hooks/formHooks/useFetchSessionInfo';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import useSetupContext from '../../shared/setupUserContext';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setNoticeStatus } from '../../redux/storeSlice';

const FormPage: FC = () => {
    const { useRevalidateOnFocus1734 } = useFlags();
    const { formId, parentSectionId, sectionId, parentArrayElementId } =
        useParams<{
            formId: string;
            parentSectionId: string;
            sectionId: string;
            parentArrayElementId?: string;
        }>();
    const [backendValidationErrors, setBackendValidationErrors] =
        useState<ValidationErrorObject>({
            submittedErrors: {},
            renderedFieldsWithErrors: {},
            errorObjectWithPaths: {},
            isFormValidatedWithoutErrors: false,
        });
    const reduxNoticeStatus = useAppSelector(
        (state) => state.store.noticeStatus
    );
    const reduxDispatch = useAppDispatch();

    const [refetchStatus, setRefetchStatus] = useState<boolean>(false);
    const [showMyChecklistBeacon, setShowMyChecklistBeacon] =
        useState<boolean>(true);

    const [isLoadingFetchingValidations, setIsLoadingFetchingValidations] =
        useState<boolean>(false);
    const [isErrorFetchingValidations, setIsErrorFetchingValidations] =
        useState<any>();

    const { t } = useTranslation([
        'form-content',
        'toast-content',
        'list.audit-log-status',
        'list.language',
        'list.exclusion-ground',
    ]);

    const {
        sections,
        isFormPreview,
        setHotReloadOfMenu,
        parentElementTitleKey,
        navigationTitle,
        isErrorFetchingSections,
        currentFormStatus,
        FormMainLanguage,
        noticeTimeZone,
    } = useFetchSections(
        formId,
        parentArrayElementId,
        useRevalidateOnFocus1734
    );

    const validateMethod = useValidate(
        setBackendValidationErrors,
        formId || '',
        parentSectionId || '',
        sectionId || '',
        t,
        setHotReloadOfMenu,
        setIsLoadingFetchingValidations,
        setIsErrorFetchingValidations,
        setRefetchStatus
    );
    const ldClient = useLDClient();
    const { sessionInfo } = useFetchSessionInfo();
    const { multiContext, isValid } = useSetupContext(sessionInfo);

    useEffect(() => {
        if (isValid && multiContext) {
            ldClient
                ?.waitForInitialization()
                .then(() => {
                    ldClient?.identify(multiContext);
                })
                .catch((err) => {
                    // eslint-disable-next-line no-console
                    console.warn(err);
                });
        }
    }, [isValid, multiContext, ldClient]);

    const isFormReceivedTranslation =
        currentFormStatus === FormStatus.TranslationReceived;

    const isFormDispatchedForTranslation =
        currentFormStatus === FormStatus.DispatchedForTranslation;

    useEffect(() => {
        if (
            currentFormStatus !== undefined &&
            reduxNoticeStatus?.status !== FormStatus[currentFormStatus]
        ) {
            const formStatus = t(
                `list.audit-log-status:${FormStatus[currentFormStatus]}`
            );
            reduxDispatch(
                setNoticeStatus({
                    value: currentFormStatus,
                    status: formStatus,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFormStatus, refetchStatus]);

    if (!formId) return <div>No form chosen...</div>;

    if (isFormDispatchedForTranslation) {
        return (
            <div className="col-span-full h-full flex flex-col items-center justify-center gap-y-12">
                <LoadingSpinner />
                <p className="text-studio font-semibold w-[30%] text-center">
                    {t('form-content:FetchTranslationPleaseWait')}
                </p>
            </div>
        );
    }

    if (isFormReceivedTranslation) {
        return (
            <FormTranslationReviewPage
                formId={formId}
                isLoadingFetchingValidations={isLoadingFetchingValidations}
                backendValidationErrors={backendValidationErrors}
                validateMethod={validateMethod}
                t={t}
                isFormPreview={isFormPreview}
                firstLanguage={FormMainLanguage ?? 'ENG'}
                secondLanguage="ENG"
            />
        );
    }

    return (
        <div
            className={cx(
                'col-span-full grid grid-cols-[repeat(11,1fr)] grid-rows-[1fr]'
            )}
        >
            <Outlet
                context={{
                    isErrorFetchingSections,
                    currentFormStatus,
                    setBackendValidationErrors,
                    backendValidationErrors,
                    sections,
                    isFormPreview,
                    setHotReloadOfMenu,
                    parentElementTitleKey,
                    navigationTitle,
                    validateMethod,
                    isLoadingFetchingValidations,
                    isErrorFetchingValidations,
                    setShowMyChecklistBeacon,
                    showMyChecklistBeacon,
                    isFormDispatchedForTranslation,
                    noticeTimeZone,
                }}
            />
        </div>
    );
};

FormPage.displayName = 'FormPage';
FormPage.whyDidYouRender = true;
export default FormPage;
