/* eslint-disable camelcase */
import { Alert, AlertText } from '@mercell/alert-react';
import {
    FieldOrigin,
    FormWrapper,
    AssociatedValidation,
    ChildrenRenderProps,
    FormContextInterface,
    evaluateConditions,
} from '@mercell/form-react';
import { AnySchema, ValidationError } from 'yup';
import parseFormDefinitionWrapper from '../../shared/mappedFormSchema';
import React, { FC, memo, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useFetchArrayItem, {
    FormDataStateInterface,
} from '../../hooks/formHooks/useFetchArrayItem';
import FormError from '../../components/FormError';
import {
    Link,
    useNavigate,
    useOutletContext,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { getFormLanguage } from '../../shared/storageService/sessionService';
import { ReadOnlyComponent } from '../../components/ReadOnlyComponent';
import NotFoundPage from '../NotFound/NotFoundPage';
import { useStoreContext } from '../../state';
import LoadingSpinner from '@mercell/loading-spinner-react';
import { scrollIntoView } from '../../shared/scrollIntoView';
import { backendErrorSubmitMutator } from '../../shared/validationModule/backendErrorSubmitMutator';
import Breadcrumbs from '@mercell/breadcrumbs-react';
import { OutletFormContextInterface } from '../../types/outletInterface';
import { lingualFieldSelectionMutator } from '../../shared/validationModule/lingualFieldSelectionMutator';
import { useLingualFieldSelectionMutator } from '../../hooks/formHooks/useLingualFieldSelectionMutator';
import { useBackendErrorSubmitMutator } from '../../hooks/formHooks/useBackendErrorSubmitMutator';
import { useAutoSaveForm } from '../../hooks/formHooks/useAutoSaveForm';
import { PreviewElementStyle } from '../../shared/getStyleClasses';
import { FormStatus } from '../../types/generated/formMenuResult';
import Button from '@mercell/button-react';
import BackNavigation from '../../types/backNavigation';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setNoticeStatus, setTenderName } from '../../redux/storeSlice';

const ArrayElementDetail: FC = memo(() => {
    const navigate = useNavigate();

    const { featureFlagUseUtcDatesOnly, featureFlagEnableTextAreaCounter } =
        useFlags();
    const {
        state: { tenderName: tenderNameState },
        dispatch,
    } = useStoreContext();
    const reduxNoticeStatus = useAppSelector(
        (state) => state.store.noticeStatus
    );
    const reduxDateFormat = useAppSelector((state) => state.store.dateFormat);
    const reduxLocale = useAppSelector((state) => state.store.locale);
    const reduxTenderName = useAppSelector((state) => state.store.tenderName);
    const reduxDispatch = useAppDispatch();
    const {
        setBackendValidationErrors,
        backendValidationErrors,
        currentSelectedSection = {
            elementTitleKey: '',
            labelAppend: '',
            translationKey: '',
        },
        navigationTitle,
        setHotReloadOfMenu,
        parentElementTitleKey,
    } = useOutletContext<OutletFormContextInterface>();
    const { renderedFieldsWithErrors } = backendValidationErrors;
    const {
        formId,
        sectionId,
        arrayPath,
        parentSectionId,
        parentArrayPath,
        arrayElementId,
        parentArrayElementId,
    } = useParams<{
        formId: string;
        sectionId: string;
        arrayPath?: string;
        parentSectionId: string;
        parentArrayPath?: string;
        arrayElementId?: string;
        parentArrayElementId?: string;
    }>();
    const [searchParams, setSearchParams] = useSearchParams();
    const sectionPath = !parentArrayElementId
        ? sectionId
        : `${parentArrayElementId}.${sectionId}`;

    const {
        formData,
        isErrorFetchingForm,
        isLoadingArrayItem,
        tenderName,
        setState,
    } = useFetchArrayItem(
        formId ?? '',
        sectionPath ?? '',
        arrayPath,
        arrayElementId
    );

    const setupBackNavigation = () => {
        let backNavigation: BackNavigation = { path: '' };
        if (parentSectionId && !arrayElementId && parentElementTitleKey) {
            backNavigation = {
                path: `/form/${formId}/${parentSectionId}/${parentArrayPath}`,
            };
        }
        if (parentSectionId && arrayElementId && currentSelectedSection) {
            backNavigation = {
                path: `/form/${formId}/sub/${parentSectionId}/${parentArrayPath}/${parentArrayElementId}/${sectionId}/${arrayPath}`,
            };
        }
        if (!parentSectionId && arrayElementId && currentSelectedSection) {
            backNavigation = {
                path: `/form/${formId}/${sectionId}/${arrayPath}`,
            };
        }
        return backNavigation;
    };

    const backNavigation = setupBackNavigation();

    const { t } = useTranslation(
        ['form-content'].concat(formData?.codelists ?? [])
    );
    const arrayElementTitle =
        arrayElementId && arrayElementId !== 'add'
            ? `${t(currentSelectedSection.elementTitleKey ?? '').toLowerCase()}`
            : t(currentSelectedSection?.labelAppend ?? '');
    const language = getFormLanguage();

    const baseUrl = () =>
        !parentSectionId
            ? `/form/${formId}`
            : `/form/${formId}/sub/${parentSectionId}/${parentArrayPath}/${parentArrayElementId}`;

    function resetNoticeStatusToDraft() {
        const noticeStatusPayload = {
            value: 0,
            status: t(`list.audit-log-status:${FormStatus[0]}`),
        };

        if (reduxNoticeStatus?.value !== 0) {
            reduxDispatch(setNoticeStatus(noticeStatusPayload));
        }
    }

    const { onAutoSave } = useAutoSaveForm<FormDataStateInterface>(
        `array/item/${formId}/${language}/${
            sectionId ? `${sectionPath}` : ''
        }/${arrayPath}`,
        formData,
        setState,
        setHotReloadOfMenu,
        resetNoticeStatusToDraft
    );

    useEffect(() => {
        if (tenderName && reduxTenderName !== tenderName) {
            reduxDispatch(setTenderName(tenderName ?? ''));
        }
    }, [dispatch, tenderNameState, tenderName, reduxTenderName, reduxDispatch]);

    useEffect(() => {
        const fieldName = searchParams.get('clicked') || '';
        const field = renderedFieldsWithErrors[fieldName];
        if (fieldName && field) {
            scrollIntoView(
                renderedFieldsWithErrors[fieldName],
                searchParams,
                setSearchParams
            );
        }
    }, [setSearchParams, renderedFieldsWithErrors, searchParams]);

    if (!formId) {
        return null;
    }
    if (isLoadingArrayItem) {
        return (
            <div className="col-span-full h-full flex items-center justify-center">
                <LoadingSpinner />
            </div>
        );
    }

    if (isErrorFetchingForm) {
        return (
            <NotFoundPage errorCode={isErrorFetchingForm.status.toString()} />
        );
    }
    if (!formData || !formData.formDefinition.length) {
        return <div>No form in DB</div>;
    }
    const { formDefinition, errors, values, isFormPreview, noticeTimeZone } =
        formData;
    const schema = parseFormDefinitionWrapper(
        formDefinition,
        t,
        isFormPreview,
        reduxDateFormat,
        undefined,
        reduxLocale,
        featureFlagUseUtcDatesOnly,
        noticeTimeZone,
        featureFlagEnableTextAreaCounter
    );

    const validationCheck: FormContextInterface['validationCallback'] = async (
        fieldValue: any,
        fieldValidation: any,
        allValues?: object,
        associatedValidationBasedOnOtherFieldValues?: AssociatedValidation,
        reApplyRequiredIndicator?: React.Dispatch<React.SetStateAction<boolean>>
    ): Promise<string> => {
        const arrayOfErrors: string[] = [];

        if (!fieldValidation && !associatedValidationBasedOnOtherFieldValues) {
            return '';
        }

        if (associatedValidationBasedOnOtherFieldValues?.conditionalFields) {
            const result = evaluateConditions(
                associatedValidationBasedOnOtherFieldValues.conditionalFields,
                allValues
            );
            if (
                result &&
                !associatedValidationBasedOnOtherFieldValues.rules &&
                associatedValidationBasedOnOtherFieldValues.message
            ) {
                arrayOfErrors.push(
                    associatedValidationBasedOnOtherFieldValues.message
                );
            } else if (
                result &&
                associatedValidationBasedOnOtherFieldValues.rules
            ) {
                try {
                    (
                        associatedValidationBasedOnOtherFieldValues.rules as AnySchema
                    )?.validateSync(fieldValue, {
                        abortEarly: false,
                    });
                } catch (err: any) {
                    err.inner.forEach((e: ValidationError) => {
                        arrayOfErrors.push(e.message);
                    });
                    if (
                        associatedValidationBasedOnOtherFieldValues.reApplyRequiredIndicator &&
                        reApplyRequiredIndicator
                    ) {
                        reApplyRequiredIndicator(true);
                    }
                }
            } else if (
                !result &&
                associatedValidationBasedOnOtherFieldValues.rules
            ) {
                if (
                    associatedValidationBasedOnOtherFieldValues.reApplyRequiredIndicator &&
                    reApplyRequiredIndicator
                ) {
                    reApplyRequiredIndicator(false);
                }
            }
        }
        try {
            fieldValidation?.validateSync(fieldValue, {
                abortEarly: false,
            });
        } catch (err: any) {
            err.inner.forEach((e: ValidationError) => {
                arrayOfErrors.push(e.message);
            });
        }
        return arrayOfErrors.join('\n');
    };
    return (
        <div>
            <Breadcrumbs className="ml-4 mt-1 mb-4">
                {parentElementTitleKey ? (
                    <Link
                        to={`/form/${formId}/${parentSectionId}/${parentArrayPath}`}
                    >
                        {t(parentElementTitleKey ?? '')}
                    </Link>
                ) : null}
                <Link
                    to={
                        parentSectionId
                            ? `/form/${formId}/sub/${parentSectionId}/${parentArrayPath}/${parentArrayElementId}/${sectionId}/${arrayPath}`
                            : `/form/${formId}/${sectionId}/${arrayPath}`
                    }
                >
                    {t(currentSelectedSection?.translationKey ?? '')}
                </Link>
                <Link
                    to={window.location.pathname}
                    className="pointer-events-none"
                >
                    {arrayElementTitle}
                </Link>
            </Breadcrumbs>
            {parentSectionId && (
                <div className="flex items-center text-h2 ml-4 mb-4 mt-1 pb-3 border-alto border-b-2">
                    {navigationTitle ?? t('form-content:NewItem')}
                </div>
            )}
            {errors.formErrors?.map((error: any) => (
                <FormError error={error} key={error} />
            ))}
            {arrayPath === 'Buyers' && arrayElementId === undefined && (
                <Alert role="alert" scheme="information" className="mt-5">
                    <AlertText>
                        <Trans
                            i18nKey="form-content:BuyerCreationInformation"
                            t={t}
                            components={[
                                <Link
                                    className="link"
                                    to={`/form/${formId}/CompaniesSection`}
                                />,
                            ]}
                        />
                    </AlertText>
                </Alert>
            )}
            {formDefinition?.length > 0 && values && (
                <>
                    <FormWrapper
                        onSubmit={() => undefined}
                        onAutoSave={!isFormPreview ? onAutoSave : undefined}
                        validationCallback={validationCheck}
                        schema={schema}
                        formLabelProperties={{
                            className: 'text-h1 font-bold',
                        }}
                        formProperties={{
                            className: 'm-4', // max-w-lg
                        }}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        readOnlyFieldRender={(
                            content: any,
                            name: string,
                            restReadOnlyProperties,
                            fieldOrigin?: FieldOrigin,
                            elementProperties?: React.DetailedHTMLProps<
                                React.HTMLAttributes<HTMLElement>,
                                HTMLElement
                            >
                        ) => (
                            <ReadOnlyComponent
                                content={content}
                                name={name}
                                fieldOrigin={fieldOrigin}
                                readOnlyProperties={restReadOnlyProperties}
                                elementProperties={elementProperties}
                                t={t}
                                locale={reduxLocale}
                                noticeTimeZone={noticeTimeZone}
                            />
                        )}
                        previewElementStyle={PreviewElementStyle}
                        subscription={{
                            dirtySinceLastSubmit: true,
                        }}
                        mutators={{
                            backendErrorSubmitMutator,
                            lingualFieldSelectionMutator,
                        }}
                        autoSaveThrottleDelay={1000}
                        formTitle="" // "React Final Form"
                        initialValue={values}
                        showErrorsOnInitialRender={arrayElementId !== undefined}
                        childrenRender={Render}
                        parentSectionId={parentSectionId}
                        sectionId={sectionId}
                        arrayElementId={arrayElementId}
                        parentArrayElementId={parentArrayElementId}
                        backendValidationErrors={backendValidationErrors}
                        setBackendValidationErrors={setBackendValidationErrors}
                    />

                    <div className="flex flex-row justify-end pr-4 pt-4">
                        <Button
                            scheme="secondary"
                            onClick={() => navigate(backNavigation.path)}
                        >
                            {t('form-content:Close')}
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
});

const Render = ({
    mutators,
    setBackendValidationErrors,
    backendValidationErrors,
    parentSectionId,
    sectionId,
    arrayElementId,
    parentArrayElementId,
}: ChildrenRenderProps) => {
    useLingualFieldSelectionMutator(
        mutators.lingualFieldSelectionMutator,
        setBackendValidationErrors
    );
    useBackendErrorSubmitMutator(
        mutators.backendErrorSubmitMutator,
        backendValidationErrors,
        setBackendValidationErrors,
        parentSectionId || '',
        sectionId || '',
        arrayElementId || '',
        parentArrayElementId || ''
    );
    return null;
};

ArrayElementDetail.displayName = 'ArrayElementDetail';
ArrayElementDetail.whyDidYouRender = true;
export default ArrayElementDetail;
