import React, { FC } from 'react';
import { TFunction } from 'react-i18next';
import CustomContentMenu from '../../../components/CustomContentMenu';
import Button from '@mercell/button-react';
import { ChevronLeft24 } from '@carbon/icons-react';
import cx from 'classnames';
import { FormMenuItem } from '../../../types/generated/formMenuResult';
import { NavigateFunction } from 'react-router-dom';
import BackNavigation from '../../../types/backNavigation';

interface MenuSectionsInterface {
    arrayElementId?: string;
    parentSectionId?: string;
    sections?: FormMenuItem[];
    sectionId?: string;
    isErrorFetchingSections: boolean;
    navigate: NavigateFunction;
    backNavigation: BackNavigation;
    isFormPreview?: boolean;
    isDrawerVisible: boolean;
    openDrawer: () => void;
    closeDrawer: () => void;
    showMyChecklistBeacon?: boolean;
    changeSectionIndex: (value: string) => void;
    setShowNavigation: (value: React.SetStateAction<boolean>) => void;
    t: TFunction<string, unknown>;
}

export const MenuSections: FC<MenuSectionsInterface> = ({
    arrayElementId,
    parentSectionId,
    sections,
    isErrorFetchingSections,
    navigate,
    backNavigation,
    isFormPreview,
    isDrawerVisible,
    openDrawer,
    closeDrawer,
    showMyChecklistBeacon,
    changeSectionIndex,
    setShowNavigation,
    sectionId,
    t,
}) => (
    <>
        {' '}
        {(arrayElementId || parentSectionId) &&
            sections &&
            !isErrorFetchingSections && (
                <div className="my-2 min-w-full print:hidden whitespace-nowrap">
                    <Button
                        scheme="tertiary"
                        onClick={() => navigate(backNavigation.path)}
                        iconSettings={{ Icon: ChevronLeft24 }}
                        type="button"
                        className="flex text-valhalla font-semibold px-0"
                        data-test="back-navigation-button"
                    >
                        {t('form-content:BackToElement', {
                            element: backNavigation.label,
                        })}
                    </Button>
                </div>
            )}
        {!isFormPreview &&
            (!sections || isErrorFetchingSections ? (
                <div
                    className={cx(`p-2 my-1 text-valhalla flex items-baseline`)}
                >
                    <p className="bg-alto w-full h-6 animate-pulse rounded-default" />
                </div>
            ) : (
                <Button
                    type="button"
                    scheme="secondary"
                    className="my-2 min-w-full border-2 relative inline-block  overflow-visible"
                    onClick={() => {
                        if (!isDrawerVisible) {
                            openDrawer();
                        } else {
                            closeDrawer();
                        }
                    }}
                >
                    {t('form-content:CheckList')}
                    {!showMyChecklistBeacon && (
                        <span className="absolute flex -top-2 -right-2 w-4 h-4">
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-well-read opacity-85" />
                            <span className="relative inline-flex rounded-full  w-4 h-4 bg-well-read" />
                        </span>
                    )}
                </Button>
            ))}
        <CustomContentMenu
            className={cx(
                'w-[100%]',
                (isErrorFetchingSections || !sections) && 'min-w-[230px]'
            )}
            primaryItems={
                sections
                    ? sections.map((section) => ({
                          title: t(section.translationKey),
                          path: section.arrayPath
                              ? `${section.sectionName}/${section.arrayPath}`
                              : section.sectionName,
                          parentSectionPath: section.parentSectionName,
                          isItemActive: sectionId === section.sectionName,
                          isChevronActive: sectionId === section.sectionName,
                      }))
                    : []
            }
            onClick={(e, title, path) => {
                e.preventDefault();
                changeSectionIndex(path);
                setShowNavigation(false);
                window.scrollTo(0, 0);
            }}
        />
    </>
);
