/* istanbul ignore file */
import axios, { AxiosResponse } from 'axios';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { FormTextsResult } from '../../types/generated/formTextsResult';
import { delay } from '../../shared/utilities/delay';
import { useAppDispatch } from '../../redux/hooks';
import {
    setAutoSaveLoading,
    setaAutoSaveError,
    setaAutoSaveSaved,
} from '../../redux/storeSlice';

interface SubmitReviewTranslationsProps {
    formId?: string;
    setTranslationsApproved: Dispatch<SetStateAction<boolean | undefined>>;
    reviewedLanguage: string;
    setIsLoadingTranslationsApproval: Dispatch<SetStateAction<boolean>>;
}
const axiosPost = (
    url: string,
    formData?: FormTextsResult
): Promise<any | undefined> =>
    axios.post(url, formData).then((res: AxiosResponse): number => res.status);

interface SubmitReturn {
    onSubmit: (
        data: FormTextsResult,
        isSaving: boolean
    ) => Promise<string | undefined>;
}

export const useSubmitReviewTranslations = (
    props: SubmitReviewTranslationsProps
): SubmitReturn => {
    const {
        formId,
        reviewedLanguage,
        setIsLoadingTranslationsApproval,
        setTranslationsApproved,
    } = props;
    const reduxDispatch = useAppDispatch();
    const onSubmit = useCallback(
        async (data: FormTextsResult, isSaving = true): Promise<any> => {
            let result;

            if (isSaving) {
                reduxDispatch(setAutoSaveLoading());
            }
            try {
                if (!isSaving) {
                    setIsLoadingTranslationsApproval(true);
                }
                result = await axiosPost(
                    `form/texts/save/${
                        formId ?? ''
                    }/${reviewedLanguage}/${!isSaving}`,
                    data
                );

                if (!isSaving) {
                    setIsLoadingTranslationsApproval(false);
                }

                if (isSaving) {
                    await delay(2000);
                    reduxDispatch(setaAutoSaveSaved());
                }
            } catch {
                if (!isSaving) {
                    setIsLoadingTranslationsApproval(false);
                    setTranslationsApproved(false);
                } else if (isSaving) {
                    reduxDispatch(setaAutoSaveError());
                }
            }

            if (setTranslationsApproved && result === 200 && !isSaving)
                setTranslationsApproved(true);

            return undefined;
        },
        [
            setTranslationsApproved,
            reduxDispatch,
            formId,
            reviewedLanguage,
            setIsLoadingTranslationsApproval,
        ]
    );
    return { onSubmit };
};
