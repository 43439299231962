/* istanbul ignore file */
import axios, { AxiosResponse } from 'axios';
import { PublicationSiteConfiguration } from '../../types/generated/publicationSiteConfiguration';
import { useCallback } from 'react';
import { KeyedMutator } from 'swr';

interface SubmitPublicationSiteConfigurationProps {
    fetchPublicationSiteConfigurations: KeyedMutator<
        PublicationSiteConfiguration[]
    >;
}

const axiosPost = (
    url: string,
    publicationSiteConfiguration?: PublicationSiteConfiguration
): Promise<any | undefined> =>
    axios
        .post(url, publicationSiteConfiguration)
        .then((res: AxiosResponse): number => res.status);

interface SubmitReturn {
    onSubmit: (
        data: PublicationSiteConfiguration
    ) => Promise<string | undefined>;
}

export const useSubmitPublicationSiteConfiguration = (
    props: SubmitPublicationSiteConfigurationProps
): SubmitReturn => {
    const { fetchPublicationSiteConfigurations } = props;
    const onSubmit = useCallback(
        async (data: PublicationSiteConfiguration): Promise<any> => {
            let result;

            try {
                result = await axiosPost(
                    `/configurator/publication-site-configurations`,
                    data
                );

                if (result) {
                    fetchPublicationSiteConfigurations();
                }
                return true;
            } catch (error) {
                console.warn(error);
            }

            return false;
        },
        [fetchPublicationSiteConfigurations]
    );
    return { onSubmit };
};
