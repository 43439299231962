import React from 'react';
import { Card, CardText, CardTitle } from '@mercell/card-react';
import Button from '@mercell/button-react';

const Login = () => (
    <div className="col-span-full">
        <Card>
            <CardTitle>LOGIN</CardTitle>
            <CardText>Some text</CardText>
            <Button
                scheme="primary"
                onClick={() => window.location.replace('/')}
            >
                Login
            </Button>
        </Card>
    </div>
);

export default Login;
