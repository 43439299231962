import Checkbox from '@mercell/checkbox-react';
import React, { FC, useState } from 'react';
import { ConfiguratorFormDefinition } from '../../../types/generated/configuratorFormDefinition';
import { Link, useParams } from 'react-router-dom';
import {
    CheckmarkFilled24,
    PendingFilled24,
    WarningFilled24,
} from '@carbon/icons-react';
import { TFunction } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { useAppSelector } from '../../../redux/hooks';

interface SingleNoticeProps {
    country?: string;
    formDefinition: ConfiguratorFormDefinition;
    t: TFunction<string, unknown>;
    onChangeAllowed: (
        value: boolean,
        definitionId: string,
        setError: React.Dispatch<React.SetStateAction<string | undefined>>
    ) => Promise<number | null>;
}

const SingleNotice: FC<SingleNoticeProps> = ({
    formDefinition,
    t,
    country,
    onChangeAllowed,
}) => {
    const [error, setError] = useState<string | undefined>();

    const reduxLocale = useAppSelector((state) => state.store.locale);
    const reduxDateFormat = useAppSelector((state) => state.store.dateFormat);

    const { versionName, versionId } = useParams<{
        versionName: string;
        versionId: string;
    }>();

    const {
        isAllowed,
        noticeSubtype,
        directiveKey,
        formTypeKey,
        isFinalized,
        id,
        updatedOn,
        updatedBy,
    } = formDefinition;

    const [isChecked, setIsChecked] = useState<boolean>(isAllowed);

    const url = `/configurator/${versionName}/${versionId}/notices/definitionid/${id}${
        country ? `/${country}` : ''
    }`;

    const onCheck = async () => {
        setIsChecked(!isChecked);
        await onChangeAllowed(!isChecked, id, setError);
    };
    const lastChanged =
        updatedOn !== undefined
            ? format(
                  parseISO(updatedOn?.toString()),
                  reduxDateFormat?.DateTime,
                  {
                      locale: reduxLocale,
                  }
              )
            : '';
    const linkContent = `${noticeSubtype} ${t(directiveKey ?? '')} ${t(
        formTypeKey ?? ''
    )}`;

    return (
        <div className="p-5 pl-3 flex w-full items-center">
            <div className="w-1/12 flex justify-center">
                <Checkbox
                    id={`is-allowed-${noticeSubtype}`}
                    checked={isChecked}
                    onChange={onCheck}
                />
            </div>
            <Link to={url} className="w-9/12 px-8 whitespace-normal">
                {linkContent}
            </Link>
            {!error ? (
                <div className="w-4/12 flex justify-center">
                    {isFinalized ? (
                        <div className="flex">
                            <CheckmarkFilled24 color="green" />
                            <p className="pl-2">
                                {t('form-configurator:Finalized')}
                            </p>
                        </div>
                    ) : (
                        <div className="flex">
                            <PendingFilled24 color="orange" />
                            <p className="pl-2">
                                {t('form-configurator:InProgress')}
                            </p>
                        </div>
                    )}
                </div>
            ) : (
                <div className="w-4/12 flex justify-center text-important-accent">
                    <div className="flex">
                        <WarningFilled24 color="red" />
                        <p className="pl-2">{error}</p>
                    </div>
                </div>
            )}
            <div className="w-7/12 flex justify-center">
                <div className="flex">
                    <p className="pl-2">{lastChanged}</p>
                </div>
            </div>
            <div className="w-6/12 flex justify-center">
                <div className="flex">
                    <p className="pl-2">{updatedBy?.split('@')[0]}</p>
                </div>
            </div>
        </div>
    );
};

export default SingleNotice;
