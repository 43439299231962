import { FieldStructure, InputFieldStructure } from '@mercell/form-react';
import { FormDefinitionItem } from '../../types/generated/formDefinitionItem';
import { getRulesSetup } from '../getRulesSetup';
import * as yup from 'yup';
import { TFunction } from 'react-i18next';

export const mapNumberField = (
    formDefinition: FormDefinitionItem,
    genericFieldStructure: FieldStructure,
    t: TFunction<string, unknown>
): InputFieldStructure => {
    if (genericFieldStructure.associatedValidationBasedOnOtherFieldValues) {
        if (
            formDefinition.conditionalValidation?.rules &&
            genericFieldStructure.associatedValidationBasedOnOtherFieldValues
        ) {
            const conditionalValidations = getRulesSetup(
                yup.number().nullable(),
                formDefinition.conditionalValidation.rules,
                t
            );
            genericFieldStructure.associatedValidationBasedOnOtherFieldValues.rules =
                conditionalValidations;
        }
    }
    return {
        ...genericFieldStructure,
        type: 'number',
        validations: formDefinition.rules
            ? getRulesSetup(yup.number().nullable(), formDefinition.rules, t)
            : undefined,
    };
};

export const mapNumberReadOnly = (content: any, language: string) =>
    Number(content).toLocaleString(language);
