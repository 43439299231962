/* eslint-disable camelcase */
import {
    FieldStructure,
    DateTimePickerFieldStructure,
} from '@mercell/form-react';
import * as yup from 'yup';
import { FormDefinitionItem } from '../../types/generated/formDefinitionItem';
import { TFunction } from 'react-i18next';
import { getRulesSetup } from '../getRulesSetup';
import { parseISO } from 'date-fns';
import { getPlaceHolder } from '../utilities/dateFormat';
import { DateFns } from '../dateFnsFormats';

export const mapTimepickerField = (
    formDefinition: FormDefinitionItem,
    genericFieldStructure: FieldStructure,
    t: TFunction<string, unknown>,
    dateFormat: DateFns,
    locale?: Locale,
    noticeTimeZone?: string,
    featureFlagUseUtcDatesOnly?: boolean
): DateTimePickerFieldStructure => {
    if (genericFieldStructure.associatedValidationBasedOnOtherFieldValues) {
        if (
            formDefinition.conditionalValidation?.rules &&
            genericFieldStructure.associatedValidationBasedOnOtherFieldValues
        ) {
            const conditionalValidations = getRulesSetup(
                yup
                    .date()
                    .nullable()
                    .transform((value, originalValue) => {
                        if (!originalValue) return null;
                        return parseISO(originalValue);
                    }),
                formDefinition.conditionalValidation.rules,
                t,
                '',
                locale,
                true
            );
            genericFieldStructure.associatedValidationBasedOnOtherFieldValues.rules =
                conditionalValidations;
        }
    }
    return {
        ...genericFieldStructure,
        id: formDefinition.name,
        validations: formDefinition.rules
            ? getRulesSetup(
                  yup
                      .date()
                      .nullable()
                      .transform((value, originalValue) => {
                          if (!originalValue) return null;
                          return parseISO(originalValue);
                      }),
                  formDefinition.rules,
                  t,
                  '',
                  locale,
                  true
              )
            : undefined,
        type: 'date-time-picker',
        submitWithTimeZone: true,
        disabled: formDefinition.disabled,
        dateFormat: dateFormat.DateTimeWithZoneLong,
        timeFormat: dateFormat.Time,
        placeholder: `${
            locale
                ? getPlaceHolder(locale?.formatLong?.date({ width: 'short' }))
                : 'DD/MM/YYYY'
        } HH:MM`,
        showTimeSelect: true,
        timeIntervals: 5,
        locale,
        timeZone: featureFlagUseUtcDatesOnly ? noticeTimeZone : undefined,
    };
};
