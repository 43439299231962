import React from 'react';
import { CPVFieldStructure, FieldStructure } from '@mercell/form-react';
import { CPVFieldComponent } from '../../components/CPVFieldComponent';
import { TFunction } from 'react-i18next';
import { FormDefinitionItem } from '../../types/generated/formDefinitionItem';

export const mapCPVSelectorField = (
    formDefinition: FormDefinitionItem,
    genericFieldStructure: FieldStructure,
    language: string,
    t: TFunction<string, unknown>
): CPVFieldStructure => ({
    ...genericFieldStructure,
    type: 'cpv-selector',
    language,
    Component: CPVFieldComponent,
    allowToSelectOnlyOne: formDefinition.allowToSelectOnlyOne,
    fieldToTrackToDisableCodes: {
        fieldName: formDefinition.parentField?.fieldName ?? '',
        useSamePath: formDefinition.parentField?.useSamePath,
    },
    disabledCPVList: (values: any) => values,
    title: t('form-content:CPVSelectorTitle'),
    selectedCodesText: t('form-content:CPVSelectorSelectedCodes'),
    noCodesFoundText: t('form-content:CPVSelectorNoCodesFoundText'),
    ariaExpandChevronLabel: t('form-content:CPVSelectorAriaExpandChevronLabel'),
    ariaCollapseChevronLabel: t(
        'form-content:CPVSelectorAriaCollapseChevronLabel'
    ),
    cpvSearchPlaceholder: t('form-content:CPVSelectorSearchPlaceholder'),
    ariaLabelClearSearch: t('form-content:CPVSelectorAiaLabelClearSearch'),
    saveText: t('form-content:CPVSelectorSaveText'),
    cancelText: t('form-content:CPVSelectorCancelText'),
});

export const mapCPVSelectorReadOnly = (
    content: any,
    t: TFunction<string, unknown>
) => (
    <div className="overflow-hidden">
        {content && content.length && content.allowToSelectOnlyOne !== true ? (
            <>
                {content.map((code: string) => (
                    <p key={code} className="font-semibold truncate">
                        {code} {t(`list.cpv:${code}`)}
                        {content.allowToSelectOnlyOne}
                    </p>
                ))}
            </>
        ) : null}
    </div>
);
