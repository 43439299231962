import React, { FC, ReactNode } from 'react';
import { Tooltip } from '@mercell/tooltip-react';
import { CarbonIconProps, CarbonIconType, Help32 } from '@carbon/icons-react';
import cx from 'classnames';

interface TooltipStructure {
    content?: ReactNode;
    tooltipWrapperProperties?: JSX.IntrinsicElements['div'];
    Icon?: CarbonIconType;
    tooltipIconProperties?: CarbonIconProps;
}

export const TooltipWrapper: FC<TooltipStructure> = ({
    content,
    tooltipWrapperProperties,
    Icon = Help32,
    tooltipIconProperties,
}) => {
    if (!content) return null;
    return (
        <Tooltip
            {...tooltipWrapperProperties}
            message={content}
            tooltipMessageClassName="text-left"
            placement="right"
            className={cx(
                'ml-2 text-body inline-flex align-top',
                tooltipWrapperProperties?.className
            )}
        >
            <Icon
                {...tooltipIconProperties}
                className={cx(
                    'h-5 w-5 fill-current text-mirage',
                    tooltipIconProperties?.className
                )}
            />
        </Tooltip>
    );
};
