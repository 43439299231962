import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { ValidationErrorObject } from '../../types/validationError';
import { isEqual } from 'lodash';

export const useBackendErrorSubmitMutator = (
    backendErrorSubmitMutator: (...args: any[]) => any,
    backendValidationErrors: ValidationErrorObject,
    setBackendValidationErrors: Dispatch<SetStateAction<ValidationErrorObject>>,
    parentSectionId: string,
    sectionId: string,
    arrayElementId: string,
    parentArrayElementId: string
) => {
    const backendValidationErrorsRef = useRef<Record<string, any>>({
        backendValidationErrors,
    });
    useEffect(() => {
        if (
            !isEqual(backendValidationErrorsRef.current, {
                backendValidationErrors,
                parentSectionId,
                sectionId,
                arrayElementId,
                parentArrayElementId,
            })
        ) {
            backendErrorSubmitMutator(
                setBackendValidationErrors,
                backendValidationErrors.submittedErrors,
                parentSectionId || '',
                sectionId || '',
                backendValidationErrors.errorObjectWithPaths,
                arrayElementId || '',
                parentArrayElementId || ''
            );
            backendValidationErrorsRef.current = {
                backendValidationErrors,
                parentSectionId,
                sectionId,
                arrayElementId,
                parentArrayElementId,
            };
        }
    }, [
        arrayElementId,
        backendErrorSubmitMutator,
        backendValidationErrors,
        parentArrayElementId,
        parentSectionId,
        sectionId,
        setBackendValidationErrors,
    ]);
};
