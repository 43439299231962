import React, { FC, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet, Navigate } from 'react-router-dom';
import LoadingSpinner from '@mercell/loading-spinner-react';
import { KeyedMutator } from 'swr';
import { UserRightsResult } from '../types/generated/userRightsResult';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface SecureOktaRoutesProps {
    mutateUserRights: KeyedMutator<UserRightsResult> | undefined;
}

// this is a workaround for react-router-dom v6,
// in the future check for @okta/okta-react updates on compatibility with react-router-dom v6.

const SecureOktaRoutes: FC<SecureOktaRoutesProps> = ({
    mutateUserRights,
}: SecureOktaRoutesProps) => {
    const { oktaAuth, authState } = useOktaAuth();
    const [shouldRender, setShouldRender] = useState(false);
    const { useMutateUserRightsSprint4 } = useFlags();

    useEffect(() => {
        if (!authState) {
            return;
        }

        if (!authState.isAuthenticated) {
            const originalUri = toRelativeUrl(
                window.location.href,
                window.location.origin
            );
            oktaAuth.setOriginalUri(originalUri);

            oktaAuth.signInWithRedirect().then(() => {
                if (useMutateUserRightsSprint4 && mutateUserRights) {
                    mutateUserRights();
                }
            });
        } else {
            setTimeout(() => {
                setShouldRender(true);
            }, 1500);
        }
    }, [oktaAuth, authState, mutateUserRights, useMutateUserRightsSprint4]);

    if (!authState?.isAuthenticated || !shouldRender) {
        return (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <LoadingSpinner />
            </div>
        );
    }

    if (authState?.error) return <Navigate to="/login" />;

    return <Outlet />;
};

export default SecureOktaRoutes;
