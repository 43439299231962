import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

const fetcher = (url: string) =>
    axios.get(url).then(async (res: AxiosResponse<string>) => res.data);

const useFetchDownloadXml = (formId: string) => {
    const { data, error } = useSWRImmutable(
        `rep/form/downloadxml/${formId}`,
        fetcher,
        { shouldRetryOnError: false }
    );

    return {
        filestream: data,
        isLoading: !data && !error,
        isErrorFetchingXml: error,
    };
};

export default useFetchDownloadXml;
