/* istanbul ignore file */
import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';
import { FeatureFlagModel } from '../../types/generated/featureFlagModel';

const fetcher = (url: string) =>
    axios
        .get(url)
        .then(
            async (res: AxiosResponse): Promise<FeatureFlagModel[]> => res.data
        );

const useFetchFeatureFlags = () => {
    const { data, error, mutate } = useSWRImmutable(
        `featureflag/features`,
        fetcher,
        {
            shouldRetryOnError: false,
        }
    );
    return {
        features: data ?? [],
        isLoading: !error && !data,
        isErrorFetchingFeatures: error,
        refetchFeatureFlags: mutate,
    };
};
export default useFetchFeatureFlags;
