import { ReadOnlyFieldStructure, FieldStructure } from '@mercell/form-react';
import { FormDefinitionItem } from '../../types/generated/formDefinitionItem';
import { parseDefinitionType } from '../parseDefinitionType';
import * as yup from 'yup';
import { TFunction } from 'react-i18next';
import { getRulesSetup } from '../getRulesSetup';

export const mapReadOnlyField = (
    formDefinition: FormDefinitionItem,
    genericFieldStructure: FieldStructure,
    t: TFunction<string, unknown>,
    isFormPreview: boolean,
    locale?: Locale
): ReadOnlyFieldStructure => ({
    ...genericFieldStructure,
    type: 'readOnly',
    readOnlyContent: formDefinition.text,
    fieldOrigin: parseDefinitionType(formDefinition.type),
    locale,
    infoIcon: formDefinition.infoIcon,
});
