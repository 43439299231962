import axios, { AxiosResponse } from 'axios';

import { FormStatusResult } from '../../types/generated/formStatusResult';
import { useEffect } from 'react';
import useSWR, { useSWRConfig } from 'swr';

const fetcher = (url: string) =>
    axios.get(url).then((res: AxiosResponse<FormStatusResult>) => res.data);

const useFetchFormStatuses = (
    formId?: string,
    useRevalidateOnFocus1734?: boolean
) => {
    const { cache } = useSWRConfig();
    const url = !formId
        ? `testapp/formstatuses`
        : `testapp/formstatuses/${formId}`;

    const { data, error, mutate } = useSWR(url, fetcher, {
        shouldRetryOnError: false,
        revalidateIfStale: true,
        revalidateOnFocus: useRevalidateOnFocus1734,
    });

    useEffect(
        () => () => {
            cache.delete(url);
        },
        [cache, url]
    );

    return {
        statuses: data?.statuses,
        currentStatus: data?.currentStatus,
        isLoadingStatuses: !data && !error,
        isErrorFetchingStatuses: error,
        mutateFormStatuses: mutate,
    };
};

export default useFetchFormStatuses;
