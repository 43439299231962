/* istanbul ignore file */
import { FormSaveResult as FormSaveResultN } from '../../types/generated/formSaveResult';
import axios, { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { KeyedMutator } from 'swr';
import {
    setFormAdditionalLanguages,
    setFormLanguage,
} from '../../shared/storageService/sessionService';
import { FormMenuResult } from '../../types/generated/formMenuResult';
import { delay } from '../../shared/utilities/delay';
import {
    setaAutoSaveError,
    setaAutoSaveSaved,
    setAutoSaveLoading,
} from '../../redux/storeSlice';
import { useAppDispatch } from '../../redux/hooks';

interface FormSaveResult extends FormSaveResultN {
    isError?: boolean;
}
const axiosPost = (
    url: string,
    values: Record<string, any>
): Promise<FormSaveResult | Partial<FormSaveResult>> | undefined =>
    axios
        .post(url, values)
        .then(
            async (
                res: AxiosResponse<FormSaveResult>
            ): Promise<FormSaveResult> => {
                const { data } = res;
                return {
                    errors: data.errors,
                    isCompleted: data.isCompleted,
                    selectedLanguage: data.selectedLanguage,
                    shouldReloadMenu: data.shouldReloadMenu,
                    additionalLanguages: data.additionalLanguages,
                };
            }
        );

interface AutoSaveFormReturn {
    onAutoSave: (formValues: Record<string, any>) => Promise<void>;
}

export const useAutoSaveForm = <T extends Record<string, any>>(
    url: string,
    formData: T | undefined,
    setState: KeyedMutator<T>,
    setHotReloadOfMenu: KeyedMutator<FormMenuResult>,
    resetNoticeStatusToDraft: () => void
): AutoSaveFormReturn => {
    const reduxDispatch = useAppDispatch();
    const onAutoSave = useCallback(
        async (formValues: Record<string, any>) => {
            reduxDispatch(setAutoSaveLoading());

            try {
                const data = await axiosPost(url, formValues);

                resetNoticeStatusToDraft();

                if (data && formData && formData.isCompleted) {
                    await setState(
                        { ...formData, isCompleted: data.isCompleted },
                        false
                    );
                }
                if (data && formData && data.selectedLanguage) {
                    setFormLanguage(
                        data.selectedLanguage ??
                            sessionStorage.getItem('language')
                    );
                }

                if (data && data.additionalLanguages) {
                    setFormAdditionalLanguages(data.additionalLanguages ?? []);
                } else if (data && !data.additionalLanguages) {
                    setFormAdditionalLanguages([]);
                }

                if (data && data.shouldReloadMenu)
                    setHotReloadOfMenu((currentData) => currentData, {
                        revalidate: true,
                    });

                await delay(2000);

                reduxDispatch(setaAutoSaveSaved());
            } catch {
                reduxDispatch(setaAutoSaveError());
            }
        },
        [
            reduxDispatch,
            url,
            resetNoticeStatusToDraft,
            formData,
            setHotReloadOfMenu,
            setState,
        ]
    );

    return { onAutoSave };
};
