export const getPlaceHolder = (placeholder: any) =>{
  let datePlaceholder = placeholder.toUpperCase();
  const result = [...datePlaceholder].reduce((a, e) => { a[e] = a[e] ? a[e] + 1 : 1; return a }, {});
  if(result.D === 1) {
    datePlaceholder = datePlaceholder.replace('D', 'DD');
  }
  if(result.M === 1) {
    datePlaceholder = datePlaceholder.replace('M', 'MM');
  }
  if(result.Y === 1) {
    datePlaceholder = datePlaceholder.replace('Y', 'YYYY');
  }
  return datePlaceholder
}