/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Dispatch, FC, SetStateAction } from 'react';
import { TFunction } from 'react-i18next';
import { ErrorObjectWithPaths } from '../../../types/validationError';

import { ValidationCheckbox } from './ValidationCheckbox';
import { Renew24 } from '@carbon/icons-react';
import Toggle from '@mercell/toggle-react';
import { AnimatedIcon } from '../../../components/AnimatedIconSpinner';
import { ProgressBar } from '../../../components/ProgressBar';
import { ValidateResponse } from '../../../shared/validationModule/useValidate';

interface ErrorGroup {
    id?: string;
    name?: string;
    values: ErrorObjectWithPaths[];
}
interface ValidationCheckboxListInterface {
    errorListToShow: ErrorObjectWithPaths[];
    fixedErrorList: string[];
    t: TFunction<string, unknown>;
    setFixedErrorList: Dispatch<SetStateAction<string[]>>;
    validateMethod: (isTranslate?: boolean) => Promise<ValidateResponse>;
    arrayOfErrorObjectWithPaths: ErrorObjectWithPaths[];
    isShowErrorForSectionEnable: boolean;
    onChangeHandler: () => void;
    sectionFixedErrorList: any;
    sectionErrors: any;
    isLoadingFetchingValidations: boolean;
    isTechnicalLot: boolean;
}

function groupErrorsByType(
    data: ErrorObjectWithPaths[],
    isTechnicalLot: boolean,
    t: TFunction<string, unknown>
) {
    const groupedItems: ErrorGroup[] = [];
    const nonGroupedItems: ErrorObjectWithPaths[] = [];
    const nonGroupedTitle = t('form-content:ValidationGroupMain');

    data.forEach((item) => {
        if (item.mainSectionName === 'LotsSection') {
            const regexLotUniqueId = /LotUniqueId='([^']+)'/;
            const isMatch = item.arrayOfIds[0].match(regexLotUniqueId);
            if (isMatch) {
                const lotUniqueId = isMatch[1];
                const existingGroup = groupedItems.find(
                    (group) => group.id === lotUniqueId
                );
                if (existingGroup) {
                    existingGroup.values.push({ ...item });
                } else {
                    groupedItems.push({
                        id: lotUniqueId,
                        name: item.arrayOfNames[0],
                        values: [{ ...item }],
                    });
                }
            }
        } else {
            nonGroupedItems.push({ ...item });
        }
    });

    return nonGroupedItems.length > 0
        ? [
              {
                  name: !isTechnicalLot ? nonGroupedTitle : undefined,
                  values: nonGroupedItems,
              },
              ...groupedItems,
          ]
        : groupedItems;
}

export const ValidationCheckboxList: FC<ValidationCheckboxListInterface> = ({
    setFixedErrorList,
    errorListToShow,
    fixedErrorList,
    validateMethod,
    arrayOfErrorObjectWithPaths,
    isShowErrorForSectionEnable,
    onChangeHandler,
    sectionFixedErrorList,
    sectionErrors,
    isLoadingFetchingValidations,
    isTechnicalLot,
    t,
}) => {
    const groupedErrors = groupErrorsByType(errorListToShow, isTechnicalLot, t);

    return (
        <div className="flex flex-col h-full px-8 min-h-0 w-full">
            <div className="sticky z-20  pt-[0.3rem] bg-white  pb-2">
                {arrayOfErrorObjectWithPaths.length > 0 && (
                    <>
                        <Toggle
                            className="my-4 -ml-2 w-[350px]"
                            onLabel={t('form-content:ShowSectionErrors')}
                            offLabel={t('form-content:ShowSectionErrors')}
                            onChange={onChangeHandler}
                            checked={isShowErrorForSectionEnable}
                        />

                        <div className="flex items-center">
                            {isShowErrorForSectionEnable &&
                            sectionFixedErrorList &&
                            sectionErrors &&
                            arrayOfErrorObjectWithPaths.length > 0 ? (
                                <p className="font-medium">
                                    {sectionFixedErrorList.length}/
                                    {`${sectionErrors.length} ${t(
                                        'form-content:ActionsCompleted'
                                    )} ${t('form-content:ActionsOutOf', {
                                        value: arrayOfErrorObjectWithPaths.length,
                                    })}`}
                                </p>
                            ) : (
                                <p className="font-medium ">
                                    {fixedErrorList.length}/
                                    {`${arrayOfErrorObjectWithPaths.length} ${t(
                                        'form-content:ActionsCompleted'
                                    )}`}
                                </p>
                            )}
                            <div className="ml-2 hover:cursor-pointer flex">
                                {!isLoadingFetchingValidations ? (
                                    <Renew24
                                        onClick={() => {
                                            validateMethod();
                                        }}
                                        className="-scale-x-[1]"
                                    />
                                ) : (
                                    <AnimatedIcon />
                                )}
                            </div>
                        </div>
                    </>
                )}
                {arrayOfErrorObjectWithPaths.length > 0 ? (
                    <ProgressBar
                        showText={false}
                        textContent={t('form-content:ErrorsMarkedFixed')}
                        wrapperProps={{ className: 'my-4' }}
                        totalAmount={arrayOfErrorObjectWithPaths.length}
                        progressMadeAmount={fixedErrorList.length}
                    />
                ) : null}
            </div>
            {groupedErrors.length > 0 && (
                <div className="overflow-y-auto">
                    {groupedErrors.map((item: ErrorGroup, key) => (
                        <div
                            className="bg-magnolia rounded-default p-4 mb-4"
                            key={key}
                        >
                            {item.name && (
                                <p className="z-[50] sticky top-0 font-semibold bg-magnolia py-4 px-1.5">
                                    {item.name}
                                </p>
                            )}
                            {item.values.map(
                                (errorObjectWithPaths: any, ix: any) => (
                                    <ValidationCheckbox
                                        key={`${errorObjectWithPaths.urlPath}_${errorObjectWithPaths.fieldPath}_${ix}`}
                                        {...errorObjectWithPaths}
                                        t={t}
                                        checked={fixedErrorList.includes(
                                            `${errorObjectWithPaths.urlPath}_${errorObjectWithPaths.fieldPath}`
                                        )}
                                        setFixedErrorList={setFixedErrorList}
                                    />
                                )
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
