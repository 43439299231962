import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import OktaAuthWrapper from '../../authorization/OktaAuthWrapper';
import useFetchFormStatuses from '../../hooks/administrativeHooks/useFetchFormStatuses';
import useFetchPlatformNames from '../../hooks/administrativeHooks/useFetchPlatformNames';
import { DropdownOption } from '../../types/generated/dropdownOption';
import { SelectOption } from '../../types/generated/selectOption';
import NoAccessPage from '../NotFound/NoAccessPage';
import SelectForm from './SelectForm';
import LoadingSpinner from '@mercell/loading-spinner-react';
import { useStoreContext } from '../../state';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import useSetupContext from '../../shared/setupUserContext';
import { FormListItem, TenderListItem } from '../../types/tenderList';
import { useAppDispatch } from '../../redux/hooks';
import { setPlatformName as setPlatformNameStore } from '../../redux/storeSlice';

export const Home: React.FC = () => {
    const { useRevalidateOnFocus1734 } = useFlags();
    const [platformName, setPlatformName] = React.useState<string>();
    const [formUserRights, setFormUserRights] = React.useState<number>(2);
    const [status, setStatus] = React.useState<SelectOption | null>(null);
    const [tender, setTender] = React.useState<TenderListItem | null>(null);
    const [form, setForm] = React.useState<FormListItem | null>(null);
    const { platformNames, isLoadingPlatformNames } = useFetchPlatformNames();
    const { statuses, currentStatus, mutateFormStatuses } =
        useFetchFormStatuses(form?.value, useRevalidateOnFocus1734);
    const { dispatch } = useStoreContext();
    const reduxDispatch = useAppDispatch();
    const { multiContext, isValid } = useSetupContext();

    const ldClient = useLDClient();
    const { t } = useTranslation([
        'form-content',
        'list.form-type',
        'list.legal-basis',
        'list.notice-type',
        'list.audit-log-status',
        'toast-content',
    ]);

    const onChangePlatformName = (newValue?: SelectOption) => {
        const platformNamePayload = newValue?.value ?? '';
        reduxDispatch(setPlatformNameStore(platformNamePayload));

        setPlatformName(newValue?.value);
        setTender(null);
        setForm(null);
        setStatus(null);
    };

    const onChangeFormUserRights = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.value !== formUserRights.toString()) {
            setFormUserRights(Number(e.currentTarget.value));
        }
    };

    const onChangeTender = (newValue: TenderListItem | null) => {
        setTender(newValue);
        setForm(null);
        setStatus(null);
    };

    const onChangeStatus = (newValue: SelectOption | null) => {
        setStatus(newValue);
    };

    const onChangeForm = (newValue: FormListItem | null) => {
        if (form?.value !== newValue?.value) {
            setStatus(null);
        }
        setForm(newValue);
    };

    useEffect(() => {
        if (platformNames && !platformName) {
            reduxDispatch(setPlatformNameStore(platformNames[0]));
            setPlatformName(platformNames[0]);
        }
        if (statuses) {
            const selectedStatus = statuses.find(
                (x: DropdownOption) => x.isSelected === true
            );
            if (selectedStatus) {
                setStatus({
                    value: selectedStatus.value.toString(),
                    label: t(`list.audit-log-status:${selectedStatus.name}`),
                });
            }
        }
    }, [platformName, platformNames, statuses, dispatch, t, reduxDispatch]);

    useEffect(() => {
        if (isValid && multiContext) {
            ldClient
                ?.waitForInitialization()
                .then(() => {
                    ldClient?.identify(multiContext);
                })
                .catch((err) => {
                    // eslint-disable-next-line no-console
                    console.warn(err);
                });
        }
    }, [platformName, isValid, multiContext, ldClient]);

    if (isLoadingPlatformNames)
        return (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <LoadingSpinner />
            </div>
        );
    if (!platformNames) return <NoAccessPage />;

    return (
        <OktaAuthWrapper>
            <div className="col-span-full justify-self-center min-w-full md:min-w-[700px] max-w-[700px]">
                <input
                    type="hidden"
                    id="BuildNumber"
                    value={`${process.env.REACT_APP_BUILD_NUMBER}`}
                />
                <SelectForm
                    t={t}
                    platformNames={platformNames ?? []}
                    onChangePlatformName={onChangePlatformName}
                    onChangeFormUserRights={onChangeFormUserRights}
                    formUserRights={formUserRights}
                    onChangeTender={onChangeTender}
                    onChangeStatus={onChangeStatus}
                    onChangeForm={onChangeForm}
                    platformName={platformName}
                    tender={tender}
                    status={status}
                    currentStatus={currentStatus}
                    form={form}
                    statuses={statuses}
                    mutateFormStatuses={mutateFormStatuses}
                />
            </div>
        </OktaAuthWrapper>
    );
};

export default Home;
