import { DefinitionElementType } from '../types/generated/formDefinitionItem';
import { FieldOrigin } from '@mercell/form-react';

export const parseDefinitionType = (
    type: DefinitionElementType
): FieldOrigin => {
    switch (type) {
        case DefinitionElementType.Text:
            return 'text';
        case DefinitionElementType.TextArea:
            return 'textarea';
        case DefinitionElementType.Checkbox:
            return 'checkbox';
        case DefinitionElementType.Radio:
            return 'radio';
        case DefinitionElementType.Select:
            return 'select';
        case DefinitionElementType.Datepicker:
            return 'date-time-picker';
        case DefinitionElementType.Time:
            return 'date-time-picker';
        case DefinitionElementType.Hidden:
            return 'hidden';
        case DefinitionElementType.Number:
            return 'number';
        case DefinitionElementType.CpvSelector:
            return 'cpv-selector';
        case DefinitionElementType.NutsSelector:
            return 'nuts-selector';
        case DefinitionElementType.Phone:
            return 'phone';
        case DefinitionElementType.Email:
            return 'email';
        case DefinitionElementType.ReadOnly:
            return 'readOnly';
        case DefinitionElementType.Button:
            return 'button';
        case DefinitionElementType.Array:
            return 'array';
        case DefinitionElementType.InputWithDropdown:
            return 'input-dropdown';
        default:
            return 'text';
    }
};
