import React, { FC, Ref, forwardRef, useEffect, useState } from 'react';
import { Close24 } from '@carbon/icons-react';
import cx from 'classnames';

interface MyChecklistDrawerInterface {
    closeDrawer: () => void;
    isDrawerVisible: boolean;
    children?: React.ReactNode;
    wrapperClassName?: string;
    headerClassName?: string;
    closeButtonClassName?: string;
    hideHeader?: boolean;
    drawerTitle?: string;
    ref?: Ref<HTMLDivElement>;
    drawerDynamicHeight: number;
}

export const MyChecklistDrawer: FC<MyChecklistDrawerInterface> = forwardRef(
    (props, ref: any) => {
        const {
            isDrawerVisible,
            wrapperClassName,
            closeButtonClassName,
            closeDrawer,
            drawerTitle,
            headerClassName,
            hideHeader,
            children,
            drawerDynamicHeight,
        } = props;

        const [viewportHeight, setViewportHeight] = useState(
            window.innerHeight
        );

        useEffect(() => {
            const handleResize = () => {
                setViewportHeight(window.innerHeight);
            };

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return (
            <div
                ref={ref}
                id="myChecklist"
                className={cx(
                    'bg-white min-w-[400px] max-w-[500px] w-full shadow-[-10px_10px_40px_rgba(114,114,114,0.15)] opacity-0',
                    isDrawerVisible &&
                        'min-w-[400px] max-w-[500px] w-full opacity-100',
                    !isDrawerVisible && 'min-w-[0px] min-w-[0px] w-[0px]',
                    'duration-[300ms]',
                    'col-start-11',
                    'fixed lg:sticky !top-[100px] right-0',
                    wrapperClassName
                )}
                style={{
                    height: `${viewportHeight - drawerDynamicHeight}px`,
                }}
            >
                <div className="h-full">
                    <div className="h-full flex flex-col">
                        {!hideHeader && (
                            <header
                                className={cx(
                                    'bg-white pt-6 px-8',
                                    headerClassName
                                )}
                            >
                                <h2 className="text-h2">{drawerTitle}</h2>
                                <button
                                    type="button"
                                    className={cx(
                                        'absolute top-7 right-4 cursor-pointer',
                                        closeButtonClassName
                                    )}
                                    onClick={closeDrawer}
                                >
                                    <Close24 />
                                </button>
                            </header>
                        )}
                        {children}
                    </div>
                </div>
            </div>
        );
    }
);
