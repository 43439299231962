import React, { FC, useEffect, useState, useMemo } from 'react';
import useFetchPublicationlog, {
    PublicationTaskHistory,
} from '../../hooks/administrativeHooks/useFetchPublicationLog';
import { format, parseISO } from 'date-fns';
import cx from 'classnames';
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    useReactTable,
    TableOptions,
    getSortedRowModel,
    SortingState,
} from '@tanstack/react-table';
import {
    Modal,
    ModalButtonsSection,
    ModalContent,
    ModalTitle,
} from '@mercell/modal-react';
import { CaretDown16, CaretUp16 } from '@carbon/icons-react';
import Button from '@mercell/button-react';
import { DateFns } from '../../shared/dateFnsFormats';
import { TFunction } from 'react-i18next';
import { DateTime } from 'luxon';
import Ribbon from '@mercell/ribbon-react';

export interface PublicationLogProps {
    t: TFunction<string, unknown>;
    formId: string;
    locale: Locale | undefined;
    dateFormat: DateFns;
    setShowPublicationLog: React.Dispatch<React.SetStateAction<boolean>>;
    copyToClipboard: (text: string) => void;
}
const PublicationLog: FC<any> = ({
    t,
    locale,
    dateFormat,
    formId,
    setShowPublicationLog,
    copyToClipboard,
}: PublicationLogProps) => {
    const { publicationlog, isErrorFetchingPublicationlog } =
        useFetchPublicationlog(formId ?? '');

    const [isPublicationLogVisible, setPublicationLogVisibility] =
        useState(false);

    const closeModal = () => {
        setShowPublicationLog(false);
    };

    useEffect(() => {
        if (!isErrorFetchingPublicationlog && publicationlog) {
            setPublicationLogVisibility(true);
        }
    }, [isErrorFetchingPublicationlog, publicationlog]);

    const [tasks, setTasks] = useState<PublicationTaskHistory[]>([]);
    const [clientSorting, setClientSorting] = useState<SortingState>([
        {
            id: 'executedAt',
            desc: false,
        },
    ]);

    useEffect(() => {
        if (publicationlog != null) {
            const tempData =
                publicationlog?.map((item: any) => {
                    const tempItem = { ...item };
                    return tempItem;
                }) ?? [];
            setTasks(tempData);
        }
    }, [publicationlog]);

    type ColumnMeta = {
        meta?: {
            size?: number | string;
            textAlign?: 'text-left' | 'text-center' | 'text-right';
        };
    };

    type AugmentedColumnDef = ColumnDef<PublicationTaskHistory> & ColumnMeta;
    const columns = useMemo<AugmentedColumnDef[]>(
        () => [
            {
                accessorKey: 'internalStatus',
                cell: (info: any) => info.getValue(),
                header: t('dashboard:InternalStatus'),
                meta: {
                    size: '20%',
                    textAlign: 'text-center',
                },
            },
            {
                accessorKey: 'errorMessage',
                header: t('dashboard:ErrorMessage'),
                cell: (info: any) => info.renderValue(),
                meta: {
                    size: '30%',
                    textAlign: 'text-center',
                },
            },
            {
                accessorKey: 'executedAt',
                id: 'executedAt',
                cell: (info: any) => info.getValue(),
                header: t('dashboard:ExecutedAt'),
                meta: {
                    size: '25%',
                    textAlign: 'text-center',
                },
                enableHiding: true,
            },
            {
                accessorKey: 'createdAt',
                id: 'createdAt',
                cell: (info: any) => info.getValue(),
                header: t('dashboard:CreatedAt'),
                meta: {
                    size: '25%',
                    textAlign: 'text-center',
                },
                enableHiding: true,
            },
        ],
        [t]
    );

    const conditionalOptions = {
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setClientSorting,
        state: { sorting: clientSorting },
    };

    const tableOptions: TableOptions<PublicationTaskHistory> = {
        data: tasks,
        columns,
        getCoreRowModel: getCoreRowModel(),
        ...conditionalOptions,
    };

    const table = useReactTable(tableOptions);

    const getCellValue = (cell: any) => {
        switch (cell.column.id) {
            case 'errorMessage': {
                return (
                    <div className="flex justify-center items-center whitespace-nowrap">
                        {cell.getValue() && (
                            <Ribbon
                                scheme="error"
                                className="cursor-pointer"
                                onClick={() => {
                                    copyToClipboard(cell.getValue() ?? '');
                                }}
                                title={cell.getValue()}
                            >
                                {t('dashboard:CopyErrorToClipBoard')}
                            </Ribbon>
                        )}
                    </div>
                );
            }
            case 'executedAt':
            case 'createdAt': {
                return getParsedDateTime(cell.getValue());
            }
            default:
                return cell.getValue();
        }
    };

    const getParsedDateTime = (timestamp: DateTime | undefined): string =>
        timestamp
            ? format(
                  parseISO(timestamp.toString()),
                  dateFormat?.DateTimeWithZoneShort,
                  {
                      locale,
                  }
              )
            : '';

    return (
        <Modal
            isModalVisible={isPublicationLogVisible}
            onCloseCallback={closeModal}
            shouldCloseOnOutsideClick
            parentClassName="parentClassName"
            className="max-w-6xl max-h-100"
        >
            <ModalTitle>{t('form-content:PublicationLog')}</ModalTitle>
            <ModalContent>
                {isErrorFetchingPublicationlog ||
                table.getRowModel().rows.length < 1 ? (
                    <Ribbon scheme="error" className="cursor-pointer">
                        {t('form-content:NoResultsFound')}
                    </Ribbon>
                ) : (
                    <div className="col-span-full h-96 overflow-y-auto overscroll-contain">
                        <div className="overflow-hidden border border-alto md:rounded-default">
                            <table className="min-w-full divide-y divide-concrete table-fixed">
                                <thead className="bg-minsk">
                                    <tr>
                                        {table
                                            .getFlatHeaders()
                                            .map((header: any) => {
                                                const columnSize =
                                                    header.column.columnDef.meta
                                                        ?.size;

                                                const textAlign =
                                                    header.column.columnDef.meta
                                                        ?.textAlign;
                                                const columnWidth = `w-[${columnSize}]`;
                                                return (
                                                    <th
                                                        key={header.id}
                                                        onClick={header.column.getToggleSortingHandler()}
                                                        scope="col"
                                                        className={cx(
                                                            'p-3 text-white title-body select-none',
                                                            columnSize &&
                                                                columnWidth,
                                                            textAlign &&
                                                                textAlign,
                                                            header.column.getCanSort() &&
                                                                ' hover:cursor-pointer'
                                                        )}
                                                    >
                                                        {header.isPlaceholder ? null : (
                                                            <div>
                                                                {flexRender(
                                                                    header
                                                                        .column
                                                                        .columnDef
                                                                        .header,
                                                                    header.getContext()
                                                                )}
                                                                {{
                                                                    asc: (
                                                                        <CaretDown16 className="inline-block" />
                                                                    ),
                                                                    desc: (
                                                                        <CaretUp16 className="inline-block" />
                                                                    ),
                                                                }[
                                                                    header.column.getIsSorted() as string
                                                                ] ?? null}
                                                            </div>
                                                        )}
                                                    </th>
                                                );
                                            })}
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-concrete bg-white">
                                    {table
                                        .getRowModel()
                                        .rows.map((row: any) => (
                                            <tr
                                                key={row.id}
                                                className="rt-tr-group"
                                            >
                                                {row
                                                    .getVisibleCells()
                                                    .map((cell: any) => {
                                                        const columnSize =
                                                            cell.column
                                                                .columnDef.meta
                                                                ?.size;

                                                        const textAlign =
                                                            cell.column
                                                                .columnDef.meta
                                                                ?.textAlign;
                                                        const columnWidth = `w-[${columnSize}]`;

                                                        return (
                                                            <td
                                                                key={cell.id}
                                                                className={cx(
                                                                    'p-3 text-black title-body break-all text-[14px] font-medium',
                                                                    columnSize &&
                                                                        columnWidth,
                                                                    textAlign &&
                                                                        textAlign
                                                                )}
                                                            >
                                                                {getCellValue(
                                                                    cell
                                                                )}
                                                            </td>
                                                        );
                                                    })}
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </ModalContent>
            <ModalButtonsSection className="flex justify-end">
                <Button
                    scheme="secondary"
                    className="mr-2"
                    onClick={closeModal}
                >
                    {t('dashboard:LabelClose')}
                </Button>
            </ModalButtonsSection>
        </Modal>
    );
};

export default PublicationLog;
