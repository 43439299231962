export interface DateFns {
    Date: string;
    DateTime: string;
    Time: string;
    DateTimeWithZoneShort: string;
    TimeWithZoneShort: string;
    DateWithZoneShort: string;
    DateTimeWithZoneLong: string;
    TimeWithZoneLong: string;
    DateWithZoneLong: string;
}

export const DateFormats: DateFns = {
    Date: 'P',
    Time: 'p',
    DateTime: 'Pp',
    DateTimeWithZoneShort: 'Pp z',
    TimeWithZoneShort: 'p z',
    DateWithZoneShort: 'P z',
    DateTimeWithZoneLong: 'Pp zzzz',
    TimeWithZoneLong: 'p zzzz',
    DateWithZoneLong: 'P zzzz',
};

export const DateFormatsNew: DateFns = {
    Date: 'P',
    Time: 'p',
    DateTime: 'Pp',
    DateTimeWithZoneShort: 'Pp z',
    TimeWithZoneShort: 'p z',
    DateWithZoneShort: 'P z',
    DateTimeWithZoneLong: 'Pp zzzz',
    TimeWithZoneLong: 'p zzzz',
    DateWithZoneLong: 'P zzzz',
};
