import { Add32, Email24, Renew32 } from '@carbon/icons-react';
import React, { useEffect, useRef, useState } from 'react';
import { TFunction } from 'react-i18next';
import cx from 'classnames';
import { Input, InputContainer, InputLeftElement } from '@mercell/input-react';
import { DropdownMenu, DropdownMenuItem } from '@mercell/dropdown-menu-react';
import { SelectOption } from '../types/generated/formSelectorConfiguration';
import Button from '@mercell/button-react';
import {
    PanelInterface,
    TriggerInterface,
} from '@mercell/use-accordion-react-hook';
import { Dropdown } from '@mercell/dropdown-react';
import { UserEntry, UserRole } from '../types/generated/userEntry';
import { isNaN } from 'lodash';
import { createEditUser } from '../apiCalls/userManagement/createEditUser';
import { UserRightsResult } from '../types/generated/userRightsResult';

const mapUserData = (userData: any, data: any, t: any) => {
    if (userData && data) {
        const matchingObjects = data
            .filter((item: any) => userData.includes(item.value))
            .map((obj: any) => ({ ...obj, label: t(obj.label) }));

        return matchingObjects;
    }
};

const UserManagementAccordion = ({
    platformNames,
    t,
    onPlatformChange,
    countries,
    userRoles,
    user,
    onGlobalSearchChange,
    onEmailMaskChange,
    setUser,
    globalFilter,
    accordionHeader,
    setAccordionHeader,
    setIsUserManagementLoading,
    getUserManagementData,
    onClickExpand,
    panel,
    trigger,
    expanded,
    userRights,
    userPermissions,
    statusChangeLevel,
}: {
    platformNames?: string[];
    t: TFunction<string, unknown>;
    onPlatformChange: (newData: SelectOption, resetData?: boolean) => void;
    selectedPlatform: string[] | undefined;
    countries: SelectOption[] | undefined;
    userRoles: typeof UserRole;
    user: UserEntry;
    emailMask: string;
    globalFilter: string;
    onGlobalSearchChange: (newVal: string) => void;
    onEmailMaskChange: (newVal: string) => void;
    setUser: React.Dispatch<React.SetStateAction<UserEntry>>;
    setAccordionHeader: React.Dispatch<React.SetStateAction<string>>;
    accordionHeader: string;
    setIsUserManagementLoading: React.Dispatch<React.SetStateAction<boolean>>;
    getUserManagementData: any;
    onClickExpand: () => void;
    panel: PanelInterface<HTMLElement>;
    trigger: TriggerInterface;
    expanded: boolean;
    userRights: UserRightsResult | undefined;
    userPermissions: string[] | undefined;
    statusChangeLevel: string[] | undefined;
}) => {
    const [showPlatform, setShowPlatform] = useState<boolean>(false);
    const platformsButtonRef = useRef<HTMLButtonElement>(null);

    const {
        id: userId,
        countries: userCountries,
        platforms: userPlatforms,
        email,
        userRole,
        statusChangeEnabledLevel,
        ...rest
    } = user;

    const userPermissionOpt = rest as any;
    const [emailInput, setEmailInput] = useState<string | undefined>('');
    const [userIdInput, setUserIdInput] = useState<string | undefined>(
        undefined
    );
    const [countriesInput, setCountriesInput] = useState<
        SelectOption[] | undefined
    >([]);
    const [platformsInput, setPlatformsInput] = useState<
        SelectOption[] | undefined
    >([]);
    const [userRoleInput, setUserRoleInput] = useState<
        SelectOption | undefined
    >();
    const [userPermissionsInput, setUserPermissionsInput] = useState<
        SelectOption[] | undefined
    >();
    const [statusChangeEnabledLevelInput, setStatusChangeEnabledLevelInput] =
        useState<SelectOption | undefined>();

    // Options Mapping

    // Countries
    const mappedCountryOptions: SelectOption[] | undefined = countries?.map(
        (item) => ({
            value: item.value,
            label: t(item.label ?? ''),
        })
    );

    // User Roles
    let userRoleNames = Object.keys(userRoles);
    userRoleNames = userRoleNames.filter(
        (key: any) => !isNaN(Number(userRoles[key]))
    );

    const isOptionDisable = (role: any) => {
        if (
            userRights?.userRole === userRoles.User &&
            (role === userRoles.Admin || role === userRoles.SuperAdmin)
        ) {
            return true;
        }
        if (
            userRights?.userRole === userRoles.Admin &&
            role === userRoles.SuperAdmin
        ) {
            return true;
        }
    };

    const mappedUserRolesOptions: SelectOption[] = userRoleNames.map(
        (userRoleItem: any) => ({
            value: userRoles[userRoleItem],
            label: userRoleItem,
            disabled: isOptionDisable(userRoles[userRoleItem]),
        })
    );

    // Platforms
    const mappedPlatformOptions: SelectOption[] | undefined =
        platformNames?.map((item) => ({
            value: item,
            label: t(item ?? ''),
        }));

    // User Permissions
    const userPermissionsOptions: SelectOption[] | undefined =
        userPermissions?.map((item) => ({
            value: item,
            label: item ? t(`user-management:${item}`) : '',
        }));

    // Status Change Level
    const statusChangeLevelOptions: SelectOption[] | undefined =
        statusChangeLevel?.map((item, index) => ({
            value: index.toString(),
            label: item ? t(`user-management:${item}`) : '',
        }));

    const submitUserCreateEdit = async () => {
        const platformsValues = platformsInput?.map(
            (platform) => platform.value
        );

        const countriesValues = countriesInput?.map((country) => country.value);

        const userPermissionsBoolean = (label: string) =>
            userPermissionsInput?.some(
                (userPermission: SelectOption) => userPermission.label === label
            );

        const userEntry: UserEntry = {
            id: userIdInput,
            email: emailInput,
            userRole: Number(userRoleInput?.value),
            platforms: platformsValues as string[],
            countries: countriesValues as string[],
            dashboardEnabled: userPermissionsBoolean(
                t('user-management:DashboardEnabled')
            ),
            configuratorEnabled: userPermissionsBoolean(
                t('user-management:ConfiguratorEnabled')
            ),
            exportVersionEnabled: userPermissionsBoolean(
                t('user-management:ExportVersionEnabled')
            ),
            featuresReviewEnabled: userPermissionsBoolean(
                t('user-management:FeaturesReviewEnabled')
            ),
            importVersionEnabled: userPermissionsBoolean(
                t('user-management:ImportVersionEnabled')
            ),
            publicationConfiguratorEnabled: userPermissionsBoolean(
                t('user-management:PublicationConfiguratorEnabled')
            ),
            translationsReviewEnabled: userPermissionsBoolean(
                t('user-management:TranslationsReviewEnabled')
            ),
            userManagementEnabled: userPermissionsBoolean(
                t('user-management:UserManagementEnabled')
            ),
            logsExplorerEnabled: userPermissionsBoolean(
                t('user-management:LogsExplorerEnabled')
            ),
            statusChangeEnabledLevel: Number(
                statusChangeEnabledLevelInput?.value
            ),
        };
        await createEditUser(userEntry, {
            onPendingText:
                accordionHeader === 'CreateUser'
                    ? t('toast-content:ToastCreatingUserPending')
                    : t('toast-content:ToastEditingUserPending'),
            onSuccessText:
                accordionHeader === 'CreateUser'
                    ? t('toast-content:ToastCreatingUserSuccess')
                    : t('toast-content:ToastEditingUserSuccess'),
        }).then(async () => {
            onClickExpand();
            setUser({
                id: undefined,
                configuratorEnabled: undefined,
                countries: undefined,
                dashboardEnabled: undefined,
                exportVersionEnabled: undefined,
                featuresReviewEnabled: undefined,
                importVersionEnabled: undefined,
                publicationConfiguratorEnabled: undefined,
                platforms: undefined,
                statusChangeEnabledLevel: 0,
                translationsReviewEnabled: undefined,
                email: '',
                userManagementEnabled: undefined,
                logsExplorerEnabled: undefined,
                userRole: undefined,
            });
            setIsUserManagementLoading(true);
            getUserManagementData();
        });
    };

    const firstLetterSmall = (str: string) =>
        str.slice(0, 1).toLowerCase() + str.slice(1, str.length);

    useEffect(() => {
        setCountriesInput(mapUserData(userCountries, countries, t));
        setUserIdInput(userId);
        setEmailInput(email);
        setPlatformsInput(mapUserData(userPlatforms, mappedPlatformOptions, t));
        setUserRoleInput(
            mappedUserRolesOptions?.find(
                (roleOption: any) => roleOption.value === userRole
            )
        );
        setStatusChangeEnabledLevelInput(
            statusChangeLevelOptions?.find(
                (roleOption: any) =>
                    Number(roleOption.value) === statusChangeEnabledLevel
            )
        );
        setUserPermissionsInput(
            userPermissionsOptions
                ?.filter(
                    (option: any) =>
                        userPermissionOpt[firstLetterSmall(option.value)]
                )
                .map((option: any) => ({
                    value: option.value,
                    label: option.label,
                }))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const clearUserInputs = () => {
        setUser({
            id: undefined,
            configuratorEnabled: undefined,
            countries: undefined,
            dashboardEnabled: undefined,
            exportVersionEnabled: undefined,
            featuresReviewEnabled: undefined,
            importVersionEnabled: undefined,
            publicationConfiguratorEnabled: undefined,
            platforms: undefined,
            statusChangeEnabledLevel: 0,
            translationsReviewEnabled: undefined,
            email: '',
            userManagementEnabled: undefined,
            logsExplorerEnabled: undefined,
            userRole: undefined,
        });
    };

    const isRoleDisabled = () => {
        if (
            Number(userRoleInput?.value) === Number(userRoles.SuperAdmin) &&
            (userRights?.userRole === UserRole.User ||
                userRights?.userRole === UserRole.Admin)
        ) {
            return true;
        }
        if (
            Number(userRoleInput?.value) === userRoles.Admin &&
            userRights?.userRole === UserRole.User
        ) {
            return true;
        }
        return false;
    };

    return (
        <div className="flex flex-col w-full">
            <div className="flex items-center w-full flex-wrap">
                <div className="flex items-center mr-auto">
                    <div className="flex divide-x divide-concrete  border rounded-default text-white ">
                        {!!userRights && userRights.userManagementEnabled && (
                            <Button
                                {...trigger}
                                disabled={
                                    !!userRights &&
                                    !userRights.userManagementEnabled
                                }
                                onClick={() => {
                                    setAccordionHeader('CreateUser');
                                    clearUserInputs();
                                    if (!expanded) {
                                        onClickExpand();
                                    }
                                }}
                                scheme="primary"
                                className="rounded-br-none rounded-tr-none bg-minsk  focus:ring-offset-0"
                                iconSettings={{ Icon: Add32 }}
                            />
                        )}

                        <div>
                            <Button
                                onClick={() => {
                                    setIsUserManagementLoading(true);
                                    getUserManagementData();
                                }}
                                scheme="primary"
                                className={cx(
                                    'bg-minsk  focus:ring-offset-0',
                                    !!userRights &&
                                        userRights.userManagementEnabled &&
                                        'rounded-bl-none rounded-tl-none'
                                )}
                                iconSettings={{ Icon: Renew32 }}
                            />
                        </div>
                    </div>
                </div>
                {showPlatform && (
                    <DropdownMenu
                        id="platformNamesDropdown"
                        className="p-2 break-all"
                        anchorRef={platformsButtonRef}
                        closeOnClick
                        onClose={() => setShowPlatform(false)}
                    >
                        {platformNames?.map((platform, index) => (
                            <div className="mt-4" key={index}>
                                <DropdownMenuItem
                                    className="hover:bg-transparent hover:text-minsk"
                                    onClick={() => {
                                        onPlatformChange(
                                            {
                                                label: platform,
                                                value: platform,
                                            },
                                            true
                                        );
                                    }}
                                >
                                    {platform}
                                </DropdownMenuItem>
                            </div>
                        ))}
                    </DropdownMenu>
                )}
                <div className="flex">
                    <InputContainer className="w-[500px] rounded-default min-w-[50%] ">
                        <InputLeftElement className="bg-minsk text-white pr-2">
                            <Email24 />
                        </InputLeftElement>
                        <Input
                            placeholder={t('user-management:SearchPlaceholder')}
                            value={globalFilter}
                            onChange={(e) => {
                                onEmailMaskChange('');
                                onGlobalSearchChange(e.target.value);
                            }}
                            className="bg-minsk text-white placeholder:italic placeholder:text-athens focus:placeholder:invisible"
                        />
                    </InputContainer>
                </div>
            </div>
            <div
                {...(panel as PanelInterface<HTMLDivElement>)}
                className={cx(
                    'h-auto overflow-hidden my-0 mx-6 transition-all duration-500 text-body border-b border-base-50 border-alto scroll-shadows',
                    { 'mt-0 mx-6 mb-6 ': expanded }
                )}
            >
                <div className="flex flex-col w-full gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8 pt-12">
                    <h2 className="mb-6">
                        {t(`user-management:${accordionHeader}`)}
                    </h2>
                    <div className="w-full grid grid-cols-2 grid-rows-4 gap-x-6 gap-y-6">
                        <div className="col-start-1 row-start-1">
                            <label htmlFor="email">
                                {t('user-management:Email')}
                            </label>
                            <Input
                                id="email"
                                name="email"
                                value={emailInput}
                                placeholder={t('user-management:Email')}
                                onChange={(e) => {
                                    setEmailInput(e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-start-2 row-start-1">
                            <label htmlFor="userRole">
                                {t('user-management:UserRole')}
                            </label>
                            <Dropdown
                                id="mappedUserRoles"
                                name="mappedUserRoles"
                                value={userRoleInput}
                                menuPosition="fixed"
                                styles={{
                                    control: (styles) => {
                                        styles.transition = 'none';
                                        return styles;
                                    },
                                }}
                                isClearable
                                onChange={(e: any) => {
                                    setUserRoleInput(e);
                                }}
                                options={mappedUserRolesOptions}
                                isOptionDisabled={(option: any) =>
                                    option.disabled
                                }
                                isDisabled={isRoleDisabled()}
                            />
                        </div>
                        <div className="col-start-1 row-start-2">
                            <label htmlFor="platforms">
                                {t('user-management:Platforms')}
                            </label>
                            <Dropdown
                                id="platforms"
                                name="platforms"
                                isMulti
                                value={platformsInput}
                                menuPosition="fixed"
                                styles={{
                                    control: (styles) => {
                                        styles.transition = 'none';
                                        return styles;
                                    },
                                }}
                                isClearable
                                onChange={(e: any) => {
                                    setPlatformsInput(e);
                                }}
                                options={mappedPlatformOptions}
                            />
                        </div>
                        <div className="col-start-2 row-start-2">
                            <label htmlFor="userPermissions">
                                {t('user-management:UserPermissions')}
                            </label>
                            <Dropdown
                                isMulti
                                id="userPermissions"
                                value={userPermissionsInput}
                                menuPosition="fixed"
                                styles={{
                                    control: (styles) => {
                                        styles.transition = 'none';
                                        return styles;
                                    },
                                }}
                                name="userPermissions"
                                isClearable
                                onChange={(e: any) => {
                                    setUserPermissionsInput(e);
                                }}
                                options={userPermissionsOptions}
                            />
                        </div>
                        <div className="col-start-1 row-start-3">
                            <label htmlFor="countries">
                                {t('user-management:Countries')}
                            </label>
                            <Dropdown
                                id="countries"
                                name="countries"
                                isMulti
                                value={countriesInput}
                                menuPosition="fixed"
                                styles={{
                                    control: (styles) => {
                                        styles.transition = 'none';
                                        return styles;
                                    },
                                }}
                                isClearable
                                onChange={(e: any) => {
                                    setCountriesInput(e);
                                }}
                                options={mappedCountryOptions}
                            />
                        </div>
                        <div className="col-start-2 row-start-3">
                            <label htmlFor="statusChangeEnabledLevel">
                                {t('user-management:StatusChangeEnabledLevel')}
                            </label>
                            <Dropdown
                                id="statusChangeEnabledLevel"
                                name="statusChangeEnabledLevel"
                                value={statusChangeEnabledLevelInput}
                                menuPosition="fixed"
                                styles={{
                                    control: (styles) => {
                                        styles.transition = 'none';
                                        return styles;
                                    },
                                }}
                                isClearable={false}
                                onChange={(e: any) => {
                                    setStatusChangeEnabledLevelInput(e);
                                }}
                                options={statusChangeLevelOptions}
                            />
                        </div>
                        <div className="row-start-4 col-start-2 ml-auto">
                            <div className="flex w-full gap-x-2">
                                <Button
                                    scheme="primary"
                                    type="button"
                                    onClick={() => {
                                        submitUserCreateEdit();
                                    }}
                                >
                                    {t('form-content:LabelSave')}
                                </Button>
                                <Button
                                    scheme="secondary"
                                    type="button"
                                    onClick={onClickExpand}
                                >
                                    {t('form-content:LabelCancel')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserManagementAccordion;
