/* istanbul ignore file */
import axios, { AxiosResponse } from 'axios';
import { ConfiguratorFormDefinition } from '../../types/generated/configuratorFormDefinition';
import useSWRImmutable from 'swr/immutable';

const fetcher = (url: string) =>
    axios
        .get(url)
        .then(async (res: AxiosResponse<ConfiguratorFormDefinition[]>) => {
            const { data } = res;
            return data;
        });

const useFetchFormDefinitions = (versionId: string) => {
    const { data, error, mutate } = useSWRImmutable(
        !versionId ? null : `configurator/definitions/${versionId}`,
        fetcher,
        { shouldRetryOnError: false }
    );

    return {
        setStateDefinitions: mutate,
        formDefinitions: data,
        isLoadingFormDefinitions: !error && !data,
        isErrorFetchingFormDefinitions: error,
    };
};
export default useFetchFormDefinitions;
