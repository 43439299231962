export function searchForRenderedFields(arrayOfErrorPaths: string[]) {
    const renderedForm = Array.from(document.forms);
    const renderedFieldsWithErrors: Record<string, HTMLElement> = {};
    if (renderedForm.length && arrayOfErrorPaths?.length) {
        Array.prototype.slice
            .call(renderedForm[0])
            .reduce((acc: string[], field: HTMLInputElement) => {
                if (acc.includes(field.name)) {
                    return acc;
                }
                if (field.name && arrayOfErrorPaths.includes(field.name)) {
                    renderedFieldsWithErrors[field.name] = field;
                    return [...acc, field.name];
                }
                if (
                    !field.name ||
                    (field.name &&
                        (field.type === 'radio' || field.type === 'checkbox'))
                ) {
                    const fieldWrapper = field.closest(
                        '[data-field]'
                    ) as HTMLDivElement;
                    const fieldName = fieldWrapper?.dataset?.field;
                    if (fieldWrapper && fieldName && acc.includes(fieldName)) {
                        return acc;
                    }
                    if (fieldName && arrayOfErrorPaths.includes(fieldName)) {
                        renderedFieldsWithErrors[fieldName] = fieldWrapper;
                        return [...acc, fieldWrapper?.dataset?.field];
                    }
                }
                return acc;
            }, []);
    }
    return renderedFieldsWithErrors;
}
