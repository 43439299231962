import { FieldStructure, AlertFieldStructure } from '@mercell/form-react';
import { FormDefinitionItem } from '../../types/generated/formDefinitionItem';
import { parseDefinitionType } from '../parseDefinitionType';
import { TFunction } from 'react-i18next';

export const mapAlertField = (
    formDefinition: FormDefinitionItem,
    genericFieldStructure: FieldStructure,
    t: TFunction<string, unknown>,
    locale?: Locale
): AlertFieldStructure => ({
    ...genericFieldStructure,
    type: 'alert',
    readOnlyContent: t(`${formDefinition.text}`),
    fieldOrigin: parseDefinitionType(formDefinition.type),
    scheme: formDefinition.scheme,
    locale,
});
