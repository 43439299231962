import axios from 'axios';
import { ConfiguratorVersionActivation } from '../../types/generated/configuratorVersionActivation';

export const activateVersion = (
    payload: ConfiguratorVersionActivation,
    toastText?: {
        onSuccessText?: string;
        onPendingText?: string;
    }
) =>
    axios
        .post(`configurator/version/activate`, payload, {
            headers: {
                'Toast-Pending-Text': toastText?.onPendingText,
                'Toast-Success-Text': toastText?.onSuccessText,
            },
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
