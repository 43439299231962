import axios from 'axios';
import { UserEntry } from '../../types/generated/userEntry';

export const deleteUser = async (
    userId: string,
    toastText?: {
        onSuccessText?: string;
        onPendingText?: string;
    }
) =>
    axios
        .delete(`/admin/users/${userId}`, {
            headers: {
                'Toast-Pending-Text': toastText?.onPendingText,
                'Toast-Success-Text': toastText?.onSuccessText,
            },
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
