import React, { FC } from 'react';
import cx from 'classnames';

interface RequiredIndicatorProps {
    className?: string;
    showRequired?: boolean;
}

export const RequiredIndicator: FC<RequiredIndicatorProps> = ({
    showRequired = false,
    className,
}) => {
    if (!showRequired) return null;
    return (
        <span className={cx('text-well-read', className)} aria-hidden="true">
            *
        </span>
    );
};
