import axios from 'axios';

const UseLockVersion = async (versionId?: string) =>
    axios
        .post(`configurator/lock-version/${versionId ?? ''}`)
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });

export default UseLockVersion;
