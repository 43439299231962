/* eslint-disable camelcase */
import { FieldOrigin, FormWrapper } from '@mercell/form-react';
import React, { FC, useEffect, useRef } from 'react';
import useFetchArrayItem from '../../hooks/formHooks/useFetchArrayItem';
import { useTranslation } from 'react-i18next';
import parseFormDefinitionWrapper from '../../shared/mappedFormSchema';
import { useParams } from 'react-router-dom';
import { FormArrayItem } from '../../types/generated/formArrayItem';
import { ReadOnlyComponent } from '../../components/ReadOnlyComponent';
import NotFoundPage from '../NotFound/NotFoundPage';
import LoadingSpinner from '@mercell/loading-spinner-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppSelector } from '../../redux/hooks';
import { useStoreContext } from '../../state';

interface ArrayElementPreviewProps {
    arrayElement: FormArrayItem;
    arrayPath: string;
}

export const ArrayElementPreview: FC<ArrayElementPreviewProps> = ({
    arrayElement,
    arrayPath,
}) => {
    const { featureFlagUseUtcDatesOnly, featureFlagEnableTextAreaCounter } =
        useFlags();
    const arrayPathRef = useRef<string>(arrayPath);
    const uniqueIdRef = useRef<string>(arrayElement.uniqueId);
    const {
        formId,
        sectionId,
        parentSectionId,
        parentArrayPath,
        parentArrayElementId,
    } = useParams<{
        formId: string;
        sectionId: string;
        parentSectionId?: string;
        parentArrayPath?: string;
        parentArrayElementId?: string;
    }>();
    const { uniqueId } = arrayElement;
    const reduxLocale = useAppSelector((state) => state.store.locale);
    const reduxDateFormat = useAppSelector((state) => state.store.dateFormat);
    const {
        state: { locale, dateFormat },
    } = useStoreContext();

    let conditionallySetFormId = formId ?? '';
    if (
        (arrayPath === '' || arrayPathRef.current !== arrayPath) &&
        uniqueIdRef.current === uniqueId
    ) {
        conditionallySetFormId = '';
    }

    const { formData, isErrorFetchingForm, isLoadingArrayItem } =
        useFetchArrayItem(
            conditionallySetFormId,
            !parentSectionId
                ? sectionId ?? ''
                : `${parentArrayElementId}.${sectionId}`,
            arrayPathRef.current,
            uniqueId,
            true
        );

    const { t } = useTranslation(
        ['form-content'].concat(formData?.codelists ?? [])
    );
    useEffect(() => {
        if (arrayPathRef.current !== arrayPath) {
            arrayPathRef.current = arrayPath;
        }
        if (uniqueIdRef.current !== uniqueId) {
            uniqueIdRef.current = uniqueId;
        }
    }, [arrayPath, uniqueId]);
    if (!formId) return null;
    if (isLoadingArrayItem) {
        return (
            <div className="col-span-full h-full flex items-center justify-center">
                <LoadingSpinner />
            </div>
        );
    }

    if (isErrorFetchingForm)
        return (
            <NotFoundPage
                wrapperClass="w-full"
                errorCode={isErrorFetchingForm?.status?.toString()}
            />
        );
    if (!formData || !formData.formDefinition.length) {
        return <div>No form in DB</div>;
    }
    const { formDefinition, values, noticeTimeZone } = formData;

    const schema = parseFormDefinitionWrapper(
        formDefinition,
        t,
        true,
        reduxDateFormat,
        true,
        reduxLocale,
        featureFlagUseUtcDatesOnly,
        noticeTimeZone,
        featureFlagEnableTextAreaCounter
    );

    return (
        <div>
            {formDefinition?.length > 0 && values && (
                <FormWrapper
                    onSubmit={() => {
                        console.log('do nothing');
                    }}
                    schema={schema}
                    formLabelProperties={{
                        className: 'text-h1 font-bold',
                    }}
                    formProperties={{
                        className: 'my-4 mr-4', // max-w-lg
                    }}
                    formTitle="" // "React Final Form"
                    initialValue={values}
                    readOnlyFieldRender={(
                        content: any,
                        name: string,
                        restReadOnlyProperties,
                        fieldOrigin?: FieldOrigin,
                        elementProperties?: React.DetailedHTMLProps<
                            React.HTMLAttributes<HTMLElement>,
                            HTMLElement
                        >
                    ) => (
                        <ReadOnlyComponent
                            content={content}
                            name={name}
                            fieldOrigin={fieldOrigin}
                            readOnlyProperties={restReadOnlyProperties}
                            elementProperties={elementProperties}
                            t={t}
                            locale={reduxLocale}
                            noticeTimeZone={noticeTimeZone}
                        />
                    )}
                />
            )}
        </div>
    );
};
