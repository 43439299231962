import React from 'react';
import { useParams } from 'react-router-dom';
import useFetchPublicationTask from '../../hooks/administrativeHooks/useFetchPublicationTask';
import NotFoundPage from '../NotFound/NotFoundPage';
import LoadingSpinner from '@mercell/loading-spinner-react';

const PublicationTaskPage = () => {
    const { formId } = useParams<{ formId: string }>();

    const {
        publicationTask,
        isLoadingPublicationTask,
        isErrorFetchingPublicationTask,
    } = useFetchPublicationTask(formId ?? '');

    if (isLoadingPublicationTask)
        return (
            <div className="col-span-full h-full flex items-center justify-center">
                <LoadingSpinner />
            </div>
        );

    if (isErrorFetchingPublicationTask) {
        return (
            <NotFoundPage
                wrapperClass="col-span-full"
                errorCode={isErrorFetchingPublicationTask.status.toString()}
            />
        );
    }

    return (
        <div className="col-span-full">
            <pre>
                {publicationTask?.id
                    ? JSON.stringify(publicationTask, undefined, 2)
                    : 'No data'}
            </pre>
        </div>
    );
};

export default PublicationTaskPage;
