import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

const fetcher = (url: string) =>
    axios.get(url).then(async (res: AxiosResponse) => res.data);

const useFetchFormPreviewUrl = (
    versionId: string,
    definitionId: string,
    country: string,
    manyLotsEnabled: boolean
) => {
    const { data, error, mutate } = useSWRImmutable(
        !versionId || !definitionId
            ? null
            : `configurator/preview-url/${versionId}/${definitionId}/${manyLotsEnabled}/${
                  sessionStorage.getItem('i18nextLng') ?? 'en'
              }`,
        fetcher,
        { shouldRetryOnError: false }
    );
    return {
        setStatePreviewUrl: mutate,
        otpUrl: data,
        isLoadingOtpUrl: !data && !error,
        isErrorFetchingOtpUrl: error,
    };
};

export default useFetchFormPreviewUrl;
