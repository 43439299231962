import React, {
    FC,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Ribbon } from '@mercell/ribbon-react';
import {
    ColumnDef,
    SortingState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
    TableOptions,
    VisibilityState,
} from '@tanstack/react-table';
import { getDashboard } from '../../apiCalls/getDashboard';
import { DashboardEntry } from '../../types/generated/dashboardResponseEntry';
import { useTranslation } from 'react-i18next';
import useFetchPortalNames from '../../hooks/administrativeHooks/useFetchPortalName';
import useFetchFormStatuses from '../../hooks/administrativeHooks/useFetchFormStatuses';
import useFetchPlatformNames from '../../hooks/administrativeHooks/useFetchPlatformNames';
import Button from '@mercell/button-react';
import {
    ArrowLeft32,
    ArrowRight32,
    CaretDown16,
    CaretUp16,
} from '@carbon/icons-react';
import useFetchPortalStatuses from '../../hooks/administrativeHooks/useFetchPortalStatuses';
import useFetchFormCreationProperties from '../../hooks/administrativeHooks/useFetchFormCreationProperties';
import { format, parseISO } from 'date-fns';
import { useStoreContext } from '../../state';
import NoAccessPage from '../NotFound/NoAccessPage';
import { FormStatus } from '../../types/generated/formMenuResult';
import TableDropDown from './TableDropDown';
import { saveFormStatus } from '../../apiCalls/saveFormStatus';
import { setBackInProgress } from '../../apiCalls/dashboard/setBackInProgress';
import { fetchStopPublication } from '../../apiCalls/dashboard/fetchStopPublication';
import ConfirmationModal from '../../components/ConfirmationModal';
import {
    getAdminTokenFromApi,
    getOtpUrlFromApi,
    getRepTokenFromApi,
} from '../../authorization/getAuthFromApi';
import DashboardFiltersAccordion from '../../components/DashboardFiltersAccordion';
import { SelectOption } from '../../types/generated/selectOption';
import cx from 'classnames';
import Tooltip from '@mercell/tooltip-react';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import {
    isSelectOption,
    isSelectOptionArrayState,
    isString,
    isStringState,
} from '../../shared/typeguards/typeguards';
import { toast } from 'react-toastify';
import PublicationLog, { PublicationLogProps } from './PublicationLog';
import StatusLog, { StatusLogProps } from './StatusLog';
import { getSchemeName, getStatusName } from '../../shared/dashboardShared';
import { useAppSelector } from '../../redux/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AuditLog, { AuditLogProps } from './AuditLogComp';
import ValidationFull from './ActionMenuComponent/ValidateFull';
import { formOptions } from '../../types/enums/formOptions';

interface SearchFilterData {
    searchMask: string | undefined;
    title: string | undefined;
    contractingAuthority: string | undefined;
    subtype: string[] | undefined;
    portalname: string[] | undefined;
    tenderingSystem: string[] | undefined;
    country: string[] | undefined;
    portalStatus: string[] | undefined;
    status: string[] | undefined;
}

interface PagingData {
    isAscending: boolean | undefined;
    pageNumber: number | undefined;
    pageSize: number | undefined;
    sortableField: string | undefined;
}

interface FilterData {
    searchFilterData: SearchFilterData | null;
    pagingData: PagingData | undefined;
}

const getValueData = (options: any) =>
    options.map((option: any) => option.value.toString());

const DashboardPage: FC = () => {
    const { mef2179, mef2123 } = useFlags();
    const {
        state: { locale, dateFormat },
    } = useStoreContext();

    const didMount = useRef(false);
    const reduxDateFormat = useAppSelector((state) => state.store.dateFormat);
    const reduxLocale = useAppSelector((state) => state.store.locale);
    const reduxRole = useAppSelector((state) => state.store.role);
    const reduxUserId = useAppSelector((state) => state.store.userId);
    const { statuses, isLoadingStatuses } = useFetchFormStatuses();
    const { platformNames, isLoadingPlatformNames } = useFetchPlatformNames();
    const { portalName, isLoadingPortalNames } = useFetchPortalNames();
    const { portalStatuses, isLoadingPortalStatuses } =
        useFetchPortalStatuses();
    const { data, isLoadingData } = useFetchFormCreationProperties('all');

    const isLoadingFetchingItems =
        isLoadingData &&
        isLoadingPlatformNames &&
        isLoadingPortalNames &&
        isLoadingPortalStatuses &&
        isLoadingStatuses;

    const [clientSorting, setClientSorting] = useState<SortingState>([
        { id: 'executedAt', desc: true },
    ]);

    const [sortableField, setSortableField] = useState<string>('');
    const [isAscending, setIsAscending] = useState<boolean>(false);

    const [noAccess, setNoAccess] = useState(false);

    const [countriesData, setCountriesData] = useState<SelectOption[]>([]);
    const [noticeSubtypeData, setNoticeSubtypeData] = useState<SelectOption[]>(
        []
    );
    const [portalNameData, setPortalNameData] = useState<SelectOption[]>([]);
    const [portalStatusesData, setPortalStatusesData] = useState<
        SelectOption[]
    >([]);
    const [statusesData, setStatusesData] = useState<SelectOption[]>([]);
    const [platformData, setPlatformData] = useState<SelectOption[]>([]);
    const [searchMask, setSearchMask] = useState<string>('');
    const [modelFormId, setModelFormId] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [contractingAuthority, setContractingAuthority] =
        useState<string>('');

    const [tasks, setTasks] = useState<DashboardEntry[]>([]);
    const [filterData, setFilterData] = useState<FilterData>({
        searchFilterData: {
            searchMask,
            title,
            contractingAuthority,
            subtype: getValueData(noticeSubtypeData),
            portalname: getValueData(portalNameData),
            tenderingSystem: getValueData(platformData),
            country: getValueData(countriesData),
            portalStatus: getValueData(portalStatusesData),
            status: getValueData(statusesData),
        },
        pagingData: {
            pageNumber: 1,
            pageSize: 10,
            isAscending: clientSorting[0]?.desc ?? false,
            sortableField: clientSorting[0]?.id ?? '',
        },
    });
    const [numberOfSelectedFilters, setNumberOfSelectedFilters] =
        useState<number>(0);
    const [isDashboardLoading, setIsDashboardLoading] = useState(true);
    const [isErrorModalVisible, SetErrorModelVisible] =
        useState<boolean>(false);
    const [formStatusError, setFormStatusError] = useState<string>();
    const [isReturnStatusModalVisible, setIsReturnStatusModalVisible] =
        useState<boolean>(false);
    const [
        isReturnStatusToDispatchedModalVisible,
        setIsReturnStatusToDispatchedModalVisible,
    ] = useState<boolean>(false);
    const [
        isReturnStatusToStopPublication,
        setIsReturnStatusToStopPublication,
    ] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useState<boolean>(false);
    const { t } = useTranslation([
        'dashboard',
        'list.country',
        'form-content',
        'list.notice-subtype',
        'list.audit-log-status',
        'toast-content',
    ]);

    const [showAuditLog, setShowAuditLog] = useState(false);
    const [auditLogProps, setAuditLogProps] = useState<AuditLogProps>({
        statuses,
        t,
        locale: reduxLocale,
        dateFormat: reduxDateFormat,
        formId: '',
        setShowAuditLog,
    });

    const [showStatusLog, setShowStatusLog] = useState(false);
    const [statusLogProps, setStatusLogProps] = useState<StatusLogProps>({
        statuses,
        t,
        locale: reduxLocale,
        dateFormat: reduxDateFormat,
        formId: '',
        setShowStatusLog,
    });

    const [showPublicationLog, setShowPublicationLog] = useState(false);
    const [numberOfRecords, setNumberOfRecords] = useState<number>();

    const [showValidationFull, setShowValidationFull] =
        useState<boolean>(false);
    const [validationFullFormId, setValidationFullFormId] = useState<any>();

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        const copiedMessage = t('form-content:CopiedToClipboard');
        toast.success(copiedMessage);
    };

    const [publicationLogProps, setPublicationLogProps] =
        useState<PublicationLogProps>({
            t,
            locale: reduxLocale,
            dateFormat: reduxDateFormat,
            formId: '',
            setShowPublicationLog,
            copyToClipboard,
        });

    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
        contractingAuthority: false,
        title: false,
        portalExecutedAt: false,
        executedAt: false,
        formId: false,
        portalNoticeId: false,
        portalNoticeIdAndVersionId: false,
        versionName: false,
        tenderId: false,
    });

    // Table
    type ColumnMeta = {
        meta?: {
            size?: number | string;
            textAlign?: 'text-left' | 'text-center' | 'text-right';
        };
    };

    type AugmentedColumnDef = ColumnDef<DashboardEntry> & ColumnMeta;

    let columns = useMemo<AugmentedColumnDef[]>(
        () => [
            {
                accessorKey: 'tenderId',
                cell: (info: any) => info.getValue(),
                header: t('dashboard:TenderId'),
                meta: {
                    size: '2/3',
                    textAlign: 'text-center',
                },
                enableHiding: false,
                enableColumnFilter: true,
            },
            {
                accessorFn: (row) => ({
                    tenderId: row.tenderId,
                    platform: row.tenderingSystem,
                }),
                id: 'tenderIdAndPlatform',
                cell: (info: any) => info.getValue(),
                header: t('dashboard:TenderId'),
                meta: {
                    size: '2/3',
                    textAlign: 'text-center',
                },
                enableHiding: false,
                enableColumnFilter: false,
            },
            {
                accessorKey: 'formId',
                meta: {
                    size: '1/3',
                    textAlign: 'text-center',
                },
                enableHiding: true,
                enableColumnFilter: true,
                cell: (info: any) => info.getValue(),
            },
            {
                accessorKey: 'portalNoticeId',
                meta: {
                    size: '1/3',
                    textAlign: 'text-center',
                },
                enableHiding: true,
                enableColumnFilter: true,
                cell: (info: any) => info.getValue(),
            },
            // {
            //     accessorKey: 'title',
            //     header: t('dashboard:Title'),
            //     cell: (info) => info.renderValue(),
            //     meta: {
            //         size: '1/3',
            //         textAlign: 'text-center',
            //     },
            //     enableHiding: true,
            //     enableColumnFilter: false,
            // },
            // {
            //     accessorFn: (row) => row.contractingAuthority,
            //     id: 'contractingAuthority',
            //     cell: (info) => info.getValue(),
            //     header: t('dashboard:ContractingAuthorityOrEntity'),
            //     meta: {
            //         size: '1/3',
            //         textAlign: 'text-center',
            //     },
            //     enableHiding: true,
            //     enableColumnFilter: false,
            // },
            {
                accessorFn: (row) => ({
                    country: row.country,
                    portalName: row.portalName,
                }),
                id: 'countryAndPortal',
                cell: (info) => info.getValue(),
                header: t('dashboard:Country'),
                meta: {
                    size: '1/3',
                    textAlign: 'text-center',
                },
                enableHiding: false,
                enableColumnFilter: false,
            },
            {
                accessorKey: 'subtype',
                id: 'subtype',
                cell: (info) => info.getValue(),
                header: t('dashboard:Subtype'),
                meta: {
                    size: '1/3',
                    textAlign: 'text-center',
                },
                enableHiding: true,
                enableColumnFilter: false,
            },
            {
                accessorKey: 'versionName',
                id: 'versionName',
                cell: (info: any) => info.getValue(),
                header: t('SDKVersionName'),
                meta: {
                    size: '2/3',
                    textAlign: 'text-center',
                },
                enableHiding: true,
                enableSorting: true,
                enableColumnFilter: false,
            },
            {
                accessorFn: (row) => ({
                    status: row.portalStatus,
                    executedAt: row.portalExecutedAt,
                }),
                id: 'portalStatusAndExecutedAt',
                cell: (info) => info.getValue(),
                header: t('dashboard:PortalStatus'),
                meta: {
                    size: '1/3',
                    textAlign: 'text-center',
                },
                enableHiding: true,
                enableColumnFilter: false,
            },
            {
                accessorFn: (row) => ({
                    status: row.status,
                    executedAt: row.executedAt,
                }),
                id: 'statusAndExecutedAt',
                cell: (info) => info.getValue(),
                header: t('dashboard:MeFormsStatus'),
                meta: {
                    size: '1/3',
                    textAlign: 'text-center',
                },
                enableHiding: true,
                enableColumnFilter: false,
            },
            {
                accessorKey: 'portalExecutedAt',
                header: t('dashboard:PortalExecutedAt'),
                cell: (info: any) => info.getValue(),
                meta: {
                    size: '2/3',
                    textAlign: 'text-center',
                },
                enableHiding: true,
                enableSorting: true,
                enableColumnFilter: false,
            },
            {
                accessorKey: 'executedAt',
                id: 'executedAt',
                header: t('dashboard:ExecutedAt'),
                cell: (info: any) => info.getValue(),
                meta: {
                    size: '2/3',
                    textAlign: 'text-center',
                },
                enableHiding: true,
                enableSorting: true,
                enableColumnFilter: false,
            },
            {
                id: 'menuColumn',
                cell: (info: any) => info.getValue(),
                meta: {
                    size: '1/5',
                    textAlign: 'text-right',
                },
                enableHiding: false,
                enableSorting: false,
                enableColumnFilter: false,
            },
            {
                accessorFn: (row) => `${row.portalNoticeId}-${row.versionId}`,
                id: 'portalNoticeIdAndVersionId',
                meta: {
                    size: '1/3',
                    textAlign: 'text-center',
                },
                enableHiding: true,
                enableColumnFilter: true,
                cell: (info: any) => info.getValue(),
            },
        ],
        [t]
    );

    if (!mef2179) {
        columns = columns.filter((x) => x.id !== 'versionName');
    }

    const conditionalOptions = {
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setClientSorting,
        initialState: {
            pagination: {
                pageIndex: 0,
                pageSize: 10,
            },
        },
        state: {
            sorting: clientSorting,
            columnVisibility,
            searchMask,
        },
        manualPagination: true,
        manualSorting: true,
    };
    const tableOptions: TableOptions<DashboardEntry> = {
        data: tasks,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        ...conditionalOptions,
    };

    const table = useReactTable(tableOptions);

    // Hooks
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const platformStorage = localStorage.getItem('platformData');
        const defaultPlatform = [
            platformNames
                ? { value: platformNames[0], label: platformNames[0] }
                : { value: 'ClientApp', label: 'ClientApp' },
        ];
        const valueToData = (
            values: string,
            itemsData?: any
        ): SelectOption[] | any => {
            const splitString = values.split(',');
            if (itemsData) {
                return splitString.map((value: string) =>
                    itemsData.find((item: any) => item.value === value)
                );
            }

            return splitString.map((value: string) => ({
                label: value,
                value,
            }));
        };

        const fetchURLParams = (
            dataOptions: {
                name: string;
                setData: React.Dispatch<React.SetStateAction<SelectOption[]>>;
            }[]
        ) => {
            dataOptions.forEach((item) => {
                const searchParamItem = params.get(item.name);
                item.setData(
                    searchParamItem ? valueToData(searchParamItem) : []
                );
            });
        };

        const updateStateAndURLSearchParams = () => {
            const searchParamFilterData = [
                { name: 'countries', setData: setCountriesData },
                { name: 'noticeSubTypes', setData: setNoticeSubtypeData },
                { name: 'portalNames', setData: setPortalNameData },
                { name: 'portalStatuses', setData: setPortalStatusesData },
                { name: 'statuses', setData: setStatusesData },
            ];

            fetchURLParams(searchParamFilterData);
        };
        if (!isLoadingFetchingItems) {
            if (params.toString() === '' && searchParams) {
                setStatusesData([
                    {
                        value:
                            statuses
                                ?.find(
                                    (status) =>
                                        status.name === 'Error' ||
                                        status.value === 60
                                )
                                ?.value.toString() ?? '60',
                        label:
                            statuses
                                ?.find(
                                    (status) =>
                                        status.name === 'Error' ||
                                        status.value === 60
                                )
                                ?.value.toString() ?? '60',
                    },
                ]);
            } else {
                updateStateAndURLSearchParams();
            }
        }
        setPlatformData(
            platformStorage ? valueToData(platformStorage) : defaultPlatform
        );
        setSearchParams(true);

        const savedColumnVisibilityString =
            localStorage.getItem('columnVisibility');

        if (savedColumnVisibilityString) {
            const savedColumnVisibility = JSON.parse(
                savedColumnVisibilityString
            );
            setColumnVisibility(savedColumnVisibility);
        }
    }, [isLoadingFetchingItems, platformNames, searchParams, statuses]);

    useEffect(() => {
        const params = new URLSearchParams();
        const dataToValue = (selectOptions: SelectOption[]) =>
            selectOptions.map((option) => option.value);

        const setupURLParams = (
            dataOptions: { name: string; data: SelectOption[] }[]
        ) => {
            dataOptions.forEach((item) => {
                if (item.data.length > 0) {
                    params.set(item.name, dataToValue(item.data).toString());
                }
            });
        };

        const updateURLSearchParams = () => {
            const searchParamFilterData = [
                { name: 'countries', data: countriesData },
                { name: 'noticeSubTypes', data: noticeSubtypeData },
                { name: 'portalNames', data: portalNameData },
                { name: 'portalStatuses', data: portalStatusesData },
                { name: 'statuses', data: statusesData },
            ];

            localStorage.setItem(
                'platformData',
                dataToValue(platformData).toString()
            );

            setupURLParams(searchParamFilterData);

            window.history.replaceState({}, '', `?${params.toString()}`);
        };

        if (!isLoadingFetchingItems && searchParams) {
            updateURLSearchParams();
        }

        setNumberOfSelectedFilters(
            noticeSubtypeData.length +
                statusesData.length +
                portalNameData.length +
                portalStatusesData.length +
                countriesData.length
        );

        if (!didMount.current) {
            setNumberOfSelectedFilters(
                noticeSubtypeData.length +
                    statusesData.length +
                    portalNameData.length +
                    portalStatusesData.length +
                    countriesData.length
            );

            return () => {
                didMount.current = true;
            };
        }

        setFilterData({
            searchFilterData: {
                searchMask,
                title,
                contractingAuthority,
                subtype: getValueData(noticeSubtypeData),
                portalname: getValueData(portalNameData),
                tenderingSystem: getValueData(platformData),
                country: getValueData(countriesData),
                portalStatus: getValueData(portalStatusesData),
                status: getValueData(statusesData),
            },
            pagingData: {
                pageNumber: table.getState().pagination.pageIndex + 1,
                pageSize: table.getState().pagination.pageSize,
                isAscending,
                sortableField,
            },
        });
    }, [
        contractingAuthority,
        countriesData,
        isLoadingFetchingItems,
        noticeSubtypeData,
        platformData,
        portalNameData,
        portalStatusesData,
        searchMask,
        searchParams,
        sortableField,
        isAscending,
        statusesData,
        table,
        title,
    ]);

    useEffect(() => {
        setIsDashboardLoading(true);
        getDashBoardData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterData?.searchFilterData]);

    useEffect(() => {
        localStorage.setItem(
            'columnVisibility',
            JSON.stringify(columnVisibility)
        );
    }, [columnVisibility]);

    useEffect(() => {
        setIsDashboardLoading(true);
        getDashBoardData();
        setSortableField(clientSorting[0]?.id ?? '');
        setIsAscending(clientSorting[0]?.desc ?? false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table.getState().sorting]);

    // Functions
    const getDashBoardData = useDebouncedCallback(
        async () => {
            setIsDashboardLoading(true);
            try {
                const resData = await getDashboard('ClientApp', {
                    ...filterData,
                    pagingData: {
                        pageNumber: table.getState().pagination.pageIndex + 1,
                        pageSize: table.getState().pagination.pageSize,
                        isAscending,
                        sortableField,
                    },
                }).finally(() => setIsDashboardLoading(false));

                if (resData) {
                    const tempData =
                        resData.dashboardData?.map((item: DashboardEntry) => ({
                            ...item,
                            showDropDown: false,
                        })) ?? [];

                    // Correctly access totalCount from resData directly
                    setNumberOfRecords(resData.totalCount);
                    table.setOptions({
                        ...table.options,
                        pageCount: resData.totalPages,
                    });

                    // Then, set the tasks (or whatever tempData represents) with the mapped data
                    setTasks(tempData);
                }
            } catch (error: unknown) {
                if (
                    typeof error === 'object' &&
                    error !== null &&
                    'status' in error
                ) {
                    const { status } = error as { status: number };
                    if (status === 401) {
                        setNoAccess(true);
                    }
                } else {
                    console.error('An error occurred', error);
                }
            }
        },
        2500,
        [filterData?.searchFilterData]
    );

    useEffect(() => {
        table.setPageIndex(0);
        setIsDashboardLoading(true);
        getDashBoardData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchMask, table]);

    useEffect(() => {
        setIsDashboardLoading(true);
        getDashBoardData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table.getState().pagination.pageIndex]);

    const handleDropDownShow = (e: any, cell: any) => {
        e.preventDefault();
        e.stopPropagation();
        const tempData =
            tasks.map((item: any) => {
                if (item.formId === cell.row.original.formId) {
                    return {
                        ...item,
                        showDropDown: !cell.row.original.showDropDown,
                    };
                }
                return { ...item, showDropDown: false };
            }) ?? [];
        setTasks(tempData);
    };

    const clearFilter = useCallback(() => {
        if (title !== '' || contractingAuthority !== '') {
            setTitle('');
            setContractingAuthority('');
        }
        if (
            countriesData.length ||
            noticeSubtypeData.length ||
            noticeSubtypeData.length ||
            portalNameData.length ||
            portalStatusesData.length ||
            statusesData.length
        ) {
            setCountriesData([]);
            setNoticeSubtypeData([]);
            setNoticeSubtypeData([]);
            setPortalNameData([]);
            setPortalStatusesData([]);
            setStatusesData([]);
        }

        table.setPageIndex(0);
    }, [
        contractingAuthority,
        countriesData.length,
        noticeSubtypeData.length,
        portalNameData.length,
        portalStatusesData.length,
        statusesData.length,
        table,
        title,
    ]);

    const handleCheckedInputStateChange = (
        inputData: SelectOption | string,
        currentData: SelectOption[] | string,
        setState:
            | React.Dispatch<React.SetStateAction<SelectOption[]>>
            | React.Dispatch<React.SetStateAction<string>>
    ) => {
        if (
            isSelectOption(inputData) &&
            isSelectOptionArrayState(setState) &&
            Array.isArray(currentData)
        ) {
            if (
                currentData.find(
                    (currentItem) =>
                        currentItem.value?.toString() ===
                        inputData.value?.toString()
                )
            ) {
                setState((prevCheckedItems) =>
                    prevCheckedItems.filter(
                        (previousItem) =>
                            previousItem.value?.toString() !==
                            inputData.value?.toString()
                    )
                );
            } else {
                setState((prevCheckedItems) => [
                    ...prevCheckedItems,
                    inputData,
                ]);
            }
        } else if (isStringState(setState) && isString(inputData)) {
            setState(inputData);
        }
        table.setPageIndex(0);
    };

    const onCountryChange = (newData: SelectOption) => {
        handleCheckedInputStateChange(newData, countriesData, setCountriesData);
    };

    const onPlatformChange = (newData: SelectOption, resetData?: boolean) => {
        if (resetData) {
            setPlatformData([newData]);
        } else
            handleCheckedInputStateChange(
                newData,
                platformData,
                setPlatformData
            );
    };

    const onNoticeSubtypeChange = (newData: SelectOption) => {
        handleCheckedInputStateChange(
            newData,
            noticeSubtypeData,
            setNoticeSubtypeData
        );
    };

    const onPortalNameChange = (newData: SelectOption) => {
        handleCheckedInputStateChange(
            newData,
            portalNameData,
            setPortalNameData
        );
    };

    const onPortalStatusesChange = (newData: SelectOption) => {
        handleCheckedInputStateChange(
            newData,
            portalStatusesData,
            setPortalStatusesData
        );
    };

    const onStatusesChange = (newData: SelectOption) => {
        handleCheckedInputStateChange(newData, statusesData, setStatusesData);
    };

    const onSearchMaskChange = (newVal: string) => {
        setSearchMask(newVal);
    };

    const openModal = (formID: string) => {
        setIsReturnStatusModalVisible(true);
        setModelFormId(formID);
    };

    const openModalBackToDispatched = (formID: string) => {
        setIsReturnStatusToDispatchedModalVisible(true);
        setModelFormId(formID);
    };

    const openModalPublicationStop = (formID: string) => {
        setIsReturnStatusToStopPublication(true);
        setModelFormId(formID);
    };

    const onConfirm = async () => {
        setIsReturnStatusModalVisible(false);
        setIsReturnStatusToDispatchedModalVisible(false);
        saveFormStatus(modelFormId, FormStatus.Draft.toString(), {
            onPendingText: t('toast-content:ToastSaving'),
            onSuccessText: t('toast-content:ToastSaveNoticeStatusSuccess'),
        }).then(() => {
            getDashBoardData();
        });
    };

    const onConfirmDispatched = async () => {
        setIsReturnStatusModalVisible(false);
        setIsReturnStatusToDispatchedModalVisible(false);

        setBackInProgress(modelFormId, {
            onPendingText: t('toast-content:ToastSaving'),
            onSuccessText: t('toast-content:ToastSaveNoticeStatusSuccess'),
        }).then(() => {
            getDashBoardData();
        });
    };

    const onConfirmStopPublication = async () => {
        setIsReturnStatusToStopPublication(false);
        fetchStopPublication(modelFormId, {
            onPendingText: t('toast-content:ToastSaving'),
            onSuccessText: t('toast-content:ToastSaveNoticeStatusSuccess'),
        }).then(() => {
            getDashBoardData();
        });
    };

    const getCellValue = (cell: any) => {
        switch (cell.column.id) {
            case 'countryAndPortal': {
                const { country: cellCountry, portalName: cellPortalName } =
                    cell.getValue();
                return (
                    <div className="flex flex-col">
                        {cellCountry && (
                            <p>{t(`list.country:${cellCountry}`)}</p>
                        )}
                        {cellPortalName && (
                            <p className="text-caption text-dove">
                                {cellPortalName}
                            </p>
                        )}
                    </div>
                );
            }
            case 'menuColumn':
                return (
                    <TableDropDown
                        cell={cell}
                        t={t}
                        handleDropDownShow={handleDropDownShow}
                        copyToClipboard={copyToClipboard}
                        openModal={openModal}
                        openModalBackToDispatched={openModalBackToDispatched}
                        openModalPublicationStop={openModalPublicationStop}
                        viewAuditLog={viewAuditLog}
                        viewStatusLog={viewStatusLog}
                        viewPublicationLog={viewPublicationLog}
                        viewValidationFull={viewValidationFull}
                    />
                );
            case 'statusAndExecutedAt':
            case 'portalStatusAndExecutedAt': {
                const { status, executedAt } = cell.getValue();

                if (status !== undefined) {
                    const RibbonElement = (
                        <div className="flex justify-center items-center whitespace-nowrap">
                            <Ribbon scheme={getSchemeName(status, statuses)}>
                                {t(
                                    `list.audit-log-status:${getStatusName(
                                        status,
                                        statuses
                                    )}`
                                )}
                            </Ribbon>
                        </div>
                    );
                    if (executedAt) {
                        return (
                            <div className="flex justify-center items-center">
                                <Tooltip
                                    message={format(
                                        parseISO(executedAt),
                                        reduxDateFormat.DateTimeWithZoneShort,
                                        {
                                            locale: reduxLocale,
                                        }
                                    )}
                                    tooltipArrowClassName="after:border-b-minsk"
                                    tooltipInnerWrapperClassName="bg-minsk"
                                    placement="bottom"
                                >
                                    <div className="truncate">
                                        {RibbonElement}
                                    </div>
                                </Tooltip>
                            </div>
                        );
                    }

                    return RibbonElement;
                }
                return <div />;
            }
            case 'executedAt':
            case 'portalExecutedAt': {
                if (cell.getValue()) {
                    return (
                        <div>
                            <div>
                                {format(
                                    parseISO(cell.getValue()),
                                    dateFormat.Date,
                                    {
                                        locale,
                                    }
                                )}
                            </div>

                            <div>
                                {format(
                                    parseISO(cell.getValue()),
                                    dateFormat.TimeWithZoneShort,
                                    {
                                        locale,
                                    }
                                )}
                            </div>
                        </div>
                    );
                }
                return '';
            }
            case 'tenderIdAndPlatform': {
                const { tenderId: cellTenderId, platform: cellPlatform } =
                    cell.getValue();
                return (
                    <div className="flex flex-col">
                        {cellTenderId && <p>{cellTenderId}</p>}
                        {cellPlatform && mef2123 && (
                            <p className="text-caption text-dove">
                                {cellPlatform}
                            </p>
                        )}
                    </div>
                );
            }
            default:
                return cell.getValue();
        }
    };

    if (noAccess) {
        return <NoAccessPage />;
    }

    const getAuthToken = async (
        frmId: string,
        tnderId: string,
        tenderingSystem: string,
        logType: string
    ) => {
        if (frmId && frmId !== '') {
            await getAdminTokenFromApi({
                formId: frmId,
                formUserRights: 1,
                name: tenderingSystem,
                role: reduxRole,
                userId: reduxUserId,
                tenderId: tnderId,
            }).then(() => {
                if (logType === 'audit') setShowAuditLog(true);
                else if (logType === 'status') setShowStatusLog(true);
                else if (logType === formOptions.validationFull)
                    setShowValidationFull(true);
            });
        }
    };

    const viewAuditLog = (
        frmId: string,
        tndrId: string,
        tenderingSystem: string
    ) => {
        setAuditLogProps({
            ...auditLogProps,
            ...{
                statuses,
                formId: frmId,
                tenderId: tndrId,
                platformName: tenderingSystem,
            },
        });
        getAuthToken(frmId, tndrId, tenderingSystem, 'audit');
    };

    const viewStatusLog = (
        frmId: string,
        tndrId: string,
        tenderingSystem: string
    ) => {
        setStatusLogProps({
            ...statusLogProps,
            ...{
                statuses,
                formId: frmId,
                tenderId: tndrId,
                platformName: tenderingSystem,
            },
        });
        getAuthToken(frmId, tndrId, tenderingSystem, 'status');
    };

    const viewPublicationLog = (frmId: string) => {
        setPublicationLogProps({
            ...publicationLogProps,
            ...{
                formId: frmId,
            },
        });
        setShowPublicationLog(true);
    };

    const showValidationFullModal = async (otp: string) => {
        await getRepTokenFromApi(otp ?? '', 'dashboard');

        setShowValidationFull(true);
    };

    const viewValidationFull = async (tenderData: any) => {
        const otpUrl = await getOtpUrlFromApi(
            tenderData.formId,
            2,
            formOptions.validationFull
        );

        if (otpUrl) {
            const url = new URL(otpUrl);
            const otp = url.pathname.split('/')[2];
            await showValidationFullModal(otp);
        }
        setValidationFullFormId(tenderData.formId);
    };

    return (
        <div className="col-span-full mt-5">
            <ConfirmationModal
                isModalVisible={
                    isReturnStatusModalVisible ||
                    isReturnStatusToDispatchedModalVisible
                }
                closeModal={() => {
                    setIsReturnStatusModalVisible(false);
                    setIsReturnStatusToDispatchedModalVisible(false);
                }}
                onConfirm={
                    isReturnStatusModalVisible ? onConfirm : onConfirmDispatched
                }
                confirmText={t('form-configurator:Confirm')}
                cancelText={t('form-configurator:Cancel')}
            >
                <div>
                    {isReturnStatusModalVisible
                        ? t('dashboard:ChangeStatusToDraft')
                        : t('dashboard:ReturnStatusToDispatchedForPublication')}
                </div>
            </ConfirmationModal>
            <ConfirmationModal
                isModalVisible={isErrorModalVisible}
                closeModal={() => SetErrorModelVisible(false)}
                cancelText={t('form-configurator:Cancel')}
            >
                <div className="flex flex-col justify-between">
                    <img
                        src="/images/not-found.svg"
                        className="w-1/3  m-auto mb-2"
                        alt={formStatusError}
                    />
                    <div className="flex flex-row justify-center">
                        <h1 className="mb-6">{formStatusError}</h1>
                    </div>
                </div>
            </ConfirmationModal>
            <ConfirmationModal
                isModalVisible={isReturnStatusToStopPublication}
                closeModal={() => {
                    setIsReturnStatusToStopPublication(false);
                }}
                onConfirm={onConfirmStopPublication}
                confirmText={t('form-configurator:Confirm')}
                cancelText={t('form-configurator:Cancel')}
            >
                <div>{t('dashboard:ChangeStatusToStopPublication')}</div>
            </ConfirmationModal>
            <section className="container mx-auto px-4">
                <div className="mt-6 md:flex md:items-center md:justify-between">
                    <DashboardFiltersAccordion
                        numberOfSelectedFilters={numberOfSelectedFilters}
                        platformNames={platformNames}
                        meFormStatuses={statuses}
                        countries={data?.countries}
                        clearFilter={clearFilter}
                        subTypes={data?.noticeSubtypes}
                        portalStatuses={portalStatuses}
                        portals={portalName}
                        t={t}
                        onCountryChange={onCountryChange}
                        onPlatformChange={onPlatformChange}
                        onNoticeSubtypeChange={onNoticeSubtypeChange}
                        onPortalNameChange={onPortalNameChange}
                        onPortalStatusesChange={onPortalStatusesChange}
                        onStatusesChange={onStatusesChange}
                        onSearchMaskChange={onSearchMaskChange}
                        filterData={filterData}
                        table={table}
                        globalFilter={searchMask}
                        getDashboardData={getDashBoardData}
                        setIsDashboardLoading={setIsDashboardLoading}
                    />
                </div>
                <div className="mt-6 flex flex-col ">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 ">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden border border-alto md:rounded-default">
                                <table className="min-w-full divide-y divide-concrete table-fixed">
                                    <thead className="bg-minsk sticky top-0">
                                        <tr>
                                            {table
                                                .getFlatHeaders()
                                                .map((header: any) => {
                                                    const columnSize =
                                                        header.column.columnDef
                                                            .meta?.size;

                                                    const textAlign =
                                                        header.column.columnDef
                                                            .meta?.textAlign;
                                                    const columnWidth = `w-${columnSize}`;
                                                    return (
                                                        <th
                                                            key={header.id}
                                                            onClick={header.column.getToggleSortingHandler()}
                                                            scope="col"
                                                            className={`
                                                                ${cx(
                                                                    ' p-2 text-white title-body select-none whitespace-normal',
                                                                    columnSize &&
                                                                        columnWidth,
                                                                    textAlign &&
                                                                        textAlign,
                                                                    header.column.getCanSort() &&
                                                                        ' hover:cursor-pointer'
                                                                )}`}
                                                        >
                                                            {header.isPlaceholder ? null : (
                                                                <div>
                                                                    {flexRender(
                                                                        header
                                                                            .column
                                                                            .columnDef
                                                                            .header,
                                                                        header.getContext()
                                                                    )}
                                                                    {{
                                                                        asc: (
                                                                            <CaretDown16 className="inline-block" />
                                                                        ),
                                                                        desc: (
                                                                            <CaretUp16 className="inline-block" />
                                                                        ),
                                                                    }[
                                                                        header.column.getIsSorted() as string
                                                                    ] ?? null}
                                                                </div>
                                                            )}
                                                        </th>
                                                    );
                                                })}
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-concrete bg-white block max-h-[calc(60vh-100px)] lg:max-h-[calc(50vh-100px)] xl:max-h-[calc(60vh-100px)] 3xl:max-h-[calc(70vh-100px)]  overflow-auto">
                                        {isDashboardLoading
                                            ? Array.from(Array(10).keys()).map(
                                                  (ix) => (
                                                      <tr
                                                          key={ix}
                                                          className="rt-tr-group"
                                                      >
                                                          {Array.from(
                                                              Array(6).keys()
                                                          ).map((ix2) => (
                                                              <td
                                                                  key={ix2}
                                                                  className={cx(
                                                                      'px-12 text-black title-body break-all !h-24'
                                                                  )}
                                                              >
                                                                  <p className="bg-alto w-full h-6 animate-pulse rounded-default" />
                                                              </td>
                                                          ))}
                                                      </tr>
                                                  )
                                              )
                                            : table
                                                  .getRowModel()
                                                  .rows.map((row: any) => (
                                                      <tr
                                                          key={row.id}
                                                          className="rt-tr-group"
                                                      >
                                                          {row
                                                              .getVisibleCells()
                                                              .map(
                                                                  (
                                                                      cell: any
                                                                  ) => {
                                                                      const columnSize =
                                                                          cell
                                                                              .column
                                                                              .columnDef
                                                                              .meta
                                                                              ?.size;

                                                                      const textAlign =
                                                                          cell
                                                                              .column
                                                                              .columnDef
                                                                              .meta
                                                                              ?.textAlign;
                                                                      const columnWidth = `w-${columnSize}`;

                                                                      return (
                                                                          <td
                                                                              key={
                                                                                  cell.id
                                                                              }
                                                                              className={`
                                                                                  ${cx(
                                                                                      ' p-2 text-black title-body break-all whitespace-normal',
                                                                                      columnSize &&
                                                                                          columnWidth,
                                                                                      textAlign &&
                                                                                          textAlign
                                                                                  )}
                                                                              `}
                                                                          >
                                                                              {getCellValue(
                                                                                  cell
                                                                              )}
                                                                          </td>
                                                                      );
                                                                  }
                                                              )}
                                                      </tr>
                                                  ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-6 sm:flex sm:items-center sm:justify-between">
                    {!isDashboardLoading && (
                        <span className="flex items-center gap-1">
                            <div>{t('dashboard:Page')}</div>
                            <strong>
                                {table.getState().pagination.pageIndex + 1}{' '}
                                {t('dashboard:Of')} {table.getPageCount()}
                            </strong>
                            {numberOfRecords !== 0 && (
                                <p>{`(${numberOfRecords} ${t(
                                    'dashboard:Records'
                                )})`}</p>
                            )}
                        </span>
                    )}
                    {showAuditLog && <AuditLog {...auditLogProps} />}
                    {showStatusLog && <StatusLog {...statusLogProps} />}
                    {showPublicationLog && (
                        <PublicationLog
                            {...publicationLogProps}
                            copyToClipboard={copyToClipboard}
                        />
                    )}
                    {showValidationFull && (
                        <ValidationFull
                            formId={validationFullFormId}
                            isValidateFullVisible={showValidationFull}
                            closeModal={() => {
                                setShowValidationFull(false);
                            }}
                        />
                    )}
                    <div className="mt-4 flex items-center sm:mt-0 ml-auto">
                        {table.getCanPreviousPage() && (
                            <Button
                                scheme="secondary"
                                className="m-2 focus:ring-offset-0"
                                onClick={() => table.previousPage()}
                                iconSettings={{
                                    iconSide: 'left',
                                    Icon: ArrowLeft32,
                                }}
                            >
                                <div className="text-xs w-max">
                                    {t('dashboard:Previous')}
                                </div>
                            </Button>
                        )}
                        {table.getCanNextPage() && (
                            <Button
                                scheme="primary"
                                className="m-2 focus:ring-offset-0"
                                onClick={() => table.nextPage()}
                                iconSettings={{
                                    iconSide: 'right',
                                    Icon: ArrowRight32,
                                }}
                            >
                                <div className="text-xs w-max">
                                    {t('dashboard:Next')}
                                </div>
                            </Button>
                        )}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default DashboardPage;
