import React from 'react';
import { useParams } from 'react-router-dom';
import useFetchDownloadXml from '../../hooks/administrativeHooks/useFetchDownloadXml';
import NotFoundPage from '../NotFound/NotFoundPage';
import Editor from '@monaco-editor/react';
import LoadingSpinner from '@mercell/loading-spinner-react';

const DownloadXmlPage = () => {
    const { formId } = useParams<{ formId: string }>();
    const { filestream, isLoading, isErrorFetchingXml } = useFetchDownloadXml(
        formId ?? ''
    );

    if (isLoading)
        return (
            <div className="col-span-full h-full flex items-center justify-center">
                <LoadingSpinner />
            </div>
        );

    if (isErrorFetchingXml)
        return (
            <NotFoundPage
                wrapperClass="col-span-full"
                errorCode={isErrorFetchingXml.status.toString()}
            />
        );

    return (
        <div className="absolute top-30 left-0 w-full">
            <div className="w-full h-screen  max-w-screen-2xl m-auto">
                <Editor
                    height="90%"
                    className="mt-3"
                    defaultLanguage="xml"
                    defaultValue={filestream}
                    options={{
                        scrollBeyondLastLine: false,
                        minimap: { enabled: false },
                        lineNumbers: 'off',
                    }}
                />
            </div>
        </div>
    );
};

export default DownloadXmlPage;
