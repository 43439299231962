/* eslint-disable camelcase */
import React, { FC } from 'react';
import {
    DisabledTime,
    PublicationSiteConfiguration,
} from '../../../types/generated/publicationSiteConfiguration';
import Button from '@mercell/button-react';
import { Add16, ChevronDown16, TrashCan16 } from '@carbon/icons-react';
import { DatePicker } from '@mercell/date-picker-react';
import Textarea from '@mercell/textarea-react';
import { useAppSelector } from '../../../redux/hooks';
import { parseISO, format, formatISO } from 'date-fns';
import { Form, Field } from 'react-final-form';
import { useSubmitPublicationSiteConfiguration } from '../../../hooks/administrativeHooks/useSubmitPublicationSiteConfiguration';
import { KeyedMutator } from 'swr';
import { TFunction } from 'react-i18next';
import cx from 'classnames';
import { useExpandedState } from '@mercell/use-expanded-state-react-hook';
import {
    PanelInterface,
    useAccordionProps,
} from '@mercell/use-accordion-react-hook';
import useFetchUserRights from '../../../hooks/useFetchUserRights';
import { UserRole } from '../../../types/generated/userRightsResult';
import { FormApi } from 'final-form';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface PublicationSiteCardProps {
    publicationSite: PublicationSiteConfiguration;
    fetchPublicationSiteConfigurations: KeyedMutator<
        PublicationSiteConfiguration[]
    >;
    t: TFunction<string, unknown>;
}
export const PublicationSiteCard: FC<PublicationSiteCardProps> = ({
    publicationSite,
    fetchPublicationSiteConfigurations,
    t,
}) => {
    const reduxDateFormat = useAppSelector((state) => state.store.dateFormat);
    const reduxLocale = useAppSelector((state) => state.store.locale);
    const { UserRights } = useFetchUserRights();

    const { onSubmit } = useSubmitPublicationSiteConfiguration({
        fetchPublicationSiteConfigurations,
    });

    const { expanded, onClick } = useExpandedState(false);

    const { expanded: inputsExpanded, onClick: inputsOnClick } =
        useExpandedState(false);

    const { trigger, panel } = useAccordionProps(
        `publication-site-configuration`,
        expanded
    );

    const { trigger: inputsTrigger, panel: inputsPanel } = useAccordionProps(
        `publication-site-configuration-inputs`,
        inputsExpanded
    );

    const { mef1911 } = useFlags();

    const submitFormHandler = async (
        form: FormApi<
            Omit<PublicationSiteConfiguration, 'disabledTimes'> & DisabledTime,
            Partial<
                Omit<PublicationSiteConfiguration, 'disabledTimes'> &
                    DisabledTime
            >
        >
    ) => {
        const isApiSuccess = await form.submit();
        if (isApiSuccess) {
            inputsOnClick();
            form.reset();
        }
    };

    return (
        <div className="mt-6 bg-valhalla rounded-default text-white p-6 flex flex-col items-center">
            <div className="flex flex-wrap items-center justify-between sm:flex-nowrap w-full">
                <div className="ml-4 mt-2">
                    <h2 className="text-base font-semibold leading-6 text-gray-900">
                        {publicationSite.publicationSite}
                    </h2>
                </div>
                <div className="ml-auto mt-2 flex-shrink-0 flex items-center justify-center">
                    {publicationSite &&
                        publicationSite.disabledTimes &&
                        publicationSite.disabledTimes.length > 0 && (
                            <p className="text-base font-semibold leading-6 text-gray-900 mr-4 whitespace-nowrap">
                                {`${publicationSite.disabledTimes.length} ${
                                    publicationSite.disabledTimes.length > 1
                                        ? t('form-content:Disruptions')
                                        : t('form-content:Disruption')
                                }`}
                            </p>
                        )}
                    {publicationSite &&
                    publicationSite.disabledTimes &&
                    publicationSite.disabledTimes.length ? (
                        <Button
                            {...trigger}
                            onClick={() => {
                                if (inputsExpanded) {
                                    onClick();
                                    inputsOnClick();
                                } else {
                                    onClick();
                                }
                            }}
                            scheme="icon"
                            type="button"
                            iconSettings={{
                                Icon: ChevronDown16,
                            }}
                            className="bg-valhalla focus:ring-offset-0 focus:bg-valhalla"
                        />
                    ) : (
                        <Button
                            {...inputsTrigger}
                            onClick={inputsOnClick}
                            scheme="icon"
                            type="button"
                            iconSettings={{ Icon: Add16 }}
                            className="ml-auto bg-valhalla focus:ring-offset-0 focus:bg-valhalla disabled:bg-valhalla"
                            disabled={
                                mef1911 &&
                                UserRights?.userRole === UserRole.User
                            }
                        />
                    )}
                </div>
            </div>

            <Form
                onSubmit={(
                    data: Omit<PublicationSiteConfiguration, 'disabledTimes'> &
                        DisabledTime
                ) => {
                    const { disabledTimes } = publicationSite;
                    let combinedDisableTimes;
                    let mutatedData;
                    if (disabledTimes && disabledTimes.length > 0) {
                        combinedDisableTimes = [
                            ...disabledTimes,
                            {
                                startDate: data.startDate,
                                endDate: data.endDate,
                                details: data.details,
                            },
                        ].filter((x) => !!x);
                        mutatedData = {
                            id: data.id,
                            publicationSite: data.publicationSite,
                            disabledTimes: combinedDisableTimes,
                        };
                    } else {
                        mutatedData = {
                            id: data.id,
                            publicationSite: data.publicationSite,
                            disabledTimes: [
                                {
                                    startDate: data.startDate,
                                    endDate: data.endDate,
                                    details: data.details,
                                },
                            ],
                        };
                    }

                    return onSubmit(mutatedData);
                }}
                render={({ handleSubmit, form, errors }) => (
                    <form
                        {...(inputsPanel as PanelInterface<HTMLFormElement>)}
                        className={cx(
                            'bg-valhalla h-auto overflow-hidden my-0 mx-6 transition-all duration-500 w-full text-mirage items-center flex flex-col',
                            { 'mt-0 mx-6 mb-6 ': inputsExpanded }
                        )}
                        onSubmit={handleSubmit}
                    >
                        <Field
                            name="id"
                            component="input"
                            type="hidden"
                            initialValue={publicationSite.id}
                        />
                        <Field
                            name="publicationSite"
                            component="input"
                            type="hidden"
                            initialValue={publicationSite.publicationSite}
                        />
                        <Field
                            name="startDate"
                            validate={(value) => !value}
                            render={({ input }) => {
                                let parsedDate: any;
                                const { value, ...restInputProps } = input;

                                if (value) {
                                    parsedDate = parseISO(value);
                                }
                                return (
                                    <DatePicker
                                        {...restInputProps}
                                        locale={reduxLocale}
                                        showTimeSelect
                                        dateFormat={reduxDateFormat.DateTime}
                                        selected={parsedDate}
                                        placeholderText={t(
                                            'form-content:StartDate'
                                        )}
                                        onChange={(date) => {
                                            if (date) {
                                                input.onChange(formatISO(date));
                                            } else {
                                                input.onChange(null);
                                            }
                                        }}
                                        wrapperClassName="mt-4 w-[50%]"
                                    />
                                );
                            }}
                        />
                        <Field
                            name="endDate"
                            render={({ input }) => {
                                let parsedDate: any;
                                const { value, ...restInputProps } = input;

                                if (value) {
                                    parsedDate = parseISO(value);
                                }
                                return (
                                    <DatePicker
                                        {...restInputProps}
                                        locale={reduxLocale}
                                        showTimeSelect
                                        dateFormat={reduxDateFormat.DateTime}
                                        selected={parsedDate}
                                        placeholderText={t(
                                            'form-content:EndDate'
                                        )}
                                        onChange={(date) => {
                                            if (date) {
                                                input.onChange(formatISO(date));
                                            } else {
                                                input.onChange(null);
                                            }
                                        }}
                                        wrapperClassName="mt-4 w-[50%]"
                                    />
                                );
                            }}
                        />
                        <Field
                            name="details"
                            render={({ input }) => (
                                <Textarea
                                    {...input}
                                    className="mt-4 w-[50%] min-h-[150px]"
                                    placeholder={t('form-content:Details')}
                                />
                            )}
                        />
                        <div className="flex ml-auto">
                            <Button
                                scheme="secondary"
                                disabled={
                                    errors && Object.keys(errors).length > 0
                                }
                                type="button"
                                onClick={() => submitFormHandler(form)}
                                className="mt-6 ml-auto mr-4 focus:ring-offset-0 focus:bg-white"
                            >
                                {t('form-content:LabelSave')}
                            </Button>
                            <Button
                                scheme="secondary"
                                type="button"
                                onClick={() => {
                                    inputsOnClick();
                                    form.reset();
                                }}
                                className="mt-6 ml-auto focus:ring-offset-0 focus:bg-white"
                            >
                                {t('form-content:LabelCancel')}
                            </Button>
                        </div>
                    </form>
                )}
            />

            <div
                {...(panel as PanelInterface<HTMLDivElement>)}
                className={cx(
                    'bg-valhalla h-auto overflow-hidden  transition-all duration-500 w-full',
                    { 'mt-0 mx-6 mb-6 ': expanded }
                )}
            >
                {publicationSite &&
                    publicationSite.disabledTimes &&
                    publicationSite.disabledTimes.length > 0 && (
                        <div className="flex w-full mt-4 items-center">
                            <h3 className="font-semibold ml-4">
                                {t('form-content:DisabledTimes')}
                            </h3>
                            <div className="ml-auto mt-2 flex-shrink-0">
                                <Button
                                    scheme="icon"
                                    {...inputsTrigger}
                                    type="button"
                                    onClick={inputsOnClick}
                                    iconSettings={{
                                        Icon: Add16,
                                    }}
                                    className="ml-auto bg-valhalla focus:ring-offset-0 focus:bg-valhalla disabled:bg-valhalla"
                                    disabled={
                                        mef1911 &&
                                        UserRights?.userRole === UserRole.User
                                    }
                                />
                            </div>
                        </div>
                    )}

                {publicationSite.disabledTimes?.map(
                    (disabledTime, disabledTimeIndex) => (
                        <div
                            key={disabledTimeIndex}
                            className={cx(
                                'w-full flex items-between flex-col mt-4',
                                publicationSite &&
                                    publicationSite.disabledTimes &&
                                    publicationSite.disabledTimes.length > 1 &&
                                    'border-b border-athens',
                                publicationSite &&
                                    publicationSite.disabledTimes &&
                                    disabledTimeIndex ===
                                        publicationSite.disabledTimes.length -
                                            1 &&
                                    'border-none'
                            )}
                        >
                            <div className="ml-4">
                                <div className="mt-4 flex flex-col">
                                    <p className="font-semibold mr-4">
                                        {t('form-content:StartDate')}
                                    </p>
                                    {format(
                                        parseISO(
                                            disabledTime.startDate.toString()
                                        ),
                                        reduxDateFormat.DateTimeWithZoneShort,
                                        { locale: reduxLocale }
                                    )}
                                </div>
                                <div className="mt-4 flex flex-col">
                                    <p className="font-semibold mr-4">
                                        {t('form-content:EndDate')}
                                    </p>
                                    {disabledTime.endDate &&
                                        format(
                                            parseISO(
                                                disabledTime.endDate.toString()
                                            ),
                                            reduxDateFormat.DateTimeWithZoneShort,
                                            { locale: reduxLocale }
                                        )}
                                </div>
                                {disabledTime.details && (
                                    <div className="mt-4 flex flex-col">
                                        <p className="font-semibold mr-4">
                                            {t('form-content:Details')}
                                        </p>
                                        {disabledTime.details}
                                    </div>
                                )}
                                <Button
                                    scheme="icon"
                                    type="button"
                                    onClick={async () => {
                                        const mutatedDisabledTimes =
                                            publicationSite.disabledTimes?.filter(
                                                (time, index) =>
                                                    index !== disabledTimeIndex
                                            );
                                        await onSubmit({
                                            ...publicationSite,
                                            disabledTimes: mutatedDisabledTimes,
                                        });
                                        fetchPublicationSiteConfigurations();
                                    }}
                                    iconSettings={{
                                        Icon: TrashCan16,
                                    }}
                                    className="ml-auto bg-valhalla focus:ring-offset-0 focus:bg-valhalla mb-4 disabled:bg-valhalla"
                                    disabled={
                                        mef1911 &&
                                        UserRights?.userRole === UserRole.User
                                    }
                                />
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    );
};
