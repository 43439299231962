import { FeatureFlagBody, PatchWithComment } from 'launchdarkly-api-typescript';

export const FeatureFlagBodyBoolean: Omit<
    FeatureFlagBody,
    'name' | 'description' | 'key'
> = {
    tags: [],
    temporary: false,
    clientSideAvailability: {
        usingMobileKey: true,
        usingEnvironmentId: false,
    },
    defaults: {
        onVariation: 0,
        offVariation: 1,
    },
    variations: [
        {
            value: true,
            name: 'Variation1',
        },
        {
            value: false,
            name: 'Variation2',
        },
    ],
};

export const UpdateFlagWithEnvironmentKey = (
    flagBooleanValue: boolean,
    flagNameValue: string,
    flagDescriptionValue?: string,
    environment?: string,
    comment?: string
): PatchWithComment => {
    const patchWithComment: PatchWithComment = {
        patch: [
            {
                op: 'replace',
                path: `/environments/${environment}/on`,
                value: flagBooleanValue,
            },
            {
                op: 'replace',
                path: `/description`,
                value: flagDescriptionValue,
            },
            {
                op: 'replace',
                path: `/name`,
                value: flagNameValue,
            },
        ],
        comment,
    };
    return patchWithComment;
};
