import React, { FC, useEffect, useMemo, useState } from 'react';
import {
    ColumnDef,
    PaginationState,
    SortingState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
    TableOptions,
    FilterFn,
} from '@tanstack/react-table';
import { rankItem } from '@tanstack/match-sorter-utils';
import { useTranslation } from 'react-i18next';
import Button from '@mercell/button-react';
import {
    ArrowLeft32,
    ArrowRight32,
    CaretDown16,
    CaretUp16,
} from '@carbon/icons-react';
import NoAccessPage from '../NotFound/NoAccessPage';
import { SelectOption } from '../../types/generated/selectOption';
import cx from 'classnames';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import { getUserManagement } from '../../apiCalls/userManagement/getUserManagement';
import { UserEntry, UserRole } from '../../types/generated/userEntry';
import useFetchPlatformNames from '../../hooks/administrativeHooks/useFetchPlatformNames';
import UserManagementAccordion from '../../components/UserManagementAccordion';
import {
    isSelectOption,
    isSelectOptionArrayState,
    isStringState,
} from '../../shared/typeguards/typeguards';
import { isString } from 'lodash';
import TableDropDown from './TableDropdown';
import useFetchFormCreationProperties from '../../hooks/administrativeHooks/useFetchFormCreationProperties';
import ConfirmationModal from '../../components/ConfirmationModal';
import { deleteUser } from '../../apiCalls/userManagement/deleteUser';
import { useExpandedState } from '@mercell/use-expanded-state-react-hook';
import { useAccordionProps } from '@mercell/use-accordion-react-hook';
import useFetchUserRights from '../../hooks/useFetchUserRights';
import useFetchPermission from '../../hooks/administrativeHooks/useFetchPermission';

interface SearchFilterData {
    emailMask: string | undefined;
    platforms: string[] | undefined;
}

interface PagingData {
    isAscending: boolean | undefined;
    pageNumber: number | undefined;
    pageSize: number | undefined;
    sortableField: string | undefined;
}

interface FilterData {
    searchFilterData: SearchFilterData | null;
    pagingData: PagingData | undefined;
}

const getValueData = (options: any) =>
    options.map((option: any) => option.value.toString());

const UserManagementPage: FC = () => {
    const { UserRights } = useFetchUserRights();
    const { platformNames, isLoadingPlatformNames } = useFetchPlatformNames();
    const { data } = useFetchFormCreationProperties();
    const { permissions } = useFetchPermission();
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [clientSorting, setClientSorting] = useState<SortingState>([]);
    const [isUserManagementLoading, setIsUserManagementLoading] =
        useState<boolean>(false);
    const [{ isAscending, sortableField }] = useState({
        isAscending: false,
        sortableField: '',
    });
    const [accordionHeader, setAccordionHeader] = useState<string>('');
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [modelUserId, setModelUserId] = useState<string>('');
    const [globalFilter, setGlobalFilter] = React.useState('');
    const [noAccess, setNoAccess] = useState(false);
    const [user, setUser] = useState<UserEntry>({
        id: undefined,
        configuratorEnabled: undefined,
        countries: undefined,
        dashboardEnabled: undefined,
        exportVersionEnabled: undefined,
        featuresReviewEnabled: undefined,
        importVersionEnabled: undefined,
        platforms: undefined,
        publicationConfiguratorEnabled: undefined,
        statusChangeEnabledLevel: 0,
        translationsReviewEnabled: undefined,
        email: undefined,
        userManagementEnabled: undefined,
        logsExplorerEnabled: undefined,
        userRole: undefined,
    });
    const [emailMask, setEmailMask] = useState<string>('');
    const [platformData, setPlatformData] = useState<SelectOption[]>([]);
    const [tasks, setTasks] = useState<UserEntry[]>([]);
    const [searchParams, setSearchParams] = useState<boolean>(false);
    const [filterData, setFilterData] = useState<FilterData>({
        searchFilterData: {
            emailMask,
            platforms: getValueData(platformData),
        },
        pagingData: {
            pageNumber: 0,
            pageSize: 10,
            isAscending: false,
            sortableField: '',
        },
    });

    const { expanded, onClick: onClickExpand } = useExpandedState(false);
    const { trigger, panel } = useAccordionProps(`create-edit-users`, expanded);

    const { t } = useTranslation([
        'user-management',
        'list.country',
        'form-content',
        'list.notice-subtype',
        'list.audit-log-status',
        'toast-content',
    ]);

    // Table
    type ColumnMeta = {
        meta?: {
            size?: number | string;
            textAlign?: 'text-left' | 'text-center' | 'text-right';
        };
    };

    type AugmentedColumnDef = ColumnDef<UserEntry> & ColumnMeta;

    const columns = useMemo<AugmentedColumnDef[]>(
        () => [
            {
                accessorKey: 'email',
                cell: (info) => info.getValue(),
                header: t('user-management:Email'),
                meta: {
                    size: '30%',
                    textAlign: 'text-left',
                },
                enableHiding: false,
                enableColumnFilter: true,
            },
            {
                accessorKey: 'platforms',
                header: t('user-management:Platforms'),
                cell: (info) => info.renderValue(),
                meta: {
                    size: '30%',
                    textAlign: 'text-center',
                },
                enableHiding: true,
                enableColumnFilter: false,
            },
            {
                accessorKey: 'userRole',
                header: t('user-management:UserRole'),
                cell: (info) => info.renderValue(),
                meta: {
                    size: '30%',
                    textAlign: 'text-center',
                },
                enableHiding: true,
                enableColumnFilter: false,
            },
            {
                id: 'menuColumn',
                cell: (info: any) => info.getValue(),
                meta: {
                    size: '10%',
                },
                enableHiding: false,
                enableSorting: false,
                enableColumnFilter: false,
            },
        ],
        [t]
    );
    const fuzzyFilter: FilterFn<any> = (row, columnId, value) => {
        const itemRank = rankItem(row.getValue(columnId), value);

        return itemRank.passed;
    };

    const conditionalOptions = {
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setClientSorting,
        initialState: { pagination: { pageSize: 10 } },
        state: { sorting: clientSorting, globalFilter },
        autoResetPageIndex: false,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
    };
    const tableOptions: TableOptions<any> = {
        data: tasks,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        ...conditionalOptions,
    };

    const table = useReactTable(tableOptions);

    // Hooks
    useEffect(() => {
        const platformStorage = localStorage.getItem('platformData');
        const defaultPlatform = [
            platformNames
                ? { value: platformNames[0], label: platformNames[0] }
                : { value: 'ClientApp', label: 'ClientApp' },
        ];
        const valueToData = (
            values: string,
            itemsData?: any
        ): SelectOption[] | any => {
            const splitString = values.split(',');
            if (itemsData) {
                return splitString.map((value: string) =>
                    itemsData.find((item: any) => item.value === value)
                );
            }

            return splitString.map((value: string) => ({
                label: value,
                value,
            }));
        };

        setPlatformData(
            platformStorage ? valueToData(platformStorage) : defaultPlatform
        );
        setSearchParams(true);

        localStorage.getItem('columnVisibility');
    }, [platformNames]);

    useEffect(() => {
        const dataToValue = (selectOptions: SelectOption[]) =>
            selectOptions.map((option) => option.value);

        if (!isLoadingPlatformNames && searchParams) {
            localStorage.setItem(
                'platformData',
                dataToValue(platformData).toString()
            );
        }

        setFilterData({
            searchFilterData: {
                emailMask,
                platforms: getValueData(platformData),
            },
            pagingData: {
                pageNumber: 1,
                pageSize,
                isAscending,
                sortableField,
            },
        });
        setPagination((curr) => ({ ...curr, pageIndex: 0 }));
        table.setPageIndex(0);
    }, [
        emailMask,
        isAscending,
        isLoadingPlatformNames,
        pageSize,
        platformData,
        searchParams,
        sortableField,
        table,
    ]);

    useEffect(() => {
        setIsUserManagementLoading(true);
        getUserManagementData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterData?.searchFilterData]);

    useEffect(() => {
        if (table.getRowModel().rows.length === 0 || globalFilter === '') {
            handleCheckedInputStateChange(
                globalFilter,
                emailMask,
                setEmailMask
            );
        }
    }, [emailMask, globalFilter, table]);

    // Functions
    const getUserManagementData = useDebouncedCallback(
        async () => {
            setIsUserManagementLoading(true);
            try {
                const resData = await getUserManagement({
                    ...filterData,
                    pagingData: {
                        isAscending,
                        pageNumber: pageIndex + 1,
                        pageSize,
                        sortableField,
                    },
                }).finally(() => setIsUserManagementLoading(false));

                const tempData =
                    resData?.userData.map((item: any) => {
                        const tempItem = { ...item, showDropDown: false };
                        return tempItem;
                    }) ?? [];
                setTasks(tempData);
            } catch (error: any) {
                if (error.status === 401) {
                    setNoAccess(true);
                }
            }
        },
        2500,
        [filterData?.searchFilterData]
    );

    const handleDropDownShow = (e: any, cell: any) => {
        e.preventDefault();
        e.stopPropagation();
        const tempData =
            tasks.map((item: any) => {
                if (item.id === cell.row.original.id) {
                    return {
                        ...item,
                        showDropDown: !cell.row.original.showDropDown,
                    };
                }
                return { ...item, showDropDown: false };
            }) ?? [];
        setTasks(tempData);
    };

    const openModal = (userId: string) => {
        setIsModalVisible(true);
        setModelUserId(userId);
    };

    const getCellValue = (cell: any) => {
        switch (cell.column.id) {
            case 'menuColumn':
                if (!!UserRights && UserRights.userManagementEnabled) {
                    return (
                        <TableDropDown
                            cell={cell}
                            t={t}
                            setUser={setUser}
                            onClickExpand={onClickExpand}
                            setAccordionHeader={setAccordionHeader}
                            handleDropDownShow={handleDropDownShow}
                            openModal={openModal}
                            expanded={expanded}
                        />
                    );
                }
                return <div />;
            case 'userRole':
                return UserRole[cell.getValue()];
            case 'platforms':
                return (
                    <div className="flex flex-col">
                        {cell.getValue().map((platform: any) => (
                            <p>{platform}</p>
                        ))}
                    </div>
                );
            default:
                return cell.getValue();
        }
    };

    if (noAccess) {
        return <NoAccessPage />;
    }
    const handleCheckedInputStateChange = (
        inputData: SelectOption | string,
        currentData: SelectOption[] | string,
        setState:
            | React.Dispatch<React.SetStateAction<SelectOption[]>>
            | React.Dispatch<React.SetStateAction<string>>
    ) => {
        if (
            isSelectOption(inputData) &&
            isSelectOptionArrayState(setState) &&
            Array.isArray(currentData)
        ) {
            if (
                currentData.find(
                    (currentItem) =>
                        currentItem.value?.toString() ===
                        inputData.value?.toString()
                )
            ) {
                setState((prevCheckedItems) =>
                    prevCheckedItems.filter(
                        (previousItem) =>
                            previousItem.value?.toString() !==
                            inputData.value?.toString()
                    )
                );
            } else {
                setState((prevCheckedItems) => [
                    ...prevCheckedItems,
                    inputData,
                ]);
            }
        } else if (isStringState(setState) && isString(inputData)) {
            setState(inputData);
        }
    };
    const onPlatformChange = (newData: SelectOption, resetData?: boolean) => {
        if (resetData) {
            setPlatformData([newData]);
        } else
            handleCheckedInputStateChange(
                newData,
                platformData,
                setPlatformData
            );
    };

    const onGlobalSearchChange = (newVal: string) => {
        setGlobalFilter(String(newVal));
    };

    const onEmailMaskChange = (newVal: string) => {
        setGlobalFilter(String(newVal));
    };

    const onConfirmDelete = async () => {
        setIsModalVisible(false);
        deleteUser(modelUserId, {
            onPendingText: t('toast-content:ToastUserDeletePending'),
            onSuccessText: t('toast-content:ToastUserDeleteSuccess'),
        }).then(() => {
            getUserManagementData();
        });
    };

    return (
        <div className="col-span-full mt-5">
            {isModalVisible && (
                <ConfirmationModal
                    cancelText={t('form-content:LabelCancel')}
                    confirmText={t('form-content:LabelDelete')}
                    onConfirm={onConfirmDelete}
                    closeModal={() => setIsModalVisible(false)}
                    isModalVisible={isModalVisible}
                    title={t('form-content:ConfirmDelete')}
                >
                    <p className="text-center">
                        {t('user-management:DeleteUserInfo')}
                    </p>
                </ConfirmationModal>
            )}
            <section className="container mx-auto px-4">
                <div className="mt-6 md:flex md:items-center md:justify-between">
                    <UserManagementAccordion
                        t={t}
                        globalFilter={globalFilter}
                        platformNames={platformNames}
                        onPlatformChange={onPlatformChange}
                        selectedPlatform={
                            filterData.searchFilterData?.platforms
                        }
                        countries={data?.countries}
                        userRoles={UserRole}
                        user={user}
                        setUser={setUser}
                        onEmailMaskChange={onEmailMaskChange}
                        onGlobalSearchChange={onGlobalSearchChange}
                        emailMask={emailMask}
                        setAccordionHeader={setAccordionHeader}
                        accordionHeader={accordionHeader}
                        setIsUserManagementLoading={setIsUserManagementLoading}
                        getUserManagementData={getUserManagementData}
                        onClickExpand={onClickExpand}
                        panel={panel}
                        trigger={trigger}
                        expanded={expanded}
                        userRights={UserRights}
                        userPermissions={permissions?.userPermissions}
                        statusChangeLevel={permissions?.statusChangeLevel}
                    />
                </div>
                <div className="mt-6 flex flex-col ">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 ">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden border border-alto md:rounded-default">
                                <table className="min-w-full divide-y divide-concrete table-fixed">
                                    <thead className="bg-minsk">
                                        <tr>
                                            {table
                                                .getFlatHeaders()
                                                .map((header: any) => {
                                                    const columnSize =
                                                        header.column.columnDef
                                                            .meta?.size;

                                                    const textAlign =
                                                        header.column.columnDef
                                                            .meta?.textAlign;
                                                    const columnWidth = `w-[${columnSize}]`;
                                                    return (
                                                        <th
                                                            key={header.id}
                                                            onClick={header.column.getToggleSortingHandler()}
                                                            scope="col"
                                                            className={cx(
                                                                'p-5 text-white title-body select-none',
                                                                columnSize &&
                                                                    columnWidth,
                                                                textAlign &&
                                                                    textAlign,
                                                                header.column.getCanSort() &&
                                                                    ' hover:cursor-pointer'
                                                            )}
                                                        >
                                                            {header.isPlaceholder ? null : (
                                                                <div>
                                                                    {flexRender(
                                                                        header
                                                                            .column
                                                                            .columnDef
                                                                            .header,
                                                                        header.getContext()
                                                                    )}
                                                                    {{
                                                                        asc: (
                                                                            <CaretDown16 className="inline-block" />
                                                                        ),
                                                                        desc: (
                                                                            <CaretUp16 className="inline-block" />
                                                                        ),
                                                                    }[
                                                                        header.column.getIsSorted() as string
                                                                    ] ?? null}
                                                                </div>
                                                            )}
                                                        </th>
                                                    );
                                                })}
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-concrete bg-white">
                                        {isUserManagementLoading
                                            ? Array.from(Array(10).keys()).map(
                                                  (ix) => (
                                                      <tr
                                                          key={ix}
                                                          className="rt-tr-group"
                                                      >
                                                          {Array.from(
                                                              Array(4).keys()
                                                          ).map((ix2) => (
                                                              <td
                                                                  key={ix2}
                                                                  className={cx(
                                                                      'px-12 text-black title-body break-all !h-24'
                                                                  )}
                                                              >
                                                                  <p className="bg-alto w-full h-6 animate-pulse rounded-default" />
                                                              </td>
                                                          ))}
                                                      </tr>
                                                  )
                                              )
                                            : table
                                                  .getRowModel()
                                                  .rows.map((row: any) => (
                                                      <tr
                                                          key={row.id}
                                                          className="rt-tr-group"
                                                      >
                                                          {row
                                                              .getVisibleCells()
                                                              .map(
                                                                  (
                                                                      cell: any
                                                                  ) => {
                                                                      const columnSize =
                                                                          cell
                                                                              .column
                                                                              .columnDef
                                                                              .meta
                                                                              ?.size;

                                                                      const textAlign =
                                                                          cell
                                                                              .column
                                                                              .columnDef
                                                                              .meta
                                                                              ?.textAlign;
                                                                      const columnWidth = `w-[${columnSize}]`;

                                                                      return (
                                                                          <td
                                                                              key={
                                                                                  cell.id
                                                                              }
                                                                              className={cx(
                                                                                  'p-5 text-black title-body break-all',
                                                                                  columnSize &&
                                                                                      columnWidth,
                                                                                  textAlign &&
                                                                                      textAlign
                                                                              )}
                                                                          >
                                                                              {getCellValue(
                                                                                  cell
                                                                              )}
                                                                          </td>
                                                                      );
                                                                  }
                                                              )}
                                                      </tr>
                                                  ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-6 sm:flex sm:items-center sm:justify-between">
                    {!isUserManagementLoading && (
                        <span className="flex items-center gap-1">
                            <div>{t('user-management:Page')}</div>
                            <strong>
                                {tasks.length > 0
                                    ? table.getState().pagination.pageIndex + 1
                                    : 0}{' '}
                                {t('user-management:Of')} {table.getPageCount()}
                            </strong>
                        </span>
                    )}

                    <div className="mt-4 flex items-center sm:mt-0 ml-auto">
                        {table.getCanPreviousPage() && (
                            <Button
                                scheme="secondary"
                                className="m-2 focus:ring-offset-0"
                                onClick={() => table.previousPage()}
                                iconSettings={{
                                    iconSide: 'left',
                                    Icon: ArrowLeft32,
                                }}
                            >
                                <div className="text-xs w-max">
                                    {t('user-management:Previous')}
                                </div>
                            </Button>
                        )}
                        {table.getCanNextPage() && (
                            <Button
                                scheme="primary"
                                className="m-2 focus:ring-offset-0"
                                onClick={() => table.nextPage()}
                                iconSettings={{
                                    iconSide: 'right',
                                    Icon: ArrowRight32,
                                }}
                            >
                                <div className="text-xs w-max">
                                    {t('user-management:Next')}
                                </div>
                            </Button>
                        )}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default UserManagementPage;
