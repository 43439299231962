import React, { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface NotFoundPageProps {
    errorCode?: string;
    wrapperClass?: string;
    altText?: string;
}

let count = 0;

const NotFoundPage: FC<NotFoundPageProps> = memo(
    ({ errorCode, altText, wrapperClass }) => {
        const [isMounted, setIsMounted] = useState<boolean>(false);
        const { t } = useTranslation(['list.error']);

        useEffect(() => {
            count++;
            if (count === 1) {
                setIsMounted(true);
            }
            return () => {
                count--;
            };
        }, []);

        if (!isMounted) return null;

        return (
            <div className={wrapperClass ?? 'col-span-full'}>
                <div className="flex flex-col-reverse sm:flex-row justify-between sm:py-10 col-span-full p-10">
                    <div className="flex flex-col justify-between">
                        <h1 className="mb-20" style={{ fontSize: 100 }}>
                            {errorCode ?? 404}
                        </h1>
                        <h3 className="mb-6">{t(errorCode ?? '404')}</h3>
                    </div>
                    <img
                        src="/images/not-found.svg"
                        className="w-1/2 mb-10 sm:mb-0"
                        alt={t(altText ?? '')}
                    />
                </div>
            </div>
        );
    }
);

export default NotFoundPage;
