// const scriptSrc = (document.currentScript as HTMLScriptElement)?.src;

// const url = new URL(scriptSrc);

let suffix = '';

if (process.env.NODE_ENV === 'production') {
    if (window.location.origin === 'https://dev.mercellforms.mercell.com') {
        suffix = '_DEV';
    } else if (
        window.location.origin === 'https://stage.mercellforms.mercell.com'
    ) {
        suffix = '_STAGE';
    } else if (
        window.location.origin === 'https://demo.mercellforms.mercell.com'
    )
        suffix = '_DEMO';
    else {
        suffix = '_LIVE';
    }
}

const envWithSuffix = (key: string) => {
    const keyName = `${key + suffix}`;
    return process.env[keyName];
};

export default envWithSuffix;
