import axios, { AxiosError, AxiosResponse } from 'axios';
import { DashboardResponseEntry } from '../types/generated/dashboardResponseEntry';

export const getDashboard = (
    platform?: string,
    paramData?: Record<string, any>
) =>
    axios
        .post(`/dashboard/list/${platform}`, { ...paramData })
        .then((res: AxiosResponse): Promise<DashboardResponseEntry | any> => {
            const { data } = res;
            return data;
        })
        .catch((error: AxiosError) => {
            throw error;
        });
