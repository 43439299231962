/* istanbul ignore file */
import React, {
    useContext,
    useMemo,
    useReducer,
    createContext,
    FC,
} from 'react';
import { reducer } from './reducer';
import { Action } from './action';
import AppState from '../types/appState';
import roles from './roles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DateFormats, DateFormatsNew } from '../shared/dateFnsFormats';

export interface Store {
    state: AppState;
    dispatch: React.Dispatch<Action>;
}

const StoreContext = createContext<Store>({} as Store);

export const useStoreContext = () => useContext(StoreContext);

export const StoreContextProvider: FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [state, dispatch] = useReducer(reducer, {
        userName: undefined,
        userId: undefined,
        userRole: roles.unauthorized,
        role: 'SOME ROLE',
        platformName: undefined,
        menuSections: [],
        tenderName: undefined,
        locale: undefined,
        UserRights: undefined,
        autoSave: {
            isLoading: false,
            isSaved: false,
            isError: false,
        },
        noticeStatus: undefined,
        dateFormat:  DateFormatsNew,
    });

    const stateMemo = useMemo(() => ({ state, dispatch }), [state]);
    return (
        <StoreContext.Provider value={stateMemo}>
            {children}
        </StoreContext.Provider>
    );
};
