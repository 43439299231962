import { FieldOrigin, ReadOnlyFieldStructure } from '@mercell/form-react';
import React, { FC, useState } from 'react';
import { prepareContent } from '../shared/prepareContent';
import { TFunction } from 'react-i18next';
import FieldError from '@mercell/field-error-react';
import { nl2br } from '../shared/utilities/nl2br';
import { useAppSelector } from '../redux/hooks';
import Tooltip from '@mercell/tooltip-react';
import { EarthFilled20 } from '@carbon/icons-react';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ReadOnlyComponentProps {
    content: any;
    name: string;
    readOnlyProperties: ReadOnlyFieldStructure;
    fieldOrigin?: FieldOrigin;
    elementProperties?: React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
    >;
    t: TFunction<string, unknown>;
    locale?: Locale;
    fieldErrors?: string;
    isFormPreview?: boolean;
    noticeTimeZone?: string;
}

export const ReadOnlyComponent: FC<ReadOnlyComponentProps> = ({
    content,
    name,
    fieldOrigin,
    elementProperties,
    readOnlyProperties,
    t,
    locale,
    fieldErrors,
    isFormPreview,
    noticeTimeZone,
}) => {
    const { featureFlagUseUtcDatesOnly } = useFlags();
    const [clickCount, setClickCount] = useState(0);
    const [isRageClick, setRageClick] = useState(false);

    const reduxDateFormat = useAppSelector((state) => state.store.dateFormat);

    const handleDivClick = () => {
        if (elementProperties?.className?.includes('read-only')) {
            if (clickCount + 1 === 2) {
                setClickCount(0);
                setRageClick(true);
                setTimeout(() => {
                    setRageClick(false);
                }, 3000); // Message will fade away after 3 seconds
            } else {
                setClickCount(clickCount + 1);
            }
        }
    };
    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div onMouseDown={handleDivClick}>
            <div
                style={{ wordBreak: 'break-word' }}
                {...(elementProperties as JSX.IntrinsicElements['div'])}
            >
                {fieldOrigin === 'date-time-picker' &&
                    noticeTimeZone &&
                    featureFlagUseUtcDatesOnly &&
                    isDateStringUtcFormat(content) && (
                        <Tooltip
                            message={noticeTimeZone}
                            placement="right"
                            tooltipMessageClassName="text-left"
                            className="mr-2 text-body inline-flex align-top"
                        >
                            <EarthFilled20 className="mt-[3px]" />
                        </Tooltip>
                    )}
                {prepareContent(
                    content,
                    fieldOrigin,
                    readOnlyProperties,
                    name,
                    t,
                    reduxDateFormat,
                    locale,
                    featureFlagUseUtcDatesOnly,
                    noticeTimeZone
                )}
            </div>
            {fieldErrors && !isFormPreview && (
                <FieldError className="mt-1 ml-1">
                    {nl2br(fieldErrors)}
                </FieldError>
            )}
            {isRageClick && !isFormPreview && (
                <p className="mt-1 ml-1 italic text-dove">
                    {t('form-content:FieldIsLocked')}
                </p>
            )}
        </div>
    );
};
function isDateStringUtcFormat(input: string): boolean {
    const isoFormatRegex =
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?\+00:00$/;

    return isoFormatRegex.test(input);
}
