import React, { ChangeEvent, FC, useState } from 'react';
import { Input, InputContainer } from '@mercell/input-react';
import Button from '@mercell/button-react';
import OktaAuthWrapper from '../../authorization/OktaAuthWrapper';
import LoadingSpinner from '@mercell/loading-spinner-react';
import { getLogs } from '../../hooks/administrativeHooks/useFetchLogs';
import { useAppSelector } from '../../redux/hooks';
import { LogsModelSearch } from '../../types/generated/logsModelSearch';
import { LogsModel } from '../../types/generated/logsModel';
import { DatePicker } from '@mercell/date-picker-react';
import NoAccessPage from '../NotFound/NoAccessPage';
import { useTranslation } from 'react-i18next';

const LogsExplorerPage: FC = () => {
    const [searchString, setSearchString] = useState<string>();
    const [extendedSeconds, setExtendedSeconds] = useState<string>();
    const [logs, setLogs] = useState<LogsModel[] | undefined>([]);
    const [startDateTime, setStartDateTime] = React.useState<Date | null>();
    const [endDateTime, setEndDateTime] = React.useState<Date | null>();

    const reduxUserRights = useAppSelector((state) => state.store.userRights);
    const reduxDateFormat = useAppSelector((state) => state.store.dateFormat);
    const { t } = useTranslation(['logs-explorer']);

    const onSearch = async () => {
        setLogs(undefined);

        const payload: LogsModelSearch = {
            queryString: searchString,
            startTime: startDateTime ?? undefined,
            endTime: endDateTime ?? undefined,
            extendedLogSeconds: Number(extendedSeconds ?? '0'),
        };

        const resData = await getLogs(payload);
        // some error handling needed?
        setLogs(resData);
    };

    if (reduxUserRights?.logsExplorerEnabled === false) {
        return <NoAccessPage />;
    }

    return (
        <OktaAuthWrapper>
            {!logs ? (
                <div className="col-span-full h-full flex items-center justify-center">
                    <LoadingSpinner />
                </div>
            ) : (
                <div className="col-span-full xl:justify-self-center xl:min-w-[1200px] max-w-[1200px] whitespace-normal">
                    <div className="col-span-full xl:justify-self-center xl:min-w-[1200px] max-w-[1200px] whitespace-normal">
                        <h2 className="mb-10">Logs Explorer</h2>
                        <div className="flex justify-between">
                            <div className="mb-10">
                                <p>{t('StringToSearch')}</p>
                                <InputContainer>
                                    <Input
                                        placeholder="Search..."
                                        onChange={(
                                            e: ChangeEvent<HTMLInputElement>
                                        ) =>
                                            setSearchString(
                                                e.currentTarget.value
                                            )
                                        }
                                    />
                                </InputContainer>
                                <p>{t('NumberOfSeconds')}</p>
                                <InputContainer>
                                    <Input
                                        placeholder="Extended log seconds"
                                        onChange={(
                                            e: ChangeEvent<HTMLInputElement>
                                        ) =>
                                            setExtendedSeconds(
                                                e.currentTarget.value
                                            )
                                        }
                                    />
                                </InputContainer>

                                <p>{t('LogStartDateAndTime')}</p>
                                <DatePicker
                                    onChange={(date) => setStartDateTime(date)}
                                    selected={startDateTime}
                                    showTimeSelect
                                    timeIntervals={5}
                                    dateFormat={reduxDateFormat.DateTime}
                                />
                                <p>{t('LogEndDateAndTIme')}</p>
                                <DatePicker
                                    onChange={(date) => setEndDateTime(date)}
                                    selected={endDateTime}
                                    showTimeSelect
                                    timeIntervals={5}
                                    dateFormat={reduxDateFormat.DateTime}
                                />
                                <Button scheme="primary" onClick={onSearch}>
                                    {t('Search')}
                                </Button>
                            </div>
                        </div>
                        <pre>{JSON.stringify(logs, undefined, 2)}</pre>
                    </div>
                </div>
            )}
        </OktaAuthWrapper>
    );
};

export default LogsExplorerPage;
