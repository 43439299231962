/* istanbul ignore file */
import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';
import { TranslationsResult } from '../../types/generated/translationsResult';

const fetcher = (url: string) =>
    axios
        .get(url)
        .then(
            async (res: AxiosResponse): Promise<TranslationsResult[]> =>
                res.data
        );

const useFetchTranslations = () => {
    const { data, error } = useSWRImmutable(`help/translations`, fetcher, {
        shouldRetryOnError: false,
    });
    return {
        translations: data,
        isLoading: !error && !data,
        isErrorFetchingTranslations: error,
    };
};
export default useFetchTranslations;
