/* istanbul ignore file */
import axios, { AxiosResponse } from 'axios';
import { FormsMenuItem } from '../../types/generated/formsMenuItem';
import { TenderListItem } from '../../types/tenderList';
import useSWRImmutable from 'swr/immutable';
import { TFunction } from 'react-i18next';

const fetcher = ({ url, t }: { url: string; t: TFunction<string, unknown> }) =>
    axios.get(url).then((res: AxiosResponse<FormsMenuItem[]>) => {
        const { data } = res;
        return data.reduce(
            (prevItem: Record<string, TenderListItem>, item: FormsMenuItem) => {
                const { tenderId } = item;
                if (tenderId) {
                    if (prevItem[tenderId]) {
                        prevItem[tenderId].forms.push({
                            name: `${t(
                                `list.form-type:${item.formType}` ?? ''
                            )} ${
                                item.isChangeNotice === true ? 'Change' : ''
                            } [${item.noticeSubtype}]`,
                            value: item.id ?? '',
                            manyLots: item.manyLots,
                            mainLanguage: item.mainLanguage,
                            noticeTimeZone: item.noticeTimeZone,
                        });
                        return {
                            ...prevItem,
                        };
                    }
                    return {
                        ...prevItem,
                        [tenderId]: {
                            name: `${tenderId}`,
                            forms: [
                                {
                                    name: `${t(
                                        `list.form-type:${item.formType}` ?? ''
                                    )} ${
                                        item.isChangeNotice === true
                                            ? 'Change'
                                            : ''
                                    } [${item.noticeSubtype}]`,
                                    value: item.id ?? '',
                                    manyLots: item.manyLots,
                                    mainLanguage: item.mainLanguage,
                                    noticeTimeZone: item.noticeTimeZone,
                                },
                            ],
                        },
                    };
                }
                return prevItem;
            },
            {}
        );
    });

const useFetchTenders = (
    t: TFunction<string, unknown>,
    platformName?: string,
    onlyPublished?: boolean,
    inputValue?: string
) => {
    const dynamicUrl = inputValue
        ? `testapp/names-dynamic/${platformName}/${onlyPublished}/${inputValue}`
        : `testapp/names-dynamic/${platformName}/${onlyPublished}`;
    const url = dynamicUrl;

    const {
        data,
        error,
        mutate: refetchTenders,
    } = useSWRImmutable(
        !platformName
            ? null
            : {
                  url,
                  t,
              },
        fetcher,
        {
            shouldRetryOnError: false,
        }
    );

    return {
        tenders: data,
        isLoadingTenders: !error && !data,
        isErrorFetchingTenders: error,
        refetchTenders,
    };
};

export default useFetchTenders;
