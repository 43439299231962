import React, { Fragment } from 'react';

export const nl2br = (string: string | null) => {
    if (string && string !== null && string !== '') {
        return string.split('\n').map((item, key) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={key}>
                {item}
                <br />
            </Fragment>
        ));
    }
    return string;
};
