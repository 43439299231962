import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mercell/button-react';
import { SelectOption } from '../../types/generated/formSelectorConfiguration';
import { useCreateForm } from '../../hooks/administrativeHooks/useCreateForm';
import useFetchFormCreationProperties from '../../hooks/administrativeHooks/useFetchFormCreationProperties';
import { Dropdown } from '@mercell/dropdown-react';
import { Checkbox } from '@mercell/checkbox-react';
import { Input } from '@mercell/input-react';
import { getOtpUrlFromApi } from '../../authorization/getAuthFromApi';
import { formOptions } from '../../types/enums/formOptions';
import useFetchTenders from '../../hooks/administrativeHooks/useFetchTenders';
import useFetchPlatformNames from '../../hooks/administrativeHooks/useFetchPlatformNames';
import Toggle from '@mercell/toggle-react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { DateTime } from 'luxon';
import Editor from '@monaco-editor/react';
import { setPlatformName as setPlatformNameStore } from '../../redux/storeSlice';
import useSetupContext from '../../shared/setupUserContext';
import { FormListItem } from '../../types/tenderList';

const CreateForm: FC = () => {
    const { featureFlagUseUtcDatesOnly, featureFlagMef2481,featureFlagMef2544MultipleStageEnabled } = useFlags();
    const { t } = useTranslation([
        'form-content',
        'list.country',
        'list.legal-basis',
        'list.form-type',
        'list.notice-type',
        'list.notice-subtype',
        'list.language',
        'toast-content',
    ]);
    const [toggled, setToggled] = React.useState<boolean>(false);
    const [platformName, setPlatformName] = React.useState<string>();
    const [tenderId, setTenderId] = React.useState<SelectOption>();
    const [formIdSelected, setFormIdSelected] = React.useState<SelectOption>();
    const [country, setCountry] = useState<SelectOption>();
    const [directive, setDirective] = useState<SelectOption>();
    const [timeZone, setTimeZone] = useState<SelectOption>();
    const [formType, setformType] = useState<SelectOption>();
    const [noticeType, setnoticeType] = useState<SelectOption>();
    const [noticeSubtype, setnoticeSubtype] = useState<SelectOption>();
    const [language, setLanguage] = useState<SelectOption>();
    const [manyLotsEnabled, setManyLotsEnabled] = useState<boolean>(true);
    const [changeNoticeEnabled, setChangeNoticeEnabled] =
        useState<boolean>(false);
    const [formData, setFormData] = useState<string>();
    const [multiStageEnabled, setMultiStageEnabled] = useState<boolean|undefined>();

    const { multiContext, isValid } = useSetupContext();
    const ldClient = useLDClient();

    const reduxUserId = useAppSelector((state) => state.store.userId);

    const reduxDispatch = useAppDispatch();

    const { platformNames } = useFetchPlatformNames();
    const { tenders } = useFetchTenders(t, platformName, true);
    const [tenderOptions, setTenderOptions] = useState<any>();
    const { data } = useFetchFormCreationProperties(
        country?.value,
        directive?.value,
        formType?.value,
        noticeType?.value
    );

    const { onSubmit } = useCreateForm({
        platformName,
        tenderId: tenderId?.value,
        country: (country as SelectOption)?.value,
        noticeSubtype: (noticeSubtype as SelectOption)?.value,
        language: (language as SelectOption)?.value,
        manyLotsEnabled,
        multiStageEnabled,
        formId: (formIdSelected as SelectOption)?.value,
        changeNoticeEnabled,
        formData,
        timeZone: (timeZone as SelectOption)?.value,
        onSuccessCallback: async (formId?: string) => {
            if (formId) {
                const url = await getOtpUrlFromApi(formId, 2, formOptions.form);
                window.open(url ?? '/login', '_blank')?.focus();
            }
        },
        onFailCallback: async (param?: string) => {
            if (param) {
                const errorMessage = t('form-content:UnExpectedFormSubtype', {
                    element: param,
                });
                toast.error(errorMessage);
            }
        },
        toastText: {
            onPendingText: t('toast-content:ToastCreatingFormPending'),
            onSuccessText: t('toast-content:ToastCreatingFormSuccess'),
        },
        t,
    });

    const onToggle = () => {
        setToggled(!toggled);
        setTenderId(undefined);
        setCountry(undefined);
        setDirective(undefined);
        setformType(undefined);
        setnoticeType(undefined);
        setnoticeSubtype(undefined);
        setLanguage(undefined);
        setFormData(undefined);
    };

    useEffect(() => {
        if (platformNames && !platformName) {
            reduxDispatch(setPlatformNameStore(platformNames[0]));
            setPlatformName(platformNames[0]);
        }
    }, [platformName, platformNames, reduxDispatch]);

    const headerValue = `${t('form-content:CreateFormForUser')} ${reduxUserId}`;

    useEffect(() => {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const luxonTimeZone = DateTime.local().setZone(userTimeZone);
        const unixBasedTimeZone = luxonTimeZone.zoneName;

        const defaultTimeZone = data?.timeZones.find(
            (x) => x.value === unixBasedTimeZone
        );

        setTimeZone(
            defaultTimeZone?.value
                ? defaultTimeZone
                : { label: 'Europe/Berlin', value: 'Europe/Berlin' }
        );
    }, [data?.timeZones]);

    useEffect(() => {
        if (isValid && multiContext) {
            ldClient
                ?.waitForInitialization()
                .then(() => {
                    ldClient?.identify(multiContext);
                })
                .catch((err) => {
                    // eslint-disable-next-line no-console
                    console.warn(err);
                });
        }
    }, [platformName, isValid, multiContext, ldClient]);

    useEffect(() => {
        if (tenders) {
            const options = Object.keys(tenders).map((key) => ({
                value: key,
                label: tenders[key].name,
                ...tenders[key],
            }));
            setTenderOptions(options);
        }
    }, [tenders]);

    const onTenderChange = (newValue: any) => {
        if (featureFlagMef2481) {
            const selectedValue = newValue ? {
                label: newValue.label,
                value: newValue.label
            } as SelectOption : undefined;
            setTenderId(selectedValue);
        }
        else {
            const selectedValue = newValue as SelectOption;
            setTenderId(selectedValue);
        }
        setFormIdSelected(undefined);
        setCountry(undefined);
        setDirective(undefined);
        setformType(undefined);
        setnoticeType(undefined);
        setnoticeSubtype(undefined);
        setLanguage(undefined);
        setFormData(undefined);
    }

    const onFormChange = (newValue: unknown) => {
        const selectedValue = newValue as SelectOption;
        setFormIdSelected(selectedValue);
        let form: FormListItem | undefined;
        if (featureFlagMef2481) {
            form = tenderOptions[
                tenderId?.value ?? 0
            ].forms.find(
                (a: any) =>
                    a.value ===
                    selectedValue.value
            );
        }
        else  if (tenders) {
            form = tenders[
                tenderId?.value ?? 0
            ].forms.find(
                (a) =>
                    a.value ===
                    selectedValue.value
            );
        }

        const formLanguage =
            data?.languages.find(
                (a) =>
                    a.value ===
                    form?.mainLanguage ??
                    ''
            );

        setManyLotsEnabled(
            form?.manyLots ?? false
        );

        setTimeZone({
            label:
                form?.noticeTimeZone ??
                data?.timeZones[0]
                    .label,
            value:
                form?.noticeTimeZone ??
                data?.timeZones[0]
                    .value,
        });

        setLanguage({
            value: formLanguage?.value,
            label: t(
                formLanguage?.label ??
                ''
            ),
        });

        setCountry(undefined);
        setDirective(undefined);
        setformType(undefined);
        setnoticeType(undefined);
        setnoticeSubtype(undefined);
        setFormData(undefined);
    }

    return (
        <div className="col-span-full px-6 justify-self-center xl:min-w-[800px] max-w-[800px] whitespace-normal">
            <h1>{headerValue}</h1>
            <form onSubmit={onSubmit}>
                <div className="mt-10 mb-10 -ml-2">
                    <Toggle
                        onLabel={t('form-content:CreateForm')}
                        offLabel={t('form-content:ChooseParent')}
                        checked={toggled}
                        onChange={onToggle}
                    />
                </div>
                <div className="mt-10 mb-10">
                    {platformNames && (
                        <div>
                            <label htmlFor="platformName">
                                {t('form-content:PlatformName')}
                            </label>
                            <Dropdown
                                id="platformName"
                                name="platformName"
                                options={platformNames?.map((pn: string) => ({
                                    value: pn,
                                    label: pn,
                                }))}
                                value={{
                                    value: platformName,
                                    label: platformName,
                                }}
                                onChange={(newValue: unknown) => {
                                    const selectedValue =
                                        newValue as SelectOption;
                                    setPlatformName(selectedValue.value);
                                    reduxDispatch(
                                        setPlatformNameStore(
                                            selectedValue.value ?? ''
                                        )
                                    );
                                    setCountry(undefined);
                                    setDirective(undefined);
                                    setformType(undefined);
                                    setnoticeType(undefined);
                                    setnoticeSubtype(undefined);
                                    setLanguage(undefined);
                                    setFormData(undefined);
                                }}
                            />
                        </div>
                    )}
                </div>
                {featureFlagMef2481 ?
                    <div className="mt-10 mb-10">
                        {toggled ? (
                            <div>
                                <label htmlFor="tenderId">
                                    {t('form-content:TenderId')}
                                </label>
                                <Dropdown
                                    id="tenderId"
                                    name="tenderId"
                                    value={tenderId ?? ''}
                                    isClearable
                                    onChange={onTenderChange}
                                    options={tenderOptions}
                                    placeholder={t('form-content:ChooseTender')}
                                />
                                {tenderOptions &&
                                    Object.keys(tenderOptions).length > 0 &&
                                    tenderId?.value && (
                                        <div className="mt-10 mb-10">
                                            <label htmlFor="formId">
                                                {t('form-content:FormId')}
                                            </label>
                                            <Dropdown
                                                id="formId"
                                                name="formId"
                                                value={formIdSelected ?? ''}
                                                isClearable
                                                onChange={onFormChange}
                                                options={tenderOptions.find((to: { value: string | undefined; }) => to.value === tenderId.value)
                                                    .forms?.map((form: any) => ({
                                                        value: form.value,
                                                        label: form.name,
                                                    }))}
                                                placeholder={t(
                                                    'form-content:ChooseForm'
                                                )}
                                            />
                                        </div>
                                    )}
                            </div>
                        ) : (
                            <Input
                                id="tenderId"
                                name="TenderId"
                                placeholder={t('form-content:TenderIdPlaceholder')}
                                onChange={(e) =>
                                    setTenderId({
                                        value: e.target.value,
                                        label: e.target.value,
                                    })
                                }
                            />
                        )}
                    </div> :
                    <div className="mt-10 mb-10">
                        {tenders && toggled ? (
                            <div>
                                <label htmlFor="tenderId">
                                    {t('form-content:TenderId')}
                                </label>
                                <Dropdown
                                    id="tenderId"
                                    name="tenderId"
                                    value={tenderId ?? ''}
                                    isClearable
                                    onChange={onTenderChange}
                                    options={Object.keys(tenders).map((key) => ({
                                        value: key,
                                        label: tenders[key].name,
                                    }))}
                                    placeholder={t('form-content:ChooseTender')}
                                />
                                {Object.keys(tenders).length > 0 &&
                                    tenderId?.value && (
                                        <div className="mt-10 mb-10">
                                            <label htmlFor="formId">
                                                {t('form-content:FormId')}
                                            </label>
                                            <Dropdown
                                                id="formId"
                                                name="formId"
                                                value={formIdSelected ?? ''}
                                                isClearable
                                                onChange={onFormChange}
                                                options={tenders[
                                                    tenderId.value
                                                ].forms?.map((form) => ({
                                                    value: form.value,
                                                    label: form.name,
                                                }))}
                                                placeholder={t(
                                                    'form-content:ChooseForm'
                                                )}
                                            />
                                        </div>
                                    )}
                            </div>
                        ) : (
                            <Input
                                id="tenderId"
                                name="TenderId"
                                placeholder={t('form-content:TenderIdPlaceholder')}
                                onChange={(e) =>
                                    setTenderId({
                                        value: e.target.value,
                                        label: e.target.value,
                                    })
                                }
                            />
                        )}
                    </div>
                }
                <div className="mb-10">
                    <Checkbox
                        id="lots-enabled-create-form"
                        checked={manyLotsEnabled}
                        onChange={() => setManyLotsEnabled(!manyLotsEnabled)}
                    >
                        Split into lots
                    </Checkbox>
                </div>
                <div className="mb-10">
                    <label htmlFor="country">{t('form-content:Country')}</label>
                    <Dropdown
                        id="country"
                        name="country"
                        value={country ?? ''}
                        isClearable
                        onChange={(newValue: unknown) => {
                            const selectedValue = newValue as SelectOption;
                            setCountry(selectedValue);
                            setDirective(undefined);
                            setformType(undefined);
                            setnoticeType(undefined);
                            setnoticeSubtype(undefined);
                            setFormData(undefined);
                        }}
                        options={data?.countries.map((item) => ({
                            value: item.value,
                            label: t(item.label ?? ''),
                        }))}
                    />
                </div>
                {featureFlagUseUtcDatesOnly && (
                    <div className="mb-10">
                        <label htmlFor="timezone">
                            {t('form-content:TimeZone')}
                        </label>
                        <Dropdown
                            id="timezone"
                            name="timezone"
                            value={timeZone ?? ''}
                            isClearable
                            onChange={(newValue: unknown) => {
                                const selectedValue = newValue as SelectOption;
                                setTimeZone(selectedValue);
                            }}
                            options={data?.timeZones.map((item) => ({
                                value: item.value,
                                label: t(item.label ?? ''),
                            }))}
                        />
                    </div>
                )}

                <div className="mb-10">
                    <label htmlFor="directive">
                        {t('form-content:LegalBasis')}
                    </label>
                    <Dropdown
                        id="directive"
                        name="directive"
                        value={directive ?? ''}
                        isClearable
                        onChange={(newValue: unknown) => {
                            const selectedValue = newValue as SelectOption;
                            setDirective(selectedValue);
                            setformType(undefined);
                            setnoticeType(undefined);
                            setnoticeSubtype(undefined);
                            setFormData(undefined);
                        }}
                        options={data?.directives.map((item) => ({
                            value: item.value,
                            label: t(item.label ?? ''),
                        }))}
                    />
                </div>

                <div className="mb-10">
                    <label htmlFor="formType">
                        {t('form-content:FormTypeCode')}
                    </label>
                    <Dropdown
                        id="formType"
                        name="formType"
                        value={formType ?? ''}
                        isClearable
                        onChange={(newValue: unknown) => {
                            const selectedValue = newValue as SelectOption;
                            setformType(selectedValue);
                            setnoticeType(undefined);
                            setnoticeSubtype(undefined);
                            setFormData(undefined);
                        }}
                        options={data?.formTypes.map((item) => ({
                            value: item.value,
                            label: t(item.label ?? ''),
                        }))}
                    />
                </div>

                <div className="mb-10">
                    <label htmlFor="noticeType">
                        {t('form-content:NoticeTypeCode')}
                    </label>
                    <Dropdown
                        id="noticeType"
                        name="noticeType"
                        value={noticeType ?? ''}
                        isClearable
                        onChange={(newValue: unknown) => {
                            const selectedValue = newValue as SelectOption;
                            setnoticeType(selectedValue);
                            setnoticeSubtype(undefined);
                            setFormData(undefined);
                        }}
                        options={data?.noticeTypes.map((item) => ({
                            value: item.value,
                            label: t(item.label ?? ''),
                        }))}
                    />
                </div>

                <div className="mb-10">
                    <label htmlFor="noticeSubtype">
                        {t('form-content:NoticeSubtype')}
                    </label>
                    <Dropdown
                        id="noticeSubtype"
                        name="noticeSubtype"
                        value={noticeSubtype ?? ''}
                        isClearable
                        onChange={(newValue: unknown) => {
                            const selectedValue = newValue as SelectOption;
                            setnoticeSubtype(selectedValue);
                            setFormData(undefined);
                            if(featureFlagMef2544MultipleStageEnabled && ["14", "15","19","22"].indexOf(selectedValue?.value || "")>-1){
                                setMultiStageEnabled(false);
                            }
                            else{
                                setMultiStageEnabled(undefined);
                            }
                        }}
                        options={data?.noticeSubtypes.map((item) => ({
                            value: item.value,
                            label: t(item.label ?? ''),
                        }))}
                    />
                </div>

                <div className="mb-10">
                    <Checkbox
                        id="change-notice-enabled"
                        checked={changeNoticeEnabled}
                        onChange={() =>
                            setChangeNoticeEnabled(!changeNoticeEnabled)
                        }
                    >
                        Create change notice
                    </Checkbox>
                </div>
                {featureFlagMef2544MultipleStageEnabled &&
                    (["14", "15", "19", "22"].indexOf(noticeSubtype?.value || "") > -1 ) &&
                    <div className="mb-10">
                        <Checkbox
                            id="multi-stage-enabled"
                            checked={multiStageEnabled}
                            onChange={() =>
                                setMultiStageEnabled(!multiStageEnabled)
                            }
                        >
                            Multiple stage enabled?
                        </Checkbox>
                    </div>
                }

                <div className="mb-10">
                    <label htmlFor="language">
                        {t('form-content:SubmissionLanguageCode')}
                    </label>
                    <Dropdown
                        id="language"
                        name="language"
                        value={language ?? ''}
                        isClearable
                        onChange={(newValue: unknown) => {
                            const selectedValue = newValue as SelectOption;
                            setLanguage(selectedValue);
                            setFormData(undefined);
                        }}
                        options={data?.languages.map((item) => ({
                            value: item.value,
                            label: t(item.label ?? ''),
                        }))}
                    />
                </div>

                {language && (
                    <div className="mb-10">
                        <Editor
                            height="150px"
                            defaultLanguage="json"
                            onChange={(e) => setFormData(e)}
                            options={{ scrollBeyondLastLine: false }}
                        />
                    </div>
                )}
                <Button
                    scheme="primary"
                    className="ml-auto"
                    type="submit"
                    data-test="create-form-button"
                >
                    Submit
                </Button>
            </form>
        </div>
    );
};
export default CreateForm;
