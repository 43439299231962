import { ValidationErrorObject } from '../../types/validationError';
import { TFunction } from 'react-i18next';
import { translate } from '../errorsTranslation';
import { getIn } from 'final-form';

export function addErrorObjectWithPathsRecursive(
    errorSchema: Record<string, unknown>,
    errorObjectWithPaths: ValidationErrorObject['errorObjectWithPaths'],
    mainSectionKeys: string[],
    t: TFunction<string, unknown>,
    formId?: string,
    defaultErrorSchema?: Record<string, unknown>,
    fieldPath = '',
    urlPath = '',
    fullFieldPath = '',
    arrayOfIds: string[] = [],
    arrayOfNames: string[] = [],
    arrayOfComplexPathsToError: string[] = [],
    mainSectionKey = '',
    addNextLevelSectionToUrl = false,
    subSectionName = ''
): void {
    for (const key in errorSchema) {
        let currentPath = '';
        if (key.startsWith('_') && key !== '_errors') {
            continue;
        }
        const foundMainSectionKey =
            mainSectionKey ||
            mainSectionKeys.find((sectionKey) =>
                (urlPath || fieldPath || key).startsWith(sectionKey)
            );
        if (key === '_errors') {
            if (
                foundMainSectionKey &&
                errorObjectWithPaths[foundMainSectionKey]
            ) {
                let updatedUrlPath = urlPath;
                if (urlPath && subSectionName && arrayOfIds.length === 1) {
                    const subSectionSubKey = fieldPath.split('.');
                    if (
                        (subSectionSubKey.length > 0 &&
                            subSectionSubKey.length === 2) ||
                        subSectionName === 'ContractsSection' ||
                        subSectionName === 'TenderSection'
                    ) {
                        updatedUrlPath = `${urlPath}/${subSectionName}/${subSectionSubKey[1]}`;
                    } else {
                        updatedUrlPath = `${urlPath}/${subSectionName}`;
                    }
                } else if (urlPath && arrayOfIds.length > 1) {
                    updatedUrlPath = urlPath;
                } else if (!urlPath) {
                    const mainSectionSubKey = fieldPath.split('.');
                    if (
                        (mainSectionSubKey.length > 0 &&
                            mainSectionSubKey.length === 2) ||
                        mainSectionKey === 'CompaniesSection' ||
                        mainSectionKey === 'LotsSection'
                    ) {
                        updatedUrlPath = `/form/${formId}/${mainSectionKey}/${mainSectionSubKey[1]}`;
                    } else {
                        updatedUrlPath = `/form/${formId}/${mainSectionKey}`;
                    }
                }
                errorObjectWithPaths[foundMainSectionKey].push({
                    fieldPath,
                    urlPath: updatedUrlPath,
                    arrayOfIds,
                    arrayOfNames,
                    arrayOfComplexPathsToError,
                    fieldErrors: translate(errorSchema, t),
                    languageOfField:
                        (errorSchema._language as string) || undefined,
                    languageCode:
                        (errorSchema._languageCode as string) || undefined,
                    fieldLabel: t((errorSchema._label as string) || ''),
                    nestedLevelSectionName:
                        addNextLevelSectionToUrl && subSectionName
                            ? subSectionName
                            : undefined,
                    mainSectionName: foundMainSectionKey,
                });
                const fullFieldPathSplit = fullFieldPath.split('.');
                const lastElementOfPath =
                    fullFieldPathSplit[fullFieldPathSplit.length - 1];
                fullFieldPathSplit.pop();
                getIn(
                    defaultErrorSchema as object,
                    fullFieldPathSplit.join('.')
                )[lastElementOfPath] = translate(errorSchema, t);
            }
        } else {
            if (fieldPath) {
                currentPath = `${fieldPath}.${key}`;
            } else {
                currentPath = key;
            }

            if (Array.isArray(errorSchema[key])) {
                const arrayOfSchemas = errorSchema[key] as Record<
                    string,
                    unknown
                >[];
                arrayOfSchemas.forEach(
                    (element: Record<string, unknown>, index: number) => {
                        if (
                            element.constructor === Object &&
                            Object.keys(element).length
                        ) {
                            const nextSchema = Object.keys(
                                arrayOfSchemas[index]
                            );
                            const updatedArrayOfIds = [...arrayOfIds];
                            const updatedArrayOfNames = [...arrayOfNames];
                            const updatedArrayOfComplexPathsToError = [
                                ...arrayOfComplexPathsToError,
                            ];
                            if (nextSchema && nextSchema.includes('_id')) {
                                const id = arrayOfSchemas[index]._id as string;
                                const name = arrayOfSchemas[index]
                                    ._name as string;
                                updatedArrayOfIds.push(id);
                                updatedArrayOfNames.push(name);
                                updatedArrayOfComplexPathsToError.push(
                                    currentPath
                                );
                                let urlPathFromField =
                                    urlPath || `/form/${formId}`;
                                let addSubSectionToURL = false;
                                if (arrayOfSchemas[index]._hasSubMenu) {
                                    urlPathFromField =
                                        urlPath || `/form/${formId}/sub`;
                                    addSubSectionToURL = true;
                                }
                                urlPathFromField = `${urlPathFromField}/${currentPath.replaceAll(
                                    '.',
                                    '/'
                                )}/${id}`;
                                addErrorObjectWithPathsRecursive(
                                    element,
                                    errorObjectWithPaths,
                                    mainSectionKeys,
                                    t,
                                    formId,
                                    defaultErrorSchema || errorSchema,
                                    '',
                                    urlPathFromField,
                                    fullFieldPath
                                        ? `${fullFieldPath}.${key}[${index}]`
                                        : currentPath,
                                    updatedArrayOfIds,
                                    updatedArrayOfNames,
                                    updatedArrayOfComplexPathsToError,
                                    foundMainSectionKey,
                                    addSubSectionToURL
                                );
                            } else {
                                addErrorObjectWithPathsRecursive(
                                    element,
                                    errorObjectWithPaths,
                                    mainSectionKeys,
                                    t,
                                    formId,
                                    defaultErrorSchema || errorSchema,
                                    `${currentPath}[${index}]`,
                                    urlPath,
                                    fullFieldPath
                                        ? `${fullFieldPath}.${key}[${index}]`
                                        : currentPath,
                                    arrayOfIds,
                                    arrayOfNames,
                                    arrayOfComplexPathsToError,
                                    foundMainSectionKey,
                                    addNextLevelSectionToUrl,
                                    addNextLevelSectionToUrl && !subSectionName
                                        ? key
                                        : subSectionName
                                );
                            }
                        }
                    }
                );
            }
            const schemaAsRecord = errorSchema[key] as Record<string, unknown>;
            if (schemaAsRecord.constructor === Object) {
                addErrorObjectWithPathsRecursive(
                    schemaAsRecord,
                    errorObjectWithPaths,
                    mainSectionKeys,
                    t,
                    formId,
                    defaultErrorSchema || errorSchema,
                    currentPath,
                    urlPath,
                    fullFieldPath ? `${fullFieldPath}.${key}` : currentPath,
                    arrayOfIds,
                    arrayOfNames,
                    arrayOfComplexPathsToError,
                    foundMainSectionKey,
                    addNextLevelSectionToUrl,
                    addNextLevelSectionToUrl && !subSectionName
                        ? key
                        : subSectionName
                );
            }
        }
    }
}
