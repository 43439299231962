import Backend from 'i18next-http-backend';
import ChainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getTranslationKeysVisibility } from './shared/storageService/sessionService';
import envWithSuffix from './shared/envWithSuffix';

/** List of supported languages.
 * When adding a language, don't forget to add a corresponsing translation! */
export const languages = [
    'en',
    'da',
    'de',
    'fi',
    'lt',
    'nb',
    'nl',
    'sv',
    'sv-FI',
];

document.documentElement.lang = sessionStorage.i18nextLng ?? 'en';
if (!languages.some((x) => x === document.documentElement.lang)) {
    document.documentElement.lang = document.documentElement.lang.slice(0, 2);
    if (!languages.some((x) => x === document.documentElement.lang)) {
        document.documentElement.lang = 'en';
    }
}

const getPath = (): string => {
    const cdnUrl = process.env.REACT_APP_CROWDIN_CDN;
    return sessionStorage.i18nextLng === 'en' || !cdnUrl || cdnUrl.length === 0
        ? '/locales/{{lng}}/{{ns}}.json'
        : `${cdnUrl}/locales/{{lng}}/{{ns}}.json`;
};

i18n.use(ChainedBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        lng: document.documentElement.lang, // sessionStorage.i18nextLng, // 'en',
        // debug: process.env.NODE_ENV === 'development',
        backend: {
            backends: [LocalStorageBackend, Backend, Backend, Backend],
            backendOptions: [
                {
                    expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
                    defaultVersion:
                        process.env.NODE_ENV === 'development'
                            ? Date().toLocaleString() // refresh on every page reload (F5)
                            : `${process.env.REACT_APP_BUILD_NUMBER}`, // refresh on very new build
                },
                {
                    loadPath: !getTranslationKeysVisibility() ? getPath() : '',
                },
                {
                    loadPath: !getTranslationKeysVisibility()
                        ? '/locales/{{lng}}/{{ns}}.json'
                        : '',
                },
                {
                    loadPath: !getTranslationKeysVisibility()
                        ? '/locales/en/{{ns}}.json'
                        : '',
                },
            ],
        },
        react: {
            useSuspense: process.env.MODE !== 'test',
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        detection: {
            order: ['navigator'],
            caches: ['sessionStorage'],
        },
    });

export default i18n;
