import { getIn } from 'final-form';
import { ValidationErrorObject } from '../../types/validationError';

export function trimErrorObjectByFormSchema(
    submittedErrors: Record<string, unknown>,
    errorObjectWithPaths: ValidationErrorObject['errorObjectWithPaths'],
    parentSectionId: string,
    sectionId: string,
    parentArrayElementId: string,
    arrayElementId: string
): Record<string, unknown> | null {
    if (
        (parentSectionId && !errorObjectWithPaths[parentSectionId]) ||
        (!parentSectionId && sectionId && !errorObjectWithPaths[sectionId])
    ) {
        return null;
    }
    switch (true) {
        case !!(parentArrayElementId && arrayElementId): {
            const errorsOfCurrentSection = errorObjectWithPaths[
                parentSectionId
            ].filter((errorObject) =>
                errorObject.arrayOfIds.includes(arrayElementId)
            )[0];
            if (errorsOfCurrentSection) {
                let getInPath = '';
                errorsOfCurrentSection.arrayOfComplexPathsToError.forEach(
                    (complexPath, index) => {
                        if (index === 0) {
                            const pathIndex = getIn(
                                submittedErrors,
                                errorsOfCurrentSection
                                    .arrayOfComplexPathsToError[index]
                            ).findIndex(
                                (arrayOfErrors: Record<string, unknown>) =>
                                    arrayOfErrors._id ===
                                    errorsOfCurrentSection.arrayOfIds[index]
                            );

                            getInPath += `${errorsOfCurrentSection.arrayOfComplexPathsToError[index]}[${pathIndex}]`;
                        } else {
                            const pathIndex = getIn(
                                submittedErrors,
                                `${getInPath}.${errorsOfCurrentSection.arrayOfComplexPathsToError[index]}`
                            ).findIndex(
                                (arrayOfErrors: Record<string, unknown>) =>
                                    arrayOfErrors._id ===
                                    errorsOfCurrentSection.arrayOfIds[index]
                            );
                            getInPath += `.${errorsOfCurrentSection.arrayOfComplexPathsToError[index]}[${pathIndex}]`;
                        }
                    }
                );
                return getIn(submittedErrors, getInPath);
            }
            return null;
        }
        case !!(parentArrayElementId && parentSectionId && sectionId): {
            const errorsOfCurrentSection = errorObjectWithPaths[parentSectionId]
                .filter((errorObject) =>
                    errorObject.arrayOfIds.includes(parentArrayElementId)
                )
                .find((errorObject) =>
                    errorObject.fieldPath.startsWith(sectionId)
                );
            if (errorsOfCurrentSection) {
                const index = getIn(
                    submittedErrors,
                    errorsOfCurrentSection.arrayOfComplexPathsToError[0]
                ).findIndex(
                    (arrayOfErrors: Record<string, unknown>) =>
                        arrayOfErrors._id === parentArrayElementId
                );
                return {
                    [sectionId]: getIn(
                        submittedErrors,
                        `${errorsOfCurrentSection.arrayOfComplexPathsToError[0]}[${index}].${sectionId}`
                    ),
                };
            }
            return null;
        }
        case !!(arrayElementId && sectionId): {
            const errorsOfCurrentSection = errorObjectWithPaths[
                sectionId
            ].filter((errorObject) =>
                errorObject.arrayOfIds.includes(arrayElementId)
            )[0];
            if (errorsOfCurrentSection) {
                const index = getIn(
                    submittedErrors,
                    errorsOfCurrentSection.arrayOfComplexPathsToError[0]
                ).findIndex(
                    (arrayOfErrors: Record<string, unknown>) =>
                        arrayOfErrors._id === arrayElementId
                );
                return getIn(
                    submittedErrors,
                    `${errorsOfCurrentSection.arrayOfComplexPathsToError[0]}[${index}]`
                );
            }
            return null;
        }
        default:
            if (submittedErrors[sectionId]) {
                return { [sectionId]: submittedErrors[sectionId] };
            }
            return null;
    }
}
