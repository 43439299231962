import { FunctionScheme } from '@mercell/tailwind';
import { PublicationTaskStatus } from '../pages/Dashboard/publicationTaskStatus';
import { FormStatus } from '../types/generated/formMenuResult';
import { DropdownOption } from '../types/generated/formStatusResult';

export const getStatusName = (
    statusId: number | string | undefined,
    statuses: DropdownOption[] | undefined
) => {
    if (!!statuses && statuses !== undefined && typeof statusId === 'number') {
        const status = statuses?.find((x) => x.value === statusId);
        return status?.name;
    }
    return statusId;
};

export const getSchemeName = (
    statusId: number | string | undefined,
    statuses: DropdownOption[] | undefined
) => {
    const status = statuses?.find((x) => x.value === statusId);
    const statusValue = status ? status?.value : statusId;
    let schemeName: FunctionScheme = 'default';

    if (
        statusValue === FormStatus.Published ||
        statusId === PublicationTaskStatus[4] ||
        statusId === PublicationTaskStatus[6]
    ) {
        schemeName = 'success';
    }
    if (
        statusValue === FormStatus.ReadyForPublication ||
        statusValue === FormStatus.DispatchedForPublication ||
        statusValue === FormStatus.ReadyForTranslation ||
        statusValue === FormStatus.DispatchedForTranslation ||
        statusValue === FormStatus.TranslationReceived ||
        statusId === PublicationTaskStatus[0] ||
        statusId === PublicationTaskStatus[1] ||
        statusId === PublicationTaskStatus[5]
        // || statusValue === FormStatus.TranslationApproved
    ) {
        schemeName = 'information';
    }
    if (
        statusValue === FormStatus.Error ||
        statusValue === FormStatus.Rejected ||
        statusValue === FormStatus.Deleted ||
        statusId === PublicationTaskStatus[2] ||
        statusId === PublicationTaskStatus[3]
    ) {
        schemeName = 'error';
    }

    return schemeName;
};
