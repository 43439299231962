import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import {
    Outlet,
    useNavigate,
    useOutletContext,
    useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Menu32 } from '@carbon/icons-react';
import { Button } from '@mercell/button-react';
import { OutletFormNavigationContextInterface } from '../../types/outletInterface';
import BackNavigation from '../../types/backNavigation';
import { Portal } from '@mercell/portal-react';
import { Drawer } from '@mercell/drawer-react';
import { ValidationDrawerContent } from './ValidationDrawerContent/ValidationDrawerContent';
import { MenuSections } from './ValidationDrawerContent/MenuSections';
import { MyChecklistDrawer } from './MyChecklistDrawer/MyChecklistDrawer';
import { Footer } from './Footer/Footer';
import { useAppSelector } from '../../redux/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';

const FormNavigation: FC = memo(() => {
    const {
        formId,
        parentSectionId,
        parentArrayPath,
        sectionId,
        arrayPath,
        arrayElementId,
        parentArrayElementId,
    } = useParams<{
        formId: string;
        parentSectionId?: string;
        parentArrayPath?: string;
        sectionId: string;
        arrayPath?: string;
        parentArrayElementId?: string;
        arrayElementId?: string;
    }>();
    const {
        setBackendValidationErrors,
        backendValidationErrors,
        sections,
        isFormPreview,
        setHotReloadOfMenu,
        parentElementTitleKey,
        navigationTitle,
        validateMethod,
        isLoadingFetchingValidations,
        isErrorFetchingSections,
        isErrorFetchingValidations,
        setShowMyChecklistBeacon,
        showMyChecklistBeacon,
        isFormDispatchedForTranslation,
        noticeTimeZone,
    } = useOutletContext<OutletFormNavigationContextInterface>();

    const navigate = useNavigate();
    const [, setShowNavigation] = useState<boolean>(false);
    const [showRevalidateMessage, setShowRevalidateMessage] = useState(false);
    const { featureFlagUseFooter, featureFlagUseFooterChecklistDrawer } =
        useFlags();

    const { t } = useTranslation([
        'form-content',
        'form-content-uk',
        'toast-content',
    ]);

    const navWrapper = useRef<HTMLDivElement>(null);
    const currentSelectedSection = sections
        ? sections.find((section) => section.sectionName === sectionId)
        : undefined;
    useEffect(() => {
        if (sections) {
            if (!sectionId && sections && !isFormDispatchedForTranslation)
                navigate(
                    `${
                        !parentSectionId
                            ? `/form/${formId}`
                            : `/form/${formId}/sub/${parentSectionId}/${parentArrayPath}/${parentArrayElementId}`
                    }/${sections[0].sectionName}`,
                    { replace: true }
                );
        }
    }, [
        sections,
        sectionId,
        formId,
        navigate,
        arrayPath,
        parentSectionId,
        parentArrayPath,
        parentArrayElementId,
        isFormDispatchedForTranslation,
    ]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (!navWrapper.current?.contains(event.target as Element)) {
                setShowNavigation(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () =>
            document.removeEventListener('mousedown', handleClickOutside);
    }, [navWrapper]);

    const baseUrl = !parentSectionId
        ? `/form/${formId}`
        : `/form/${formId}/sub/${parentSectionId}/${parentArrayPath}/${parentArrayElementId}`;

    const changeSectionIndex = (value: string) => {
        if (value === sectionId && !arrayPath) return;

        const menuItem = sections?.filter(
            (section) => section.sectionName === value
        )[0];

        navigate(
            `${baseUrl}${value ? `/${value}` : ''}${
                menuItem?.isDetailedArray === true ? '?pageNo=1' : ''
            }`
        );
    };

    const setupBackNavigation = () => {
        let backNavigation: BackNavigation = { path: '', label: '' };
        if (parentSectionId && !arrayElementId && parentElementTitleKey) {
            backNavigation = {
                path: `/form/${formId}/${parentSectionId}/${parentArrayPath}`,
                label: t(parentElementTitleKey),
            };
        }
        if (parentSectionId && arrayElementId && currentSelectedSection) {
            backNavigation = {
                path: `/form/${formId}/sub/${parentSectionId}/${parentArrayPath}/${parentArrayElementId}/${sectionId}/${arrayPath}`,
                label: t(currentSelectedSection.translationKey),
            };
        }
        if (!parentSectionId && arrayElementId && currentSelectedSection) {
            backNavigation = {
                path: `/form/${formId}/${sectionId}/${arrayPath}`,
                label: t(currentSelectedSection.translationKey),
            };
        }
        return backNavigation;
    };

    const checklistRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);
    const [isDrawerVisible, toggleDrawer] = useState<boolean>(false);
    const [isMobileDrawerVisible, toggleMobileDrawer] =
        useState<boolean>(false);
    const [drawerDynamicHeight, setDrawerDynamicHeight] = useState<number>(100);

    const reduxIsLoadingForm = useAppSelector(
        (state) => state.store.isLoadingForm
    );

    const backNavigation = setupBackNavigation();
    const closeDrawer = () => toggleDrawer(false);
    const openDrawer = () => toggleDrawer(true);

    const closeMobileDrawer = () => toggleMobileDrawer(false);
    const openMobileDrawer = () => toggleMobileDrawer(true);

    const errorKeys = useMemo(
        () => Object.keys(backendValidationErrors.submittedErrors),
        [backendValidationErrors.submittedErrors]
    );

    const calculateDistance = () => {
        const footer = footerRef.current;
        const checklist = checklistRef.current;

        if (!footer) {
            setDrawerDynamicHeight(100);
        } else if (footer && checklist && !reduxIsLoadingForm) {
            const distanceToFooter =
                footer.getBoundingClientRect().top -
                checklist.getBoundingClientRect().bottom;

            if (distanceToFooter <= 0.2) {
                setDrawerDynamicHeight(160);
            } else {
                setDrawerDynamicHeight(100);
            }
        }
    };

    useEffect(() => {
        calculateDistance();

        const handleScroll = () => {
            calculateDistance();
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        window.location.pathname,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        footerRef.current?.getBoundingClientRect().y,
        footerRef,
    ]);

    // Feature Flag for Footer and new Implementation of Checklist Drawer

    const renderChecklistDrawer = () => {
        if (featureFlagUseFooterChecklistDrawer) {
            return (
                <MyChecklistDrawer
                    closeDrawer={closeDrawer}
                    isDrawerVisible={isDrawerVisible}
                    ref={checklistRef}
                    drawerTitle={t('form-content:CheckList')}
                    drawerDynamicHeight={drawerDynamicHeight}
                >
                    <ValidationDrawerContent
                        showRevalidateMessage={showRevalidateMessage}
                        setShowRevalidateMessage={setShowRevalidateMessage}
                        validateMethod={validateMethod}
                        backendValidationErrors={backendValidationErrors}
                        t={t}
                        errorKeys={errorKeys}
                        isLoadingFetchingValidations={
                            isLoadingFetchingValidations
                        }
                        isErrorFetchingValidations={isErrorFetchingValidations}
                        setShowMyChecklistBeacon={setShowMyChecklistBeacon}
                        featureFlagUseFooterChecklistDrawer={
                            featureFlagUseFooterChecklistDrawer
                        }
                    />
                </MyChecklistDrawer>
            );
        }
        return (
            <Drawer
                isDrawerVisible={isDrawerVisible}
                usePortal={false}
                documentOverflowHidden={false}
                onCloseCallback={closeDrawer}
                className={cx(
                    'shadow-[-10px_10px_40px_rgba(114,114,114,0.15)]',
                    'px-0 pb-0 pt-0 ml-auto -mb-6',
                    'top-24 h-[calc(100vh-80px)]',
                    'min-w-[400px] max-w-[500px] w-full',
                    'fixed lg:sticky col-start-11',
                    !isDrawerVisible && 'w-0 min-w-[0px] min-w-[0px]'
                )}
                drawerTitle={t('form-content:CheckList')}
                headerProps={{
                    className: 'sticky top-0 z-20 bg-white pt-6 px-8',
                }}
                closeButtonProps={{
                    className: 'top-[1.7rem right-0 px-8',
                }}
            >
                <ValidationDrawerContent
                    showRevalidateMessage={showRevalidateMessage}
                    setShowRevalidateMessage={setShowRevalidateMessage}
                    validateMethod={validateMethod}
                    backendValidationErrors={backendValidationErrors}
                    t={t}
                    errorKeys={errorKeys}
                    isLoadingFetchingValidations={isLoadingFetchingValidations}
                    isErrorFetchingValidations={isErrorFetchingValidations}
                    setShowMyChecklistBeacon={setShowMyChecklistBeacon}
                    featureFlagUseFooterChecklistDrawer={
                        featureFlagUseFooterChecklistDrawer
                    }
                />
            </Drawer>
        );
    };

    return (
        <>
            {/* MyChecklist Portal Button */}
            {!isDrawerVisible && !isFormPreview && (
                <Portal parent={document.body}>
                    <Button
                        className="fixed right-0 z-100 top-1/4 -translate-x-[48px] translate-y-1/2 origin-[100%_0%] -rotate-90 border-b-0 rounded-b-none ignore-outside-click"
                        scheme="secondary"
                        onClick={() => {
                            openDrawer();
                        }}
                    >
                        {t('form-content:CheckList')}
                    </Button>
                </Portal>
            )}

            {/* Menu Sections */}
            <div className="w-fit mr-8 top-28 sticky col-start-3 col-end-5 h-auto justify-self-end self-start whitespace-nowrap lg:block hidden">
                <MenuSections
                    backNavigation={backNavigation}
                    closeDrawer={closeDrawer}
                    isDrawerVisible={isDrawerVisible}
                    isErrorFetchingSections={isErrorFetchingSections}
                    changeSectionIndex={changeSectionIndex}
                    isFormPreview={isFormPreview}
                    navigate={navigate}
                    openDrawer={openDrawer}
                    setShowNavigation={setShowNavigation}
                    showMyChecklistBeacon={showMyChecklistBeacon}
                    t={t}
                    arrayElementId={arrayElementId}
                    parentSectionId={parentSectionId}
                    sectionId={sectionId}
                    sections={sections}
                />
            </div>

            {/* Form Wrapper */}

            <div className="lg:col-start-5 col-start-1 lg:col-end-10 col-end-12 mx-2 mt-5 mb-6">
                <Outlet
                    context={{
                        validateMethod,
                        setBackendValidationErrors,
                        backendValidationErrors,
                        sections,
                        isFormPreview,
                        setShowRevalidateMessage,
                        setHotReloadOfMenu,
                        parentElementTitleKey,
                        navigationTitle,
                        currentSelectedSection,
                        noticeTimeZone,
                    }}
                />
            </div>

            {/* My Checklist Drawer */}
            {renderChecklistDrawer()}

            {/* Mobile Drawer */}
            <Drawer
                isDrawerVisible={isMobileDrawerVisible}
                isOutsideClickDisabled
                usePortal
                documentOverflowHidden={false}
                onCloseCallback={closeMobileDrawer}
                className={cx(
                    'shadow-[-10px_10px_40px_rgba(114,114,114,0.15)]',
                    'top-24 h-full',
                    'min-w-[250px] max-w-[350px] w-full',
                    'fixed right-50',
                    !isMobileDrawerVisible && 'w-0 min-w-[0px] min-w-[0px]'
                )}
            >
                <div className="ml-6 w-fit h-full whitespace-nowrap self-center">
                    <MenuSections
                        backNavigation={backNavigation}
                        closeDrawer={closeDrawer}
                        isDrawerVisible={isDrawerVisible}
                        isErrorFetchingSections={isErrorFetchingSections}
                        changeSectionIndex={changeSectionIndex}
                        isFormPreview={isFormPreview}
                        navigate={navigate}
                        openDrawer={openDrawer}
                        setShowNavigation={setShowNavigation}
                        showMyChecklistBeacon={showMyChecklistBeacon}
                        t={t}
                        arrayElementId={arrayElementId}
                        parentSectionId={parentSectionId}
                        sectionId={sectionId}
                        sections={sections}
                    />
                </div>
            </Drawer>
            <Button
                type="button"
                scheme="secondary"
                className={cx(
                    'flex justify-center items-center h-12 px-2 py-3 rounded-default border w-12 sm:w-11 lg:hidden focus:ring-offset-0 right-[24px] bottom-[60px] z-[15] fixed',
                    isDrawerVisible && 'hidden'
                )}
                onClick={() => openMobileDrawer()}
            >
                <Menu32 />
            </Button>
            {!reduxIsLoadingForm && featureFlagUseFooter && (
                <Footer ref={footerRef} t={t} noticeTimeZone={noticeTimeZone} />
            )}
        </>
    );
});

FormNavigation.displayName = 'FormNavigation';
FormNavigation.whyDidYouRender = true;
export default FormNavigation;
