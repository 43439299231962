import React from 'react';
import { useTranslation } from 'react-i18next';

const NoAccessPage = () => {
    const { t } = useTranslation('form-content');
    return (
        <div className="flex mx-16 flex-col-reverse sm:flex-row items-center justify-between sm:py-10 col-span-full">
            <div>
                <h1 className="mb-2">{t('form-content:NoAccess')}</h1>
                <p>{t('form-content:NoAccessGranted')}</p>
                <p>{t('form-content:NoAccessNoRepError')}</p>
                <p>{t('form-content:NoAccessContact')}</p>
            </div>
            <img
                src="/images/not-found.svg"
                className="w-1/2 mb-10 sm:mb-0"
                alt="No Access"
            />
        </div>
    );
};

export default NoAccessPage;
