import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { saveFormJson } from '../apiCalls/formJsonCalls';
import Button from '@mercell/button-react';
import Editor from '@monaco-editor/react';
import { useTranslation } from 'react-i18next';
import { Drawer } from '@mercell/drawer-react';
import useFetchFormJson from '../hooks/administrativeHooks/useFetchFormJson';
import ConfirmationModal from '../components/ConfirmationModal';
import { Information32, Help32 } from '@carbon/icons-react';
import LoadingSpinner from '@mercell/loading-spinner-react';
import { toast } from 'react-toastify';
import useFetchUserRights from '../hooks/useFetchUserRights';

interface ValidationError {
    dataPath: string;
    error: {
        message: string;
        params: string[];
    };
}

const EditJson = () => {
    const { formId } = useParams<{ formId: string }>();
    const [validationErrors, setValidationErrors] = useState<
        ValidationError[] | undefined
    >(undefined);
    const [isDrawerVisible, toggleDrawerVisibility] = useState<boolean>(false);
    const [newJson, setNewJson] = useState<string>();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const { formJson, isLoadingFormJson } = useFetchFormJson(formId ?? '');

    const { t } = useTranslation(['form-content', 'toast-content']);
    const { UserRights } = useFetchUserRights();
    if (isLoadingFormJson)
        return (
            <div className="col-span-full h-full flex items-center justify-center">
                <LoadingSpinner />
            </div>
        );
    if (!formJson) return <div>Form not found</div>;

    const onSave = async () => {
        setIsModalVisible(true);
    };

    const onConfirm = () => {
        if (!newJson) return;
        setValidationErrors(undefined);
        saveChanges();
        setNewJson(undefined);
        setIsModalVisible(false);
    };

    const saveChanges = async () => {
        if (!newJson) return;
        let errors = [];
        let isJsonError;
        try {
            errors = await saveFormJson(
                formId ?? '',
                JSON.parse(newJson),
                formJson.data?.Administrative.NoticeLanguage,
                {
                    onPendingText: t('toast-content:ToastFormUpdatePending'),
                    onSuccessText: t('toast-content:ToastFormUpdateSuccess'),
                }
            );
        } catch (e: any) {
            isJsonError = e;
            toast.error(e.message);
        }

        if (errors.length > 0) {
            setValidationErrors(errors);
            toggleDrawerVisibility(true);
        }
        if (!isJsonError) {
            const formUpdatedMessage = t(
                'toast-content:ToastFormUpdateSuccess'
            );
            toast.success(formUpdatedMessage);
        }
    };

    return (
        <div className="absolute top-0 left-0 w-full">
            {!newJson && isModalVisible && (
                <ConfirmationModal
                    text={t('form-content:NoChanges')}
                    confirmText="Ok"
                    onConfirm={() => setIsModalVisible(false)}
                    closeModal={() => setIsModalVisible(false)}
                    isModalVisible={isModalVisible}
                >
                    <Information32
                        style={{
                            width: 150,
                            height: 150,
                            margin: '30px auto',
                            color: 'aquamarine',
                        }}
                    />
                </ConfirmationModal>
            )}
            {isModalVisible && newJson && (
                <ConfirmationModal
                    text={t('form-content:SaveConfirmation')}
                    confirmText={t('form-content:LabelSave')}
                    cancelText={t('form-content:LabelCancel')}
                    onConfirm={onConfirm}
                    closeModal={() => setIsModalVisible(false)}
                    isModalVisible={isModalVisible}
                >
                    <Help32
                        style={{
                            width: 150,
                            height: 150,
                            margin: '30px auto',
                            color: 'aquamarine',
                        }}
                    />
                </ConfirmationModal>
            )}
            <div className="w-full h-screen  max-w-screen-2xl m-auto">
                <Editor
                    height="90%"
                    className="mt-3"
                    defaultLanguage="json"
                    defaultValue={JSON.stringify(formJson, null, '\t')}
                    onChange={(updatedJson) => setNewJson(updatedJson)}
                    options={{ scrollBeyondLastLine: false }}
                />
                <div className="mt-4 flex justify-end">
                    {validationErrors && !isDrawerVisible && (
                        <Button
                            scheme="secondary"
                            className="ignore-outside-click mr-4 ml-2"
                            onClick={() => toggleDrawerVisibility(true)}
                        >
                            {t('form-content:ShowErrors')}
                        </Button>
                    )}
                    {!!UserRights?.isAdmin && (
                        <Button
                            scheme="primary"
                            className="mr-2"
                            onClick={onSave}
                            title={t('form-content:LabelSave')}
                        >
                            {t('form-content:LabelSave')}
                        </Button>
                    )}
                </div>
            </div>
            <Drawer
                drawerTitle={`${'form-content:ValidationErrors'} (${
                    validationErrors?.length
                }):`}
                isDrawerVisible={isDrawerVisible}
                onCloseCallback={() => toggleDrawerVisibility(false)}
                ignoreOutsideClickClassName="ignore-outside-click"
            >
                <ul>
                    {validationErrors?.map((err) => (
                        <li
                            className="p-3 mt-3 border border-alto"
                            key={err.error.message + err.dataPath}
                        >
                            <div>
                                <b>{t('form-content:Path')}\</b>: {err.dataPath}
                            </div>
                            <div>
                                <b>{t('form-content:Message')}</b>:{' '}
                                {t(err.error.message)}
                            </div>
                        </li>
                    ))}
                </ul>
            </Drawer>
        </div>
    );
};

export default EditJson;
