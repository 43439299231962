import React from 'react';
import {
    Edit24,
    OverflowMenuVertical16,
    TrashCan24,
} from '@carbon/icons-react';
import Button from '@mercell/button-react';
import { DropdownMenu, DropdownMenuItem } from '@mercell/dropdown-menu-react';
import { TFunction } from 'react-i18next';
import { UserEntry } from '../../types/generated/usersResponseEntry';

interface TableDropDownProps {
    cell: any;
    t: TFunction<string, unknown>;
    handleDropDownShow: (e: any, cell: any) => void;
    setUser: React.Dispatch<React.SetStateAction<UserEntry>>;
    openModal: (userId: string) => void;
    setAccordionHeader: React.Dispatch<React.SetStateAction<string>>;
    onClickExpand: () => void;
    expanded: boolean;
}

const TableDropDown = (props: TableDropDownProps) => {
    const {
        cell,
        t,
        handleDropDownShow,
        setUser,
        openModal,
        setAccordionHeader,
        onClickExpand,
        expanded,
    } = props;
    const buttonRef = React.useRef<HTMLButtonElement>(null);

    return (
        <>
            <Button
                scheme="icon"
                className="rt-tr-group focus:ring-offset-0 text-minsk focus:bg-transparent"
                ref={buttonRef}
                style={{ boxShadow: 'none' }}
                iconSettings={{
                    Icon: OverflowMenuVertical16,
                }}
                onClick={(e) => handleDropDownShow(e, cell)}
                data-test="array-button"
            />
            {cell.row.original.showDropDown && (
                <DropdownMenu
                    id="dashboard-table-dropdown-menu"
                    anchorRef={buttonRef}
                    closeOnClick
                    onClose={(e) => handleDropDownShow(e, cell)}
                    popperOptions={{ placement: 'left-start' }}
                    className="p-4 !min-w-[200px]"
                >
                    <div className="flex items-center justify-center px-2">
                        <Edit24 className="text-minsk" />
                        <DropdownMenuItem
                            onClick={() => {
                                setAccordionHeader('EditUser');
                                setUser(cell.row.original);

                                if (!expanded) {
                                    onClickExpand();
                                }
                            }}
                            className="hover:bg-transparent hover:text-minsk focus:ring-offset-0  active:bg-transparent"
                            data-test="array-item-edit-button"
                        >
                            {t('user-management:EditUser')}
                        </DropdownMenuItem>
                    </div>
                    <div className="flex items-center justify-center px-2">
                        <TrashCan24 className="text-minsk" />
                        <DropdownMenuItem
                            onClick={() => openModal(cell.row.original.id)}
                            className="hover:bg-transparent hover:text-minsk focus:ring-offset-0  active:bg-transparent"
                            data-test="array-item-edit-button"
                        >
                            {t('user-management:DeleteUser')}
                        </DropdownMenuItem>
                    </div>
                </DropdownMenu>
            )}
        </>
    );
};

export default TableDropDown;
