import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';
import { FormValidationError } from '../../types/generated/formValidationError';

const fetcher = (url: string) =>
    axios
        .get(url)
        .then(async (res: AxiosResponse<FormValidationError[]>) => res.data);

const useFetchValidation = (
    formId: string,
    includeSlowSchematron: boolean,
    onlySchematron: boolean
) => {
    const { data, error } = useSWRImmutable(
        `rep/form/validate/${formId}/${includeSlowSchematron}/${onlySchematron}`,
        fetcher,
        { shouldRetryOnError: false }
    );

    return {
        validation: data,
        isLoadingValidation: !data && !error,
        isErrorFetchingValidation: error,
    };
};

export default useFetchValidation;
