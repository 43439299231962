import { TFunction } from 'react-i18next';

interface ErrorObject {
    prefix: string;
    translationKey: string;
    params: string[];
}
export const recursiveAddTranslationToErrors = (
    schema: Record<string, any>,
    t: TFunction<string, unknown>
) => {
    for (const key in schema) {
        if (!schema[key]) {
            continue;
        }
        if (schema[key]?._errors) {
            schema[key] = translate(schema[key], t);
        }
        if (Array.isArray(schema[key])) {
            schema[key].forEach((element: any) => {
                if (element._errors) {
                    element = translate(element, t);
                }
                if (element.constructor.toString().indexOf(Object) !== -1) {
                    recursiveAddTranslationToErrors(element, t);
                }
            });
        }
        if (schema[key]?.constructor.toString().indexOf(Object) !== -1) {
            recursiveAddTranslationToErrors(schema[key], t);
        }
    }
};

export const translate = (el: any, t: TFunction<string, unknown>) =>
    el._errors.map(
        (errorObject: ErrorObject) =>
            `${t(
                `${errorObject.prefix ?? 'form-content'}:${
                    errorObject.translationKey
                }`,
                errorObject.params?.reduce(
                    (acc, next, index) => ({
                        ...acc,
                        [`${index + 1}`]: t(next),
                    }),
                    {}
                )
            )}`
    );
