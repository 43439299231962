import axios from 'axios';

export const saveFormStatus = (
    formId: string,
    status: string,
    toastText?: {
        onSuccessText?: string;
        onPendingText?: string;
    }
) =>
    axios
        .put(
            `testapp/form/${formId}/status/${status}`,
            {},
            {
                headers: {
                    'Toast-Pending-Text': toastText?.onPendingText,
                    'Toast-Success-Text': toastText?.onSuccessText,
                },
            }
        )
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
