import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';
import { PublicationSiteConfiguration } from '../../types/generated/publicationSiteConfiguration';

const fetcher = (url: string) =>
    axios
        .get(url)
        .then(
            async (
                res: AxiosResponse
            ): Promise<PublicationSiteConfiguration[]> => {
                const { data } = res;
                return data;
            }
        );

const useFetchPublicationSiteConfigurations = () => {
    const { data, error, mutate } = useSWRImmutable(
        `/configurator/publication-site-configurations`,
        fetcher,
        { shouldRetryOnError: false }
    );

    return {
        publicationSiteConfigurations: data,
        isLoadingPublicationSiteConfigurations: !error && !data,
        isErrorPublicationSiteConfigurations: error,
        fetchPublicationSiteConfigurations: mutate,
    };
};

export default useFetchPublicationSiteConfigurations;
