import axios from 'axios';
import {
    getAdminToken,
    getRepToken,
} from '../shared/storageService/cookieService';

export const translateForm = (
    formId?: string,
    toastText?: {
        onSuccessText?: string;
        onPendingText?: string;
    },
    setIsErrorTranslating?: any,
    isNoticeEditor = false
) =>
    axios
        .post(
            `/rep/form/translate/${formId}`,
            {},
            {
                headers: {
                    'Toast-Pending-Text': toastText?.onPendingText,
                    'Toast-Success-Text': toastText?.onSuccessText,
                    SkipTokenHelper: true,
                    SkipToast: !!isNoticeEditor,
                    Authorization: `Bearer ${
                        isNoticeEditor ? getRepToken() : getAdminToken()
                    }`,
                },
            }
        )
        .then((res) => res.data)
        .catch((error) => {
            if (setIsErrorTranslating) {
                setIsErrorTranslating(error);
            }
            throw error;
        });
