import axios from 'axios';
import { ConfiguratorVersion } from '../../types/generated/configuratorVersion';

const saveVersion = async (payload: ConfiguratorVersion) =>
    axios
        .post('configurator/version/save', payload)
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });

export default saveVersion;
