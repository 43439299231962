import axios from 'axios';

const finalizeCodelist = async (codelistId: string) =>
    axios
        .get(`/configurator/codelist/${codelistId}/finalize`)
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });

export default finalizeCodelist;
