import { useEffect, FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getRepTokenFromApi } from '../authorization/getAuthFromApi';
import { useStoreContext } from '../state';
import roles from '../state/roles';
import i18n from 'i18next';
import { useAppSelector } from '../redux/hooks';

const OtpComponent: FC = () => {
    const { otp, option, locale } =
        useParams<{ otp: string; option: string; locale: string }>();
    const navigate = useNavigate();
    const {
        state: { userRole },
    } = useStoreContext();

    const reduxUserRole = useAppSelector((state) => state.store.userRole);

    useEffect(() => {
        (async () => {
            i18n?.changeLanguage(locale);
            const url = await getRepTokenFromApi(otp ?? '', option);
            if (!url) {
                navigate('/page-not-found', { replace: true });
            } else if (reduxUserRole === roles.admin) {
                window.open(url, '/login', '_base')?.focus();
                navigate(-1);
            } else {
                navigate(url, { replace: true });
            }
        })();
    }, [otp, option, navigate, userRole, locale, reduxUserRole]);

    return null;
};

export default OtpComponent;
