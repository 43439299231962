/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Dispatch, FC, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';

import { TFunction } from 'react-i18next';
import { ErrorObjectWithPaths } from '../../../types/validationError';
import Checkbox from '@mercell/checkbox-react';
import { getFormAdditionalLanguages } from '../../../shared/storageService/sessionService';

interface ValidationCheckboxInterface extends ErrorObjectWithPaths {
    t: TFunction<string, unknown>;
    setFixedErrorList: Dispatch<SetStateAction<string[]>>;
    checked: boolean;
}

export const ValidationCheckbox: FC<ValidationCheckboxInterface> = ({
    urlPath,
    fieldPath,
    mainSectionName,
    arrayOfNames,
    nestedLevelSectionName,
    languageOfField,
    languageCode,
    fieldLabel,
    checked,
    setFixedErrorList,
    t,
}) => {
    const navigate = useNavigate();
    const additionalLanguages = getFormAdditionalLanguages();

    const sectionPath = [t(`form-content:${mainSectionName}`)];
    if (arrayOfNames.length === 2) {
        sectionPath.push(
            arrayOfNames[0],
            t(`form-content:${nestedLevelSectionName ?? ''}`),
            arrayOfNames[1],
            fieldLabel
        );
    } else if (arrayOfNames.length === 1 && nestedLevelSectionName) {
        sectionPath.push(arrayOfNames[0], nestedLevelSectionName, fieldLabel);
    } else if (arrayOfNames.length === 1) {
        sectionPath.push(arrayOfNames[0], fieldLabel);
    } else {
        sectionPath.push(fieldLabel);
    }
    if (languageOfField) {
        sectionPath.splice(sectionPath.length - 1, 0, languageOfField);
    }
    const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        if (e.target.checked === false) {
            setFixedErrorList((currentList) =>
                currentList.filter(
                    (element) => element !== `${urlPath}_${fieldPath}`
                )
            );
        } else {
            setFixedErrorList((currentList) => [
                ...currentList,
                `${urlPath}_${fieldPath}`,
            ]);
        }
    };
    const navigateToField = () => {
        setFixedErrorList((currentList) => {
            if (!currentList.includes(`${urlPath}_${fieldPath}`)) {
                return [...currentList, `${urlPath}_${fieldPath}`];
            }

            return [...currentList];
        });
        navigate(
            `${urlPath}?clicked=${fieldPath}${
                languageOfField ? `&language=${languageOfField}` : ''
            }`
        );
    };

    return (
        <div className="my-[32px] flex items-start">
            <Checkbox
                labelProps={{
                    className: 'gap-[10px] mt-[4px] ml-1 ',
                }}
                checked={checked}
                onChange={(e) => {
                    onCheckboxChange(e);
                }}
            />
            {sectionPath.map((path, ix) => {
                if (ix === sectionPath.length - 1) {
                    return (
                        <div
                            key={`${path}_${ix}`}
                            className="whitespace-pre-wrap  break-words hover:cursor-pointer"
                        >
                            <span
                                onClick={navigateToField}
                                className="underline"
                            >
                                {path}
                            </span>
                            {languageCode &&
                                additionalLanguages &&
                                additionalLanguages.length > 0 && (
                                    <span className="p-1 ml-2 w-7 h-7 rounded-half border border-alto title-small text-dove bg-alto">
                                        {languageCode}
                                    </span>
                                )}
                        </div>
                    );
                }
                return <div key={`${path}_${ix}`} />;
            })}
        </div>
    );
};
