import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';

const { REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_DOMAIN } = process.env;
if (!REACT_APP_AUTH0_CLIENT_ID || !REACT_APP_AUTH0_DOMAIN)
    console.warn(
        'REACT_APP_AUTH0_DOMAIN and REACT_APP_AUTH0_CLIENT_ID is configured wrongly, make sure they are present'
    );

export const Auth0: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => (
    <Auth0Provider
        domain={REACT_APP_AUTH0_DOMAIN ?? ''}
        clientId={REACT_APP_AUTH0_CLIENT_ID ?? ''}
        redirectUri={window.location.origin}
    >
        {children}
    </Auth0Provider>
);

export default Auth0;
