import React, { FC, useMemo, useState } from 'react';
import {
    ErrorObjectWithPaths,
    ValidationErrorObject,
} from '../../../types/validationError';
import { TFunction, Trans } from 'react-i18next';
import { ValidationCheckboxList } from './ValidationCheckboxList';
import { InformationFilled24 } from '@carbon/icons-react';
import { useLocation, useParams } from 'react-router-dom';
import LoadingSpinner from '@mercell/loading-spinner-react';
import Button from '@mercell/button-react';
import cx from 'classnames';
import { ValidateResponse } from '../../../shared/validationModule/useValidate';
import { translateForm } from '../../../apiCalls/translateForm';
import { AnimatedSaveProgress } from '../../../components/AnimatedSaveProgress';
import { useAppSelector } from '../../../redux/hooks';

interface ValidationDrawerContentInterface {
    backendValidationErrors: ValidationErrorObject;
    t: TFunction<string, unknown>;
    errorKeys: string[];
    validateMethod: (isTranslate?: boolean) => Promise<ValidateResponse>;
    isLoadingFetchingValidations: boolean;
    isErrorFetchingValidations?: any;
    setShowMyChecklistBeacon: React.Dispatch<React.SetStateAction<boolean>>;
    showRevalidateMessage?: boolean;
    setShowRevalidateMessage: React.Dispatch<React.SetStateAction<boolean>>;
    featureFlagUseFooterChecklistDrawer?: boolean;
}

export const ValidationDrawerContent: FC<ValidationDrawerContentInterface> = ({
    backendValidationErrors,
    t,
    errorKeys,
    validateMethod,
    isLoadingFetchingValidations,
    isErrorFetchingValidations,
    setShowMyChecklistBeacon,
    showRevalidateMessage,
    setShowRevalidateMessage,
    featureFlagUseFooterChecklistDrawer,
}) => {
    const reduxAutoSave = useAppSelector((state) => state.store.autoSave);
    const reduxNoticeStatus = useAppSelector(
        (state) => state.store.noticeStatus
    );
    const { pathname } = useLocation();
    const [isShowErrorForSectionEnable, toggleShowErrors] =
        useState<boolean>(false);
    const [fixedErrorList, setFixedErrorList] = useState<string[]>([]);
    const onChangeHandler = () => toggleShowErrors((current) => !current);
    const { errorObjectWithPaths, isFormValidatedWithoutErrors } =
        backendValidationErrors;
    const [isErrorTranslating, setIsErrorTranslating] = useState<any>();

    const isTechnicalLot = !errorKeys.includes('LotsSection');

    const arrayOfErrorObjectWithPaths = useMemo(() => {
        setFixedErrorList([]);
        return errorKeys.reduce(
            (acc: ErrorObjectWithPaths[], key) => [
                ...acc,
                ...errorObjectWithPaths[key],
            ],
            []
        );
    }, [errorKeys, errorObjectWithPaths]);
    let errorListToShow;
    let sectionFixedErrorList;
    let sectionErrors;
    if (isShowErrorForSectionEnable) {
        errorListToShow = arrayOfErrorObjectWithPaths.filter((errorObject) => {
            const { urlPath } = errorObject;
            return urlPath === pathname;
        });
        sectionFixedErrorList = fixedErrorList.filter(
            (error) => error.split('_')[0] === pathname
        );
        sectionErrors = arrayOfErrorObjectWithPaths.filter(
            (error) => error.urlPath === pathname
        );
    } else {
        errorListToShow = arrayOfErrorObjectWithPaths;
    }

    let allErrorsChecked;
    if (sectionErrors && sectionFixedErrorList && sectionErrors.length > 0) {
        allErrorsChecked =
            sectionErrors.length === sectionFixedErrorList.length;
    } else {
        allErrorsChecked = !!(
            arrayOfErrorObjectWithPaths.length > 0 &&
            fixedErrorList.length === arrayOfErrorObjectWithPaths.length
        );
    }
    const { formId } = useParams();

    const isFormReadyForTranslation = reduxNoticeStatus?.value === 105;
    const isFormDraft = reduxNoticeStatus?.value === 0;

    return (
        <div
            className={cx(
                featureFlagUseFooterChecklistDrawer
                    ? `h-full flex flex-col min-h-0`
                    : 'h-[calc(100vh-150px)] flex flex-col'
            )}
        >
            <div
                className={cx(
                    'h-full flex flex-col justify-center items-center min-h-0'
                )}
            >
                {/* Loading Data */}
                {isLoadingFetchingValidations && (
                    <div className="flex flex-col justify-center items-center gap-y-12">
                        <LoadingSpinner />
                        {isLoadingFetchingValidations && (
                            <p className="text-studio font-semibold w-[70%] text-center">
                                {t('form-content:ValidatingNoticeHold')}
                            </p>
                        )}
                    </div>
                )}

                {/* Validation Call Error Awaiting General Exception Handling */}
                {((!isLoadingFetchingValidations &&
                    isErrorFetchingValidations) ||
                    isErrorTranslating) &&
                    !showRevalidateMessage && (
                        <div className="flex flex-col justify-center items-center overflow-auto">
                            <div className="p-10 m-6 lg:m-8 md:m-16 2xl:m-2">
                                <img
                                    src="/images/not-found.svg"
                                    className="w-2/3  m-auto mb-6"
                                    alt={t('error' ?? '')}
                                />
                                <p className="text-h2 font-semibold text-center pt-14">
                                    {t('form-content:ExperiencedError')}
                                </p>

                                <div className="text-body text-center text-h2 font-semibold text-center pt-14">
                                    <p>
                                        {isErrorFetchingValidations
                                            ? t(
                                                  `form-content:${isErrorFetchingValidations.data?.message}`
                                              )
                                            : t(
                                                  `form-content:${isErrorTranslating.data?.message}`
                                              )}
                                    </p>
                                    <p className="pt-4">
                                        {isErrorFetchingValidations
                                            ? t(
                                                  `form-content:${isErrorFetchingValidations.data?.errorId}`
                                              )
                                            : t(
                                                  `form-content:${isErrorTranslating.data?.errorId}`
                                              )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}

                {/* Revalidate Message */}
                {showRevalidateMessage && !isLoadingFetchingValidations && (
                    <div className="flex flex-col justify-center items-center overflow-auto">
                        <div className="p-10 m-6 lg:m-8 md:m-16 2xl:m-2">
                            <img
                                src="/images/communication.svg"
                                className="w-2/3  m-auto mb-6 hidden h-lg:block"
                                alt={t('revalidate' ?? '')}
                            />
                            <div className="text-body p-10 rounded-default text-center bg-athens">
                                <div className="pt-5">
                                    <Trans
                                        i18nKey={t(
                                            'form-content:RevalidateTooltip'
                                        )}
                                    />
                                </div>
                            </div>
                            <img
                                src="/images/arrow-down.svg"
                                className="m-auto pt-10 hidden h-lg:block"
                                alt={t('revalidate' ?? '')}
                            />
                        </div>
                    </div>
                )}

                {/* Form Validated */}
                {!isLoadingFetchingValidations &&
                    !isErrorFetchingValidations &&
                    isFormValidatedWithoutErrors &&
                    !showRevalidateMessage &&
                    !isErrorTranslating && (
                        <div className="flex flex-col justify-center items-center overflow-auto ">
                            <div className="p-10 m-6 lg:m-8 md:m-16 2xl:m-2">
                                <img
                                    src="/images/success.svg"
                                    className="w-2/3  m-auto mb-6"
                                    alt={t('success' ?? '')}
                                />
                                <p className="text-h2 font-semibold text-center pt-14">
                                    {isFormReadyForTranslation && !isFormDraft
                                        ? t(
                                              'form-content:NoticeReadyForTranslations'
                                          )
                                        : t('form-content:NoMoreTasks')}
                                </p>
                                <div className="pt-14">
                                    <p className="text-body text-center">
                                        {t('form-content:AllErrorsFixed')}{' '}
                                        <strong>
                                            {t(
                                                'form-content:AllErrorsFixedNote'
                                            )}
                                        </strong>
                                    </p>
                                </div>
                                <p className="text-body text-center pt-14">
                                    {isFormReadyForTranslation && !isFormDraft
                                        ? t(
                                              'form-content:NoticeCompleteGetTranslations'
                                          )
                                        : t(
                                              'form-content:AllErrorsFixedCloseTab'
                                          )}
                                </p>
                            </div>
                        </div>
                    )}

                {/* Pre-validate tooltip OR Validation Errors Checklist */}
                {!isLoadingFetchingValidations &&
                    !isErrorFetchingValidations &&
                    !showRevalidateMessage &&
                    !isFormValidatedWithoutErrors && (
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        <>
                            {arrayOfErrorObjectWithPaths.length === 0 &&
                            !isFormValidatedWithoutErrors ? (
                                <div className="flex flex-col justify-center items-center overflow-auto">
                                    <div className="p-10 m-6 lg:m-8 md:m-16 2xl:m-2">
                                        <img
                                            src="/images/validate-update.svg"
                                            className="w-2/3  m-auto mb-6 hidden h-lg:block"
                                            alt={t('success' ?? '')}
                                        />
                                        <div className="text-body p-10 rounded-default text-center bg-athens">
                                            <div>
                                                {t(
                                                    'form-content:ValidateTooltip'
                                                )}
                                            </div>
                                            <div className="pt-5">
                                                <Trans
                                                    i18nKey={t(
                                                        'form-content:ValidateTooltipButton'
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <img
                                            src="/images/arrow-down.svg"
                                            className="m-auto pt-10 hidden h-lg:block"
                                            alt={t('success' ?? '')}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <ValidationCheckboxList
                                    isTechnicalLot={isTechnicalLot}
                                    validateMethod={validateMethod}
                                    fixedErrorList={fixedErrorList}
                                    setFixedErrorList={setFixedErrorList}
                                    errorListToShow={errorListToShow}
                                    t={t}
                                    arrayOfErrorObjectWithPaths={
                                        arrayOfErrorObjectWithPaths
                                    }
                                    isShowErrorForSectionEnable={
                                        isShowErrorForSectionEnable
                                    }
                                    onChangeHandler={onChangeHandler}
                                    sectionFixedErrorList={
                                        sectionFixedErrorList
                                    }
                                    sectionErrors={sectionErrors}
                                    isLoadingFetchingValidations={
                                        isLoadingFetchingValidations
                                    }
                                />
                            )}
                        </>
                    )}

                {/* Validate Button footer  */}
                <div
                    className={cx(
                        'border-t border-b border-alto flex flex-col justify-center items-center min-h-0 w-full p-6 mt-auto',
                        isLoadingFetchingValidations
                            ? 'h-0 max-h-0 min-h-0 hidden'
                            : 'h-fit',
                        allErrorsChecked && 'bg-magnolia'
                    )}
                >
                    {allErrorsChecked && (
                        <div className="w-[83%] inline-flex items-center mb-4 hidden h-lg:inline-flex mt-4">
                            <InformationFilled24 className="mr-1 text-minsk" />
                            <p className="font-semibold">
                                {t('form-content:ValidateToUpdate')}
                            </p>
                        </div>
                    )}

                    {allErrorsChecked && (
                        <p className="text-center w-[70%] mb-6 hidden h-md:block">
                            {t('form-content:ValidateActionsDone')}
                        </p>
                    )}
                    {reduxAutoSave && reduxAutoSave.isLoading && (
                        <Button
                            scheme="primary"
                            disabled
                            className="min-w-[80%] mb-4 !min-h-[40px]"
                            iconSettings={{
                                iconSide: 'left',
                                Icon: AnimatedSaveProgress,
                            }}
                        >
                            {t('form-content:SavingChanges')}
                        </Button>
                    )}
                    {!reduxAutoSave.isLoading && (
                        <Button
                            scheme="primary"
                            className="min-w-[80%] mb-4 !min-h-[40px]"
                            onClick={() => {
                                if (
                                    isFormReadyForTranslation &&
                                    !isFormDraft &&
                                    isFormValidatedWithoutErrors
                                ) {
                                    translateForm(
                                        formId,
                                        undefined,
                                        setIsErrorTranslating,
                                        true
                                    ).then(() => {
                                        setShowMyChecklistBeacon(false);
                                        validateMethod();
                                    });
                                } else {
                                    setIsErrorTranslating(false);
                                    setShowMyChecklistBeacon(false);
                                    validateMethod();
                                }
                                setShowRevalidateMessage(false);
                            }}
                        >
                            {isFormReadyForTranslation &&
                            !isFormDraft &&
                            isFormValidatedWithoutErrors
                                ? t('form-content:GetTranslations')
                                : t('form-content:ValidateNoticeTip')}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};
