import React from 'react';
import OktaAuthWrapper from '../../authorization/OktaAuthWrapper';
import AdminAuthWrapper from '../../authorization/AdminAuthWrapper';
import UserManagementPage from './UserManagementPage';

const UserManagement = () => (
    <OktaAuthWrapper>
        <AdminAuthWrapper>
            <UserManagementPage />
        </AdminAuthWrapper>
    </OktaAuthWrapper>
);

export default UserManagement;
