import axios from 'axios';
import { UserEntry } from '../../types/generated/userEntry';

export const createEditUser = async (
    userEntry: UserEntry,
    toastText?: {
        onSuccessText?: string;
        onPendingText?: string;
    }
) =>
    axios
        .post(`/admin/users/user`, userEntry, {
            headers: {
                'Toast-Pending-Text': toastText?.onPendingText,
                'Toast-Success-Text': toastText?.onSuccessText,
            },
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
