import React from 'react';
import { useTranslation } from 'react-i18next';
import useFetchValidation from '../../../hooks/administrativeHooks/useFetchValidation';
import { Modal, ModalContent, ModalTitle } from '@mercell/modal-react';
import ValidationFullComponent from '../../../components/ValidationFullComponent';

const ValidationFull = ({
    formId,
    isValidateFullVisible,
    closeModal,
}: {
    formId: string;
    isValidateFullVisible: boolean;
    closeModal: () => void;
}) => {
    const { validation, isErrorFetchingValidation, isLoadingValidation } =
        useFetchValidation(formId ?? '', true, false);
    const { t } = useTranslation(['rules', 'form-content']);

    return (
        <Modal
            isModalVisible={isValidateFullVisible}
            onCloseCallback={closeModal}
            shouldCloseOnOutsideClick
            parentClassName="parentClassName"
            className="max-w-4xl overflow-hidden"
        >
            <ModalTitle>{t('dashboard:ValidationErrors')}</ModalTitle>
            <ModalContent className="overflow-auto min-h-[300px] flex flex-col justify-center">
                <ValidationFullComponent
                    isLoadingValidation={isLoadingValidation}
                    isErrorFetchingValidation={isErrorFetchingValidation}
                    validation={validation}
                />
            </ModalContent>
        </Modal>
    );
};

export default ValidationFull;
