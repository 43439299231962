/* istanbul ignore file */
import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';
import { ConfiguratorFormDefinition } from '../../types/generated/configuratorFormDefinition';

const fetcher = (url: string) =>
    axios
        .get(url)
        .then(
            async (res: AxiosResponse): Promise<ConfiguratorFormDefinition> =>
                res.data
        );

const useFetchFormDefinition = (definitionId: string, versionId: string) => {
    const { data, error } = useSWRImmutable(
        !definitionId || !versionId
            ? null
            : `configurator/definition/${versionId}/bts/${definitionId}`,
        fetcher,
        { shouldRetryOnError: false }
    );
    return {
        formDefinition: data,
        isLoadingFormDefinition: definitionId && !error && !data,
        isErrorFetchingFormDefinition: error,
    };
};
export default useFetchFormDefinition;
