import { Country } from '@mercell/language-selector-react';

const COUNTRIES: Country[] = [
    {
        name: 'Danmark',
        domain: 'dk',
        locale: 'da',
        language: 'Dansk',
        phonePrefix: '+ 45',
    },
    {
        name: 'Deutschland',
        domain: 'de',
        locale: 'de',
        language: 'Deutsch',
        phonePrefix: '+ 49',
    },
    {
        name: 'Great Britain',
        domain: 'gb',
        locale: 'en',
        language: 'English',
        phonePrefix: '+ 44',
    },
    {
        name: 'Lietuva',
        domain: 'lt',
        locale: 'lt',
        language: 'Lietuvių',
        phonePrefix: '+ 370',
    },
    {
        name: 'Nederland',
        domain: 'nl',
        locale: 'nl',
        language: 'Nederlands',
        phonePrefix: '+ 31',
    },
    {
        name: 'Norge (Bokmål)',
        domain: 'no',
        locale: 'nb',
        language: 'Norsk Bokmål',
        phonePrefix: '+ 47',
    },
    {
        name: 'Suomi',
        domain: 'fi',
        locale: 'fi',
        language: 'Suomi',
        phonePrefix: '+ 358',
    },
    {
        name: 'Sverige',
        domain: 'se',
        locale: 'sv',
        language: 'Svenska',
        phonePrefix: '+ 46',
    },
    {
        name: 'Sverige (Suomi)',
        domain: 'svfi',
        locale: 'sv-FI',
        language: 'Finlandssvenska',
        phonePrefix: '+ 358',
    },
    {
        name: 'United Kingdom',
        domain: 'uk',
        locale: 'en-GB',
        language: 'UK English',
        phonePrefix: '+ 44',
    },
];

export default COUNTRIES;
