import { Dispatch, SetStateAction, useEffect } from 'react';
import { ValidationErrorObject } from '../../types/validationError';
import { useSearchParams } from 'react-router-dom';

export const useLingualFieldSelectionMutator = (
    lingualFieldSelectionMutator: (...args: any[]) => any,
    setBackendValidationErrors: Dispatch<SetStateAction<ValidationErrorObject>>
) => {
    const [searchParams] = useSearchParams();
    const languageSearchParam = searchParams.get('language');
    useEffect(() => {
        if (languageSearchParam) {
            lingualFieldSelectionMutator(
                languageSearchParam,
                setBackendValidationErrors
            );
        }
    }, [
        setBackendValidationErrors,
        languageSearchParam,
        lingualFieldSelectionMutator,
    ]);
};
