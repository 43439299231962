import React, { FC, useEffect, useState } from 'react';
import Breadcrumbs from '@mercell/breadcrumbs-react';
import Button from '@mercell/button-react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useFetchFormDefinition from '../../hooks/configuratorHooks/useFetchFormDefinition';
import useFetchFormOtpUrl from '../../hooks/configuratorHooks/useFetchFormOtpUrl';
import OktaAuthWrapper from '../../authorization/OktaAuthWrapper';
import { Toggle } from '@mercell/toggle-react';
import NotFoundPage from '../NotFound/NotFoundPage';
import LoadingSpinner from '@mercell/loading-spinner-react';
import cx from 'classnames';

const PreviewPage: FC = () => {
    const [manyLotsEnabled, setManyLotsEnabled] = useState<boolean>(true);

    const { versionName, versionId, definitionId, country } = useParams<{
        versionName: string;
        versionId: string;
        definitionId: string;
        country: string;
    }>();
    const navigate = useNavigate();
    const {
        formDefinition,
        isLoadingFormDefinition,
        isErrorFetchingFormDefinition,
    } = useFetchFormDefinition(definitionId ?? '', versionId ?? '');
    const { t } = useTranslation([
        'form-configurator',
        'list.country',
        'list.legal-basis',
        'list.form-type',
        'list.notice-type',
        'toast-content',
    ]);
    const { otpUrl, setStatePreviewUrl } = useFetchFormOtpUrl(
        versionId ?? '',
        definitionId ?? '',
        country ?? '',
        manyLotsEnabled
    );

    useEffect(
        () => () => {
            setStatePreviewUrl(undefined);
        },
        [setStatePreviewUrl, manyLotsEnabled]
    );

    if (isErrorFetchingFormDefinition)
        return (
            <NotFoundPage errorCode={isErrorFetchingFormDefinition.status} />
        );

    return (
        <OktaAuthWrapper>
            {isLoadingFormDefinition && !otpUrl ? (
                <div className="col-span-full h-full flex items-center justify-center">
                    <LoadingSpinner />
                </div>
            ) : (
                <div className={cx('col-span-full grid-rows-[1fr]')}>
                    <div className="col-span-full flex justify-center my-6">
                        <Breadcrumbs>
                            <Link to="/configurator">Form configurator</Link>
                            <Link
                                to={`/configurator/${versionName}/${versionId}/notices${
                                    country ? `/${country}` : ''
                                }`}
                            >
                                {versionName} {t(country ?? '')}
                            </Link>
                            <Link
                                to={`/configurator/${versionName}/${versionId}/notices/definitionid/${definitionId}${
                                    country ? `/${country}` : ''
                                }`}
                            >
                                {formDefinition?.noticeSubtype}{' '}
                                {t(formDefinition?.directiveKey ?? '')}{' '}
                                {t(formDefinition?.formTypeKey ?? '')}
                            </Link>
                            <Link
                                to={window.location.pathname}
                                className="pointer-events-none"
                            >
                                {t('Preview')}
                            </Link>
                        </Breadcrumbs>
                        <div>
                            <Toggle
                                offLabel={t('form-configurator:ShowLots')}
                                onLabel={t('form-configurator:HideLots')}
                                id="lots-enabled-configurator"
                                checked={manyLotsEnabled}
                                onChange={() => {
                                    setManyLotsEnabled(!manyLotsEnabled);
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-span-full">
                        <iframe
                            src={otpUrl}
                            title="form-preview"
                            id="form-preview"
                            className="w-full ring-1"
                            style={{
                                height: '90vh',
                            }}
                        />
                        <div className="flex justify-end mr-6 my-12">
                            <Button
                                scheme="secondary"
                                onClick={() =>
                                    navigate(
                                        `/configurator/${versionName}/${versionId}/notices/definitionid/${definitionId}${
                                            country ? `/${country}` : ''
                                        }`
                                    )
                                }
                            >
                                {t('form-configurator:Back')}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </OktaAuthWrapper>
    );
};

export default PreviewPage;
