import axios from 'axios';

export const saveFormJson = async (
    formId: string,
    json: { [key: string]: any },
    lang: string,
    toastText?: {
        onSuccessText?: string;
        onPendingText?: string;
    }
) => {
    const errors = await axios
        .post(`/rep/form/update/${formId}/${lang}`, json, {
            headers: {
                'Toast-Pending-Text': toastText?.onPendingText,
                'Toast-Success-Text': toastText?.onSuccessText,
                SkipToast: true,
            },
        })
        .then((res) => res.data.errors)
        .catch((e) => [
            {
                dataPath: window.location.host,
                error: {
                    message: e,
                    params: [''],
                },
            },
        ]);
    return errors;
};
