import Button from '@mercell/button-react';
import { useTranslation } from 'react-i18next';
import {
    Add32,
    ArrowDown24,
    ArrowDown32,
    ArrowRight20,
    Information16,
    InformationFilled24,
    Search24,
} from '@carbon/icons-react';
import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react';
import useFetchArray from '../../hooks/formHooks/useFetchArray';
import {
    Link,
    useNavigate,
    useOutletContext,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import ArrayElementAccordion from './ArrayElementAccordion';
import { Pagination } from '@mercell/pagination-react';
import { FormArrayItem } from '../../types/generated/formArrayItem';
import FormError from '../../components/FormError';
import { getFormLanguage } from '../../shared/storageService/sessionService';
import { getStyleClassesForDesignType } from '../../shared/getStyleClasses';
import { UIDesignElementType } from '../../types/generated/formDefinitionItem';
import { fetchNewFormPath } from '../../apiCalls/fetchNewFormPath';
import { Input, InputContainer, InputLeftElement } from '@mercell/input-react';
import NotFoundPage from '../NotFound/NotFoundPage';
import ConfirmationModal from '../../components/ConfirmationModal';
import { useStoreContext } from '../../state';
import LoadingSpinner from '@mercell/loading-spinner-react';
import { OutletFormContextInterface } from '../../types/outletInterface';
import Breadcrumbs from '@mercell/breadcrumbs-react';
import { ValidationErrorObject } from '../../types/validationError';
import FieldError from '@mercell/field-error-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { nl2br } from '../../shared/utilities/nl2br';
import { Card, CardText } from '@mercell/card-react';
import Checkbox from '@mercell/checkbox-react';
import { copyLot, deleteArrayList } from '../../apiCalls/arrayList';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setTenderName } from '../../redux/storeSlice';
import {
    Accordion,
    AccordionContent,
    AccordionHeader,
} from '@mercell/accordion-react';
import { useExpandedState } from '@mercell/use-expanded-state-react-hook';
import cx from 'classnames';
import Tooltip from '@mercell/tooltip-react';
import Ribbon from '@mercell/ribbon-react';

const PageSize = 5;

const ArrayList: FC = memo(() => {
    const {
        state: { tenderName: tenderNameState },
        dispatch,
    } = useStoreContext();
    const reduxTenderName = useAppSelector((state) => state.store.tenderName);
    const reduxDispatch = useAppDispatch();

    const { copyLots, mef2216 } = useFlags();

    const {
        isFormPreview,
        currentSelectedSection = {
            elementTitleKey: '',
            labelAppend: '',
            translationKey: '',
            hasSubmenu: false,
            labelRemove: '',
            introductionKey: '',
        },
        navigationTitle,
        parentElementTitleKey,
        backendValidationErrors,
    } = useOutletContext<OutletFormContextInterface>();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isCopyLotVisible, setIsCopyLotVisible] = useState<boolean>(false);
    const [arrayElement, setArrayElement] = useState<FormArrayItem>();
    const [lotElement, setLotElement] = useState<FormArrayItem>();
    const [lotUniqueIds, setLotUniqueIds] = useState<string[]>([]);
    const [lotFilter, setLotFilter] = useState<string>('');
    const [
        displayCopyLotConfirmationModel,
        setDisplayCopyLotConfirmationModel,
    ] = useState(false);

    const {
        formId,
        sectionId,
        parentSectionId,
        parentArrayPath,
        parentArrayElementId,
        arrayElementId,
        arrayPath = '',
    } = useParams<{
        formId: string;
        sectionId: string;
        parentSectionId?: string;
        parentArrayPath?: string;
        arrayElementId?: string;
        parentArrayElementId?: string;
        arrayPath?: string;
    }>();
    const { hasSubmenu = false } = currentSelectedSection;
    const isExpandable = !hasSubmenu;
    const sectionPath = !parentArrayElementId
        ? sectionId
        : `${parentArrayElementId}.${sectionId}`;
    const [searchParams] = useSearchParams();
    const [deleteError, setDeletionError] = useState<string>();
    const pageNumber = Number(searchParams.get('pageNo') || 1);
    const search = searchParams.get('search') || '';
    const {
        formData,
        isErrorFetchingArrayElements,
        isLoadingArray,
        tenderName,
        isAddingDisabled,
        isCopyLotAllowed,
        setState,
    } = useFetchArray(formId, sectionPath, arrayPath);
    const [filter, setFilter] = useState<string>(search ?? '');
    const { t } = useTranslation(['list.error', 'form-content', 'rules']);
    const { errorObjectWithPaths } = backendValidationErrors;
    const [sectionErrors, setSectionErrors] = useState<string[]>([]);
    const language = getFormLanguage();
    const navigate = useNavigate();
    let arrayElementTitle;
    if (arrayElementId && arrayElementId !== 'add') {
        arrayElementTitle = `${t('form-content:Edit')} ${t(
            currentSelectedSection.elementTitleKey ?? ''
        ).toLowerCase()}`;
    } else if (arrayElementId === 'add') {
        arrayElementTitle = t(currentSelectedSection?.labelAppend ?? '');
    }
    const onDelete = (element: FormArrayItem) => {
        setArrayElement(element);
        setIsModalVisible(true);
    };

    const onConfirmDelete = async () => {
        if (arrayElement && formId && sectionId) {
            const onPendingText = t('toast-content:ToastDeletedPending', {
                elementName: getName(arrayElement, t),
            });
            const onSuccessText = t('toast-content:ToastDeletedPending', {
                elementName: getName(arrayElement, t),
            });

            await deleteArrayList(
                formId,
                language,
                sectionPath,
                arrayPath,
                arrayElement,
                {
                    onPendingText,
                    onSuccessText,
                }
            ).catch((e: any) => {
                if (e.data?.message) {
                    if (
                        e.data?.message === 'ERROR_OCCURRED_CALL_SUPPORT' &&
                        e.data?.errorId
                    ) {
                        setDeletionError(
                            `${t(`form-content:${e?.data?.message}`)} ${
                                e.data.errorId
                            }`
                        );
                    } else {
                        setDeletionError(t(`form-content:${e?.data?.message}`));
                    }
                } else {
                    setDeletionError(t(`list.error:${e.status.toString()}`));
                }
            });
            setState(undefined, true);
        }

        setIsModalVisible(false);
    };
    const getName = (element: FormArrayItem, transtlation: any) =>
        element.name && element.name !== ''
            ? element.name
            : transtlation('form-content:Untitled');
    const filteredArray =
        formData?.array?.filter(
            (element) =>
                (filter ?? '') === '' ||
                getName(element, t).toLowerCase().includes(filter.toLowerCase())
        ) || [];

    const formDataArrayLength = filteredArray.length || 0;

    const filteredLotArray =
        filteredArray
            .filter((element) => element.uniqueId !== lotElement?.uniqueId)
            .filter(
                (element) =>
                    (lotFilter ?? '') === '' ||
                    getName(element, t)
                        .toLowerCase()
                        .includes(lotFilter.toLowerCase())
            ) || [];

    const filteredLotArrayLength = filteredLotArray.length || 0;

    useEffect(() => {
        if (
            formDataArrayLength &&
            pageNumber > Math.ceil(formDataArrayLength / PageSize)
        ) {
            navigate(
                `${
                    !parentSectionId
                        ? `/form/${formId}`
                        : `/form/${formId}/sub/${parentSectionId}/${parentArrayPath}/${parentArrayElementId}`
                }/${sectionId}/${arrayPath}?pageNo=${pageNumber - 1}${
                    filter ? `&search=${filter}` : ''
                }`
            );
        }
    }, [
        formDataArrayLength,
        pageNumber,
        navigate,
        formId,
        sectionId,
        filter,
        parentSectionId,
        parentArrayPath,
        parentArrayElementId,
        arrayPath,
    ]);

    useEffect(() => {
        if (tenderName && reduxTenderName !== tenderName) {
            reduxDispatch(setTenderName(tenderName ?? ''));
        }
    }, [dispatch, tenderNameState, tenderName, reduxTenderName, reduxDispatch]);

    useEffect(() => {
        if (!search) {
            setFilter('');
        }
    }, [search, sectionId, parentSectionId]);

    useEffect(() => {
        // section backend-end errors
        const sectionObject =
            errorObjectWithPaths[
                (parentSectionId ?? sectionId) as keyof ValidationErrorObject
            ];
        const sectionBackendErrors =
            sectionObject?.find(
                (item) => item.fieldPath === `${sectionId}.${arrayPath}`
            )?.fieldErrors ?? [];

        const allSectionErrors = [...sectionBackendErrors];
        const uniqueSectionErrors = Array.from(new Set(allSectionErrors));
        setSectionErrors(uniqueSectionErrors);
    }, [errorObjectWithPaths, arrayPath, sectionId, t, parentSectionId]);

    if (!formId) return null;
    if (isLoadingArray) {
        return (
            <div className="col-span-full h-full flex items-center justify-center">
                <LoadingSpinner />
            </div>
        );
    }
    if (isErrorFetchingArrayElements)
        return (
            <NotFoundPage
                errorCode={isErrorFetchingArrayElements.status.toString()}
            />
        );
    if (!formData) {
        return <div>No organizations in DB</div>;
    }

    const subSection = getStyleClassesForDesignType(
        UIDesignElementType.Subsection
    );

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) =>
        setFilter(event.target.value);

    const redirectToNewElement = async () => {
        let newElement;
        try {
            newElement = await fetchNewFormPath(
                formId,
                sectionPath ? `${sectionPath}.${arrayPath}` : ''
            );
        } catch (e) {
            alert(e);
        }

        if (newElement) {
            if (!parentSectionId) {
                if (hasSubmenu)
                    navigate(
                        `/form/${formId}/sub/${sectionId}/${arrayPath}/${
                            newElement.sectionPath
                        }${
                            pageNumber.toString()
                                ? `?pageRef=${pageNumber.toString()}`
                                : ''
                        }`
                    );
                else {
                    navigate(
                        `/form/${formId}/${sectionId}/${arrayPath}/${
                            newElement.uniqueId
                        }${
                            pageNumber.toString()
                                ? `?pageRef=${pageNumber.toString()}`
                                : ''
                        }`
                    );
                }
            } else
                navigate(
                    `/form/${formId}/sub/${parentSectionId}/${parentArrayPath}/${parentArrayElementId}/${sectionId}/${arrayPath}/${
                        newElement.uniqueId
                    }${
                        pageNumber.toString()
                            ? `?pageRef=${pageNumber.toString()}`
                            : ''
                    }`
                );
        }
    };

    const getEditUrl = (element: FormArrayItem) => {
        const urlParams = pageNumber.toString()
            ? `?pageRef=${pageNumber.toString()}${
                  filter ? `&search=${filter}` : ''
              }`
            : `${filter ? `?search=${filter}` : ''}`;
        if (!hasSubmenu) {
            if (!parentSectionId) {
                return `/form/${formId}/${sectionId}/${arrayPath}/${element.uniqueId}${urlParams}`;
            }
            return `/form/${formId}/sub/${parentSectionId}/${parentArrayPath}/${parentArrayElementId}/${sectionId}/${arrayPath}/${element.uniqueId}${urlParams}`;
        }

        return `/form/${formId}/sub/${sectionId}/${arrayPath}/${element.definitionPath}${urlParams}`;
    };

    const baseUrl: string = !parentSectionId
        ? `/form/${formId}`
        : `/form/${formId}/sub/${parentSectionId}/${parentArrayPath}${
              parentArrayElementId ? `/${parentArrayElementId}` : ''
          }`;

    const onSelectAll = (isChecked: boolean) => {
        const allUniqueIds = filteredLotArray.map((ele) => ele.uniqueId);
        if (isChecked) {
            setLotUniqueIds([...(allUniqueIds ?? [])]);
        } else {
            setLotUniqueIds([]);
        }
    };

    const onLotCheked = (id: string, isChecked: boolean) => {
        let uniqueIds = lotUniqueIds;
        if (isChecked) {
            uniqueIds?.push(id);
        } else {
            uniqueIds = lotUniqueIds?.filter((ele) => ele !== id);
        }
        setLotUniqueIds([...(uniqueIds ?? [])]);
    };

    const handleLotSearch = (event: ChangeEvent<HTMLInputElement>) =>
        setLotFilter(event.target.value);

    const onCopyLot = async () => {
        if (formId && lotElement && lotElement.uniqueId && lotUniqueIds) {
            await copyLot(formId, lotElement, lotUniqueIds, {
                onPendingText: t('toast-content:ToastCopyLotPending'),
                onSuccessText: t('toast-content:ToastCopyLotSuccess'),
            });
            setState(undefined, true);
            setLotUniqueIds([]);
        }
        setIsCopyLotVisible(false);
        setDisplayCopyLotConfirmationModel(false);
    };

    const getSelectedlotNames = () =>
        filteredLotArray
            .filter((x) => lotUniqueIds.some((id) => id === x.uniqueId))
            .map((ele) => ele.name)
            .join(', ');

    return (
        <div>
            {parentSectionId ? (
                <>
                    <Breadcrumbs className="ml-4 mt-1 mb-4">
                        {parentElementTitleKey ? (
                            <Link
                                to={`/form/${formId}/${parentSectionId}/${parentArrayPath}`}
                            >
                                {t(parentElementTitleKey ?? '')}
                            </Link>
                        ) : null}
                        <Link
                            to={`/form/${formId}/sub/${parentSectionId}/${parentArrayPath}/${parentArrayElementId}/${sectionId}/${arrayPath}`}
                        >
                            {t(currentSelectedSection?.translationKey ?? '')}
                        </Link>
                        {arrayElementTitle ? (
                            <Link
                                to={window.location.pathname}
                                className="pointer-events-none"
                            >
                                {arrayElementTitle}
                            </Link>
                        ) : null}
                    </Breadcrumbs>
                    <div className="flex items-center text-h2 ml-4 mb-4 mt-1 pb-3 border-alto border-b-2">
                        {navigationTitle ?? t('form-content:NewItem')}
                    </div>
                </>
            ) : null}
            {isModalVisible && arrayElement && (
                <ConfirmationModal
                    cancelText={t('form-content:LabelCancel')}
                    confirmText={t(
                        currentSelectedSection?.labelRemove ??
                            'form-content:LabelDelete'
                    )}
                    onConfirm={onConfirmDelete}
                    closeModal={() => setIsModalVisible(false)}
                    isModalVisible={isModalVisible}
                    title={t('form-content:ConfirmDelete')}
                >
                    <p className="text-center">
                        {t('form-content:ArrayItemDeleteConfirmation', {
                            elementName: getName(arrayElement, t),
                        })}
                    </p>
                </ConfirmationModal>
            )}
            {deleteError && (
                <FormError
                    error={deleteError}
                    key={`err-${deleteError}`}
                    className="mb-4"
                />
            )}
            <div className="flex items-center text-h1 pl-4">
                {t(currentSelectedSection?.translationKey ?? arrayPath)}
            </div>
            <p className="pt-8 text-body mb-1 pl-4 flex" id="Introduction">
                {currentSelectedSection?.introductionKey && (
                    <div className="mt-1 mr-1">
                        <Information16 />
                    </div>
                )}
                {t(currentSelectedSection?.introductionKey ?? '')}
            </p>
            {sectionErrors &&
                sectionErrors.map((sectionError: string) => (
                    <FieldError key={sectionError} className="pl-4">
                        {nl2br(sectionError)}
                    </FieldError>
                ))}
            <div className={subSection.sectionStyle}>
                <div className={subSection.labelStyle}>
                    {t(currentSelectedSection?.translationKey ?? arrayPath)}
                </div>
                <div className={subSection.divStyle}>
                    <div className="grid md:grid-cols-2 mt-4 ml-14">
                        <InputContainer>
                            <InputLeftElement>
                                <Search24 className="" />
                            </InputLeftElement>
                            <Input
                                placeholder={t('form-content:Search')}
                                onChange={handleSearch}
                                value={filter}
                            />
                        </InputContainer>
                        {(!isFormPreview ? !isAddingDisabled : false) && (
                            <Button
                                className="mt-6 md:mt-0 md:justify-self-end"
                                onClick={() => {
                                    redirectToNewElement();
                                }}
                                scheme="secondary"
                            >
                                <Add32 />
                                {t(
                                    currentSelectedSection?.labelAppend ??
                                        'form-content:LabelAppend'
                                )}
                            </Button>
                        )}
                    </div>
                    {formData &&
                        formData.array &&
                        formData.array.length > 0 &&
                        filter !== '' && (
                            <div className="ml-14 mt-4">
                                {t('form-content:Results')}{' '}
                                {formDataArrayLength}
                            </div>
                        )}
                    {filteredArray
                        .slice(
                            (pageNumber - 1) * PageSize,
                            pageNumber * PageSize
                        )
                        .map((element) => (
                            <div key={`acc-${element.uniqueId}`}>
                                <ArrayElementAccordion
                                    key={element.uniqueId}
                                    className="mt-6 ml-14"
                                    arrayElement={element}
                                    arrayPath={arrayPath}
                                    onDelete={() => onDelete(element)}
                                    onCopy={() => {
                                        setLotElement(element);
                                        setIsCopyLotVisible(true);
                                    }}
                                    goToUrl={getEditUrl(element)}
                                    isFormPreview={
                                        isFormPreview || element.isPreview
                                    }
                                    isExpandable={isExpandable}
                                    isCopyEnabled={
                                        sectionId === 'LotsSection' &&
                                        copyLots &&
                                        isCopyLotAllowed
                                    }
                                />
                            </div>
                        ))}
                    {filteredArray && filteredArray.length > 0 && (
                        <div className="flex justify-center">
                            <Pagination
                                currentPage={pageNumber}
                                onPageChange={(number) => {
                                    navigate(
                                        `${baseUrl}/${sectionId}/${arrayPath}?pageNo=${number}${
                                            filter ? `&search=${filter}` : ''
                                        }`
                                    );
                                }}
                                pageSize={PageSize}
                                totalCount={filteredArray.length}
                                className="mt-6 ml-14"
                            />
                        </div>
                    )}

                    <ConfirmationModal
                        closeModal={() => {
                            setIsCopyLotVisible(false);
                            setLotUniqueIds([]);
                        }}
                        isModalVisible={isCopyLotVisible && copyLots}
                        title={`${t('form-content:CopyLotDataTo')}`}
                        isDisabled={lotUniqueIds.length < 1}
                    >
                        <div className="mb-4">
                            <Accordion
                                id="test"
                                mountOnlyOnExpand
                                className="bg-foam"
                            >
                                <AccordionHeader
                                    className="group"
                                    role="button"
                                >
                                    <div className="flex items-center">
                                        <InformationFilled24
                                            className={cx(
                                                'max-w-[22px] max-h-[22px]',
                                                '[&>path[data-icon-path="inner-path"]]:fill-white [&>path[data-icon-path="inner-path"]]:opacity-100',
                                                'fill-matisse'
                                            )}
                                        />
                                        <p className="ml-4">
                                            {t('form-content:Disclaimer')}
                                        </p>
                                    </div>
                                </AccordionHeader>
                                <AccordionContent>
                                    <div>
                                        {t('form-content:CopyLotInfoBox')}
                                    </div>
                                </AccordionContent>
                            </Accordion>
                        </div>
                        <InputContainer className="mt-1 pr-2">
                            <InputLeftElement>
                                <Search24 className="" />
                            </InputLeftElement>
                            <Input
                                className="pl-10"
                                placeholder={t('form-content:Search')}
                                onChange={handleLotSearch}
                                value={lotFilter}
                            />
                        </InputContainer>
                        <div className="mt-2 pr-2 mb-3">
                            <Card className="flex p-3 hover:bg-[#F2F2F2]">
                                <Checkbox
                                    id="0"
                                    onChange={(event) =>
                                        onSelectAll(event.target.checked)
                                    }
                                    checked={
                                        lotUniqueIds.length > 0 &&
                                        filteredLotArrayLength ===
                                            lotUniqueIds?.length
                                    }
                                />
                                <CardText className="ml-2">
                                    {t('form-content:SelectAll')}
                                </CardText>
                            </Card>
                        </div>
                        <div className="flex justify-start items-center flex-col text-center p-4">
                            <p className="text-caption font-semibold">
                                {lotElement?.name}
                            </p>
                            <ArrowDown32 className="text-minsk mt-2" />
                        </div>
                        <div className="flex flex-col overflow-y-scroll max-h-[250px] my-2 mb-8 min-h-0 scroll-shadows">
                            {filteredLotArray.map((ele) => (
                                <div
                                    key={`copy_${ele.uniqueId}`}
                                    className="mb-2 pr-2"
                                >
                                    <Card className="flex p-3 font-semibold hover:bg-[#F2F2F2]">
                                        <Checkbox
                                            id={ele.uniqueId}
                                            onChange={(event) =>
                                                onLotCheked(
                                                    ele.uniqueId,
                                                    event.target.checked
                                                )
                                            }
                                            checked={lotUniqueIds?.includes(
                                                ele.uniqueId
                                            )}
                                        />
                                        <CardText className="ml-2">
                                            {ele.name}
                                        </CardText>
                                    </Card>
                                </div>
                            ))}
                        </div>
                        <div className="flex flex-row justify-end gap-x-2 items-center">
                            <Tooltip
                                message="Help us improve! Share your feedback to shape this feature"
                                className="mr-auto"
                            >
                                <Ribbon scheme="important" className="px-2">
                                    BETA
                                </Ribbon>
                            </Tooltip>
                            <Button
                                scheme="primary"
                                disabled={lotUniqueIds.length < 1}
                                onClick={() => {
                                    if (mef2216) {
                                        setIsCopyLotVisible(false);
                                        setDisplayCopyLotConfirmationModel(
                                            true
                                        );
                                    } else {
                                        onCopyLot();
                                    }
                                }}
                            >
                                {t('form-content:LabelCopy')}
                            </Button>
                            <Button
                                scheme="secondary"
                                onClick={() => {
                                    setIsCopyLotVisible(false);
                                    setLotUniqueIds([]);
                                }}
                            >
                                {t('form-content:LabelCancel')}
                            </Button>
                        </div>
                    </ConfirmationModal>
                    <ConfirmationModal
                        cancelText={t('form-content:LabelCancel')}
                        confirmText={t('form-content:LabelCopy')}
                        onConfirm={onCopyLot}
                        closeModal={() => {
                            setDisplayCopyLotConfirmationModel(false);
                            setIsCopyLotVisible(true);
                        }}
                        isModalVisible={displayCopyLotConfirmationModel}
                        title={t('form-content:ConfirmCopy')}
                    >
                        <p>
                            {t('form-content:CopyLotConfirmation', {
                                copyFrom: lotElement?.name,
                                copyTo: getSelectedlotNames(),
                            })}
                        </p>
                    </ConfirmationModal>
                </div>
            </div>
        </div>
    );
});

ArrayList.displayName = 'ArrayList';
ArrayList.whyDidYouRender = true;
export default ArrayList;
