import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

const fetcher = (url: string) =>
    axios.get(url).then(async (res: AxiosResponse<any>) => {
        const { data } = res;
        return data;
    });

const useFetchFamilyTree = (formId: string) => {
    const { data, error } = useSWRImmutable(
        `/testapp/familyTree/${formId}`,
        fetcher,
        { shouldRetryOnError: false }
    );

    return {
        familyTree: data,
        isLoadingFamilyTree: !error && !data,
        isErrorFetchingFamilyTree: error,
    };
};

export default useFetchFamilyTree;
