/* istanbul ignore file */
import axios, { AxiosError, AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';
import { LogsModel } from '../../types/generated/logsModel';
import { LogsModelSearch } from '../../types/generated/logsModelSearch';

export const getLogs = (paramData?: LogsModelSearch) =>
    axios
        .post(`testapp/logs`, { ...paramData })
        .then((res: AxiosResponse): Promise<LogsModel | any> => {
            const { data } = res;
            return data;
        })
        .catch((error: AxiosError) => {
            throw error;
        });
