import { ReadOnlyFieldStructure } from '@mercell/form-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PreviewElementStyle } from '../shared/getStyleClasses';
import { parseDefinitionType } from '../shared/parseDefinitionType';
import { prepareContent } from '../shared/prepareContent';
import { DefinitionElementType } from '../types/generated/formDefinitionBlockItem';
import { FieldLabel } from './FieldLabel';
import { useAppSelector } from '../redux/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ChangeValueItemDataInterface {
    Value: string;
    TranslationFileName: string;
    InitialFieldType: DefinitionElementType;
    IsMultiSelect?: boolean;
}

export const ChangeValueComponent = (props: any) => {
    let change: ChangeValueItemDataInterface = {
        Value: '',
        TranslationFileName: '',
        InitialFieldType: DefinitionElementType.Text,
    };

    change = props.input.value;

    const { t } = useTranslation(change.TranslationFileName ?? '');

    const reduxDateFormat = useAppSelector((state) => state.store.dateFormat);
    const { featureFlagUseUtcDatesOnly } = useFlags();

    const readOnlyProperties: ReadOnlyFieldStructure = {
        isSortable: true,
        isMultiSelect: change.IsMultiSelect,
        showAsLabel: false,
        translationFileName: change.TranslationFileName,
        options: [],
        type: 'readOnly',
        showWithTime: change.InitialFieldType === DefinitionElementType.Time,
    };

    const value = prepareContent(
        change.Value,
        parseDefinitionType(change.InitialFieldType),
        readOnlyProperties,
        '',
        t,
        reduxDateFormat,
        props.locale,
        featureFlagUseUtcDatesOnly,
        props.noticeTimeZone ?? undefined
    );

    return (
        <div className={props.fieldWrapperProperties.className}>
            <FieldLabel
                name={props.input.name}
                labelProperties={{ className: 'mb-2' }}
                content={
                    <span className={props.labelProperties}>
                        {t(props.label)}
                    </span>
                }
                tooltipWrapperProperties={{}}
                showRequiredIndicator={false}
                isFormPreview
            />
            <div className={PreviewElementStyle}>{value}</div>
        </div>
    );
};
