import { Dispatch, SetStateAction } from "react";

export async function scrollIntoView(
  field: HTMLElement,
  searchParams: URLSearchParams,
  setSearchParams: Dispatch<SetStateAction<URLSearchParams>>
) {
  let elementPosition =
    field.getBoundingClientRect().top + window.scrollY - 150;
  let tempNode;

  const fieldType = field.getAttribute("type");
  if (field.tagName !== "DIV") {
    field.focus();
  }

  if (fieldType === "hidden" && field) {
    const { tempNode: newNode, nodePosition } = await createNodeForHidden(
      field
    );
    elementPosition = nodePosition;
    tempNode = newNode;
  }

  window.scrollTo({
    top: elementPosition,
    behavior: "smooth",
  });

  tempNode?.remove();
  searchParams.delete("clicked");
  searchParams.delete("language");
  setSearchParams(searchParams);
}

async function createNodeForHidden(field: HTMLElement) {
  const tempNode = document.createElement("div");
  tempNode.setAttribute("id", "tempNode");
  field.before(tempNode);
  const nodePosition =
    tempNode.getBoundingClientRect().top + window.scrollY - 200;
  return { nodePosition, tempNode };
}
