import React from 'react';
import OktaAuthWrapper from '../../authorization/OktaAuthWrapper';
import AdminAuthWrapper from '../../authorization/AdminAuthWrapper';
import DashboardPage from './DashboardPage';

const Dashboard = () => (
    <OktaAuthWrapper>
        <AdminAuthWrapper>
            <DashboardPage />
        </AdminAuthWrapper>
    </OktaAuthWrapper>
);

export default Dashboard;
