import Cookies from 'js-cookie';
import React, { FC, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import {
    getCurrentFormId,
    setCurrentFormId,
} from '../shared/storageService/sessionService';
import { useStoreContext } from '../state';
import roles from '../state/roles';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setUserRole } from '../redux/storeSlice';

const ProtectedRepRoute: FC = () => {
    const location = useLocation();
    const { dispatch } = useStoreContext();

    const reduxDispatch = useAppDispatch();

    const reduxUserRole = useAppSelector((state) => state.store.userRole);

    useEffect(() => {
        if (!getCurrentFormId()) {
            const urlParams: string[] = location.pathname.split('/');
            const cookies = Cookies.get();

            urlParams.forEach((param) => {
                if (cookies[param]) {
                    setCurrentFormId(param);
                }
            });
        }
        if (getCurrentFormId()) {
            reduxDispatch(setUserRole(roles.rep));
        }
    }, [dispatch, location.pathname, reduxDispatch]);

    if (reduxUserRole === roles.unauthorized) return null;
    if (reduxUserRole !== roles.rep)
        return <Navigate to="/unauthorized" replace />;
    return <Outlet />;
};

export default ProtectedRepRoute;
