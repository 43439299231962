// / <reference types="@welldone-software/why-did-you-render" />

import whyDidYouRender from '@welldone-software/why-did-you-render';

import React from 'react';

if (process.env.DEV) {
    // const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
        trackAllPureComponents: true,
    });
}
