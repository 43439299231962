import React, { ChangeEvent, useEffect, useState } from 'react';
import { SelectOption } from '../../types/generated/selectOption';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@mercell/dropdown-react';
import Button from '@mercell/button-react';
import { useNavigate } from 'react-router-dom';
import useFetchCountries from '../../hooks/configuratorHooks/useFetchCountries';
import OktaAuthWrapper from '../../authorization/OktaAuthWrapper';
import useFetchVersions from '../../hooks/configuratorHooks/useFetchVersions';
import {
    Input,
    InputContainer,
    InputLeftAddon,
    InputRightAddon,
} from '@mercell/input-react';
import useFetchBaseVersions from '../../hooks/configuratorHooks/useFetchBaseVersions';
import saveVersion from '../../apiCalls/configurator/saveVersion';
import { ConfiguratorVersion } from '../../types/generated/configuratorVersion';
import NotFoundPage from '../NotFound/NotFoundPage';
import LoadingSpinner from '@mercell/loading-spinner-react';
import refreshAllConfigurations from '../../apiCalls/configurator/refreshAllConfigurations';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppSelector } from '../../redux/hooks';
import NoAccessPage from '../NotFound/NoAccessPage';
import ConfirmationModal from '../../components/ConfirmationModal';
import { unLockVersion } from '../../apiCalls/configurator/unLockVersion';
import { LockVersionEntity } from '../../types/generated/lockVersionEntity';
import { ConfiguratorCountry } from '../../types/generated/configuratorCountry';
import UseGetLockVersion from '../../apiCalls/configurator/useGetLockVersion';
import UseLockVersion from '../../apiCalls/configurator/useLockVersion';
import {
    Modal,
    ModalButtonsSection,
    ModalContent,
    ModalTitle,
} from '@mercell/modal-react';

const FormConfiguratorMainPage = () => {
    const [country, setCountry] = useState<SelectOption>();
    const [version, setVersion] = useState<SelectOption>();
    const [versionName, setVersionName] = useState<string>();
    const [baseVersion, setBaseVersion] = useState<SelectOption>();
    const [isVersionChanged, setIsVersionChanged] = useState<boolean>(false);
    const [customizationId, setCustomizationId] = useState<string>();
    const [xsdModel, setxsdModel] = useState<string>();
    const [uniqueId, setUniqueId] = useState<string>();

    const { mef1647, mef2069 } = useFlags();

    const navigate = useNavigate();
    const { countries, isErrorFetchingCountries } = useFetchCountries();
    const { versions, setStateVersions } = useFetchVersions(country?.value);
    const { baseVersions, setStateBaseVersions } = useFetchBaseVersions(
        country?.value
    );

    const [isVersionAccessible, setIsVersionAccessible] =
        useState<boolean>(false);
    const [isRemoveVersionLock, setIsRemoveVersionLock] =
        useState<boolean>(false);
    const [isisRemoveConfirmModalVisible, setIsRemoveConfirmModalVisible] =
        useState<boolean>(false);
    const [lockVersionEntity, setLockVersionEntity] =
        useState<LockVersionEntity>();

    const { t } = useTranslation([
        'form-configurator',
        'list.country',
        'list.legal-basis',
        'list.form-type',
        'list.notice-type',
        'toast-content',
    ]);

    const reduxUserRights = useAppSelector((state) => state.store.userRights);

    const onChangeCountry = async (newValue: SelectOption) => {
        setCountry(newValue);
        setVersion(undefined);
        setBaseVersion(undefined);
        setVersionName(undefined);
    };

    const onChangeVersion = (newValue: SelectOption) => {
        setVersion(newValue);
        setBaseVersion(undefined);
        const name = newValue?.label
            ?.replace(` (${t('form-configurator:Activated')})`, '')
            .replace(` (${t('form-configurator:InProgress')})`, '');
        setVersionName(newValue?.value ? name : undefined);
        if (newValue?.value) setDefaultBaseVersion(newValue.value);
        if (mef2069) getLockVersionCommonCode(newValue.value);
    };

    const setDefaultBaseVersion = (versionId: string) => {
        if (country) {
            const currentVersion = versions?.find((v) => v.id === versionId);
            const currentBaseVersion = baseVersions?.find(
                (bs) => bs.id === currentVersion?.baseVersionId
            );
            setBaseVersion({
                label: currentBaseVersion?.name,
                value: currentBaseVersion?.id,
            });
        }
    };

    const onChangeVersionName = (e: ChangeEvent<HTMLInputElement>) => {
        const name = e.currentTarget.value;
        setVersionName(name);

        if (name !== version?.label) {
            setIsVersionChanged(true);
        }
    };

    const onChangeBaseVersion = (newValue: SelectOption) => {
        setBaseVersion(newValue);
        setIsVersionChanged(true);
    };

    const onGoNext = async () => {
        if (mef2069) {
            if (!version?.value) {
                onGoNextCommonCode();
            } else {
                const lockVersionEntityData = await UseLockVersion(
                    version?.value
                );
                if (
                    lockVersionEntityData !== undefined &&
                    lockVersionEntityData !== null
                ) {
                    setLockVersionEntity(lockVersionEntityData);
                    setIsVersionAccessible(
                        !lockVersionEntityData?.isVersionAccessible
                    );
                }
                if (lockVersionEntityData?.isVersionAccessible) {
                    onGoNextCommonCode();
                }
            }
        } else {
            onGoNextCommonCode();
        }
    };

    const removeLockVersion = () => {
        setIsRemoveConfirmModalVisible(true);
    };

    const closeRemoveConfirmModal = () => {
        setIsRemoveConfirmModalVisible(false);
    };

    const onConfirm = async () => {
        setIsRemoveConfirmModalVisible(false);
        await unLockVersion(version?.value);
        await getLockVersionCommonCode(version?.value);
    };

    const onGoNextCommonCode = async () => {
        const payload: ConfiguratorVersion = {
            id: version?.value,
            name: versionName ?? 'untitled',
            country: country?.value,
            baseVersionId: baseVersion?.value,
            isFinalized: false,
            customizationId,
            xsdModel,
            uniqueId,
        };

        const versionId = isVersionChanged
            ? await saveVersion(payload)
            : payload.id;

        navigate(
            `/configurator/${versionName}/${versionId}/notices${
                country ? `/${country.value}` : ''
            }`
        );
    };

    const getLockVersionCommonCode = async (versionId: string | undefined) => {
        const listlockVersionsData = await UseGetLockVersion();
        const selectedVersionUid = versions?.find(
            (v) => v.id === versionId
        )?.uniqueId;
        setUniqueId(selectedVersionUid);
        if (
            listlockVersionsData !== undefined &&
            listlockVersionsData !== null
        ) {
            const isLockVersionExist = listlockVersionsData.find(
                (lockVer: any) => lockVer.versionUuid === selectedVersionUid
            );
            if (
                isLockVersionExist !== null &&
                isLockVersionExist !== undefined
            ) {
                setIsRemoveVersionLock(true);
            } else {
                setIsRemoveVersionLock(false);
            }
        }
    };

    const onRefreshAll = async () => {
        await refreshAllConfigurations({
            onPendingText: t(
                'toast-content:ToastRefreshingConfigurationsPending'
            ),
            onSuccessText: t(
                'toast-content:ToastRefreshingConfigurationsSuccess'
            ),
        });
    };

    const onChangeXsdModel = (e: ChangeEvent<HTMLInputElement>) => {
        setxsdModel(e.currentTarget.value);
    };
    const onChangeCustomizationId = (e: ChangeEvent<HTMLInputElement>) => {
        setCustomizationId(e.currentTarget.value);
    };

    useEffect(
        () => () => {
            setStateVersions(undefined);
            setStateBaseVersions(undefined);
        },
        [setStateVersions, setStateBaseVersions, country?.value]
    );

    useEffect(
        () => () => {
            localStorage.removeItem('listIndex');
        },
        []
    );
    const getSortedCountries = (countryArray: ConfiguratorCountry[]) => {
        countryArray.sort((a: ConfiguratorCountry, b: ConfiguratorCountry) => {
            if (t(a.key) < t(b.key)) return -1;
            if (t(a.key) > t(b.key)) return 1;
            return 0;
        });
        return countryArray;
    };

    if (isErrorFetchingCountries)
        return (
            <NotFoundPage
                wrapperClass="col-span-full"
                errorCode={isErrorFetchingCountries.status}
            />
        );

    if (reduxUserRights?.configuratorEnabled === false) {
        return <NoAccessPage />;
    }

    return (
        <OktaAuthWrapper>
            {!countries ? (
                <div className="col-span-full h-full flex items-center justify-center">
                    <LoadingSpinner />
                </div>
            ) : (
                <div className="col-span-full justify-self-center md:min-w-[600px] max-w-[600px] whitespace-nowrap flex flex-col xl:items-center">
                    {reduxUserRights?.importVersionEnabled === true && (
                        <div className="flex flex-col items-center">
                            <h2 className="mb-10">
                                {t('form-configurator:AdministrativeActivity')}
                            </h2>
                            <Button
                                className="mb-8"
                                scheme="secondary"
                                onClick={onRefreshAll}
                            >
                                {t('form-configurator:RefreshEverything')}
                            </Button>
                            <Button
                                scheme="secondary"
                                className="mb-8"
                                onClick={() => {
                                    window
                                        .open(
                                            '/configurator/version-overview',
                                            '_blank'
                                        )
                                        ?.focus();
                                }}
                            >
                                {t('form-configurator:VersionOverview')}
                            </Button>
                        </div>
                    )}
                    <h2 className="mb-10">
                        {t('form-configurator:ChooseCountryAndVersion')}
                    </h2>
                    <div className="mb-8 w-full">
                        <div className="mb-8">
                            <p>{t('list.country:Country')}</p>
                            <Dropdown
                                options={getSortedCountries(countries).map(
                                    (c) => ({
                                        value: c.code,
                                        label: t(c.key ?? ''),
                                    })
                                )}
                                isClearable
                                onChange={(newValue: unknown) =>
                                    onChangeCountry(newValue as SelectOption)
                                }
                            />
                        </div>
                        <div className="mb-8">
                            <p>{t('form-configurator:Version')}</p>
                            {versions ? (
                                <Dropdown
                                    options={[
                                        ...versions.map((v) => ({
                                            value: v.id,
                                            label: `${
                                                !v.id
                                                    ? t('New')
                                                    : v.name +
                                                      (v.isFinalized
                                                          ? ` (${t(
                                                                'form-configurator:Activated'
                                                            )})`
                                                          : '')
                                            }`,
                                        })),
                                    ]}
                                    isClearable
                                    value={version ?? ''}
                                    onChange={(newValue: unknown) =>
                                        onChangeVersion(
                                            newValue as SelectOption
                                        )
                                    }
                                />
                            ) : (
                                <div>
                                    {t('form-configurator:NoVersionsFound')}
                                </div>
                            )}
                        </div>
                        {version && (
                            <div className="mb-8">
                                <p>{t('form-configurator:VersionName')}</p>
                                <Input
                                    onChange={onChangeVersionName}
                                    value={versionName ?? ''}
                                />
                            </div>
                        )}
                        {versionName && country && (
                            <div className="mb-8">
                                <p>{t('form-configurator:BaseVersion')}</p>
                                <Dropdown
                                    options={baseVersions?.map((bv) => ({
                                        value: bv.id,
                                        label: bv.name,
                                    }))}
                                    isClearable
                                    value={baseVersion ?? ''}
                                    onChange={(newValue: unknown) =>
                                        onChangeBaseVersion(
                                            newValue as SelectOption
                                        )
                                    }
                                />
                            </div>
                        )}

                        {mef1647 && version && !version.value && (
                            <div>
                                <div className="mb-8">
                                    <p>{t('form-configurator:XSDModel')}</p>
                                    <InputContainer>
                                        <InputLeftAddon>
                                            MercellForms.
                                        </InputLeftAddon>
                                        <Input
                                            value={xsdModel ?? ''}
                                            placeholder="eForms_xx"
                                            onChange={onChangeXsdModel}
                                        />
                                        <InputRightAddon>
                                            .NoticeType
                                        </InputRightAddon>
                                    </InputContainer>
                                </div>
                                <div className="mb-8">
                                    <p>
                                        {t('form-configurator:CustomizationId')}
                                    </p>
                                    <Input
                                        value={customizationId ?? ''}
                                        onChange={onChangeCustomizationId}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {versionName && (
                        <div className="flex self-end">
                            {mef2069 && isRemoveVersionLock && (
                                <Button
                                    scheme="primary"
                                    onClick={removeLockVersion}
                                    style={{ marginRight: '5px' }}
                                >
                                    {t('form-configurator:RemoveLockVersion')}
                                </Button>
                            )}
                              <Button scheme="primary" onClick={onGoNext}>
                                {!version?.value
                                    ? t('form-configurator:CreateNewVersion')
                                    : t('form-configurator:EditVersion')}
                            </Button>
                        </div>
                    )}
                    {mef2069 && lockVersionEntity !== undefined && (
                        <div>
                            <Modal
                                isModalVisible={isVersionAccessible}
                                onCloseCallback={() =>
                                    setIsVersionAccessible(false)
                                }
                                shouldCloseOnOutsideClick
                                parentClassName="parentClassName"
                                className="max-w-2xl overflow-hidden"
                            >
                                <ModalTitle>
                                    {t('form-configurator:VersionLock')}
                                </ModalTitle>
                                <ModalContent>
                                    <p>
                                        {t(
                                            'form-configurator:LockedVersionInfo',
                                            {
                                                userId: lockVersionEntity?.userId,
                                                env: lockVersionEntity?.env,
                                            }
                                        )}
                                    </p>
                                </ModalContent>
                                <ModalButtonsSection className="flex justify-end">
                                    <Button
                                        scheme="secondary"
                                        className="mr-2"
                                        onClick={() =>
                                            setIsVersionAccessible(false)
                                        }
                                    >
                                        {t('form-configurator:Close')}
                                    </Button>
                                </ModalButtonsSection>
                            </Modal>
                        </div>
                    )}
                    {mef2069 && isisRemoveConfirmModalVisible && (
                        <ConfirmationModal
                            isModalVisible={isisRemoveConfirmModalVisible}
                            closeModal={closeRemoveConfirmModal}
                            onConfirm={onConfirm}
                            text={t(
                                'form-configurator:RemoveLockVersionComfirmationMessage'
                            )}
                            confirmText={t('form-configurator:Confirm')}
                            cancelText={t('form-configurator:Cancel')}
                        >
                            <img
                                src="/images/success.svg"
                                className="w-1/3  m-auto mb-6"
                                alt={t('')}
                            />
                        </ConfirmationModal>
                    )}
                </div>
            )}
        </OktaAuthWrapper>
    );
};

FormConfiguratorMainPage.whyDidYouRender = true;
export default FormConfiguratorMainPage;
