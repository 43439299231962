import axios, { AxiosError, AxiosResponse } from 'axios';
import { UsersResponseEntry } from '../../types/generated/usersResponseEntry';

export const getUserManagement = (paramData?: Record<string, any>) =>
    axios
        .post(`/admin/users`, { ...paramData })
        .then((res: AxiosResponse): Promise<UsersResponseEntry | any> => {
            const { data } = res;
            return data;
        })
        .catch((error: AxiosError) => {
            throw error;
        });
