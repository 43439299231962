import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';
import { ConfiguratorCodelist } from '../../types/generated/configuratorCodelist';

const fetcher = (url: string) =>
    axios.get(url).then(async (res: AxiosResponse<ConfiguratorCodelist[]>) => {
        const { data } = res;
        return data;
    });

const useFetchCodelists = (versionId: string) => {
    const { data, error, mutate } = useSWRImmutable(
        !versionId ? null : `/configurator/codelists/${versionId}`,
        fetcher,
        { shouldRetryOnError: false }
    );

    return {
        setStateCodelists: mutate,
        codelists: data,
        isFetchingCodelists: !data && !error,
        isErrorFetchingCodelists: error,
    };
};

export default useFetchCodelists;
