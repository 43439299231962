export function checkFieldExists(fieldName: string): boolean {
    const renderedForm = Array.from(document.forms);
    if (renderedForm.length) {
        const fieldExists = Array.prototype.slice
            .call(renderedForm[0])
            .filter((value) => value.name === fieldName);

        return fieldExists.length >= 1;
    }
    return false;
}
