import { Alert, AlertText } from '@mercell/alert-react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const InformationBoxComponent = (props: any) => {
    const change = props.input.value;
    const { t } = useTranslation('form-content');

    const placeholderValue = change ? t(change) : undefined;

    return (
        <Alert
            key={props.input.name}
            scheme="information"
            role="alert"
            className="mt-6 ml-14 print-field w-fit"
        >
            <AlertText>
                <Trans>
                    {t(props.label, { placeholder: placeholderValue })}
                </Trans>
            </AlertText>
        </Alert>
    );
};
