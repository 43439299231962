import Checkbox from '@mercell/checkbox-react';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigurationFieldVisibility } from '../../../types/generated/configuratorFormDefinition';

interface SingleFieldConfiguratorProps {
    field?: ConfigurationFieldVisibility;
    mutateField: (field: ConfigurationFieldVisibility) => void;
}
const mandatory = 'M';
const hidden = 'X';
const optional = 'O';

const SingleFieldConfigurator: FC<SingleFieldConfiguratorProps> = ({
    field,
    mutateField,
}) => {
    const { t } = useTranslation(['form-content']);

    const [hiddenCheckbox, setHiddenCheckbox] = useState<boolean>(
        field?.currentVisibility === hidden
    );

    const [mandatoryCheckbox, setMandatoryCheckbox] = useState<boolean>(
        field?.currentVisibility === mandatory
    );

    const isDefaultMandatory = field?.originalVisibility === mandatory;

    if (!field) return null;

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.id === `${field.businessTermId}_hidden`) {
            setHiddenCheckbox(e.currentTarget.checked);
            if (e.currentTarget.checked) {
                field.currentVisibility = hidden;
                setMandatoryCheckbox(false);
            } else {
                field.currentVisibility = optional;
            }
        }
        if (e.currentTarget.id === `${field.businessTermId}_mandatory`) {
            setMandatoryCheckbox(e.currentTarget.checked);
            if (e.currentTarget.checked) {
                field.currentVisibility = mandatory;
                setHiddenCheckbox(false);
            } else {
                field.currentVisibility = optional;
            }
        }
        mutateField(field);
    };

    return (
        <div className="p-5 pl-0 flex w-full items-center">
            <div className="flex w-3/12 whitespace-normal">
                <p>
                    <b>({field?.businessTermId})</b> {field?.businessTermName}
                </p>
            </div>
            <div className="flex w-3/12 flex whitespace-normal justify-center pl-5">
                <p
                    title={`Changed by ${field?.updatedBy} at ${field?.updatedDate}`}
                >
                    <b>
                        {field?.initialVisibility !== field?.currentVisibility
                            ? field?.initialVisibility
                            : ''}
                    </b>
                </p>
            </div>
            <div className="w-1/12 flex justify-center pl-5">
                <Checkbox
                    id={`${field.businessTermId}_hidden`}
                    disabled={isDefaultMandatory}
                    checked={hiddenCheckbox}
                    onChange={onChange}
                />
            </div>
            <div className="w-1/12 flex justify-center pl-5">
                <Checkbox
                    id={`${field.businessTermId}_mandatory`}
                    disabled={isDefaultMandatory}
                    checked={mandatoryCheckbox}
                    onChange={onChange}
                />
            </div>
            <div
                className="w-4/12 pl-5 whitespace-normal"
                title={field?.translationKey}
            >
                {t(field?.translationKey ?? '')}
            </div>
        </div>
    );
};

export default SingleFieldConfigurator;
