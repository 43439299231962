import axios, { AxiosResponse } from 'axios';
import { FormRenderResult } from '../../types/generated/formRenderResult';
import useSWRImmutable from 'swr/immutable';
import { getFormLanguage } from '../../shared/storageService/sessionService';
import { FormDataStateInterface } from './useFetchForm';

const fetcher = (url: string) =>
    axios
        .get(url)
        .then(
            async (
                res: AxiosResponse<FormRenderResult>
            ): Promise<FormDataStateInterface> => {
                const { data } = res;
                const defaultValues = JSON.parse(data.values ?? '');
                return {
                    codelists: data.codeLists ?? [],
                    formDefinition: data.definition ?? [],
                    values: defaultValues,
                    errors: JSON.parse(data.errors ?? ''),
                    isCompleted: data.isCompleted,
                    noticeTimeZone: data?.noticeTimeZone,
                };
            }
        );

export const useFetchFormReadonly = (formId?: string) => {
    const language = getFormLanguage();
    const { data, error } = useSWRImmutable(
        !formId || !language ? null : `form/readonly/${formId}/${language}`,
        fetcher,
        { shouldRetryOnError: false }
    );

    return {
        formData: data,
        isLoadingForm: formId && !error && !data,
        isErrorFetchingForm: error,
        noticeTimeZone: data?.noticeTimeZone,
    };
};
