import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';
import { Permissions } from '../../types/generated/permissions';

const fetcher = (url: string) =>
    axios.get(url).then(async (res: AxiosResponse<Permissions>) => {
        const { data } = res;
        return data;
    });

const useFetchPermission = () => {
    const { data, error } = useSWRImmutable(
        `/admin/users/permissions`,
        fetcher,
        { shouldRetryOnError: false }
    );

    return {
        permissions: data,
        isLoadingPublicationTask: !error && !data,
        isErrorFetchingPublicationTask: error,
    };
};

export default useFetchPermission;
