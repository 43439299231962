import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';
import { FormTextsResult } from '../../types/generated/formTextsResult';

const fetcher = (url: string) =>
    axios.get(url).then((res: AxiosResponse<FormTextsResult>) => res.data);

const useFetchReviewTranslations = (
    formId?: string,
    language1?: string,
    language2?: string
) => {
    const { data, error } = useSWRImmutable(
        !formId ? null : `form/texts/${formId}/${language1}/${language2}`,
        fetcher,
        { shouldRetryOnError: false }
    );

    return {
        translations: data,
        isErrorFetchingTranslationsReview: error,
        isLoadingTranslations: formId && !error && !data,
    };
};
export default useFetchReviewTranslations;
