/* istanbul ignore file */
import axios, { AxiosResponse } from 'axios';
import UserCredentials from '../types/userCredentials';
import {
    setAdminToken,
    setRepToken,
} from '../shared/storageService/cookieService';
import { setCurrentFormId } from '../shared/storageService/sessionService';
import { PlatformName, SecretKey } from '../shared/utilities';
import { formOptions } from '../types/enums/formOptions';

export const getAdminTokenFromApi = async (user: UserCredentials) => {
    const payload = {
        name: user.name ?? PlatformName,
        userId: user.userId,
        secretKey: SecretKey,
        role: user.role,
        formUserRights: user.formUserRights,
        tenderId: user.tenderId,
        formId: user.formId,
    };
    await axios
        .post('/auth/authorize-with-octa', payload)
        .then((res: AxiosResponse) => {
            setAdminToken(res.data.access_token);
        });
};

export const getOtpUrlFromApi = (
    formId: string,
    userRights: number,
    option: formOptions
) =>
    axios
        .get(
            `/testapp/url/${formId}/${userRights}/${
                sessionStorage.getItem('i18nextLng') ?? 'en'
            }/${option}`
        )
        .then((res: AxiosResponse<string>) => res.data);

export const getRepTokenFromApi = async (otp: string, option?: string) =>
    axios
        .post(`/auth/authenticate-otp/${otp}`, {})
        .then((res: AxiosResponse<{ [key: string]: string }>) => {
            let basePath =
                option !== formOptions.form ? `/form/${option}` : `/form`;
            if (option === 'dashboard') {
                basePath = option;
            }
            setCurrentFormId(res.data.form_id);
            return setRepToken(res.data.access_token, otp, basePath);
        })
        .catch((e) => {
            console.warn(e.message);
            // window.location.replace('/page-not-found');
        });
