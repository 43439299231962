import axios from 'axios';

const UseGetLockVersion = async () =>
    axios
        .get(`configurator/get-lock-version`)
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });

export default UseGetLockVersion;
