import axios, { AxiosResponse } from 'axios';

const refreshAllConfigurations = async (toastText?: {
    onSuccessText?: string;
    onPendingText?: string;
}) =>
    axios
        .post(
            `/configurator/refresh-all`,
            {},
            {
                headers: {
                    'Toast-Pending-Text': toastText?.onPendingText,
                    'Toast-Success-Text': toastText?.onSuccessText,
                },
            }
        )
        .then((res: AxiosResponse) => res.status)
        .catch((error) => {
            throw error;
        });
export default refreshAllConfigurations;
