import React, { FC, useEffect, useMemo, useState } from 'react';
import useFetchAuditLog, {
    AuditLog,
} from '../../hooks/administrativeHooks/useFetchAuditLog';
import NotFoundPage from '../NotFound/NotFoundPage';
import { DropdownOption } from '../../types/generated/formStatusResult';
import { format, parseISO } from 'date-fns';
import lodash from 'lodash';
import {
    Modal,
    ModalButtonsSection,
    ModalContent,
    ModalTitle,
} from '@mercell/modal-react';
import Button from '@mercell/button-react';
import { DateFns } from '../../shared/dateFnsFormats';
import { TFunction } from 'react-i18next';
import cx from 'classnames';
import {
    ColumnDef,
    TableOptions,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { CaretDown16, CaretUp16 } from '@carbon/icons-react';
import Ribbon from '@mercell/ribbon-react';
import { getSchemeName, getStatusName } from '../../shared/dashboardShared';

export interface StatusLogProps {
    t: TFunction<string, unknown>;
    statuses: DropdownOption[] | undefined;
    formId: string;
    locale: Locale | undefined;
    dateFormat: DateFns;
    setShowStatusLog: React.Dispatch<React.SetStateAction<boolean>>;
}
const StatusLog: FC<any> = ({
    statuses,
    t,
    locale,
    dateFormat,
    formId,
    setShowStatusLog,
}: StatusLogProps) => {
    const [isStatusLogVisible, setStatusLogVisibility] = useState(false);
    const [auditLogMap, setAuditLog] = useState<AuditLog[]>([]);

    const { auditLog, isErrorFetchingAuditLog } = useFetchAuditLog(
        formId ?? ''
    );

    const closeModal = () => {
        setStatusLogVisibility(false);
        setShowStatusLog(false);
    };
    useEffect(() => {
        const data = lodash(auditLog)
            .orderBy('timestamp', 'desc')
            .groupBy((dd: AuditLog) => dd.formStatus)
            .value();
        const auditMap = Object.keys(data).map((key) => ({
            ...data[key][0],
        }));
        setAuditLog(auditMap);
        setStatusLogVisibility(true);
    }, [formId, auditLog]);

    const getParsedDateTime = (timestamp: string | undefined): string =>
        timestamp && timestamp !== ''
            ? format(parseISO(timestamp), dateFormat.DateTimeWithZoneShort, {
                  locale,
              })
            : '';
    type ColumnMeta = {
        meta?: {
            size?: number | string;
            textAlign?: 'text-center';
        };
    };
    type AugmentedColumnDef = ColumnDef<AuditLog> & ColumnMeta;
    const columns = useMemo<AugmentedColumnDef[]>(
        () => [
            {
                accessorKey: 'formStatus',
                cell: (info: any) => info.getValue(),
                header: t('dashboard:FormStatus'),
                meta: {
                    size: '33%',
                    textAlign: 'text-center',
                },
            },
            {
                accessorKey: 'timestamp',
                cell: (info: any) => info.renderValue(),
                header: t('dashboard:ExecutedAt'),
                meta: {
                    size: '33%',
                    textAlign: 'text-center',
                },
            },
            {
                accessorKey: 'userId',
                cell: (info: any) => info.getValue(),
                header: t('dashboard:ExecutedBy'),
                meta: {
                    size: '34%',
                    textAlign: 'text-center',
                },
                enableHiding: true,
            },
        ],
        [t]
    );

    const tableOptions: TableOptions<AuditLog> = {
        data: auditLogMap,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        initialState: {
            sorting: [
                {
                    id: 'timestamp',
                    desc: false,
                },
            ],
        },
    };

    const table = useReactTable(tableOptions);
    const getCellValue = (cell: any) => {
        switch (cell.column.id) {
            case 'formStatus': {
                const status = statuses?.filter(
                    (stat) => stat.value === cell.getValue()
                )[0];

                const RibbonElement = (
                    <div className="flex justify-center items-center whitespace-nowrap">
                        <Ribbon scheme={getSchemeName(status?.value, statuses)}>
                            {t(
                                `list.audit-log-status:${getStatusName(
                                    status?.value,
                                    statuses
                                )}`
                            )}
                        </Ribbon>
                    </div>
                );

                return RibbonElement;
            }
            // return t(`list.audit-log-status:${status?.name}`);
            // }
            case 'timestamp': {
                return getParsedDateTime(cell.getValue());
            }
            default:
                return cell.getValue();
        }
    };

    return (
        <Modal
            isModalVisible={isStatusLogVisible}
            onCloseCallback={closeModal}
            shouldCloseOnOutsideClick
            parentClassName="parentClassName"
            className="max-w-4xl max-h-100"
        >
            <ModalTitle>{t('dashboard:ExecutedSteps')}</ModalTitle>
            <ModalContent>
                {isErrorFetchingAuditLog ? (
                    <NotFoundPage wrapperClass="col-span-full" />
                ) : (
                    <div className="col-span-full h-96 overflow-y-auto overscroll-contain">
                        <div className="overflow-hidden border border-alto md:rounded-default">
                            <table className="min-w-full divide-y divide-concrete table-fixed">
                                <thead className="bg-minsk">
                                    <tr>
                                        {table
                                            .getFlatHeaders()
                                            .map((header: any) => {
                                                const columnSize =
                                                    header.column.columnDef.meta
                                                        ?.size;

                                                const textAlign =
                                                    header.column.columnDef.meta
                                                        ?.textAlign;
                                                const columnWidth = `w-[${columnSize}]`;
                                                return (
                                                    <th
                                                        key={header.id}
                                                        onClick={header.column.getToggleSortingHandler()}
                                                        scope="col"
                                                        className={cx(
                                                            'p-3 text-white title-body select-none',
                                                            columnSize &&
                                                                columnWidth,
                                                            textAlign &&
                                                                textAlign,
                                                            header.column.getCanSort() &&
                                                                ' hover:cursor-pointer'
                                                        )}
                                                    >
                                                        {header.isPlaceholder ? null : (
                                                            <div>
                                                                {flexRender(
                                                                    header
                                                                        .column
                                                                        .columnDef
                                                                        .header,
                                                                    header.getContext()
                                                                )}
                                                                {{
                                                                    asc: (
                                                                        <CaretDown16 className="inline-block" />
                                                                    ),
                                                                    desc: (
                                                                        <CaretUp16 className="inline-block" />
                                                                    ),
                                                                }[
                                                                    header.column.getIsSorted() as string
                                                                ] ?? null}
                                                            </div>
                                                        )}
                                                    </th>
                                                );
                                            })}
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-concrete bg-white">
                                    {table
                                        .getRowModel()
                                        .rows.map((row: any) => (
                                            <tr
                                                key={row.id}
                                                className="rt-tr-group"
                                            >
                                                {row
                                                    .getVisibleCells()
                                                    .map((cell: any) => {
                                                        const columnSize =
                                                            cell.column
                                                                .columnDef.meta
                                                                ?.size;

                                                        const textAlign =
                                                            cell.column
                                                                .columnDef.meta
                                                                ?.textAlign;
                                                        const columnWidth = `w-[${columnSize}]`;

                                                        return (
                                                            <td
                                                                key={cell.id}
                                                                className={cx(
                                                                    'p-3 text-black title-body break-all text-[14px] font-medium',
                                                                    columnSize &&
                                                                        columnWidth,
                                                                    textAlign &&
                                                                        textAlign
                                                                )}
                                                            >
                                                                {getCellValue(
                                                                    cell
                                                                )}
                                                            </td>
                                                        );
                                                    })}
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </ModalContent>
            <ModalButtonsSection className="flex justify-end">
                <Button
                    scheme="secondary"
                    className="mr-2"
                    onClick={closeModal}
                >
                    {t('dashboard:LabelClose')}
                </Button>
            </ModalButtonsSection>
        </Modal>
    );
};

export default StatusLog;
