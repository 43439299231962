import axios from 'axios';

const finalizeDefinition = async (definitionId: string) =>
    axios
        .get(`/configurator/definition/${definitionId}/finalize`)
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });

export default finalizeDefinition;
