import axios from 'axios';
import envWithSuffix from '../envWithSuffix';

let apiURl: string | undefined;

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    // In development get the api url from .env.local file
    apiURl = process.env.REACT_APP_API;
    // console.log('1: ', apiURl);
} else if (envWithSuffix('REACT_APP_API_API')) {
    // In production try to get API url from env variable
    apiURl = `${envWithSuffix('REACT_APP_API_API')}/api`;
    // console.log('2: ', apiURl);
} else {
    // In production our js bundle is served from the same domain as our api, we can extract the domain from bundle src url
    const scriptSrc = (document.currentScript as HTMLScriptElement)?.src;
    const url = new URL(scriptSrc);

    apiURl = `${url.origin}/api`;
    // console.log('3: ', apiURl);
}

const initAxios = () => {
    axios.defaults.baseURL = apiURl;
    axios.defaults.headers.common.MercellFormsClientTimezone =
        new Date().getTimezoneOffset();

    // Treat 200 as only valid status code
    axios.defaults.validateStatus = (status: number) => status === 200;
};

export default initAxios;
