export interface DeviceInfo {
    os: string;
    browser: string;
    userAgent: string;
    screenSize: string;
  }
  
export function getDeviceInfo(): DeviceInfo {
    const {userAgent} = window.navigator;
  
    const osLookup: {[key: string]: string} = {
      "Win": "Windows",
      "Mac": "macOS",
      "X11": "UNIX",
      "Linux": "Linux"
    };
    const os = Object.entries(osLookup).find(([key]) => userAgent.indexOf(key) !== -1)?.[1] ?? "Unknown";
  
    const browserLookup: {[key: string]: string} = {
      "Firefox": "Firefox",
      "Safari": "Safari",
      "Chrome": "Chrome",
      "Edg": "Edge"
    };
    const browser = Object.entries(browserLookup).find(([key]) => userAgent.indexOf(key) !== -1)?.[1] ?? "Unknown";
  
    const screenSize = `${window.screen.width}x${window.screen.height}`;
  
    return { os, browser, userAgent, screenSize };
  }