import React from 'react';
import NotFoundPage from '../NotFound/NotFoundPage';
import LoadingSpinner from '@mercell/loading-spinner-react';
import useFetchVersionOverview from '../../hooks/configuratorHooks/useFetchVersionOverview';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { useAppSelector } from '../../redux/hooks';
import OktaAuthWrapper from '../../authorization/OktaAuthWrapper';
import { toast } from 'react-toastify';
import { CaretDown16, CaretUp16, Copy16 } from '@carbon/icons-react';
import { VersionOverview } from '../../types/generated/versionOverview';
import cx from 'classnames';
import {
    Cell,
    ColumnDef,
    SortingState,
    TableOptions,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';

const VersionOverviewPage = () => {
    const {
        versionOverview,
        isLoadingVersionOverview,
        isErrorFetchingVersionOverview,
    } = useFetchVersionOverview();
    const { t } = useTranslation(['form-configurator', 'list.country']);
    const dateFormat = useAppSelector((state) => state.store.dateFormat);
    const locale = useAppSelector((state) => state.store.locale);
    const [sorting, setSorting] = React.useState<SortingState>([
        {
            id: 'activatedAt',
            desc: true,
        },
    ]);

    const copyToClipboard = (text: string | undefined) => {
        if (text) {
            navigator.clipboard.writeText(text);
            const copiedMessage = t('form-content:CopiedToClipboard');
            toast.success(copiedMessage);
        }
    };

    const columns = React.useMemo<ColumnDef<VersionOverview>[]>(
        () => [
            {
                accessorKey: 'country',
                cell: (info: any) => info.getValue(),
                header: t('form-configurator:Country'),
                sortingFn: (row1, row2, columnId) => {
                    const country1 = t(
                        `list.country:${row1.getValue(columnId) ?? ''}`
                    );
                    const country2 = t(
                        `list.country:${row2.getValue(columnId) ?? ''}`
                    );
                    if (country1 < country2) {
                        return -1;
                    }
                    if (country1 > country2) {
                        return 1;
                    }
                    return 0;
                },
            },
            {
                accessorKey: 'name',
                cell: (info: any) => info.getValue(),
                header: t('form-configurator:VersionName'),
                enableSorting: false,
            },
            {
                accessorKey: 'versionUuid',
                cell: (info: any) => info.getValue(),
                enableSorting: false,
            },
            {
                accessorKey: 'baseVersionName',
                cell: (info: any) => info.getValue(),
                header: t('form-configurator:BaseVersion'),
            },
            {
                accessorKey: 'baseVersionUuid',
                cell: (info: any) => info.getValue(),
                enableSorting: false,
            },
            {
                accessorKey: 'activatedAt',
                cell: (info: any) => info.getValue(),
                header: t('form-configurator:ActivatedOn'),
            },
            {
                accessorKey: 'updatedBy',
                cell: (info: any) => info.getValue(),
                header: t('form-configurator:UpdatedBy'),
            },
        ],
        [t]
    );

    const tableOptions: TableOptions<VersionOverview> = {
        data: versionOverview ?? [],
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        state: {
            sorting,
            columnVisibility: {
                versionUuid: false,
                baseVersionUuid: false,
            },
        },
    };

    const table = useReactTable(tableOptions);

    const getCellValue = (cell: any) => {
        switch (cell.column.id) {
            case 'country':
                return (
                    <div className="p-3 text-black title-body break-all text-[14px] font-medium">
                        {t(`list.country:${cell.getValue() ?? ''}`)}
                    </div>
                );
            case 'baseVersionName':
            case 'name': {
                const uuidKey =
                    cell.column.id === 'baseVersionName'
                        ? 'baseVersionUuid'
                        : 'versionUuid';
                const uuid = cell.row.getValue(uuidKey);
                const name = cell.getValue();
                return (
                    name && (
                        <div className="p-3 text-black title-body break-all text-[14px] font-medium">
                            {name}
                            <button
                                type="button"
                                className="text-small text-dove p-0 block"
                                onClick={() => copyToClipboard(uuid)}
                            >
                                {uuid}
                                <Copy16 className="ml-2 inline-block" />
                            </button>
                        </div>
                    )
                );
            }
            case 'activatedAt': {
                const act = cell.row.getValue('activatedAt');
                return (
                    <div className="p-3 text-black title-body break-all text-[14px] font-medium">
                        {act &&
                            format(
                                parseISO(act ?? ''),
                                dateFormat.DateTimeWithZoneShort,
                                { locale }
                            )}
                    </div>
                );
            }
            default:
                return (
                    <div className="p-3 text-black title-body break-all text-[14px] font-medium">
                        {cell.getValue()}
                    </div>
                );
        }
    };

    if (isLoadingVersionOverview)
        return (
            <div className="col-span-full h-full flex items-center justify-center">
                <LoadingSpinner />
            </div>
        );

    if (isErrorFetchingVersionOverview)
        return (
            <NotFoundPage
                wrapperClass="col-span-full"
                errorCode={isErrorFetchingVersionOverview.status.toString()}
            />
        );

    return (
        <OktaAuthWrapper>
            {isLoadingVersionOverview ? (
                <div className="col-span-full h-full flex items-center justify-center">
                    <LoadingSpinner />
                </div>
            ) : (
                <div className="col-span-full justify-self-center">
                    <h2 className="mb-10">
                        {t('form-configurator:VersionOverview')}
                    </h2>
                    <div className="overflow-hidden border border-alto md:rounded-default">
                        <table className="min-w-full divide-y divide-concrete table-fixed">
                            <thead className="bg-minsk">
                                <tr>
                                    {table
                                        .getFlatHeaders()
                                        .map((header: any) => (
                                            <th
                                                scope="col"
                                                key={header.id}
                                                onClick={header.column.getToggleSortingHandler()}
                                                className={cx(
                                                    'p-3 text-white title-body select-none text-left',
                                                    header.id === 'country' &&
                                                        'text-center w-[15%]',
                                                    header.column.getCanSort() &&
                                                        ' hover:cursor-pointer'
                                                )}
                                            >
                                                {header.isPlaceholder ? null : (
                                                    <div>
                                                        {flexRender(
                                                            header.column
                                                                .columnDef
                                                                .header,
                                                            header.getContext()
                                                        )}
                                                        {{
                                                            asc: (
                                                                <CaretUp16 className="inline-block" />
                                                            ),
                                                            desc: (
                                                                <CaretDown16 className="inline-block" />
                                                            ),
                                                        }[
                                                            header.column.getIsSorted() as string
                                                        ] ?? null}
                                                    </div>
                                                )}
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-concrete bg-white">
                                {table.getRowModel().rows.map((row: any) => (
                                    <tr key={row.id} className="rt-tr-group">
                                        {row
                                            .getVisibleCells()
                                            .map(
                                                (
                                                    cell: Cell<unknown, unknown>
                                                ) => (
                                                    <td
                                                        key={cell.id}
                                                        className={cx(
                                                            cell.column.id ===
                                                                'country' &&
                                                                'text-center w-[15%]',
                                                            'text-black title-body break-all text-[14px] font-medium'
                                                        )}
                                                    >
                                                        {getCellValue(cell)}
                                                    </td>
                                                )
                                            )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </OktaAuthWrapper>
    );
};

export default VersionOverviewPage;
