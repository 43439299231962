/* istanbul ignore file */

export const removeCurrentFormId = () => sessionStorage.removeItem('form-id');

export const getCurrentFormId = () => sessionStorage.getItem('form-id') ?? '';

export const setCurrentFormId = (formId: string) =>
    sessionStorage.setItem('form-id', formId);

export const setFormLanguage = (lang: string) =>
    sessionStorage.setItem('language', lang);

export const setFormAdditionalLanguages = (additionalLanguages: string[]) =>
    sessionStorage.setItem(
        'additionalLanguages',
        JSON.stringify(additionalLanguages)
    );

export const getFormLanguage = () =>
    sessionStorage.getItem('language') ?? undefined;

export const getFormAdditionalLanguages = () =>
    JSON.parse(sessionStorage.getItem('additionalLanguages') ?? '[]');

export const removeDefinitionFieldsSession = () =>
    sessionStorage.removeItem('form-definition-fields');

export const getTranslationKeysVisibility = () => {
    const isVisible = sessionStorage.getItem('translation-keys-visibility');
    if (isVisible) {
        return isVisible === 'true';
    }
    return false;
};

export const setTranslationKeysVisibility = (isVisible: boolean) =>
    sessionStorage.setItem(
        'translation-keys-visibility',
        JSON.stringify(isVisible)
    );
