import axios, { AxiosResponse } from 'axios';
import { ConfiguratorCodelist } from '../../types/generated/configuratorCodelist';
import useSWRImmutable from 'swr/immutable';

const fetcher = (url: string) =>
    axios
        .get(url)
        .then(async (res: AxiosResponse<ConfiguratorCodelist>) => res.data);

const useFetchCodelist = (versionId: string, codelistId: string) => {
    const { data, error, mutate } = useSWRImmutable(
        !versionId || !codelistId
            ? null
            : `/configurator/codelist/${versionId}/${codelistId}`,
        fetcher,
        { shouldRetryOnError: false }
    );

    return {
        setStateCodelist: mutate,
        codelist: data,
        isFetchingCodelist: !data && !error,
        isErrorFetchingCodelist: error,
    };
};

export default useFetchCodelist;
