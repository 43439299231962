/* eslint-disable camelcase */
import {
    FieldStructure,
    DateTimePickerFieldStructure,
} from '@mercell/form-react';
import * as yup from 'yup';
import { FormDefinitionItem } from '../../types/generated/formDefinitionItem';
import { TFunction } from 'react-i18next';
import { getRulesSetup } from '../getRulesSetup';
import { addDays, format, parseISO } from 'date-fns';
import { getPlaceHolder } from '../utilities/dateFormat';
import { DateFns } from '../dateFnsFormats';
import { formatInTimeZone } from 'date-fns-tz';

export const mapDatepickerField = (
    formDefinition: FormDefinitionItem,
    genericFieldStructure: FieldStructure,
    t: TFunction<string, unknown>,
    dateFormat: DateFns,
    locale?: Locale,
    noticeTimeZone?: string,
    featureFlagUseUtcDatesOnly?: boolean
): DateTimePickerFieldStructure => {
    if (genericFieldStructure.associatedValidationBasedOnOtherFieldValues) {
        if (
            formDefinition.conditionalValidation?.rules &&
            genericFieldStructure.associatedValidationBasedOnOtherFieldValues
        ) {
            const conditionalValidations = getRulesSetup(
                yup
                    .date()
                    .nullable()
                    .transform((value, originalValue) => {
                        if (!originalValue) return null;
                        return parseISO(originalValue);
                    }),
                formDefinition.conditionalValidation.rules,
                t,
                '',
                locale
            );
            genericFieldStructure.associatedValidationBasedOnOtherFieldValues.rules =
                conditionalValidations;
        }
    }
    return {
        ...genericFieldStructure,
        id: formDefinition.name,
        validations: formDefinition.rules
            ? getRulesSetup(
                  yup
                      .date()
                      .nullable()
                      .transform((value, originalValue) => {
                          if (!originalValue) return null;
                          return parseISO(originalValue);
                      }),
                  formDefinition.rules,
                  t,
                  '',
                  locale
              )
            : undefined,
        type: 'date-time-picker',
        submitWithTimeZone: true,
        disabled: formDefinition.disabled,
        dateFormat:
            featureFlagUseUtcDatesOnly && noticeTimeZone
                ? dateFormat.DateWithZoneLong
                : dateFormat.Date,
        showDateRangeWithTimeZone: false,
        startDateRangeFormat: dateFormat?.DateTime,
        endDateRangeFormat: dateFormat?.DateTimeWithZoneLong,
        placeholder: locale
            ? getPlaceHolder(locale?.formatLong?.date({ width: 'short' }))
            : 'DD/MM/YYYY',
        locale,
        timeZone: featureFlagUseUtcDatesOnly ? noticeTimeZone : undefined,
    };
};

export const mapDatepickerReadOnly = (
    content: any,
    showWithTime: boolean,
    dateFormat: DateFns,
    locale?: Locale,
    featureFlagUseUtcDatesOnly?: boolean,
    noticeTimeZone?: string
) => {
    if (!content) return undefined;

    if (
        noticeTimeZone &&
        featureFlagUseUtcDatesOnly &&
        isDateStringUtcFormat(content)
    ) {
        return formatInTimeZone(
            content,
            noticeTimeZone,
            showWithTime
                ? dateFormat.DateTimeWithZoneLong
                : dateFormat.DateWithZoneLong,
            { locale }
        );
    }
    if (showWithTime) {
        return format(parseISO(content), dateFormat?.DateTimeWithZoneLong, {
            locale,
        });
    }

    const isDateSameTimeZone = isDateInLocaleTimeZone(content);
    if (!isDateSameTimeZone) {
        const startDate = format(
            parseISO(content),
            dateFormat.DateTimeWithZoneLong,
            {
                locale,
            }
        );
        const endDate = format(
            addDays(parseISO(content), 1),
            dateFormat.DateTimeWithZoneLong,
            {
                locale,
            }
        );
        const dateRange = `${startDate} - ${endDate}`;

        return dateRange;
    }
    return format(parseISO(content), dateFormat?.DateTimeWithZoneLong, {
        locale,
    });
};

const isDateInLocaleTimeZone = (date: string) => {
    const offsetPattern = /([+-]\d{2}:\d{2})$/;
    const match = date.match(offsetPattern);
    if (match) {
        const currentLocalDate = new Date();
        const currentDateOffset = currentLocalDate.getTimezoneOffset();

        const offset = match[1];
        const offsetMinutes = isoOffsetToMinutes(offset);

        if (offsetMinutes !== currentDateOffset) {
            return false;
        }
        return true;
    }
};

function isoOffsetToMinutes(isoOffset: string): number {
    const offsetPattern = /^([+-])(\d{2}):(\d{2})$/;
    const match = isoOffset.match(offsetPattern);

    if (match) {
        const sign = match[1] === '+' ? -1 : 1;
        const hours = parseInt(match[2], 10);
        const minutes = parseInt(match[3], 10);
        const totalMinutes = (hours * 60 + minutes) * sign;
        return totalMinutes;
    }
    return NaN;
}

function isDateStringUtcFormat(input: string): boolean {
    const isoFormatRegex =
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?\+00:00$/;

    return isoFormatRegex.test(input);
}
