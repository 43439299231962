import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';
import { ConfiguratorCountry } from '../../types/generated/configuratorCountry';

const fetcher = (url: string) =>
    axios
        .get(url)
        .then(
            async (res: AxiosResponse): Promise<ConfiguratorCountry[]> =>
                res.data
        );

const useFetchCountries = () => {
    const { data, error } = useSWRImmutable(`configurator/countries`, fetcher, {
        shouldRetryOnError: false,
    });

    return {
        countries: data,
        isLoadingCountries: !data && !error,
        isErrorFetchingCountries: error,
    };
};

export default useFetchCountries;
