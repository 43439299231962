import axios from 'axios';

export const previewPdf = (
    formId?: string,
    language?: string,
    toastText?: {
        onSuccessText?: string;
        onPendingText?: string;
    }
) =>
    axios
        .get(
            `/rep/form/previewdocument/${formId ?? ''}/${
                language ?? 'en_GB'
            }/pdf`,
            {
                responseType: 'blob',
                headers: {
                    'Toast-Pending-Text': toastText?.onPendingText,
                    'Toast-Success-Text': toastText?.onSuccessText,
                },
            }
        )
        .then((response: any) => response.data)
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${formId}-${language}.pdf`;
            a.click();
            a.remove();
            setTimeout(() => window.URL.revokeObjectURL(url), 100);
        });
