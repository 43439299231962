import { getRulesSetup } from '../getRulesSetup';
import * as yup from 'yup';
import {
    FormOptionItem,
    UIDesignElementType,
    FormDefinitionItem,
} from '../../types/generated/formDefinitionItem';
import { CheckboxFieldStructure, FieldStructure } from '@mercell/form-react';
import { TFunction } from 'react-i18next';
import { AnySchema } from 'yup';
import { ReactNode } from 'react';

export const mapCheckboxField = (
    formDefinition: FormDefinitionItem,
    genericFieldStructure: FieldStructure,
    t: TFunction<string, unknown>,
    isFormPreview: boolean
): CheckboxFieldStructure => {
    let validationPattern: AnySchema = yup.string().nullable();
    let optionsList: CheckboxFieldStructure['optionsList'];
    let singleCheckboxLabel: ReactNode;
    if (formDefinition.options && formDefinition.options.length) {
        validationPattern = yup.array().ensure();
        optionsList = () =>
            formDefinition.options?.map((option: FormOptionItem) => ({
                label: `${t(`${option.label}`)}${
                    option.addDefaultTag
                        ? ` (${t('form-content:default')})`
                        : ''
                }`,
                value: option.value,
                // Todo: if later needed: optionProperties: { className: 'bg-main-40' }, should check wether the different browsers support the content here
            })) ?? [];
    }
    if (!formDefinition.options) {
        singleCheckboxLabel = t(formDefinition.label || '');
    }

    if (genericFieldStructure.associatedValidationBasedOnOtherFieldValues) {
        if (
            formDefinition.conditionalValidation?.rules &&
            formDefinition.options &&
            formDefinition.options?.length
        ) {
            const conditionalValidations = getRulesSetup(
                validationPattern,
                formDefinition.conditionalValidation.rules,
                t
            );
            genericFieldStructure.associatedValidationBasedOnOtherFieldValues.rules =
                conditionalValidations;
        }
    }

    return {
        ...genericFieldStructure,
        type: 'checkbox',
        fieldsetProperties: {
            className:
                formDefinition.designElementType ===
                UIDesignElementType.WithheldPublicationIndicator
                    ? 'text-caption'
                    : formDefinition.fieldsetStyle,
        },
        singleCheckboxLabel,
        optionsList,
        disabled: formDefinition.preview,
        validations: formDefinition.rules
            ? getRulesSetup(validationPattern, formDefinition.rules, t)
            : undefined,
        isCheckBoxVisible: !isFormPreview,
    };
};
