import { ButtonFieldStructure, FieldStructure } from '@mercell/form-react';
import { FormDefinitionItem } from '../../types/generated/formDefinitionItem';
import { TFunction } from 'react-i18next';

export const mapButtonField = (
    formDefinition: FormDefinitionItem,
    genericFieldStructure: FieldStructure,
    t: TFunction<string, unknown>
): ButtonFieldStructure => ({
    ...genericFieldStructure,
    type: 'button',
    buttonScheme: 'primary',
    text: t(formDefinition.label ?? formDefinition.name),
    // linkAddress: "http://df,jgndf",
    // linkProperties: {
    //     target: "_blank"
    // }
});
