import React, { FC } from 'react';
import {
    ProgressBarRound20,
    Error20,
    CheckmarkFilled20,
} from '@carbon/icons-react';
import { TFunction } from 'react-i18next';
import { AutoSaveStatus } from '../state/autoSaveStatus';

interface AutoSaveStatusProps {
    autoSave: AutoSaveStatus | undefined;
    t: TFunction<string, unknown>;
}

export const AutoSaveStatusIndicator: FC<AutoSaveStatusProps> = ({
    autoSave,
    t,
}) => {
    let Icon;
    let Status;
    if (!autoSave) return null;

    if (autoSave?.isLoading) {
        Icon = <ProgressBarRound20 className=" text-minsk animate-spin" />;
        Status = t('form-content:SavingChanges');
    } else if (autoSave?.isError) {
        Icon = <Error20 className=" text-minsk" />;
        Status = t('form-content:ChangesFailed');
    } else if (autoSave?.isSaved) {
        Icon = <CheckmarkFilled20 className=" text-minsk" />;
        Status = t('form-content:ChangesSaved');
    }

    return (
        <div className="flex items-center gap-x-2">
            {Icon}
            <span>{Status}</span>
        </div>
    );
};
