import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';
import { SessionInfoResult } from '../../types/generated/sessionInfoResult';

const fetcher = (url: string) =>
    axios.get(url).then((res: AxiosResponse<SessionInfoResult>) => res.data);

const useFetchSessionInfo = () => {
    const { data, error } = useSWRImmutable(`rep/form/session-info`, fetcher, {
        shouldRetryOnError: false,
    });

    return {
        sessionInfo: data,
        isErrorFetchingSessionInfo: error,
        isLoadingSessionInfo: !error && !data,
    };
};
export default useFetchSessionInfo;
