import axios from 'axios';

export const lockUnlockForm = (
    formId?: string,
    lock?: string,
    toastText?: {
        onSuccessText?: string;
        onPendingText?: string;
    }
) =>
    axios
        .post(
            `/rep/form/lock/${formId}/${lock}`,
            {},
            {
                headers: {
                    'Toast-Pending-Text': toastText?.onPendingText,
                    'Toast-Success-Text': toastText?.onSuccessText,
                },
            }
        )
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
