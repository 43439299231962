import axios from 'axios';

export const setBackInProgress = (
    formId: string,
    toastText?: {
        onSuccessText?: string;
        onPendingText?: string;
    }
) =>
    axios
        .post(
            `dashboard/form/${formId}/publication-in-progress`,
            {},
            {
                headers: {
                    'Toast-Pending-Text': toastText?.onPendingText,
                    'Toast-Success-Text': toastText?.onSuccessText,
                },
            }
        )
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
