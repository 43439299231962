/* istanbul ignore file */
import axios, { AxiosResponse } from 'axios';
import { FormRenderResult } from '../../types/generated/formRenderResult';
import useSWRImmutable from 'swr/immutable';
import { FormDefinitionItem } from '../../types/generated/formDefinitionItem';
import { getFormLanguage } from '../../shared/storageService/sessionService';
import { useEffect } from 'react';
import { useSWRConfig } from 'swr';

export interface FormDataStateInterface {
    codelists: string[];
    formDefinition: FormDefinitionItem[];
    values: Record<string, any>;
    errors: { fieldErrors: Record<string, any>; formErrors: string[] };
    isCompleted: boolean;
    isFormPreview: boolean;
    tenderName?: string;
    noticeTimeZone?: string;
}

const fetcher = (url: string) =>
    axios
        .get(url)
        .then(
            async (
                res: AxiosResponse<FormRenderResult>
            ): Promise<FormDataStateInterface> => {
                const { data } = res;
                const defaultValues = JSON.parse(data.values ?? '');
                return {
                    codelists: data.codeLists ?? [],
                    formDefinition: data.definition ?? [],
                    values: defaultValues,
                    errors: JSON.parse(data.errors ?? ''),
                    isCompleted: data.isCompleted,
                    isFormPreview: data.isFormPreview,
                    tenderName: data.tenderName,
                    noticeTimeZone: data?.noticeTimeZone,
                };
            }
        );

const useFetchArrayItem = (
    formId: string,
    sectionPath: string,
    arrayPath?: string,
    elementId?: string,
    isPreview?: boolean,
    isOpen?: boolean
) => {
    const language = getFormLanguage();
    const { cache } = useSWRConfig();
    const url =
        (isOpen !== undefined &&
            !isOpen &&
            formId &&
            elementId !== undefined) ||
        !formId
            ? null
            : `array/render/${
                  isPreview === true ? `${isPreview}` : 'false'
              }/${formId}/${language}/${sectionPath}${
                  arrayPath ? `/${arrayPath}` : ''
              }${elementId && elementId !== 'add' ? `/${elementId}` : ''}`;
    const { data, error, mutate } = useSWRImmutable(url, fetcher, {
        shouldRetryOnError: false,
    });
    useEffect(
        () => () => {
            cache.delete(url);
        },
        [cache, url]
    );
    return {
        setState: mutate,
        formData: data,
        isLoadingArrayItem: formId && !error && !data,
        isErrorFetchingForm: error,
        tenderName: data?.tenderName,
        noticeTimeZone: data?.noticeTimeZone,
    };
};
export default useFetchArrayItem;
