import axios, { AxiosResponse } from 'axios';
import { ConfiguratorVersion } from '../../types/generated/configuratorVersion';
import useSWRImmutable from 'swr/immutable';

const fetcher = (url: string) =>
    axios
        .get(url)
        .then(
            async (res: AxiosResponse): Promise<ConfiguratorVersion[]> =>
                res.data
        );

const useFetchVersions = (country?: string) => {
    const { data, error, mutate } = useSWRImmutable(
        `/configurator/versions/${country ?? ''}`,
        fetcher,
        { shouldRetryOnError: false }
    );

    return {
        setStateVersions: mutate,
        versions: data,
        isFetchingVersions: !data && !error,
        isErrorFetchingVersions: error,
    };
};

export default useFetchVersions;
