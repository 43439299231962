import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';
import { PublicationTask } from '../../types/generated/publicationTask';

const fetcher = (url: string) =>
    axios.get(url).then(async (res: AxiosResponse<PublicationTask>) => {
        const { data } = res;
        return data;
    });

const useFetchPublicationTask = (formId: string) => {
    const { data, error } = useSWRImmutable(
        `/testapp/publications/log/${formId}`,
        fetcher,
        { shouldRetryOnError: false }
    );

    return {
        publicationTask: data,
        isLoadingPublicationTask: !error && !data,
        isErrorFetchingPublicationTask: error,
    };
};

export default useFetchPublicationTask;
