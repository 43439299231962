import './wydr';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'tailwindcss/tailwind.css';
import './styles/styles.css';
import './styles/fonts.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { StoreContextProvider } from './state';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import envWithSuffix from './shared/envWithSuffix';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import configOkta from './configOkta';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    CheckmarkOutline32,
    Copy32,
    Information32,
    Warning32,
    WarningAlt32,
} from '@carbon/icons-react';
import cx from 'classnames';
import { AxiosInterceptor } from './AxiosInterceptor';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { basicLogger } from 'launchdarkly-js-client-sdk';

const oktaAuth: OktaAuth = new OktaAuth(configOkta);
const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    window.location.replace(
        toRelativeUrl(originalUri || '/', window.location.origin)
    );
};

const ToastClasses: any = {
    success: '!bg-minsk',
    error: '!bg-well-read',
    info: '!bg-minsk',
    warning: '!bg-well-read',
    default: '!bg-minsk',
    dark: '!bg-mirage',
};

export const ToastIcons: any = {
    success: <CheckmarkOutline32 />,
    error: <Warning32 />,
    info: <Information32 />,
    warning: <WarningAlt32 />,
    default: <CheckmarkOutline32 />,
    dark: <Warning32 />,
};

const copyButton = ({ copyToast }: any) => (
    <div
        aria-hidden="true"
        className="flex flex-col justify-center pl-1"
        onClick={copyToast}
    >
        <Copy32 className="w-[20px]" />
    </div>
);

(async () => {
    const LDProvider = await asyncWithLDProvider({
        clientSideID: envWithSuffix('REACT_APP_LAUNCH_DARKLY_CLIENT_ID') ?? '',
        options: {
            bootstrap: 'localStorage',
            logger: basicLogger({level: 'none'})
        },
        reactOptions: {
            useCamelCaseFlagKeys: true,
        },
    });
    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
        <LDProvider>
            <BrowserRouter>
                <AxiosInterceptor
                    oktaAuth={oktaAuth}
                    restoreOriginalUri={restoreOriginalUri}
                >
                    <StoreContextProvider>
                        <Provider store={store}>
                            <App
                                oktaAuth={oktaAuth}
                                restoreOriginalUri={restoreOriginalUri}
                            />
                            <ToastContainer
                                position="bottom-center"
                                autoClose={3000}
                                closeButton={copyButton}
                                toastClassName={(toastWrapper: any) =>
                                    cx(
                                        'text-center',
                                        toastWrapper.defaultClassName,
                                        `${ToastClasses[toastWrapper.type]}`,
                                        '!text-white !rounded-default !border-l-6'
                                    )
                                }
                                icon={(icon: any) => ToastIcons[icon.type]}
                                hideProgressBar
                                newestOnTop
                                closeOnClick
                                rtl={false}
                                transition={Slide}
                                pauseOnFocusLoss={false}
                                draggable
                                pauseOnHover={false}
                            />
                        </Provider>
                    </StoreContextProvider>
                </AxiosInterceptor>
            </BrowserRouter>
        </LDProvider>
    );
})();

// const oktaRoutesCondition = (req: AxiosRequestConfig) =>
//     req.url?.includes('testapp/') ||
//     req.url?.includes('translation/') ||
//     req.url?.includes('configurator/');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
