import React, { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Edit20,
    DataView20,
    OverflowMenuVertical32,
    TrashCan20,
    ChevronRight32,
    Copy20,
} from '@carbon/icons-react';
import { FormArrayItem } from '../../types/generated/formArrayItem';
import { ArrayElementPreview } from './ArrayElementPreview';
import { DropdownMenu, DropdownMenuItem } from '@mercell/dropdown-menu-react';
import {
    PanelInterface,
    useAccordionProps,
} from '@mercell/use-accordion-react-hook';
import { useExpandedState } from '@mercell/use-expanded-state-react-hook';
import { Button } from '@mercell/button-react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

interface ArrayElementAccordionProps {
    arrayElement: FormArrayItem;
    arrayPath: string;
    onDelete: (uniqueId: string) => void;
    goToUrl: string;
    isFormPreview: boolean;
    errorMessage?: string;
    isExpandable?: boolean;
    className?: string;
    onCopy: (uniqueId: string) => void;
    isCopyEnabled?: boolean;
}

const ArrayElementAccordion: FC<ArrayElementAccordionProps> = memo(
    ({
        arrayElement,
        arrayPath,
        onDelete,
        goToUrl,
        isFormPreview,
        isExpandable,
        className,
        onCopy,
        isCopyEnabled,
    }) => {
        const {
            uniqueId,
            name,
            isDeletable,
            isPreview: isLocked,
            type,
        } = arrayElement;
        const [showOverflowMenu, setShowOverflowMenu] = React.useState(false);
        const { expanded, onClick } = useExpandedState(false);
        const { trigger, panel } = useAccordionProps(
            `container-${uniqueId}`,
            expanded
        );
        const buttonRef = React.useRef<HTMLButtonElement>(null);
        const { t } = useTranslation([
            'form-content',
            'list.tech-role-selector',
        ]);
        const navigate = useNavigate();

        return (
            <div key={uniqueId} className={className}>
                <div className="rounded-default border border-alto hover:shadow-[2px_2px_12px_0px_#72727240]">
                    <div>
                        <div className="flex flex-col">
                            <div className="flex justify-between w-full items-center text-body font-semibold p-7">
                                <div className="flex items-center">
                                    {isExpandable === true && isFormPreview && (
                                        <button
                                            onClick={onClick}
                                            type="button"
                                            {...trigger}
                                            className={`cursor-pointer accordion p-2 secondary  ${
                                                expanded
                                                    ? 'transition ease-linear transform rotate-90'
                                                    : 'transition ease-linear'
                                            }`}
                                        >
                                            <ChevronRight32 />
                                        </button>
                                    )}
                                    <div className="flex justify-center flex-col">
                                        <p className="ml-2">
                                            {name !== ''
                                                ? name
                                                : t('form-content:Untitled')}
                                        </p>
                                        <p className="ml-2 text-dove text-caption">
                                            {type ? t(type) : ''}
                                        </p>
                                    </div>
                                </div>

                                <div className="flex items-center">
                                    <div className="relative w-auto inline-block">
                                        <Button
                                            scheme="icon"
                                            ref={buttonRef}
                                            style={{ boxShadow: 'none' }}
                                            iconSettings={{
                                                Icon: OverflowMenuVertical32,
                                            }}
                                            onClick={() =>
                                                setShowOverflowMenu(
                                                    !showOverflowMenu
                                                )
                                            }
                                            data-test="array-button"
                                        />

                                        {showOverflowMenu && (
                                            <DropdownMenu
                                                id="accordion-dropdown-menu"
                                                anchorRef={buttonRef}
                                                closeOnClick
                                                onClose={() =>
                                                    setShowOverflowMenu(false)
                                                }
                                            >
                                                <DropdownMenuItem
                                                    as="button"
                                                    className="flex justify-left"
                                                    onClick={() => {
                                                        navigate(goToUrl);
                                                    }}
                                                    data-test="array-item-edit-button"
                                                >
                                                    {isFormPreview ||
                                                    isLocked ? (
                                                        <DataView20 className="mr-2" />
                                                    ) : (
                                                        <Edit20 className="mr-2" />
                                                    )}
                                                    {isFormPreview || isLocked
                                                        ? t('form-content:View')
                                                        : t(
                                                              'form-content:Edit'
                                                          )}
                                                </DropdownMenuItem>
                                                {isCopyEnabled &&
                                                    !isFormPreview && (
                                                        <DropdownMenuItem
                                                            as="button"
                                                            className="flex justify-left"
                                                            onClick={() => {
                                                                onCopy(
                                                                    uniqueId
                                                                );
                                                            }}
                                                            data-test="array-item-edit-button"
                                                        >
                                                            <Copy20 className="mr-2" />{' '}
                                                            {t(
                                                                'form-content:CopyLotDataTo'
                                                            )}
                                                            <p className="text-[12px] ml-3 mb-3 text-california">
                                                                BETA
                                                            </p>
                                                        </DropdownMenuItem>
                                                    )}
                                                {isDeletable && !isFormPreview && (
                                                    <DropdownMenuItem
                                                        as="button"
                                                        className="flex justify-left"
                                                        onClick={() =>
                                                            onDelete(uniqueId)
                                                        }
                                                        data-test="array-item-delete-button"
                                                    >
                                                        <TrashCan20 className="mr-2" />{' '}
                                                        {t(
                                                            'form-content:LabelDelete'
                                                        )}
                                                    </DropdownMenuItem>
                                                )}
                                            </DropdownMenu>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {isExpandable === true && (
                                <div
                                    {...(panel as PanelInterface<HTMLDivElement>)}
                                    className={cx(
                                        'h-auto overflow-hidden my-0 mx-6 transition-all duration-500 text-body border-t border-base-50',
                                        { 'mt-0 mx-6 mb-6': expanded }
                                    )}
                                >
                                    <ArrayElementPreview
                                        arrayElement={arrayElement}
                                        arrayPath={arrayPath}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

ArrayElementAccordion.displayName = 'ArrayElementAccordion';
ArrayElementAccordion.whyDidYouRender = true;
export default ArrayElementAccordion;
