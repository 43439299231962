import { SelectOption } from '../../types/generated/selectOption';

export const isSelectOption = (input: any): input is SelectOption =>
    typeof input === 'object' && 'value' in input;

export function isSelectOptionArrayState(
    state: React.Dispatch<React.SetStateAction<SelectOption[] | any>>
): state is React.Dispatch<React.SetStateAction<SelectOption[]>> {
    return typeof state === 'function' && typeof state.length !== 'undefined';
}

export function isStringState(
    state: React.Dispatch<React.SetStateAction<string | any>>
): state is React.Dispatch<React.SetStateAction<string>> {
    return typeof state === 'function' && typeof state.length !== 'undefined';
}

export function isString(item: any): item is string {
    return typeof item === 'string';
}
