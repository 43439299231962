/* eslint-disable camelcase */
import { FieldOrigin, FormWrapper } from '@mercell/form-react';
import parseFormDefinitionWrapper from '../../shared/mappedFormSchema';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReadOnlyComponent } from '../../components/ReadOnlyComponent';
import NotFoundPage from '../NotFound/NotFoundPage';
import { useFetchFormReadonly } from '../../hooks/formHooks/useFetchFormReadonly';
import { useParams } from 'react-router-dom';
import useFetchSections from '../../hooks/formHooks/useFetchSections';
import { useReactToPrint } from 'react-to-print';
import LoadingSpinner from '@mercell/loading-spinner-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppSelector } from '../../redux/hooks';

const FormFullPreviewPage: FC = () => {
    const { featureFlagUseUtcDatesOnly, featureFlagEnableTextAreaCounter } =
        useFlags();
    const { formId } = useParams<{
        formId: string;
    }>();
    useFetchSections(formId);
    const { formData, isErrorFetchingForm, isLoadingForm } =
        useFetchFormReadonly(formId);

    const reduxLocale = useAppSelector((state) => state.store.locale);
    const reduxDateFormat = useAppSelector((state) => state.store.dateFormat);

    const { t } = useTranslation(
        [
            'form-content',
            'list.cpv',
            'list.organisation-role',
            'list.organisation-subrole',
        ].concat(formData?.codelists ?? [])
    );
    const [isRendered, setIsRendered] = useState<boolean>(false);
    const componentRef = React.useRef<any>(null);

    const reactToPrintContent = React.useCallback(
        () => componentRef.current,
        []
    );

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: `form-${formId}`,
        removeAfterPrint: true,
        onAfterPrint: () => window.close(),
    });

    useEffect(() => {
        const form = document.getElementsByTagName('form')[0];
        const subgroupArr = document.getElementsByClassName('sub-group');

        if (form?.children) {
            for (let i = 0; i < form.children.length; i++) {
                form.children[i].classList.add('print-section');
            }
            setIsRendered(true);
        }
        if (subgroupArr.length > 0) {
            for (let i = 0; i < subgroupArr.length; i++) {
                if (subgroupArr[i].clientHeight > 1000) {
                    (subgroupArr[i] as HTMLDivElement).style.pageBreakInside =
                        'auto';
                }
            }
        }
    }, [formData]);

    if (!formId) return null;
    if (isLoadingForm) {
        return (
            <div className="col-span-full h-[calc(100vh-218px)] flex items-center justify-center">
                <LoadingSpinner />
            </div>
        );
    }
    if (isErrorFetchingForm)
        return (
            <NotFoundPage
                wrapperClass="col-span-full"
                errorCode={isErrorFetchingForm.status.toString()}
            />
        );

    if (!formData || !formData.formDefinition.length) {
        return <div className="col-span-full">No form in DB</div>;
    }
    const { formDefinition, values, isCompleted, noticeTimeZone } = formData;
    const schema = parseFormDefinitionWrapper(
        formDefinition,
        t,
        true,
        reduxDateFormat,
        false,
        reduxLocale,
        featureFlagUseUtcDatesOnly,
        noticeTimeZone,
        featureFlagEnableTextAreaCounter
    );

    (() => {
        if (isRendered) {
            handlePrint();
        }
    })();

    return (
        <div className="col-span-full">
            {!isRendered && (
                <div className="col-span-full h-[calc(100vh-218px)] flex items-center justify-center">
                    <LoadingSpinner />
                </div>
            )}
            <div className="col-span-full print-source" ref={componentRef}>
                {formDefinition?.length > 0 && values && (
                    <FormWrapper
                        onSubmit={() => undefined}
                        schema={schema}
                        formLabelProperties={{
                            className: 'text-h1 font-bold',
                        }}
                        subscription={{
                            dirtySinceLastSubmit: true,
                        }}
                        readOnlyFieldRender={(
                            content: any,
                            name: string,
                            restReadOnlyProperties,
                            fieldOrigin?: FieldOrigin,
                            elementProperties?: React.DetailedHTMLProps<
                                React.HTMLAttributes<HTMLElement>,
                                HTMLElement
                            >
                        ) => (
                            <ReadOnlyComponent
                                content={content}
                                name={name}
                                readOnlyProperties={restReadOnlyProperties}
                                fieldOrigin={fieldOrigin}
                                elementProperties={elementProperties}
                                t={t}
                                locale={reduxLocale}
                                noticeTimeZone={noticeTimeZone}
                            />
                        )}
                        autoSaveThrottleDelay={1000}
                        formTitle="" // "React Final Form"
                        initialValue={values}
                        isCompleted={isCompleted}
                    />
                )}
            </div>
        </div>
    );
};

FormFullPreviewPage.displayName = 'FormFullPreview';
FormFullPreviewPage.whyDidYouRender = true;
export default FormFullPreviewPage;
