enum LanguageCode {
    en = 'en_GB',
    bg = 'bg_BG',
    da = 'da_DK',
    de = 'de_DE',
    es = 'es_ES',
    et = 'et_EE',
    fi = 'fi_FI',
    fr = 'fr_FR',
    ga = 'ga_IE',
    is = 'is_IS',
    lt = 'lt_LT',
    lv = 'lv_LV',
    nb = 'nb_NO',
    nl = 'nl_NL',
    pl = 'pl_PL',
    ru = 'ru_RU',
    sv = 'sv_SE',
    'sv-FI' = 'sv_FI',
}
export function convertLanguageToCode(language: string): string | undefined {
    return (LanguageCode as Record<string, string>)[language];
}
