import {
    DefinitionElementType,
    UIDesignElementType,
} from '../types/generated/formDefinitionItem';
import { FormDefinitionItem } from '../types/generated/formRenderResult';
import cx from 'classnames';

export const LabelDefaultStyle = 'text-caption mb-1';
export const PreviewElementStyle =
    'read-only font-bold text-body bg-concrete p-3 rounded-default whitespace-pre-wrap';
const AccordionPreviewElementStyle = 'read-only font-bold text-body';
const DivDefaultStyle = 'pt-6 pl-14 print-field';
const HiddenDefaultStyle = 'pl-14';
const TextAreaDefaultElementClasses = 'h-36';

export const getStyleClasses = (
    obj: FormDefinitionItem,
    isAccordionContent = false
): {
    divStyle?: string;
    elementStyle?: string;
    labelStyle?: string;
    sectionStyle?: string;
} => {
    let divStyle;
    if (
        obj.type !== DefinitionElementType.Section &&
        obj.type !== DefinitionElementType.Hidden
    ) {
        const divDefaultStyle = isAccordionContent
            ? 'pl-14 print-field'
            : DivDefaultStyle;
        if (obj.type === DefinitionElementType.Array) {
            divStyle = divDefaultStyle.replace(' print-field', '');
        } else {
            divStyle = divDefaultStyle;
        }
    } else {
        divStyle = '';
    }

    if (obj.type === DefinitionElementType.Hidden) {
        divStyle = HiddenDefaultStyle;
    }

    let labelStyle = LabelDefaultStyle;

    let elementStyle =
        obj.type === DefinitionElementType.TextArea
            ? TextAreaDefaultElementClasses
            : '';

    const previewElementStyle = isAccordionContent
        ? AccordionPreviewElementStyle
        : PreviewElementStyle;
    if (obj.preview && obj.type !== DefinitionElementType.Checkbox)
        elementStyle = obj.elementStyle === '' ? '' : previewElementStyle;

    let sectionStyle = '';

    switch (obj.designElementType) {
        case UIDesignElementType.Section:
            labelStyle = 'text-h2 font-semibold border-mirage border-l-8 pl-4';
            sectionStyle = 'pt-16';
            divStyle = 'pl-4';
            if (!obj.label) {
                divStyle = '';
            }
            break;
        case UIDesignElementType.Subsection:
            labelStyle =
                'text-16 text-dove uppercase border-alto border-l-8 pl-6';
            divStyle = 'border-alto border-l-2 pt-2';
            sectionStyle = 'pt-10 sub-group';
            break;
        case UIDesignElementType.SubsectionHeader:
            labelStyle = 'text-16 text-dove uppercase pl-14';
            divStyle = 'pt-2';
            sectionStyle = 'pt-14 sub-group';
            break;
        case UIDesignElementType.LanguageBar:
            labelStyle = 'text-16 text-mirage mb-4';
            divStyle =
                'py-4 px-6 ml-4 flex items-center text-16 text-mirage mb-10 rounded-half border border-minsk text-mirage';
            break;
        case UIDesignElementType.LanguageIndicator:
            labelStyle =
                'text-16 text-dove uppercase border-alto border-l-8 pl-6';
            divStyle = 'absolute pl-5 print:hidden';
            elementStyle =
                'flex justify-center mt-6 w-7 h-7 rounded-half border border-alto title-small text-dove bg-alto';
            break;
        case UIDesignElementType.Role:
            divStyle =
                'grid grid-cols-2 gap-4 px-4 py-6 ml-14 border-t border-alto';
            break;
        case UIDesignElementType.WithheldPublication:
            divStyle = 'bg-athens rounded-default ml-14 pr-8 pb-6 mt-6';
            break;
        case UIDesignElementType.WithheldPublicationIndicator:
            divStyle = 'flex place-content-end mt-6';
            break;
        case UIDesignElementType.SubSectionInfo:
            divStyle = 'flex';
            break;
        default:
            break;
    }

    return {
        divStyle: obj.divStyle ?? divStyle,
        elementStyle: cx(
            obj.designElementType === UIDesignElementType.LanguageIndicator &&
                isAccordionContent
                ? 'mt-6'
                : obj.elementStyle,
            elementStyle
        ),
        labelStyle: obj.label !== '' ? obj.labelStyle ?? labelStyle : '',
        sectionStyle: obj.sectionStyle ?? sectionStyle,
    };
};

export const getStyleClassesForDesignType = (
    type: UIDesignElementType
): {
    divStyle?: string;
    labelStyle?: string;
    sectionStyle?: string;
} => {
    let divStyle = '';

    let labelStyle = LabelDefaultStyle;

    let sectionStyle = '';

    switch (type) {
        case UIDesignElementType.Section:
            labelStyle = 'text-h2 font-semibold border-mirage border-l-8 pl-4';
            sectionStyle = 'pt-16';
            divStyle = 'pl-4';
            break;
        case UIDesignElementType.Subsection:
            labelStyle =
                'text-16 text-dove uppercase border-alto border-l-8 pl-6';
            divStyle = 'border-alto border-l-2 pt-2';
            sectionStyle = 'pt-10';
            break;
        default:
            break;
    }

    return {
        divStyle,
        labelStyle,
        sectionStyle,
    };
};
