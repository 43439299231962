import React from 'react';
import { AxiosError } from 'axios';
import {
    removeAdminToken,
    removeOktaTokens,
} from '../shared/storageService/cookieService';
import OktaAuth from '@okta/okta-auth-js';
import { TFunction } from 'react-i18next';
import { toast } from 'react-toastify';
import { Warning32 } from '@carbon/icons-react';
import { i18n } from 'i18next';
import { NavigateFunction } from 'react-router-dom';

const findTranslationKey = (
    key: string,
    i18nObject: i18n,
    t: TFunction<string, unknown>
) => {
    const namespaces = i18nObject.options.ns as string[];

    let translationNameSpace;
    if (namespaces && namespaces?.length > 0) {
        const foundKey = namespaces.some((namespace) => {
            const translation = t(`${namespace}:${key}`);

            if (translation !== key) {
                translationNameSpace = namespace;
                return true;
            }
            return false;
        });
        if (foundKey) {
            return t(`${translationNameSpace}:${key}`);
        }
    }
};

const copyMessageToClipBoard = (text: string) => {
    navigator.clipboard.writeText(text);
};

const copyToClipboard = (text: string, t: TFunction<string, unknown>) => {
    copyMessageToClipBoard(text);
    const copiedMessage = t('form-content:ErrorMessageCopiedToClipboard');
    toast.success(copiedMessage, {
        onClose: () => copyMessageToClipBoard(copiedMessage),
    });
};

export const errorHandler = (
    error: AxiosError,
    oktaAuth: OktaAuth,
    restoreOriginalUri: (
        _oktaAuth: OktaAuth,
        originalUri: string
    ) => Promise<void>,
    t: TFunction<string, unknown>,
    i18nObject: i18n,
    navigate: NavigateFunction,
    copyErrorMessageMef2099?: boolean
) => {
    const oktaRoutesCondition =
        error.config.url?.includes('testapp/names/') ||
        error.config.url?.includes('testapp/names-dynamic/') ||
        error.config.url?.includes('testapp/selector/') ||
        error.config.url?.includes('form/selector/') ||
        error.config.url?.includes('rep/form/') ||
        error.config.url?.includes('configurator/');

    const adminRoutesCondition =
        (error.config.url?.includes('rep/form/') &&
            error.config.method === 'delete') ||
        (error.config.url?.includes('rep/form/selector/') &&
            error.config.method === 'get') ||
        error.config.url?.includes('rep/form/url/') ||
        error.config.url?.includes('rep/form/create/') ||
        error.config.url?.includes('rep/form/copy/');

    if (error.response?.status === 401 || error.response?.status === 403) {
        if (oktaRoutesCondition) {
            removeOktaTokens();
            navigate('/unauthorized');
        }
        if (adminRoutesCondition) {
            removeAdminToken();
        }
    } else if (!error.config.headers.SkipToast) {
        const { response } = error;

        let errorMessage: string;
        let errorMessageWithoutErrorId;
        if (response?.status && response?.status > 500) {
            errorMessage = t('toast-content:ToastInternalServerError');
        } else if (response?.data.message) {
            const translation = findTranslationKey(
                response.data.message,
                i18nObject,
                t
            );

            errorMessageWithoutErrorId = translation ? t(translation) : '';
            errorMessage =
                `${translation ? t(translation) : ''} \n` +
                `${response.data.errorId ? response.data.errorId : ''}`;
        } else if (typeof response?.data === 'string' && response.data !== '') {
            errorMessage = t(response.data);
        } else if (response?.data.title) {
            errorMessage = t(response.data.title);
        } else if (response?.data.detail) {
            errorMessage = t(response.data.detail);
        } else {
            errorMessage = t('toast-content:ToastUnexpectedError');
        }

        if (toast.isActive(error.config.url ?? '')) {
            toast.update(error.config.url ?? '', {
                render: (
                    <p className="line-clamp-3">{errorMessage.toString()}</p>
                ),
                type: 'error',
                isLoading: false,
                delay: 1000,
                autoClose: 10 * 1000,
                icon: <Warning32 />,
                closeOnClick: true,
                onClick: () => copyToClipboard(errorMessage, t),
                closeButton: copyErrorMessageMef2099,
            });
        } else {
            toast.error(
                <p className="line-clamp-3">{errorMessage.toString()}</p>,
                {
                    toastId: errorMessageWithoutErrorId ?? errorMessage,
                    autoClose: 10 * 1000,
                    onClick: () => copyToClipboard(errorMessage, t),
                    closeButton: copyErrorMessageMef2099,
                }
            );
        }
    }
    return error;
};
