import { CheckmarkFilled24, PendingFilled24 } from '@carbon/icons-react';
import React, { FC } from 'react';
import { TFunction } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { ConfiguratorCodelist } from '../../../types/generated/configuratorCodelist';
import { format, parseISO } from 'date-fns';
import { useAppSelector } from '../../../redux/hooks';

interface SingleCodeListProps {
    codelist: ConfiguratorCodelist;
    t: TFunction;
}

const SingleCodeList: FC<SingleCodeListProps> = ({ codelist, t }) => {
    const { versionName, versionId, country } = useParams<{
        versionName: string;
        versionId: string;
        country: string;
    }>();

    const url = `/configurator/${versionName}/${versionId}/codelists/codelistid/${
        codelist.id
    }${country ? `/${country}` : ''}`;

    const reduxLocale = useAppSelector((state) => state.store.locale);
    const reduxDateFormat = useAppSelector((state) => state.store.dateFormat);

    const lastChanged =
        codelist.updatedOn !== undefined
            ? format(
                  parseISO(codelist.updatedOn?.toString()),
                  reduxDateFormat?.DateTime,
                  {
                      locale: reduxLocale,
                  }
              )
            : '';
    return (
        <div className="p-5 pl-3 flex w-full items-center">
            <Link to={url} className="w-9/12 flex whitespace-normal">
                ({t(codelist.businessTermId ?? '')}) {t(codelist.name ?? '')}
            </Link>
            <div className="w-4/12 flex justify-center">
                {codelist.isFinalized ? (
                    <div className="flex">
                        <CheckmarkFilled24 color="green" />
                        <p className="pl-2">
                            {t('form-configurator:Finalized')}
                        </p>
                    </div>
                ) : (
                    <div className="flex">
                        <PendingFilled24 color="orange" />
                        <p className="pl-2">
                            {t('form-configurator:InProgress')}
                        </p>
                    </div>
                )}
            </div>
            <div className="w-4/12 flex justify-center">
                <div className="flex">
                    <p className="pl-2">{lastChanged}</p>
                </div>
            </div>
            <div className="w-4/12 flex justify-center">
                <div className="flex">
                    <p className="pl-2">{codelist.updatedBy?.split('@')[0]}</p>
                </div>
            </div>
        </div>
    );
};

export default SingleCodeList;
