import React from 'react';
import AdminAuthWrapper from '../../authorization/AdminAuthWrapper';
import OktaAuthWrapper from '../../authorization/OktaAuthWrapper';
import CreateForm from './CreateForm';

const CreateFormPage = () => (
    <OktaAuthWrapper>
        <AdminAuthWrapper>
            <CreateForm />
        </AdminAuthWrapper>
    </OktaAuthWrapper>
);

export default CreateFormPage;
