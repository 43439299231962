import Checkbox from '@mercell/checkbox-react';
import React, { FC, useState } from 'react';
import { TFunction } from 'react-i18next';
import { ConfigurationCodelistOptionVisibility } from '../../../types/generated/configuratorCodelist';

interface SingleCodelistConfiguratorProps {
    option: ConfigurationCodelistOptionVisibility;
    t: TFunction;
    changeVisibility: (code: string, isHidden: boolean) => void;
}

const SingleCodelistConfigurator: FC<SingleCodelistConfiguratorProps> = ({
    option,
    t,
    changeVisibility,
}) => {
    const [isHidden, setIsHidden] = useState<boolean>(option.isHidden);

    const onChange = () => {
        setIsHidden(!isHidden);
        changeVisibility(option.code, !isHidden);
    };

    return (
        <div className="p-5 flex items-center">
            <div className="w-1/12 whitespace-normal">
                <p>{option.code}</p>
            </div>
            <div className="w-2/12 flex justify-center">
                <Checkbox
                    id={option.code}
                    checked={isHidden}
                    onChange={onChange}
                />
            </div>
            <p className="w-3/12 break-words">{option.translationKey}</p>
            <p className="w-6/12 ">{t(option.translationKey ?? '')}</p>
        </div>
    );
};

export default SingleCodelistConfigurator;
