import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Button from '@mercell/button-react';
import { Input, InputContainer, InputRightElement } from '@mercell/input-react';
import { Launch32, Search32 } from '@carbon/icons-react';
import Breadcrumbs from '@mercell/breadcrumbs-react';
import { useTranslation } from 'react-i18next';
import OktaAuthWrapper from '../../../authorization/OktaAuthWrapper';
import SingleCodelistConfigurator from './SingleCodelistConfigurator';
import useFetchCodelist from '../../../hooks/configuratorHooks/useFetchCodelist';
import { ConfigurationCodelistOptionVisibility } from '../../../types/generated/configuratorCodelist';
import setCodeVisibility from '../../../apiCalls/configurator/setCodeVisibility';
import finalizeCodelist from '../../../apiCalls/configurator/finalizeCodelist';
import LoadingSpinner from '@mercell/loading-spinner-react';

const CodelistConfiguratorPage: FC = () => {
    const { versionName, versionId, codelistId, country } = useParams<{
        versionName: string;
        versionId: string;
        codelistId: string;
        country: string;
    }>();
    const [codelistOptions, setCodelistOptions] =
        useState<ConfigurationCodelistOptionVisibility[]>();
    const { codelist, isFetchingCodelist, setStateCodelist } = useFetchCodelist(
        versionId ?? '',
        codelistId ?? ''
    );

    const { t } = useTranslation([
        'form-configurator',
        'list.country',
        `list.${codelist?.name}`,
    ]);

    const navigate = useNavigate();

    const changeVisibility = async (code: string, isHidden: boolean) => {
        if (codelist && versionId) {
            const changedOptions = codelist?.options?.map(
                (opt: ConfigurationCodelistOptionVisibility) => {
                    if (opt.code === code) {
                        opt.isHidden = isHidden;
                    }
                    return opt;
                }
            );
            codelist.options = changedOptions;
            setCodeVisibility(versionId, codelist);
        }
    };

    const onChangeFilter = (e: ChangeEvent<HTMLInputElement>) => {
        const options = codelist?.options?.filter(
            (option) =>
                option.code
                    .toLocaleLowerCase()
                    .includes(e.currentTarget.value.toLocaleLowerCase()) ||
                t(option.translationKey ?? '')
                    .toLocaleLowerCase()
                    .includes(e.currentTarget.value.toLocaleLowerCase())
        );
        setCodelistOptions(options);
    };

    const onFinalize = async () => {
        if (!codelist) return null;

        const data = await finalizeCodelist(codelistId ?? '');

        if (data)
            navigate(
                `/configurator/${versionName}/${versionId}/codelists${
                    country ? `/${country}` : ''
                }`
            );
    };

    useEffect(() => {
        if (codelist) {
            setStateCodelist(codelist);
            setCodelistOptions(codelist.options);
        }
    }, [setStateCodelist, codelist]);

    return (
        <OktaAuthWrapper>
            {!codelist && isFetchingCodelist ? (
                <div className="col-span-full h-full flex items-center justify-center">
                    <LoadingSpinner />
                </div>
            ) : (
                <div className="col-span-full justify-self-center min-w-[80%] max-w-[80%]">
                    <div className="mb-10">
                        <Breadcrumbs>
                            <Link to="/configurator">Form configurator</Link>
                            <Link
                                to={`/configurator/${versionName}/${versionId}/codelists${
                                    country ? `/${country}` : ''
                                }`}
                            >
                                {versionName} {t(country ?? '')}
                            </Link>
                            <Link
                                to={window.location.pathname}
                                className="pointer-events-none"
                            >
                                {codelist?.businessTermId} {codelist?.name}
                            </Link>
                        </Breadcrumbs>
                    </div>
                    <h2 className="mb-10">Tailorable codes</h2>
                    <div className="flex justify-between">
                        <div className="mb-10 w-5/12">
                            <InputContainer>
                                <Input
                                    placeholder={t('form-configurator:Search')}
                                    onChange={onChangeFilter}
                                />
                                <InputRightElement>
                                    <Search32 />
                                </InputRightElement>
                            </InputContainer>
                        </div>
                        <div>
                            <Button
                                iconSettings={{ Icon: Launch32 }}
                                scheme="secondary"
                                onClick={() =>
                                    window.open(
                                        'https://mercell.crowdin.com/u/projects/45'
                                    )
                                }
                            >
                                {t('form-configurator:OpenCrowdinProject')}
                            </Button>
                        </div>
                    </div>

                    <div className="flex text-dove text-caption p-5 border-b border-alto">
                        <p className="w-1/12">
                            {t('form-configurator:Code').toUpperCase()}
                        </p>
                        <p className="w-2/12 text-center">
                            {t('form-configurator:Hidden').toUpperCase()}
                        </p>
                        <p className="w-3/12">
                            {t(
                                'form-configurator:TranslationKey'
                            ).toUpperCase()}
                        </p>
                        <p className="w-6/12">
                            {t(
                                'form-configurator:TranslationString'
                            ).toUpperCase()}
                        </p>
                    </div>
                    <div className="overflow-auto" style={{ height: '45vh' }}>
                        {codelistOptions?.map((option) => (
                            <SingleCodelistConfigurator
                                key={option.code + option.translationKey}
                                option={option}
                                t={t}
                                changeVisibility={changeVisibility}
                            />
                        ))}
                    </div>
                    <div className="flex justify-end my-12">
                        <Button
                            className="mr-4"
                            scheme="secondary"
                            onClick={() =>
                                navigate(
                                    `/configurator/${versionName}/${versionId}/codelists${
                                        country ? `/${country}` : ''
                                    }`
                                )
                            }
                        >
                            {t('form-configurator:Back')}
                        </Button>
                        <Button scheme="primary" onClick={onFinalize}>
                            {t('form-configurator:Finalize')}
                        </Button>
                    </div>
                </div>
            )}
        </OktaAuthWrapper>
    );
};

export default CodelistConfiguratorPage;
