import React from 'react';
import {
    DefinitionElementType,
    FormDefinitionItem,
} from '../../types/generated/formDefinitionItem';
import { TFunction, useTranslation } from 'react-i18next';
import { FieldLabel } from '../../components/FieldLabel';

export const mapLabel =
    (
        formDefinition: FormDefinitionItem,
        labelStyle: string | undefined,
        isFormPreview: boolean,
        t: TFunction<string, unknown>
    ):
        | ((
              name: string,
              reApplyRequiredIndicator?: boolean
          ) => React.ReactNode)
        | undefined =>
    (name: string, reApplyRequiredIndicator?: boolean) => {
        let tooltip;
        const { i18n } = useTranslation();
        if (
            formDefinition.type !== DefinitionElementType.Hidden &&
            formDefinition.type !== DefinitionElementType.Section
        ) {
            if (formDefinition.tooltip) {
                const labelTooltip = formDefinition.tooltip;
                tooltip = i18n.exists(labelTooltip)
                    ? t(labelTooltip)
                    : undefined;
            } else {
                const labelTooltip = `form-content:${formDefinition.name}Tooltip`;
                tooltip = i18n.exists(labelTooltip)
                    ? t(labelTooltip)
                    : undefined;
            }
        }

        if (
            formDefinition.type === DefinitionElementType.Time ||
            formDefinition.type === DefinitionElementType.Datepicker
        ) {
            const localTimeTooltip = t('form-content:DateLocalTimeZone');
            tooltip = isFormPreview
                ? localTimeTooltip
                : `${
                      tooltip
                          ? `${tooltip} \n${localTimeTooltip} `
                          : localTimeTooltip
                  }`;
        }

        const parsedBusinessTerm = (businessTerm: string | undefined) => {
            if (
                businessTerm &&
                businessTerm.indexOf('INTERNAL-Readonly') > -1
            ) {
                const splitBusinessTerm = businessTerm.split('-');
                return `${splitBusinessTerm[2]}-${splitBusinessTerm[3]}-notice`;
            }
            return businessTerm;
        };

        return (
            formDefinition.type !== DefinitionElementType.Array &&
            !(
                formDefinition.type === DefinitionElementType.Checkbox &&
                !formDefinition.options
            ) && (
                <FieldLabel
                    name={name}
                    labelProperties={{
                        className: labelStyle,
                    }}
                    content={
                        formDefinition.type !== DefinitionElementType.Hidden
                            ? t(formDefinition.label || '')
                            : undefined
                    }
                    useLegendTag={
                        formDefinition.type ===
                            DefinitionElementType.Checkbox ||
                        formDefinition.type === DefinitionElementType.Radio
                    }
                    fieldType={formDefinition.type}
                    businessTerm={
                        formDefinition.type !== DefinitionElementType.Hidden
                            ? parsedBusinessTerm(formDefinition.businessTerm)
                            : undefined
                    }
                    tooltipContent={tooltip}
                    tooltipWrapperProperties={{}}
                    showRequiredIndicator={formDefinition.rules?.some(
                        (rule) => rule.function === 'required'
                    )}
                    reApplyRequiredIndicator={reApplyRequiredIndicator}
                    isFormPreview={isFormPreview}
                />
            )
        );
    };
