import React, { FC, Ref, forwardRef } from 'react';
import { Catalog20, EarthFilled20 } from '@carbon/icons-react';

import Tooltip from '@mercell/tooltip-react';
import Link from '@mercell/link-react';
import { TFunction } from 'react-i18next';

interface FooterInterface {
    noticeTimeZone?: string;
    t: TFunction<string, unknown>;
    ref?: Ref<HTMLDivElement>;
}

export const Footer: FC<FooterInterface> = forwardRef((props, ref: any) => {
    const { noticeTimeZone, t } = props;
    return (
        <footer
            ref={ref}
            className="bg-white col-span-full w-full shadow-[0px_-1px_4px_rgba(114,114,114,0.15)] bottom-0 "
        >
            <div className="flex items-center justify-between md:order-2 min-h-[60px] max-h-[60px] w-full">
                {noticeTimeZone && (
                    <Tooltip
                        placement="right"
                        message={t("form-content:FooterTimeZoneTooltip")}
                    >
                        <div className="flex items-center justify-center align-middle ml-4">
                            <EarthFilled20 />
                            <p className="ml-2">{noticeTimeZone}</p>
                        </div>
                    </Tooltip>
                )}

                <div className="flex items-center justify-center align-middle mr-4 ml-auto">
                    <Catalog20 />
                    <Link
                        externalLink
                        className="ml-2"
                        href="https://mercell.atlassian.net/wiki/spaces/MFRN/pages/196004446233/Release+notes"
                        target="_blank"
                    >
                        {t('form-content:ReleaseNotes')}
                    </Link>
                </div>
            </div>
        </footer>
    );
});
