import { CustomFieldStructure, FieldStructure } from '@mercell/form-react';
import { FormDefinitionItem } from '../../types/generated/formDefinitionItem';
import { InformationBoxComponent } from '../../components/InformationBoxComponent';

export const mapInformationBoxField = (
    formDefinition: FormDefinitionItem,
    genericFieldStructure: FieldStructure
): CustomFieldStructure => ({
    ...genericFieldStructure,
    type: 'custom',
    render: InformationBoxComponent,
    label: formDefinition.label,
    labelProperties: formDefinition.labelStyle,
});
