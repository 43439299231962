import React, { FC } from 'react';
import RadioButton from '@mercell/radio-button-react';
import { Dropdown } from '@mercell/dropdown-react';
import { SelectOption } from '../../types/generated/selectOption';
import { TFunction } from 'react-i18next';

interface UserCustomizationProps {
    t: TFunction;
    platformNames: string[];
    onChangePlatformName: (newValue: SelectOption) => void;
    onChangeFormUserRights: (e: React.ChangeEvent<HTMLInputElement>) => void;
    formUserRights: number;
}

const UserCustomization: FC<UserCustomizationProps> = ({
    t,
    platformNames,
    onChangePlatformName,
    onChangeFormUserRights,
    formUserRights,
}) => (
    <div className="grid grid-cols-3 w-full  gap-y-10 mt-8">
        <div className="col-span-full flex justify-between items-center">
            <h4>{t('form-content:PlatformName')}</h4>
            <div className="w-4/6 mr-2">
                {platformNames && (
                    <Dropdown
                        options={platformNames?.map((platformName: string) => ({
                            value: platformName,
                            label: platformName,
                        }))}
                        defaultValue={{
                            value: platformNames[0],
                            label: platformNames[0],
                        }}
                        onChange={(newValue: unknown) =>
                            onChangePlatformName(newValue as SelectOption)
                        }
                    />
                )}
            </div>
        </div>
        <div className="flex justify-between mr-2 col-span-full">
            <h4 className="w-3/4">{t('form-content:UserRights')}</h4>
            <div className="w-1/4 flex justify-around">
                <RadioButton
                    id="btnRead"
                    name="rights-group"
                    value="1"
                    checked={formUserRights === 1}
                    onChange={onChangeFormUserRights}
                >
                    {t('form-content:Read')}
                </RadioButton>
                <RadioButton
                    id="btnWrite"
                    name="rights-group"
                    value="2"
                    checked={formUserRights === 2}
                    onChange={onChangeFormUserRights}
                >
                    {t('form-content:Write')}
                </RadioButton>
            </div>
        </div>
    </div>
);

export default UserCustomization;
