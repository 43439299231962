import axios, { AxiosResponse } from 'axios';
import { ConfiguratorFormDefinition } from '../../types/generated/configuratorFormDefinition';
import { toast } from 'react-toastify';
import { TFunction } from 'react-i18next';

export const saveFormDefinitionForPreview = (
    formDefinitionId: string,
    formDefinition: ConfiguratorFormDefinition,
    t: TFunction<string, unknown>
) =>
    axios
        .post(
            `/configurator/definition/${formDefinitionId}/bts`,
            formDefinition
        )
        .then(
            (
                res: AxiosResponse<{
                    formDefinitionId: string;
                    errors: { [key: string]: string };
                    apiError: string;
                }>
            ) => res.data
        )
        .catch((error) => {
            const errorMessage = t(
                'form-content:VersionEditionValidationMessage'
            );
            toast.error(errorMessage, { toastId: errorMessage });
            return { formDefinitionId, errors: {}, apiError: error.data };
        });
