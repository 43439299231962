import React, { FC } from 'react';
import OktaAuthWrapper from '../../../authorization/OktaAuthWrapper';
import LoadingSpinner from '@mercell/loading-spinner-react';
import useFetchPublicationSiteConfigurations from '../../../hooks/administrativeHooks/useFetchPublicationSiteConfigurations';
import { PublicationSiteCard } from './PublicationSiteCard';
import { useTranslation } from 'react-i18next';

const PublicationSiteConfigurationsPage: FC = () => {
    const {
        publicationSiteConfigurations,
        isLoadingPublicationSiteConfigurations,
        fetchPublicationSiteConfigurations,
    } = useFetchPublicationSiteConfigurations();

    const { t } = useTranslation('form-content');
    return (
        <OktaAuthWrapper>
            {isLoadingPublicationSiteConfigurations ? (
                <div className="col-span-full h-full flex items-center justify-center">
                    <LoadingSpinner />
                </div>
            ) : (
                <div className="col-span-full justify-self-center self-center min-w-full xl:min-w-[1000px] xl:max-w-[1000px] lg:max-w-[900px] lg:min-w-[900px]">
                    {publicationSiteConfigurations?.map(
                        (publicationSite, index) => (
                            <PublicationSiteCard
                                key={index}
                                t={t}
                                fetchPublicationSiteConfigurations={
                                    fetchPublicationSiteConfigurations
                                }
                                publicationSite={publicationSite}
                            />
                        )
                    )}
                </div>
            )}
        </OktaAuthWrapper>
    );
};

export default PublicationSiteConfigurationsPage;
