import React, { FC, ReactNode } from 'react';
import cx from 'classnames';

export interface ProgressBarInterface {
    textContent?: ReactNode;
    wrapperProps?: JSX.IntrinsicElements['div'];
    textProps?: JSX.IntrinsicElements['p'];
    progressBarProps?: JSX.IntrinsicElements['div'];
    totalAmount: number;
    progressMadeAmount: number;
    showText?: boolean;
}
export const ProgressBar: FC<ProgressBarInterface> = ({
    wrapperProps = {},
    textProps = {},
    progressBarProps = {},
    textContent,
    progressMadeAmount,
    totalAmount,
    showText = true,
}) => {
    const progressInPct = `${((progressMadeAmount / totalAmount) * 100).toFixed(
        0
    )}%`;
    return (
        <div
            {...wrapperProps}
            className={cx(
                'py-4 px-6 rounded-default',
                progressInPct === '100%' ? 'bg-frostee' : 'bg-magnolia',
                wrapperProps.className
            )}
        >
            {showText && (
                <p
                    {...textProps}
                    className={cx('font-medium', textProps?.className)}
                >
                    {progressMadeAmount}/{totalAmount} {textContent}
                </p>
            )}
            <div
                {...progressBarProps}
                className={cx(
                    'relative w-full h-2 bg-alto rounded-default mt-1',
                    progressInPct
                )}
            >
                <div
                    {...progressBarProps}
                    style={{ width: progressInPct }}
                    className={cx(
                        'h-2 bg-studio rounded-default',
                        progressInPct === '100%'
                            ? 'bg-tropical-rain-forest'
                            : 'bg-studio',
                        progressBarProps?.className
                    )}
                />
            </div>
        </div>
    );
};
