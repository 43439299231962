import React, { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import SingleNotice from './SingleNotice';
import { ConfiguratorFormDefinition } from '../../../types/generated/configuratorFormDefinition';
import saveAllowedDefinition from '../../../apiCalls/configurator/saveAllowedDefinitions';
import { TFunction } from 'i18next';

const TabNotices: FC<{
    definitionFields?: ConfiguratorFormDefinition[];
    t: TFunction;
}> = ({ definitionFields, t }) => {
    const { versionId, country } = useParams<{
        versionId: string;
        country: string;
    }>();

    const sortableDefinitionFields = definitionFields?.sort(
        (a, b) => Number(a.noticeSubtype) - Number(b.noticeSubtype)
    );

    const divRefs = useRef<HTMLDivElement[]>([]);
    const [focusIndex, setFocusIndex] = useState<number>(-1);

    useEffect(() => {
        const itemIndex = Number(
            JSON.parse(localStorage.getItem('listIndex') ?? '-1')
        );
        if (itemIndex > -1) {
            setFocusIndex(itemIndex);
        }
    }, []);

    const onChangeAllowed = async (
        value: boolean,
        definitionId: string,
        setError: React.Dispatch<React.SetStateAction<string | undefined>>
    ) => {
        const payload: ConfiguratorFormDefinition = {
            id: definitionId,
            isAllowed: value,
            isFinalized: false,
            isBaseDefinition: false,
            versionId: undefined,
            versionUniqueId: undefined,
            directiveKey: undefined,
            formTypeKey: undefined,
            noticeTypeKey: undefined,
            noticeSubtype: undefined,
            fields: undefined,
            activationDate: undefined,
            updatedBy: undefined,
            updatedOn: undefined,
        };

        const data = await saveAllowedDefinition(
            versionId ?? '',
            payload,
            setError
        );

        return data;
    };

    const handleClick = (index: number) => {
        localStorage.setItem('listIndex', JSON.stringify(index));
        removeListIndex();
    };

    const removeListIndex = () => {
        const myinterval = 15 * 60 * 1000; // 15 min interval
        setInterval(() => {
            localStorage.removeItem('listIndex');
        }, myinterval);
    };

    window.onbeforeunload = () => {
        localStorage.removeItem('listIndex');
    };

    if (!definitionFields) return <div>No definitions fields</div>;
    return (
        <div>
            <div className="flex text-dove text-caption p-5 pl-0 justify-between border-b border-alto break-words">
                <p className="w-1/12 flex justify-center">
                    {t('form-configurator:Allowed').toUpperCase()}
                </p>
                <p className="w-7/12 pl-5">
                    {t('form-configurator:Notices').toUpperCase()}
                </p>
                <p className="w-4/12 flex justify-center">
                    {t('form-configurator:Status').toUpperCase()}
                </p>
                <p className="w-6/12 flex justify-center">
                    {t('form-configurator:LastChanged').toUpperCase()}
                </p>
                <p className="w-6/12 flex justify-center">
                    {t('form-configurator:User').toUpperCase()}
                </p>
            </div>
            <div className="overflow-auto" style={{ height: '40vh' }}>
                {sortableDefinitionFields?.map(
                    (formDefinition: ConfiguratorFormDefinition, i: number) => (
                        <div
                            ref={(element: never) => {
                                divRefs.current[i] = element;
                                divRefs.current[focusIndex]?.focus();
                            }}
                            className="cursor-pointer"
                            onClick={() => handleClick(i)}
                            onKeyDown={() => handleClick(i)}
                            role="button"
                            tabIndex={0}
                            key={formDefinition.id}
                        >
                            <SingleNotice
                                onChangeAllowed={onChangeAllowed}
                                country={country}
                                key={formDefinition.id}
                                formDefinition={formDefinition}
                                t={t}
                            />
                        </div>
                    )
                )}
            </div>
        </div>
    );
};

export default TabNotices;
